import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
// const API_URL = ""

// const API_URL = "https://api-dev.katlax.co"
const API_URL = process.env.REACT_APP_DATABASEURL

const axiosApi = axios.create({
  baseURL: API_URL,
})

var obj = JSON.parse(localStorage.getItem("authUser"))
if (obj && obj?.auth.token) {
  //console.log("Bearer " + obj.data.auth.token)
  axiosApi.defaults.headers.common["Authorization"] = "Bearer " + obj.auth.token
}
// axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data)
    .catch(error => error.response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(error => error.response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(error => error.response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(error => error.response.data)
}

export async function postFormData(url, data) {
  return axiosApi
    .post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(response => response.data)
    .catch(error => error.response.data)
}
