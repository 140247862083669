import React from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Form,
  Label,
  Input,
  Modal,
  Button,
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import * as Yup from "yup"
import CustomInput from "Custom/CustomInput"
import { Field, FormikProvider, useFormik } from "formik"
import CustomSelect from "Custom/CustomSelect"
import CustomFlatpickr from "Custom/CustomFlatpicker"
import { useState } from "react"
import { useNavigate, Link } from "react-router-dom"
import { Spin } from "antd"

const AddInvoice = props => {
  const navigate = useNavigate()
  const [modal, setModal] = useState(false)
  const [invoiceNoModal, setInvoiceModal] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const initialItemDetails = {
    ItemName: "",
    Quantity: "",
    Rate: "",
    Tax: "",
    Amount: "",
  }
  const validationSchema = Yup.object().shape({
    CustomerName: Yup.string().required("Customer Name is a required field."),
    PlaceOfSupply: Yup.string().required(
      "Place Of Supply is a required field."
    ),
    Invoice: Yup.string().required("Invoice is a required field."),
    Date: Yup.string().required("Date is required field."),
    SalesOrderNumber: Yup.string().required(
      "Sales Order Number is required field"
    ),
  })

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CustomerName: "",
      PlaceOfSupply: "",
      Invoice: "",
      ShipmentOrder: "",
      Date: "",
      ListOfItemDetails: [initialItemDetails],
      SalesOrderNumber: "",
    },
    validateOnBlur: false,
  })

  const {
    setFieldValue,
    values: { ListOfItemDetails },
  } = formik

  const handleValidSubmit = values => {}

  const delete_ItemDetails = index => {
    setFieldValue(
      "ListOfItemDetails",
      ListOfItemDetails.filter((element, i) => i !== index)
    )
  }

  const AddItemDetails = () => {
    setFieldValue("ListOfItemDetails", [
      ...ListOfItemDetails,
      {
        ItemName: "",
        Quantity: "",
        Rate: "",
        Tax: "",
        Amount: "",
      },
    ])
  }
  const SalesOrderNumberOption = [
    { label: "SO-00001", value: 1 },
    { label: "SO-00002", value: 2 },
    { label: "SO-00003", value: 3 },
  ]

  const TaxOption = [
    { label: "UGST [12%]", value: 1 },
    { label: "IGST [12%]", value: 2 },
    { label: "SGST [12%]", value: 3 },
  ]
  const CustomerNameOption = [
    { label: "Deep", value: 1 },
    { label: "Deep", value: 2 },
    { label: "Deep", value: 3 },
  ]

  const ItemNameOption = [
    { label: "Raw Material 1", value: 1 },
    { label: "Raw Material 2", value: 2 },
    { label: "Raw Material 3", value: 3 },
  ]

  const initialListofInvoiceNumber = {
    Prefix: "",
    NextNumber: "",
    Default: false,
    AddManually: false,
  }

  const validationSchemaInvoiceNo = Yup.object().shape({})

  const formikInvoiceNo = useFormik({
    validationSchema: validationSchemaInvoiceNo,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitInvoiceNo(values, formikHelpers),
    initialValues: {
      Setting: false,
      AddManually: false,
      ListofInvoiceNumber: [initialListofInvoiceNumber],
    },
    validateOnBlur: false,
  })

  const {
    values: { Setting, ListofInvoiceNumber, AddManually },
  } = formikInvoiceNo

  const handleValidSubmitInvoiceNo = values => {}

  const delete_InvoiceNumber = index => {
    formikInvoiceNo.setFieldValue(
      "ListofInvoiceNumber",
      ListofInvoiceNumber.filter((element, i) => i !== index)
    )
  }

  const AddInvoiceNumber = () => {
    formikInvoiceNo.setFieldValue("ListofInvoiceNumber", [
      ...ListofInvoiceNumber,
      {
        Prefix: "",
        NextNumber: "",
        Default: false,
      },
    ])
  }

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{"Add Shipment"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            <Row className="mt-3">
              <Col lg={6}>
                <div className="mt-2" style={{ fontSize: "large" }}>
                  <b>Add Invoice</b>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="mt-3">
                        <FormikProvider value={formik}>
                          <Form onSubmit={formik.handleSubmit}>
                            <Row>
                              <Col lg={2}>
                                <div
                                  className="mt-2"
                                  style={{ color: "#000000" }}
                                >
                                  Customer Name{" "}
                                  <span className="text-danger">*</span>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <Field
                                  name="CustomerName"
                                  placeholder={"Enter Customer Name"}
                                  component={CustomInput}
                                  // options={CustomerNameOption}
                                  readOnly
                                  value={"Deep"}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={2}></Col>
                              <Col lg={3}>
                                <Row>
                                  <Col lg={12}>BILLING ADDRESS</Col>
                                  {/* <Col lg={12}>
                                  <Link to="#">+ Add new address</Link>
                                </Col> */}
                                </Row>
                              </Col>
                              <Col lg={3}>
                                <Row>
                                  <Col lg={12}>SHIPPING ADDRESS</Col>
                                  {/* <Col lg={12}>
                                  <Link to="#">+ Add new address</Link>
                                </Col> */}
                                </Row>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col lg={2}></Col>
                              <Col lg={6}>
                                GST Treatment: &nbsp;
                                <text style={{ color: "black" }}>
                                  Register Bussiness - Regular
                                </text>
                                &nbsp;
                                <Link to="#">
                                  <i className="ti-marker-alt"></i>
                                </Link>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col lg={2}>
                                <div
                                  className="mt-2"
                                  style={{ color: "#000000" }}
                                >
                                  Sales Order Number{" "}
                                  <span className="text-danger">*</span>
                                </div>
                              </Col>
                              <Col lg={4} className="mt-0">
                                {/* <Field
                                name="SalesOrderNumber"
                                placeholder={"Select Sales Order Number"}
                                component={CustomSelect}
                                options={SalesOrderNumberOption}
                                // onChange={() => {
                                //   setModal(true)
                                // }}
                                isMulti
                              /> */}
                                <Button
                                  onClick={() => {
                                    setModal(true)
                                  }}
                                >
                                  Select Sales Order Number
                                </Button>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col lg={2}>
                                <div
                                  className="mt-2"
                                  style={{ color: "#000000" }}
                                >
                                  Place of Supply{" "}
                                  <span className="text-danger">*</span>
                                </div>
                              </Col>
                              <Col lg={4}>
                                <Field
                                  type="text"
                                  name="PlaceOfSupply"
                                  value={"[AS] Assam"}
                                  placeholder={"Enter Place Of Supply"}
                                  component={CustomInput}
                                  readOnly
                                />
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col lg={2}>
                                <div
                                  className="mt-2"
                                  style={{ color: "#000000" }}
                                >
                                  Invoice#{" "}
                                  <span className="text-danger">*</span>
                                </div>
                              </Col>
                              <Col lg={4}>
                                <div className="d-flex">
                                  <Field
                                    type="text"
                                    name="Invoice"
                                    placeholder={"Enter Invoice Number"}
                                    component={CustomInput}
                                    style={{ textTransform: "Uppercase" }}
                                  />
                                  &nbsp; &nbsp;
                                  <Link
                                    onClick={() => {
                                      setInvoiceModal(true)
                                    }}
                                    className="mt-2"
                                    title="Edit"
                                  >
                                    <i className="ti-settings"></i>
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={2}>
                                <div
                                  className="mt-2"
                                  style={{ color: "#000000" }}
                                >
                                  Date <span className="text-danger">*</span>
                                </div>
                              </Col>
                              <Col lg={4}>
                                <Field
                                  type="text"
                                  name="Date"
                                  placeholder={"dd/mm/yyyy"}
                                  component={CustomFlatpickr}
                                  options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "d/m/Y",
                                  }}
                                />
                              </Col>
                            </Row>
                            <hr></hr>
                            <div className="table-responsive abc">
                              <table className="table table-bordered mb-0">
                                <thead>
                                  <tr className="table-light">
                                    <th style={{ minWidth: "450px" }}>
                                      Item Detail
                                    </th>
                                    <th style={{ minWidth: "150px" }}>
                                      Quantity
                                    </th>
                                    <th style={{ minWidth: "150px" }}>Rate</th>

                                    <th style={{ minWidth: "150px" }}>Tax</th>
                                    <th style={{ minWidth: "150px" }}>
                                      Amount
                                    </th>
                                    <th
                                      style={{
                                        borderBlockColor: "#ffffff00",
                                        borderColor: "#ffffff00",
                                        backgroundColor: "#ffffff00",
                                      }}
                                    ></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ListOfItemDetails &&
                                    ListOfItemDetails.map(
                                      (itemDetail, index) => (
                                        <tr>
                                          <td>
                                            <Row>
                                              <Col lg={1} md={1} sm={1}>
                                                <img
                                                  src={
                                                    "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png"
                                                  }
                                                  alt=""
                                                  className="avatar-xs rounded-square"
                                                  style={{
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                              </Col>
                                              &nbsp; &nbsp;
                                              <Col lg={10} md={10} sm={10}>
                                                <Row>
                                                  <Col lg={11} md={11} sm={11}>
                                                    <Field
                                                      name={`ListOfItemDetails.[${index}].ItemName`}
                                                      placeholder={
                                                        "Select Item"
                                                      }
                                                      component={CustomSelect}
                                                      options={ItemNameOption}
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </Row>
                                          </td>
                                          <td>
                                            <Row>
                                              <Col lg={12}>
                                                <Field
                                                  type="number"
                                                  name={`ListOfItemDetails.[${index}].Quantity`}
                                                  placeholder={"Quantity"}
                                                  component={CustomInput}
                                                  readOnly
                                                />
                                              </Col>
                                            </Row>
                                          </td>
                                          <td>
                                            <Field
                                              type="number"
                                              name={`ListOfItemDetails.[${index}].Rate`}
                                              placeholder={"Rate"}
                                              component={CustomInput}
                                            />
                                          </td>

                                          <td>
                                            <Field
                                              name={`ListOfItemDetails.[${index}].Tax`}
                                              placeholder={"Tax"}
                                              component={CustomSelect}
                                              options={TaxOption}
                                            />
                                          </td>
                                          <td>
                                            <Field
                                              type="number"
                                              name={`ListOfItemDetails.[${index}].Amount`}
                                              placeholder={"Amount"}
                                              component={CustomInput}
                                            />
                                          </td>
                                          <td
                                            style={{
                                              borderBlockColor: "#ffffff00",
                                              borderColor: "#ffffff00",
                                            }}
                                          >
                                            <i
                                              class="fas fa-times-circle"
                                              style={{
                                                color: "red",
                                                marginTop: "12px",
                                              }}
                                              onClick={() => {
                                                delete_ItemDetails(index)
                                              }}
                                            ></i>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                </tbody>
                              </table>
                            </div>{" "}
                            <hr></hr>
                            <Row className="mt-3">
                              <Col lg={4} md={4} sm={4}>
                                {/* <Row>
                                <Col
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  style={{ marginTop: "10px" }}
                                >
                                  <button
                                    className="btn btn-secondary"
                                    type="button"
                                    onClick={() => {
                                      AddItemDetails()
                                    }}
                                  >
                                    <i
                                      class="fas fa-plus-circle"
                                      style={{ color: "dodgerblue" }}
                                    ></i>
                                    &nbsp; Add another line
                                  </button>
                                </Col>
                              </Row> */}
                              </Col>
                              <Col
                                lg={8}
                                md={8}
                                sm={8}
                                style={{ marginTop: "10px" }}
                              >
                                <Card>
                                  <CardBody>
                                    <Row>
                                      <Col md={12} lg={12} sm={12}>
                                        <Label
                                          md={6}
                                          sm={6}
                                          style={{
                                            textAlign: "left",
                                            paddingLeft: "15px",
                                          }}
                                        >
                                          <Label>{"Sub Total"} :</Label>
                                        </Label>
                                        <Label
                                          md={6}
                                          sm={6}
                                          style={{
                                            textAlign: "right",
                                            paddingRight: "15px",
                                          }}
                                        >
                                          Rs.10,000.00
                                        </Label>
                                      </Col>
                                      <Col
                                        md={12}
                                        lg={12}
                                        sm={12}
                                        style={{ marginTop: "-17px" }}
                                      >
                                        <Label
                                          md={6}
                                          sm={6}
                                          style={{
                                            textAlign: "left",
                                            paddingLeft: "15px",
                                            marginTop: "-17px",
                                          }}
                                        >
                                          <b style={{ fontWeight: 100 }}>
                                            Total Quantity : 1
                                          </b>
                                        </Label>
                                      </Col>
                                      <Col
                                        md={12}
                                        lg={12}
                                        sm={12}
                                        className="mb-1"
                                      >
                                        <Label
                                          md={6}
                                          sm={6}
                                          style={{
                                            textAlign: "left",
                                            paddingLeft: "15px",
                                          }}
                                        >
                                          <Label>{"IGST [12%]"}</Label>
                                        </Label>
                                        <Label
                                          md={6}
                                          sm={6}
                                          style={{
                                            textAlign: "right",
                                            paddingRight: "15px",
                                          }}
                                        >
                                          Rs.1,000.00
                                        </Label>
                                      </Col>

                                      <Col md={12} lg={12} sm={12}>
                                        <Label
                                          md={8}
                                          sm={8}
                                          style={{
                                            textAlign: "left",
                                            paddingLeft: "15px",
                                          }}
                                        >
                                          <Row>
                                            <Col lg={6}>
                                              {" "}
                                              <Field
                                                type="text"
                                                name="Adjustment"
                                                placeholder={"Enter Adjustment"}
                                                value={"Adjustment"}
                                                component={CustomInput}
                                              />
                                            </Col>
                                            <Col lg={6}>
                                              {" "}
                                              <Field
                                                type="number"
                                                name="Tax"
                                                placeholder={"Enter Tax"}
                                                component={CustomInput}
                                                min={0}
                                              />
                                            </Col>
                                          </Row>
                                        </Label>
                                        <Label
                                          md={4}
                                          style={{
                                            textAlign: "right",
                                            paddingRight: "15px",
                                          }}
                                        >
                                          Rs.0.00
                                        </Label>
                                      </Col>
                                      <Col md={12} lg={12} sm={12}>
                                        <Label
                                          md={8}
                                          style={{
                                            textAlign: "left",
                                            paddingLeft: "15px",
                                          }}
                                        >
                                          <Row>
                                            <Col lg={6}>
                                              <Label>TCS</Label>
                                            </Col>
                                            <Col lg={6}>
                                              {" "}
                                              <Field
                                                name="TCS"
                                                placeholder={"Select TCS"}
                                                component={CustomSelect}
                                                options={SalesOrderNumberOption}
                                              />
                                            </Col>
                                          </Row>
                                        </Label>
                                        {/* <Label
                                        md={4}
                                        style={{
                                          textAlign: "right",
                                          paddingRight: "15px",
                                        }}
                                      >
                                        Rs.0
                                      </Label> */}
                                      </Col>

                                      <Col md={12} lg={12} sm={12}>
                                        <hr></hr>
                                        <Label
                                          md={8}
                                          style={{
                                            textAlign: "left",
                                            paddingLeft: "15px",
                                            fontSize: "17px",
                                            color: "black",
                                          }}
                                        >
                                          {"Total (Rs.)"}
                                        </Label>
                                        <Label
                                          md={4}
                                          style={{
                                            textAlign: "right",
                                            paddingRight: "15px",
                                            fontSize: "17px",
                                            color: "black",
                                          }}
                                        >
                                          Rs.0
                                        </Label>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                            <hr></hr>
                            <Row className="mt-3">
                              <Col lg={6}>
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                >
                                  Save as Draft
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  className="btn btn-secondary"
                                  type="button"
                                >
                                  Save and Send
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    navigate("/invoices")
                                  }}
                                >
                                  Cancel
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        </FormikProvider>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal isOpen={modal} centered={true}>
          <div className="modal-header mdl_header">
            <h5 className="modal-title mt-0">{"Confirmed Sales Order"}</h5>
            <button
              type="button"
              className="btn close mt-2"
              onClick={() => setModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="table-responsive">
              <table className="table  mb-0">
                <thead>
                  <tr className="table-light">
                    <th>
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="Add"
                        checked={false}
                      />
                    </th>
                    <th style={{ minWidth: "100px" }}>Sales Order Details</th>
                    <th style={{ minWidth: "50px" }}>Date</th>
                    <th style={{ minWidth: "100px", textAlign: "right" }}>
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="Add"
                        checked={false}
                      />
                    </td>
                    <td>SO-00001 </td>
                    <td>06/06/2023</td>
                    <td style={{ textAlign: "right" }}>Rs. 100,254,521.00</td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="Add"
                        checked={false}
                      />
                    </td>
                    <td>SO-00002 </td>
                    <td>06/06/2023</td>
                    <td style={{ textAlign: "right" }}>Rs. 100,254,521.00</td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="Add"
                        checked={false}
                      />
                    </td>
                    <td>SO-00003 </td>
                    <td>06/06/2023</td>
                    <td style={{ textAlign: "right" }}>Rs. 100,254,521.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Row className="mt-3">
              <Col lg={12}>
                <button className="btn btn-primary" type="submit">
                  Add
                </button>
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setModal(false)
                  }}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </div>
        </Modal>

        <Modal isOpen={invoiceNoModal} centered={true} size="lg">
          <div className="modal-header mdl_header">
            <h5 className="modal-title mt-0">{"Invoice Number"}</h5>
            <button
              type="button"
              className="btn close mt-2"
              onClick={() => setInvoiceModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <FormikProvider value={formikInvoiceNo}>
              <Form onSubmit={formikInvoiceNo.handleSubmit}>
                <Row className="mt-3">
                  <Col lg={12}>
                    Your invoice number are set on auto-generate mode to save
                    your time. Are you sure about changing this setting
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={12}>
                    <Input
                      type="radio"
                      name="Setting"
                      id="Setting"
                      className="form-check-input"
                      onChange={e => {
                        console.log(e.target.checked)
                        formikPONo.setFieldValue("Setting", e?.target.checked)
                      }}
                      checked={Setting}
                    />
                    &nbsp;
                    <label className="custom-control-label" htmlFor="Setting">
                      Continue auto-generating invoice number
                    </label>
                  </Col>
                </Row>
                <div className="table-responsive mt-3">
                  <table className="table table-bordered mb-0">
                    <thead>
                      <tr className="table-light">
                        <th style={{ minWidth: "200px" }}>Prefix</th>
                        <th style={{ minWidth: "200px" }}>Next Number</th>
                        <th style={{ minWidth: "50px" }}>Default</th>
                        <th style={{ minWidth: "80px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ListofInvoiceNumber &&
                        ListofInvoiceNumber.map((invoiceNumber, index) => (
                          <tr>
                            <td>
                              <Field
                                type="text"
                                name={`ListofInvoiceNumber.[${index}].Prefix`}
                                placeholder={"Prefix"}
                                component={CustomInput}
                                style={{ textTransform: "Uppercase" }}
                              />
                            </td>
                            <td>
                              <Field
                                type="number"
                                name={`ListofInvoiceNumber.[${index}].NextNumber`}
                                placeholder={"Next Number"}
                                component={CustomInput}
                              />
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Input
                                type="radio"
                                name={`ListofInvoiceNumber.[${index}].Default`}
                                id={`ListofInvoiceNumber.[${index}].Default`}
                                className="form-check-input"
                                onChange={e => {
                                  formikPONo.setFieldValue(
                                    `ListofInvoiceNumber.[${index}].Default`,
                                    e?.target.checked
                                  )
                                }}
                                checked={invoiceNumber.Default}
                              />
                            </td>
                            <td>
                              <div className="d-flex">
                                <Button
                                  className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    padding: "0px",
                                  }}
                                  onClick={() => {}}
                                  title="Edit"
                                >
                                  <i
                                    className="fas fa-edit"
                                    style={{ color: "#17a98c" }}
                                  ></i>
                                </Button>
                                &nbsp;
                                <Button
                                  className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    padding: "0px",
                                  }}
                                  title="Delete"
                                  onClick={() => {
                                    delete_InvoiceNumber(index)
                                  }}
                                >
                                  <i
                                    class="fas fa-trash-alt"
                                    style={{
                                      color: "red",
                                    }}
                                  ></i>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <Row className="mt-2">
                  <Col style={{ textAlign: "end" }}>
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={() => {
                        AddInvoiceNumber()
                      }}
                    >
                      <i
                        class="fas fa-plus-circle"
                        style={{ color: "dodgerblue" }}
                      ></i>
                      &nbsp; Add More
                    </button>{" "}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={12}>
                    <Input
                      type="radio"
                      name="AddManually"
                      id="AddManually"
                      className="form-check-input"
                      onChange={e => {
                        formikPONo.setFieldValue(
                          "AddManually",
                          e?.target.checked
                        )
                      }}
                      checked={AddManually}
                    />
                    &nbsp;
                    <label
                      className="custom-control-label"
                      htmlFor="AddManually"
                    >
                      I will add them manually each time
                    </label>
                  </Col>
                </Row>
                <hr></hr>
                <Row className="mt-3">
                  <Col lg={12} style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" type="submit">
                      Save
                    </button>
                    &nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setInvoiceModal(false)
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </FormikProvider>
          </div>
        </Modal>
      </Spin>
    </React.Fragment>
  )
}
AddInvoice.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(AddInvoice))
