//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//New API URL
export const POST_login = "/api/login"
export const POST_Logout = "/api/logout"
export const Get_EmailVerify = "/api/email-verify/"

//User
export const GET_users = "/api/users"
export const GET_userdetail = "/api/user-details/"
export const POST_AddUser = "/api/users"
export const GET_UserPermission = "api/user-permission"
export const GET_UserCompanyInfo = "api/user-company-info"

//Roles
export const GET_roles = "/api/roles"
export const GET_Resource = "/api/resource"
export const GET_RoleDetails = "api/roles-details/"
export const POST_AddRoles = "api/roles"

//Comman
export const GET_UserSalutation = "/api/user-salutation"
export const GET_RoleType = "/api/role-type"
export const GET_GSTTreatment = "api/GST-treatment"
export const GET_PaymentTerms = "api/payment-terms"
export const GET_Countries = "api/countries"
export const GET_Cities = "api/cities"
export const GET_State = "api/states"
export const GET_Currency = "api/currency"
export const GET_Pincode = "api/pin-code"
export const GET_ShipmentPreference = "api/shipment-preference"
export const GET_AdditionalCharges = "api/additional-charges"
export const POST_UploadDocumnet = "api/upload-document"
export const GET_CarrierList = "api/carrier"

//Contacts(Vendors & Customers)
export const GET_ContactTypeVendor = "api/contact?contact_type=vendor"
export const GET_ContactTypeCustomer = "api/contact?contact_type=customer"
export const GET_ContactDetail = "api/contact/"
export const POST_AddContact = "api/contact"
export const GET_ContactPOList = "api/contact-po/"
export const GET_ContactShipmentList = "api/contact-shipment/"

//Purchase Order
export const GET_OrganisationDetail = "api/organization-details"
export const GET_TermsAndCondition = "api/terms-and-condition"
export const GET_CreateTermsAndCondition = "api/terms-and-condition"
export const GET_PurchaseOrderList = "api/po-list"
export const DEL_DeletePOImage = "api/delete-po-image"
export const POST_CreatePurchaseOrder = "api/create-po"
export const POST_DeleteCancelPO = "api/delete-cancel"
export const GET_PurchaseOrderDetail = "api/po-details/"
export const GET_TaxDropDownList = "api/tax"
export const POST_CreateTermsAndConditionGroup =
  "api/create-terms-and-condition-group"
export const GET_GroupTermsCondition = "api/group-terms-condition/"
export const GET_TdsTypes = "api/tds-types"
export const DELETE_poImage = "api/delete-po-image"

//Product
export const GET_ProductList = "api/product"
export const GET_ProductDetail = "api/product/"

//Sales Order
export const POST_CreateSalesOrder = "api/create-so"
export const GET_SalesOrderDetails = "api/so-details/"
export const GET_SalesList = "api/so-list"
export const POST_SOAcceptReject = "api/accept-reject"
export const POST_AmmendRequest = "api/ammend-request"

//Forgot Password
export const Post_SendOTPForForgotPassword = "api/generate-otp"
export const POST_VerifyOTP = "api/verify-otp"
export const POST_ForgotPassword = "api/create-password"

//Package
export const GET_PackagesList = "api/package-list"
export const GET_PackageSoItemList = "api/package-so-item-list"
export const POST_CreatePackage = "api/create-package"
export const POST_PackageSoList = "api/package-so-list"
export const POST_PackageDetails = "api/package/"

//Shipment
export const GET_ShipmentDetails = "api/shipment/"
export const GET_ShipmentPackageList = "api/shipment-package-list"
export const POST_CreateShipment = "api/create-shipment"
export const GET_ShipmentList = "api/shipment-list"
export const GET_OrgShipmentList = "api/org-shipment-list"
export const GET_OrgShipmentDetails = "api/org-shipment/"
