import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container, Form } from "reactstrap"
import { Field, FormikProvider, useFormik } from "formik"
// Redux
import { connect, useDispatch } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"

import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { t } from "i18next"
import * as Yup from "yup"
import CustomInput from "Custom/CustomInput"

import { get, post } from "helpers/api_helper"
import { Get_EmailVerify, POST_ForgotPassword } from "helpers/url_helper"
import { Spin } from "antd"
import swal from "sweetalert"
import SwalError from "Custom/SwalError"

const VerifyEmail = props => {
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { urlToken } = useParams()

  const navigate = useNavigate()

  const [isPasswordNew, setIsPasswordNew] = useState(true)
  const [isPasswordConfirm, setIsPasswordConfirm] = useState(true)

  const [showSwalError, setShowSwalError] = useState(false)
  const [swalErrorMsg, setSwalErrorMsg] = useState("")

  useEffect(() => {
    if (urlToken) {
      get(Get_EmailVerify + `${urlToken}`).then(res => {
        if (res?.status === 200) {
          // swal("Success!", res?.message, "success")
          formik.setFieldValue("UserToken", urlToken)
        } else {
          formik.setFieldValue("UserToken", "")
          // swal({
          //   title: "Warning",
          //   text: res?.message,
          //   icon: "warning",
          //   button: "OK",
          // })
          if (typeof res?.message == "string") {
            swal({
              title: "Error!",
              text: res.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(res?.message)
            setShowSwalError(true)
          }
          setTimeout(() => {
            navigate("/login")
          }, 1000)
          return
        }
      })
    } else {
    }
  }, [])

  // validation schema
  const validationSchema = Yup.object().shape({
    Password: Yup.string().required("Password is a required field."),
    ConfirmPassword: Yup.string()
      .oneOf([Yup.ref("Password"), null], "Passwords must match")
      .required("Confirm Password is a required field."),
    UserToken: Yup.string().required("Token is required"),
  })

  const formik = useFormik({
    validationSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      UserToken: "",
      Password: "",
      ConfirmPassword: "",
    },
    validateOnBlur: false,
  })

  const {
    values: { Password, ConfirmPassword, UserToken },
  } = formik

  const handleSubmit = async (formData, { setSubmitting }) => {
    setIsLoading(true)

    const Data = {
      password: Password,
      password_confirmation: ConfirmPassword,
      token: UserToken,
    }
    //console.log("save", Data)
    post(POST_ForgotPassword, Data)
      .then(response => {
        if (response?.status === 200) {
          setIsLoading(false)
          swal("Success!", response?.message, "success")
          setTimeout(() => {
            navigate("/login")
          }, 2000)
        } else {
          setIsLoading(false)
          // swal({
          //   title: "Error",
          //   text: response?.message,
          //   icon: "error",
          //   button: "OK",
          // })
          if (typeof response?.message == "string") {
            swal({
              title: "Error!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Verify Email </title>
      </MetaTags>
      <div className="page-content">
        <Spin size="large" spinning={isLoading} tip={"Loading..."}>
          <Container>
            <Row className="justify-content-center">
              <Col xl="4"></Col>
              <Col md={8} lg={6} xl={4}>
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-2">
                      <h5 className="text-white font-size-20 mt-3">
                        Create Password
                      </h5>

                      <p className="text-white-50">
                        {"Set your new password."}
                      </p>
                    </div>
                  </div>
                  <CardBody>
                    <FormikProvider value={formik}>
                      <Form onSubmit={formik.handleSubmit} className="mt-4">
                        <div className="mb-2 ">
                          <Field
                            type={isPasswordNew ? "password" : "text"}
                            name="Password"
                            label="New Password"
                            placeholder="Enter New Password"
                            component={CustomInput}
                            icon={true}
                            iconClass={"mdi mdi-lock"}
                            passwordEye={true}
                            setIsPassword={setIsPasswordNew}
                            required
                          />
                        </div>
                        <div className="mb-2">
                          <Field
                            type={isPasswordConfirm ? "password" : "text"}
                            name="ConfirmPassword"
                            label="Confirm Password"
                            placeholder="Enter Confirm Password"
                            component={CustomInput}
                            icon={true}
                            iconClass={"mdi mdi-lock"}
                            passwordEye={true}
                            setIsPassword={setIsPasswordConfirm}
                            required
                          />
                        </div>
                        <Row className="mb-2">
                          <Col style={{ textAlign: "right" }}>
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Confirm
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </FormikProvider>
                  </CardBody>
                </Card>
              </Col>
              <Col></Col>
            </Row>
            <SwalError
              showError={showSwalError}
              setShowError={setShowSwalError}
              ErrorMsg={swalErrorMsg}
            ></SwalError>
          </Container>
        </Spin>
      </div>
    </React.Fragment>
  )
}

VerifyEmail.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(VerifyEmail))
