import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Input,
  Form,
  Table,
  Label,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CustomTable from "../../Custom/TableWithRemotePagination"
import * as Yup from "yup"
import CustomInput from "Custom/CustomInput"
import { Field, FormikProvider, useFormik } from "formik"
import CustomSelect from "Custom/CustomSelect"
import { Spin } from "antd"

const BillList = props => {
  const navigate = useNavigate()
  const [totalSize, setTotalSize] = useState(3)
  const [hide, setHide] = useState(1)
  const [mode, setMode] = useState(1)

  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState("")

  const columns = [
    {
      text: (
        <>
          <Input
            type="checkbox"
            className="form-check-input"
            id="Add"
            checked={false}
          />
          &nbsp; Date
        </>
      ),
      dataField: "Date",
      style: { minWidth: "150px" },
    },
    {
      text: "Bill No.",
      dataField: "BillNo",
      style: { minWidth: "150px" },
    },
    {
      text: "Purchase Order No.",
      dataField: "PurchaseOrderNo",
      style: { minWidth: "150px" },
    },
    {
      text: "Vendor Name",
      dataField: "VendorName",
      style: { minWidth: "200px" },
    },
    {
      text: "Status",
      dataField: "Status",
      style: { minWidth: "150px" },
    },
    {
      text: "Due Date",
      dataField: "DueDate",
      style: { minWidth: "100px" },
    },
    {
      text: "Amount",
      dataField: "Amount",
      style: { minWidth: "150px", textAlign: "right" },
    },
    {
      text: "Balance Due",
      dataField: "BalanceDue",
      style: { minWidth: "150px", textAlign: "right" },
    },
  ]

  const rows = [
    {
      Date: (
        <>
          <Input
            type="checkbox"
            className="form-check-input"
            id="Add"
            checked={false}
          />{" "}
          &nbsp; 30/05/2023
        </>
      ),
      BillNo: (
        <Link
          onClick={() => {
            setHide(2)
          }}
        >
          BN-00001
        </Link>
      ),
      PurchaseOrderNo: "PO-00001",
      VendorName: "Deep",
      Status: <text style={{ color: "grey" }}>{"Draft"}</text>,
      DueDate: "06/06/2023",
      Amount: "Rs. 500,000.00",
      BalanceDue: "Rs. 100,000.00",
    },
    {
      Date: (
        <>
          <Input
            type="checkbox"
            className="form-check-input"
            id="Add"
            checked={false}
          />{" "}
          &nbsp; 30/05/2023
        </>
      ),
      BillNo: (
        <Link
          onClick={() => {
            setHide(2)
          }}
        >
          BN-00002
        </Link>
      ),
      PurchaseOrderNo: "PO-00002",
      VendorName: "Deep",
      Status: <text style={{ color: "blue" }}>{"Sent"}</text>,
      DueDate: "06/06/2023",
      Amount: "Rs. 10,000.00",
      BalanceDue: "Rs. 5000.00",
    },
    {
      Date: (
        <>
          <Input
            type="checkbox"
            className="form-check-input"
            id="Add"
            checked={false}
          />{" "}
          &nbsp; 30/05/2023
        </>
      ),
      BillNo: (
        <Link
          onClick={() => {
            setHide(2)
          }}
        >
          BN-00003
        </Link>
      ),
      PurchaseOrderNo: "PO-00003",
      VendorName: "Deep",
      Status: <text style={{ color: "green" }}>{"Paid"}</text>,
      DueDate: "06/06/2023",
      Amount: "Rs. 5850,000.00",
      BalanceDue: "Rs. 1850,000.00",
    },
  ]

  const getData = (page = 1, sizePerPage = 10) => {}

  useEffect(() => {
    getData()
  }, [])

  const validationSchemaFilter = Yup.object().shape({})

  const formikFilter = useFormik({
    validationSchema: validationSchemaFilter,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitFilter(values, formikHelpers),
    initialValues: {
      Status: "",
      // Search: "",
      SearchByColumn: "",
    },
    validateOnBlur: false,
  })

  const {} = formikFilter

  const handleValidSubmitFilter = values => {}

  const billOptions = [
    { label: "Bill No.", value: 1 },
    { label: "Vendor Name", value: 2 },
    { label: "Sales Order No.", value: 3 },
  ]

  const statusOption = [
    { label: "All", value: 0 },
    { label: "Draft", value: 1 },
    { label: "Sent", value: 2 },
    { label: "Paid", value: 3 },
  ]

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{"Bills"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            {hide === 1 ? (
              <>
                <Row className="mt-3">
                  <Col lg={6}>
                    <div className="mt-2" style={{ fontSize: "large" }}>
                      <b>Bills</b>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="col-12">
                    <FormikProvider value={formikFilter}>
                      <Form onSubmit={formikFilter.handleSubmit}>
                        <Row>
                          <Col lg={12}>
                            <Row>
                              <Col lg={3} sm={6} md={3}>
                                <div class="form-group has-search">
                                  <span class="fa fa-search form-control-feedback"></span>
                                  <Input
                                    type="text"
                                    name="Search"
                                    class="form-control"
                                    placeholder="Search"
                                    value={search}
                                    onChange={e => {
                                      setSearch(e.target.value)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg={2} sm={6} md={2}>
                                <Field
                                  name="SearchByColumn"
                                  placeholder={"By Column"}
                                  component={CustomSelect}
                                  options={billOptions}
                                />
                              </Col>
                              <Col lg={2} md={2}>
                                <Field
                                  name="Status"
                                  placeholder={"Status"}
                                  component={CustomSelect}
                                  options={statusOption}
                                />{" "}
                              </Col>
                              <Col
                                lg={2}
                                className="mt-2"
                                style={{ textAlign: "left" }}
                              >
                                <div
                                  onClick={() => {
                                    setSearch("")
                                    formikFilter.setFieldValue("Status", "")
                                    // formikFilter.setFieldValue("Search", "")
                                    formikFilter.setFieldValue(
                                      "SearchByColumn",
                                      ""
                                    )
                                  }}
                                >
                                  <i
                                    className="mdi mdi-filter-remove"
                                    style={{
                                      fontSize: "20px",
                                      color: "#4a539f",
                                    }}
                                  ></i>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </FormikProvider>
                    <Card>
                      <CardBody>
                        <CustomTable
                          keyField="Bill"
                          columns={columns}
                          data={rows}
                          totalSize={totalSize}
                          getData={getData}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col
                    lg={3}
                    md={3}
                    sm={3}
                    style={{
                      height: "100%",
                    }}
                  >
                    <Row style={{ background: "#f0f0f0", marginLeft: "-25px" }}>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{ marginTop: "20px", marginBottom: "6px" }}
                      >
                        <div className="d-flex">
                          <div style={{ width: "400px" }}>All Invoices</div>
                          {/* <div style={{ textAlign: "right", width: "100%" }}>
                      <button
                        className="btn btn-primary"
                        onClick={() => {}}
                        style={{
                          padding: "0.05rem 0.5rem",
                        }}
                      >
                        + New
                      </button>
                    </div> */}
                        </div>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{
                          borderTop: "1px solid #cacaca",
                        }}
                      >
                        <Row style={{ marginBottom: "5px", marginTop: "5px" }}>
                          <div className="col-1 col-lg-1 col-md-1 col-sm-2">
                            {" "}
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="check"
                              checked={false}
                            />
                          </div>
                          <div className="col col-lg-6 col-md-6 col-sm-6">
                            <text>Deep</text>
                            <br></br>
                            <div style={{ fontSize: "10px", marginTop: "6px" }}>
                              <Link to="#">SO-00004</Link> | 08/06/2023
                            </div>
                          </div>
                          <div
                            className="col col-lg-5 col-md-5 col-sm-4"
                            style={{
                              marginLeft: "-2px",
                              textAlign: "end",
                              padding: "0.05rem 0.5rem",
                            }}
                          >
                            <text>Rs.564,413.00</text>
                            <br></br>
                            <text style={{ fontSize: "10px", color: "grey" }}>
                              DRAFT
                            </text>
                          </div>
                        </Row>
                        <Row style={{ marginBottom: "5px", marginTop: "5px" }}>
                          <div className="col-1 col-lg-1 col-md-1 col-sm-2">
                            {" "}
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="check"
                              checked={false}
                            />
                          </div>
                          <div className="col col-lg-6 col-md-6 col-sm-6">
                            <text>Deep</text>
                            <br></br>
                            <div style={{ fontSize: "10px", marginTop: "6px" }}>
                              <Link to="#">SO-00003</Link> | 07/06/2023
                            </div>
                          </div>
                          <div
                            className="col col-lg-5 col-md-5 col-sm-4"
                            style={{
                              marginLeft: "-2px",
                              textAlign: "end",
                              padding: "0.05rem 0.5rem",
                            }}
                          >
                            <text>Rs.979,902.00</text>
                            <br></br>
                            <text
                              style={{ fontSize: "10px", color: "#798ad8" }}
                            >
                              SENT
                            </text>
                          </div>
                        </Row>

                        <Row style={{ marginBottom: "5px", marginTop: "5px" }}>
                          <div className="col-1 col-lg-1 col-md-1 col-sm-2">
                            {" "}
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="check"
                              checked={false}
                            />
                          </div>
                          <div className="col col-lg-6 col-md-6 col-sm-6">
                            <text>Deep</text>
                            <br></br>
                            <div style={{ fontSize: "10px", marginTop: "6px" }}>
                              <Link to="#">SO-00001</Link> | 05/06/2023
                            </div>
                          </div>
                          <div
                            className="col col-lg-5 col-md-5 col-sm-4"
                            style={{
                              marginLeft: "-2px",
                              textAlign: "end",
                              padding: "0.05rem 0.5rem",
                            }}
                          >
                            <text>Rs.2,222.00</text>
                            <br></br>
                            <text style={{ fontSize: "10px", color: "green" }}>
                              PAID
                            </text>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    lg={9}
                    md={9}
                    sm={9}
                    style={{ borderLeft: "1px solid #cacaca" }}
                  >
                    <Row>
                      <Col
                        lg={12}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <div style={{ fontSize: "16px", color: "grey" }}>
                          BN-00001
                        </div>
                        <div style={{ textAlignLast: "end" }}>
                          <button
                            type="button"
                            className="btn"
                            style={{
                              marginTop: "-50px",
                              fontSize: "20px",
                              position: "sticky",
                              border: 0,
                            }}
                            onClick={() => setHide(1)}
                          >
                            <span>×</span>
                          </button>
                        </div>
                      </Col>
                      <Col lg={12} style={{ borderTop: "1px solid #cacaca" }}>
                        <Row>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            style={{
                              marginTop: "10px",
                            }}
                          >
                            <Row
                              style={{ backgroundColor: "#f8f9fa00" }}
                              className="mt-3"
                            >
                              <Col lg={1}></Col>
                              <Col
                                lg={10}
                                md={10}
                                sm={10}
                                style={{ marginLeft: "10px" }}
                              >
                                <Row>
                                  <Col lg={6} md={12} sm={12}>
                                    <text
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      BILL
                                    </text>
                                    <br></br>
                                    <text>
                                      Bill#<b>BN-00001</b>
                                    </text>
                                    <Row style={{ marginTop: "10px" }}>
                                      <Col lg={2}>
                                        <button
                                          className="btn btn-primary"
                                          disabled
                                          style={{
                                            padding: "0.05rem 0.5rem",
                                            backgroundColor: "grey",
                                            borderColor: "grey",
                                          }}
                                        >
                                          Draft
                                        </button>
                                      </Col>
                                    </Row>
                                    <Row style={{ marginTop: "15px" }}>
                                      <Col lg={6} md={12} sm={12}>
                                        <b>SO NUMBER</b>
                                      </Col>
                                      <Col lg={6} md={5} sm={12}>
                                        SO-00003
                                      </Col>
                                    </Row>

                                    <Row style={{ marginTop: "15px" }}>
                                      <Col lg={6} md={12} sm={12}>
                                        <b>PLACE OF SUPPLY</b>
                                      </Col>
                                      <Col lg={6} md={5} sm={12}>
                                        {"Ahmedabad (Guj.)"}
                                      </Col>
                                    </Row>
                                    <Row style={{ marginTop: "15px" }}>
                                      <Col lg={6} md={12} sm={12}>
                                        <b>DATE</b>
                                      </Col>
                                      <Col lg={6} md={5} sm={12}>
                                        10/06/2023
                                      </Col>
                                    </Row>

                                    <Row style={{ marginTop: "15px" }}>
                                      <Col lg={6} md={12} sm={12}>
                                        <b>CUSTOMER NAME</b>
                                      </Col>
                                      <Col lg={6} md={5} sm={12}>
                                        Deep
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={1}></Col>
                                  <Col
                                    lg={5}
                                    md={12}
                                    sm={12}
                                    style={{ marginTop: "15px" }}
                                  >
                                    {" "}
                                    <text>
                                      <b>BILLING ADDRESS</b>
                                    </text>
                                    <br></br>
                                    <Link to="#">Deep</Link>
                                  </Col>
                                </Row>
                                <Row style={{ marginTop: "40px" }}>
                                  <Col lg={12}>
                                    <div className="table-responsive">
                                      <Table className="table mb-0">
                                        <thead
                                          style={{
                                            backgroundColor: "#484e53",
                                            color: "white",
                                          }}
                                        >
                                          <tr>
                                            <th style={{ minWidth: "170px" }}>
                                              ITEMS & DESCRIPTION
                                            </th>
                                            <th
                                              style={{
                                                minWidth: "50px",
                                                textAlign: "right",
                                              }}
                                            >
                                              QTY
                                            </th>
                                            <th
                                              style={{
                                                textAlign: "right",
                                                minWidth: "150px",
                                              }}
                                            >
                                              RATE
                                            </th>
                                            <th
                                              style={{
                                                textAlign: "right",
                                                minWidth: "100px",
                                              }}
                                            >
                                              TAX
                                            </th>
                                            <th
                                              style={{
                                                textAlign: "right",
                                                minWidth: "150px",
                                              }}
                                            >
                                              AMOUNT
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <img
                                                src={
                                                  "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png"
                                                }
                                                alt=""
                                                className="avatar-xs rounded-square"
                                                style={{
                                                  borderRadius: "5px",
                                                }}
                                              />
                                              &nbsp;<span> Raw Material</span>
                                            </td>

                                            <td style={{ textAlign: "right" }}>
                                              20<br></br>
                                              pcs
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              Rs. 100,000,254.00
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              Rs. 50000
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              Rs.541,584,254.00
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <img
                                                src={
                                                  "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png"
                                                }
                                                alt=""
                                                className="avatar-xs rounded-square"
                                                style={{
                                                  borderRadius: "5px",
                                                }}
                                              />
                                              &nbsp;<span> Raw Material 2</span>
                                            </td>

                                            <td style={{ textAlign: "right" }}>
                                              10000<br></br>
                                              pcs
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              Rs. 100,000,254.00
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              Rs. 50000
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              Rs.541,584,254.00
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>{" "}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    md={6}
                                    lg={6}
                                    sm={12}
                                    style={{ marginBottom: "-9px" }}
                                  ></Col>
                                  <Col
                                    md={6}
                                    lg={6}
                                    sm={12}
                                    style={{ marginBottom: "-9px" }}
                                  >
                                    <Label
                                      md={6}
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "15px",
                                      }}
                                    >
                                      <b style={{ fontWeight: 600 }}>
                                        {"Sub Total"} :
                                      </b>
                                    </Label>
                                    <Label
                                      md={6}
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "15px",
                                      }}
                                    >
                                      Rs.10,000.00
                                    </Label>
                                  </Col>
                                  <Col md={6} lg={6} sm={12}></Col>
                                  <Col md={6} lg={6} sm={12}>
                                    <Label
                                      md={6}
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "15px",
                                        marginTop: "-17px",
                                      }}
                                    >
                                      <b style={{ fontWeight: 100 }}>
                                        Total Quantity : 1
                                      </b>
                                    </Label>
                                  </Col>

                                  <Col
                                    md={6}
                                    lg={6}
                                    sm={12}
                                    className="mb-1"
                                  ></Col>
                                  <Col md={6} lg={6} sm={12} className="mb-1">
                                    <Label
                                      md={6}
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "15px",
                                      }}
                                    >
                                      <b style={{ fontWeight: 600 }}>
                                        {"Discount"} :
                                      </b>
                                    </Label>
                                    <Label
                                      md={6}
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "15px",
                                      }}
                                    >
                                      Rs.1,000.00
                                    </Label>
                                  </Col>

                                  <Col
                                    md={6}
                                    lg={6}
                                    sm={12}
                                    className="mb-1"
                                  ></Col>
                                  <Col md={6} lg={6} sm={12} className="mb-1">
                                    <hr className="mt-0 mb-0"></hr>
                                    <Label
                                      md={6}
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "15px",
                                      }}
                                    >
                                      <b style={{ fontWeight: 600 }}>
                                        {"Total"} :
                                      </b>
                                    </Label>
                                    <Label
                                      md={6}
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "15px",
                                      }}
                                    >
                                      Rs.9,000.00
                                    </Label>
                                    <hr className="mt-0 mb-0"></hr>
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg={1}></Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </div>
      </Spin>
    </React.Fragment>
  )
}
BillList.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(BillList))
