import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody, Container, Form, Input } from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CustomInput from "Custom/CustomInput"
import { Field, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import CustomSelect from "Custom/CustomSelect"
import { Spin } from "antd"
import {
  GET_Resource,
  GET_RoleType,
  GET_RoleDetails,
  POST_AddRoles,
} from "helpers/url_helper"
import { get, post } from "helpers/api_helper"
import SwalError from "Custom/SwalError"

const AddRoles = props => {
  const navigate = useNavigate()
  const { IdParam } = useParams()

  var Auth = JSON.parse(localStorage.getItem("authUser"))

  const [isLoading, setIsLoading] = useState(false)
  const [roleTypeOption, setRoleTypeOption] = useState([])
  const [dynamicTable, setDynamictable] = useState()

  const [showSwalError, setShowSwalError] = useState(false)
  const [swalErrorMsg, setSwalErrorMsg] = useState("")

  const validationSchema = Yup.object().shape({
    RoleName: Yup.string().required("Role Name is a required field."),
  })

  useEffect(() => {
    getRoleType()
    if (IdParam) {
      setIsLoading(true)
      get(GET_RoleDetails + `${IdParam}`).then(res => {
        if (res.status === 200) {
          var str = ""
          var permissionArray = []
          var PreviousModuleName = ""

          res?.data?.map(x => {
            setValues({
              RoleName: x.role,
              Description: x.role_description,
              RoleType: x.role_type.toLowerCase(),
            })
            if (x?.permission?.data?.length > 0) {
              x?.permission?.data?.map(x => {
                x.module_name !== PreviousModuleName
                  ? PreviousModuleName == ""
                    ? (str +=
                        "<table class='table-responsive table table-bordered dataTable'><thead class='bg-light'><tr><th colspan='9'>" +
                        x.module_label +
                        "</th></tr></thead><tbody><tr><td></td><td><center>View</center></td><td><center>Create</center></td><td><center>Edit</center></td><td><center>Delete</center></td><td><center>Approve</center></td></tr><tr><td>" +
                        x.resource_label +
                        "</td><td><center><Input type='checkbox' " +
                        (x.view_per != null ? "" : "style='display:none'") +
                        " class='form-check-input permissionView' " +
                        (x.view_per == 0 ? "" : " checked ") +
                        "/></center></td><td><center><input type='checkbox' " +
                        (x.create_per != null ? "" : "style='display:none'") +
                        " class='form-check-input permissionCreate' " +
                        (x.create_per == 0 ? "" : " checked ") +
                        "/></center></td><td><center><input type='checkbox' " +
                        (x.edit_per != null ? "" : "style='display:none'") +
                        " class='form-check-input permissionEdit' " +
                        (x.edit_per == 0 ? "" : " checked ") +
                        "/></center></td><td><center><input type='checkbox' " +
                        (x.delete_per != null ? "" : "style='display:none'") +
                        " class='form-check-input permissionDelete' " +
                        (x.delete_per == 0 ? "" : " checked ") +
                        "/></center></td><td><center><input type='checkbox' " +
                        (x.approve_per != null ? "" : "style='display:none'") +
                        " class='form-check-input permissionApprove' " +
                        (x.approve_per == 0 ? "" : " checked ") +
                        "/></center></td></tr>")
                    : (str +=
                        "</tbody></table><table class='table-responsive table table-bordered dataTable'><thead class='bg-light'><tr><th colspan='9'>" +
                        x.module_label +
                        "</th></tr></thead><tbody><tr><td></td><td><center>View</center></td><td><center>Create</center></td><td><center>Edit</center></td><td><center>Delete</center></td><td><center>Approve</center></td></tr><tr><td>" +
                        x.resource_label +
                        "</td><td><center><Input type=checkbox " +
                        (x.view_per != null ? "" : "style='display:none'") +
                        " class='form-check-input permissionView' " +
                        (x.view_per == 0 ? "" : " checked ") +
                        "/></center></td><td><center><input type='checkbox' " +
                        (x.create_per != null ? "" : "style='display:none'") +
                        " class='form-check-input permissionCreate' " +
                        (x.create_per == 0 ? "" : " checked ") +
                        "/></center></td><td><center><input type='checkbox' " +
                        (x.edit_per != null ? "" : "style='display:none'") +
                        " class='form-check-input permissionEdit' " +
                        (x.edit_per == 0 ? "" : " checked ") +
                        "/></center></td><td><center><input type='checkbox' " +
                        (x.delete_per != null ? "" : "style='display:none'") +
                        " class='form-check-input permissionDelete' " +
                        (x.delete_per == 0 ? "" : " checked ") +
                        "/></center></td><td><center><input type='checkbox' " +
                        (x.approve_per != null ? "" : "style='display:none'") +
                        " class='form-check-input permissionApprove' " +
                        (x.approve_per == 0 ? "" : " checked ") +
                        "/></center></td></tr>")
                  : (str +=
                      "<tr><td>" +
                      x.resource_label +
                      "</td><td><center><Input type=checkbox " +
                      (x.view_per != null ? "" : "style='display:none'") +
                      " class='form-check-input permissionView' " +
                      (x.view_per == 0 ? "" : " checked ") +
                      "/></center></td><td><center><input type=checkbox " +
                      (x.create_per != null ? "" : "style='display:none'") +
                      " class='form-check-input permissionCreate' " +
                      (x.create_per == 0 ? "" : " checked ") +
                      "/></center></td><td><center><input type=checkbox " +
                      (x.edit_per != null ? "" : "style='display:none'") +
                      " class='form-check-input permissionEdit' " +
                      (x.edit_per == 0 ? "" : " checked ") +
                      "/></center></td><td><center><input type=checkbox " +
                      (x.delete_per != null ? "" : "style='display:none'") +
                      " class='form-check-input permissionDelete' " +
                      (x.delete_per == 0 ? "" : " checked ") +
                      "/></center></td><td><center><input type=checkbox " +
                      (x.approve_per != null ? "" : "style='display:none'") +
                      " class='form-check-input permissionApprove' " +
                      (x.approve_per == 0 ? "" : " checked ") +
                      "/></center></td></tr>")

                PreviousModuleName = x.module_name

                permissionArray.push({
                  slug: x.slug,
                  View: x.view_per,
                  Create: x.create_per,
                  Edit: x.edit_per,
                  Delete: x.delete_per,
                  Approve: x.approve_per,
                })
              })
              str += "</tbody></table>"
              setDynamictable(
                <div dangerouslySetInnerHTML={{ __html: str }}></div>
              )
            } else {
              getResourceData()
            }
          })
          formik.setFieldValue("permission", permissionArray)
          //console.log("permission: ", permissionArray)
        }
        setIsLoading(false)
      })
    } else {
      getResourceData()
    }
  }, [])

  const getResourceData = async () => {
    setIsLoading(true)
    await get(GET_Resource).then(res => {
      if (res.status == 200) {
        var str = ""
        var permissionArray = []
        var PreviousModuleName = ""

        res?.data?.map((x, i) => {
          x.module_name !== PreviousModuleName
            ? PreviousModuleName == ""
              ? (str +=
                  "<table class='table-responsive table table-bordered dataTable'><thead class='bg-light'><tr><th colspan='9'>" +
                  x.module_label +
                  "</th></tr></thead><tbody><tr><td></td><td><center>View</center></td><td><center>Create</center></td><td><center>Edit</center></td><td><center>Delete</center></td><td><center>Approve</center></td></tr><tr><td>" +
                  x.resource_label +
                  "</td><td><center><Input type='checkbox' " +
                  (x.view_def != null ? "" : "style='display:none'") +
                  " class='form-check-input permissionView' " +
                  (x.view_def == 0 ? "" : " checked ") +
                  "/></center></td><td><center><input type='checkbox' " +
                  (x.create_def != null ? "" : "style='display:none'") +
                  " class='form-check-input permissionCreate' " +
                  (x.create_def == 0 ? "" : " checked ") +
                  "/></center></td><td><center><input type='checkbox' " +
                  (x.edit_def != null ? "" : "style='display:none'") +
                  " class='form-check-input permissionEdit' " +
                  (x.edit_def == 0 ? "" : " checked ") +
                  "/></center></td><td><center><input type='checkbox' " +
                  (x.delete_def != null ? "" : "style='display:none'") +
                  " class='form-check-input permissionDelete' " +
                  (x.delete_def == 0 ? "" : " checked ") +
                  "/></center></td><td><center><input type='checkbox' " +
                  (x.approve_def != null ? "" : "style='display:none'") +
                  " class='form-check-input permissionApprove' " +
                  (x.approve_def == 0 ? "" : " checked ") +
                  "/></center></td></tr>")
              : (str +=
                  "</tbody></table><table class='table-responsive table table-bordered dataTable'><thead class='bg-light'><tr><th colspan='9'>" +
                  x.module_label +
                  "</th></tr></thead><tbody><tr><td></td><td><center>View</center></td><td><center>Create</center></td><td><center>Edit</center></td><td><center>Delete</center></td><td><center>Approve</center></td></tr><tr><td>" +
                  x.resource_label +
                  "</td><td><center><Input type=checkbox " +
                  (x.view_def != null ? "" : "style='display:none'") +
                  " class='form-check-input permissionView' " +
                  (x.view_def == 0 ? "" : " checked ") +
                  "/></center></td><td><center><input type='checkbox' " +
                  (x.create_def != null ? "" : "style='display:none'") +
                  " class='form-check-input permissionCreate' " +
                  (x.create_def == 0 ? "" : " checked ") +
                  "/></center></td><td><center><input type='checkbox' " +
                  (x.edit_def != null ? "" : "style='display:none'") +
                  " class='form-check-input permissionEdit' " +
                  (x.edit_def == 0 ? "" : " checked ") +
                  "/></center></td><td><center><input type='checkbox' " +
                  (x.delete_def != null ? "" : "style='display:none'") +
                  " class='form-check-input permissionDelete' " +
                  (x.delete_def == 0 ? "" : " checked ") +
                  "/></center></td><td><center><input type='checkbox' " +
                  (x.approve_def != null ? "" : "style='display:none'") +
                  " class='form-check-input permissionApprove' " +
                  (x.approve_def == 0 ? "" : " checked ") +
                  "/></center></td></tr>")
            : (str +=
                "<tr><td>" +
                x.resource_label +
                "</td><td><center><Input type=checkbox " +
                (x.view_def != null ? "" : "style='display:none'") +
                " class='form-check-input permissionView' " +
                (x.view_def == 0 ? "" : " checked ") +
                "/></center></td><td><center><input type=checkbox " +
                (x.create_def != null ? "" : "style='display:none'") +
                " class='form-check-input permissionCreate' " +
                (x.create_def == 0 ? "" : " checked ") +
                "/></center></td><td><center><input type=checkbox " +
                (x.edit_def != null ? "" : "style='display:none'") +
                " class='form-check-input permissionEdit' " +
                (x.edit_def == 0 ? "" : " checked ") +
                "/></center></td><td><center><input type=checkbox " +
                (x.delete_def != null ? "" : "style='display:none'") +
                " class='form-check-input permissionDelete' " +
                (x.delete_def == 0 ? "" : " checked ") +
                "/></center></td><td><center><input type=checkbox " +
                (x.approve_def != null ? "" : "style='display:none'") +
                " class='form-check-input permissionApprove' " +
                (x.approve_def == 0 ? "" : " checked ") +
                "/></center></td></tr>")

          PreviousModuleName = x.module_name

          permissionArray.push({
            slug: x.slug,
            View: 0,
            Create: 0,
            Edit: 0,
            Delete: 0,
            Approve: 0,
          })
        })
        str += "</tbody></table>"
        setDynamictable(<div dangerouslySetInnerHTML={{ __html: str }}></div>)

        formik.setFieldValue("permission", permissionArray)
        //console.log("permission: ", permissionArray)
      }
    })
    setIsLoading(false)
  }

  useEffect(() => {
    if (dynamicTable) {
      const chkViews = document.getElementsByClassName("permissionView")
      const chkCreate = document.getElementsByClassName("permissionCreate")
      const chkEdit = document.getElementsByClassName("permissionEdit")
      const chkDelete = document.getElementsByClassName("permissionDelete")
      const chkApprove = document.getElementsByClassName("permissionApprove")
      for (var i = 0; i < chkViews.length; i++) {
        ;(function (index) {
          chkViews[i].addEventListener("change", function onChange(e) {
            formik.setFieldValue(
              `permission.[${index}].View`,
              e.target.checked ? 1 : 0
            )
          })
          chkCreate[i].addEventListener("change", function onChange(e) {
            formik.setFieldValue(
              `permission.[${index}].Create`,
              e.target.checked ? 1 : 0
            )
          })
          chkEdit[i].addEventListener("change", function onChange(e) {
            formik.setFieldValue(
              `permission.[${index}].Edit`,
              e.target.checked ? 1 : 0
            )
          })
          chkDelete[i].addEventListener("change", function onChange(e) {
            formik.setFieldValue(
              `permission.[${index}].Delete`,
              e.target.checked ? 1 : 0
            )
          })
          chkApprove[i].addEventListener("change", function onChange(e) {
            formik.setFieldValue(
              `permission.[${index}].Approve`,
              e.target.checked ? 1 : 0
            )
          })
        })(i)
      }
    }
  }, [dynamicTable])

  const getRoleType = () => {
    setIsLoading(true)
    get(GET_RoleType).then(res => {
      if (res) {
        let NewOptions = []
        res.data.role_type?.map((item, i) =>
          NewOptions.push({
            label: item,
            value: item.toLowerCase(),
          })
        )
        setRoleTypeOption(NewOptions)
      }
    })
    setIsLoading(false)
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      RoleName: "",
      Description: "",
      RoleType: "",
      // ListOfVendorPortal: [],
      // ListOfCustomerPortal: [],
      // ListOfSettings: [],
      // ListOfDocumnets: [],
      // ListOfDashboard: [],
      // ListOfOrganisationSale: [],
      // ListOfOrganisationPurchase: [],
      // ListOfOrganisationContacts: [],
      permission: [],
    },
    validateOnBlur: false,
  })

  const {
    setFieldValue,
    setValues,
    values: {
      // ListOfVendorPortal,
      // ListOfCustomerPortal,
      // ListOfSettings,
      // ListOfDocumnets,
      // ListOfDashboard,
      // ListOfOrganisationSale,
      // ListOfOrganisationPurchase,
      // ListOfOrganisationContacts,
      permission,
    },
  } = formik

  const handleValidSubmit = values => {
    setIsLoading(true)

    let permissionsData = []

    permission?.map((x, i) => {
      permissionsData[x.slug] = {
        View: x.View,
        Create: x.Create,
        Edit: x.Edit,
        Delete: x.Delete,
        Approve: x.Approve,
      }
    })

    const payload = {
      role_name: values.RoleName,
      role_type: values.RoleType
        ? values?.RoleType?.charAt(0).toUpperCase() + values?.RoleType?.slice(1)
        : "",
      role_description: values.Description,
      permission: JSON.stringify({
        permissions: Object.assign({}, permissionsData),
      }),
      id: IdParam ? IdParam : "",
    }

    if (!IdParam) {
      delete payload["id"]
    }

    //console.log("Request Data", payload)

    post(POST_AddRoles, payload)
      .then(response => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: response.message,
            icon: "success",
            button: "OK",
          })
          navigate("/roles")
        } else {
          // swal({
          //   title: "Error",
          //   text: response.message,
          //   icon: "error",
          //   button: "OK",
          // })
          if (typeof response?.message == "string") {
            swal({
              title: "Error!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
        }
      })
      .catch(error => error.response.data)

    setIsLoading(false)
  }

  // useEffect(() => {
  //   setFieldValue("ListOfVendorPortal", [
  //     {
  //       Name: "Sales Order",
  //       FullAccess: true,
  //       View: true,
  //       Create: false,
  //       Edit: false,
  //       Delete: false,
  //       Approve: true,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Package",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Shipments",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Venors Invoice(Bill)",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Package",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Shipments Order",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: false,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Credit Notes",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Sales Return",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Sales Returb Receive",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: false,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //   ])
  //   setFieldValue("ListOfCustomerPortal", [
  //     {
  //       Name: "Purchase Order",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //     },
  //     {
  //       Name: "Purchase Receive(Shipments)",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //     },
  //     {
  //       Name: "Payment Mode",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //     },
  //     {
  //       Name: "Purchase Receive",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //     },
  //     {
  //       Name: "Vendor Credit",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: true,
  //     },
  //   ])
  //   setFieldValue("ListOfSettings", [
  //     {
  //       Name: "Update Organisation Profile",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Users",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Export Data",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "General Preference",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Taxes",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Provide access to protected data",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Payment Terms",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Templates",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Email Templates",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Reporting Tags",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Manage Integration",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Automation",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Incoming Webhook",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Signal",
  //       IsChecked: true,
  //     },
  //   ])
  //   setFieldValue("ListOfDocumnets", [
  //     {
  //       Name: "View Documents",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Upload Documents",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Delete Documents",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Manage Folder",
  //       IsChecked: true,
  //     },
  //   ])
  //   setFieldValue("ListOfDashboard", [
  //     {
  //       Name: "Sales Activity",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Inventory Summary",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Product Details",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Top Selling Items",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Purchase Order",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Sales Order",
  //       IsChecked: true,
  //     },
  //     {
  //       Name: "Sales Order Summary",
  //       IsChecked: true,
  //     },
  //   ])
  //   setFieldValue("ListOfOrganisationSale", [
  //     {
  //       Name: "Sales Order",
  //       FullAccess: true,
  //       View: true,
  //       Create: false,
  //       Edit: false,
  //       Delete: false,
  //       Approve: true,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Package",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Shipments",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Venors Invoice(Bill)",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Package",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Shipments Order",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: false,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Credit Notes",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Sales Return",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //     {
  //       Name: "Sales Returb Receive",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: false,
  //       Delete: true,
  //       Approve: false,
  //       // EditLockedRecorde: false,
  //     },
  //   ])
  //   setFieldValue("ListOfOrganisationPurchase", [
  //     {
  //       Name: "Purchase Order",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //     },
  //     {
  //       Name: "Purchase Receive(Shipments)",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //     },
  //     {
  //       Name: "Payment Mode",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //     },
  //     {
  //       Name: "Purchase Receive",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: false,
  //     },
  //     {
  //       Name: "Vendor Credit",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: true,
  //     },
  //   ])
  //   setFieldValue("ListOfOrganisationContacts", [
  //     {
  //       Name: "Customers",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: true,
  //     },
  //     {
  //       Name: "Vendors",
  //       FullAccess: true,
  //       View: true,
  //       Create: true,
  //       Edit: true,
  //       Delete: true,
  //       Approve: true,
  //     },
  //   ])
  // }, [])

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{IdParam ? "Edit Role" : "Add Role"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            <Row className="mt-3">
              <Col lg={6}>
                <div className="mt-2" style={{ fontSize: "large" }}>
                  <b>{IdParam ? "Edit Role" : "Add Role"}</b>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Card>
                  <CardBody>
                    <FormikProvider value={formik}>
                      <Form onSubmit={formik.handleSubmit}>
                        <Row>
                          <Col lg={2}>
                            <div className="mt-2">
                              {" "}
                              Role Name <span className="text-danger">*</span>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="text"
                              name="RoleName"
                              placeholder={"Enter Role Name"}
                              component={CustomInput}
                              maxLength={100}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2}>
                            <div className="mt-2"> Description</div>
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="textarea"
                              name="Description"
                              placeholder={"Max. 500 Character"}
                              component={CustomInput}
                              maxLength={500}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2}></Col>
                          <Col lg={4}>
                            <Field
                              name="RoleType"
                              placeholder={"Select Role Type"}
                              component={CustomSelect}
                              options={roleTypeOption}
                              onChange={e => {
                                setFieldValue("RoleType", e?.value)
                              }}
                            />
                          </Col>
                        </Row>
                        {/* <div
                          dangerouslySetInnerHTML={{ __html: dynamicTable }}
                        ></div> */}
                        <Row className="mt-3">
                          <Col lg={2}></Col>
                          <Col lg={10}>{dynamicTable}</Col>
                        </Row>
                        {/* <Row className="mt-3">
                          <Col lg={2}></Col>
                          <Col lg={10}>
                            <div className="table-responsive">
                              <table className="table table-bordered dataTable">
                                <thead className="bg-light">
                                  <tr>
                                    <th colSpan={9} style={{ width: "40%" }}>
                                      {"Vendor Portal"}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style={{ width: "200px" }}></td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Full Access
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      View
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Create
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Edit
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Delete
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Approve
                                    </td>
                                    <td style={{ width: "6%" }}>
                                    Edit Locked Records
                                  </td>
                                  </tr>
                                  {ListOfVendorPortal?.map((item, index) => (
                                    <>
                                      <tr>
                                        <td>{item.Name}</td>
                                        <td>
                                          <center>
                                            <Input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="Add"
                                              checked={item.FullAccess}
                                            />
                                          </center>
                                        </td>
                                        <td>
                                          <center>
                                            <Input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="Add"
                                              checked={item.View}
                                            />
                                          </center>
                                        </td>
                                        <td>
                                          <center>
                                            <Input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="Add"
                                              checked={item.Create}
                                            />
                                          </center>
                                        </td>
                                        <td>
                                          <center>
                                            <Input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="Add"
                                              checked={item.Edit}
                                            />
                                          </center>
                                        </td>
                                        <td>
                                          <center>
                                            <Input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="Add"
                                              checked={item.Delete}
                                            />
                                          </center>
                                        </td>
                                        <td>
                                          <center>
                                            <Input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="Add"
                                              checked={item.Approve}
                                            />
                                          </center>
                                        </td>
                                        <td>
                                        <center>
                                          <Input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="Add"
                                            checked={item.EditLockedRecorde}
                                          />
                                        </center>
                                      </td>
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={2}></Col>
                          <Col lg={10}>
                            <div className="table-responsive">
                              <table className="table table-bordered dataTable">
                                <thead className="bg-light">
                                  <tr>
                                    <th colSpan={9} style={{ width: "40%" }}>
                                      {"Customer Portal"}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: "200px",
                                      }}
                                    ></td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Full Access
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      View
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Create
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Edit
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Delete
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Approve
                                    </td>
                                  </tr>
                                  {ListOfCustomerPortal?.map((item, index) => (
                                    <>
                                      <tr>
                                        <td>{item.Name}</td>
                                        <td>
                                          <center>
                                            <Input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="Add"
                                              checked={item.FullAccess}
                                            />
                                          </center>
                                        </td>
                                        <td>
                                          <center>
                                            <Input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="Add"
                                              checked={item.View}
                                            />
                                          </center>
                                        </td>
                                        <td>
                                          <center>
                                            <Input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="Add"
                                              checked={item.Create}
                                            />
                                          </center>
                                        </td>
                                        <td>
                                          <center>
                                            <Input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="Add"
                                              checked={item.Edit}
                                            />
                                          </center>
                                        </td>

                                        <td>
                                          <center>
                                            <Input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="Add"
                                              checked={item.Delete}
                                            />
                                          </center>
                                        </td>
                                        <td>
                                          <center>
                                            <Input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="Add"
                                              checked={item.Approve}
                                            />
                                          </center>
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={2}></Col>
                          <Col lg={10}>
                            <div className="table-responsive">
                              <table className="table table-bordered dataTable">
                                <thead className="bg-light">
                                  <tr>
                                    <th colSpan={8}>
                                      <Input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="Add"
                                        checked={true}
                                      />{" "}
                                      &nbsp;{"Settings"}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ListOfSettings?.map((item, index) => (
                                    <>
                                      <tr>
                                        <td>
                                          {" "}
                                          <Input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="Add"
                                            checked={item.IsChecked}
                                          />{" "}
                                          &nbsp;{item.Name}
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={2}></Col>
                          <Col lg={10}>
                            <div className="table-responsive">
                              <table className="table table-bordered dataTable">
                                <thead className="bg-light">
                                  <tr>
                                    <th colSpan={8}>
                                      <Input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="Add"
                                        checked={true}
                                      />{" "}
                                      &nbsp;{"Documents"}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ListOfDocumnets?.map((item, index) => (
                                    <>
                                      <tr>
                                        <td>
                                          {" "}
                                          <Input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="Add"
                                            checked={item.IsChecked}
                                          />{" "}
                                          &nbsp;{item.Name}
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={2}></Col>
                          <Col lg={10}>
                            <div className="table-responsive">
                              <table className="table table-bordered dataTable">
                                <thead className="bg-light">
                                  <tr>
                                    <th colSpan={8}>
                                      <Input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="Add"
                                        checked={true}
                                      />{" "}
                                      &nbsp;{"Dashboard"}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ListOfDashboard?.map((item, index) => (
                                    <>
                                      <tr>
                                        <td>
                                          {" "}
                                          <Input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="Add"
                                            checked={item.IsChecked}
                                          />{" "}
                                          &nbsp;{item.Name}
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={2}></Col>
                          <Col lg={10}>
                            <div className="table-responsive">
                              <table className="table table-bordered dataTable">
                                <thead className="bg-light">
                                  <tr>
                                    <th colSpan={9} style={{ width: "40%" }}>
                                      {"Organisation-Sales"}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: "200px",
                                      }}
                                    ></td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Full Access
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      View
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Create
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Edit
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Delete
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Approve
                                    </td>
                                  </tr>
                                  {ListOfOrganisationSale?.map(
                                    (item, index) => (
                                      <>
                                        <tr>
                                          <td>{item.Name}</td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.FullAccess}
                                              />
                                            </center>
                                          </td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.View}
                                              />
                                            </center>
                                          </td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.Create}
                                              />
                                            </center>
                                          </td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.Edit}
                                              />
                                            </center>
                                          </td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.Delete}
                                              />
                                            </center>
                                          </td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.Approve}
                                              />
                                            </center>
                                          </td>
                                          <td>
                                        <center>
                                          <Input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="Add"
                                            checked={item.EditLockedRecorde}
                                          />
                                        </center>
                                      </td>
                                        </tr>
                                      </>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={2}></Col>
                          <Col lg={10}>
                            <div className="table-responsive">
                              <table className="table table-bordered dataTable">
                                <thead className="bg-light">
                                  <tr>
                                    <th colSpan={9} style={{ width: "40%" }}>
                                      {"Organisation-Puchase"}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: "200px",
                                      }}
                                    ></td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Full Access
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      View
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Create
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Edit
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Delete
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Approve
                                    </td>
                                  </tr>
                                  {ListOfOrganisationPurchase?.map(
                                    (item, index) => (
                                      <>
                                        <tr>
                                          <td>{item.Name}</td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.FullAccess}
                                              />
                                            </center>
                                          </td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.View}
                                              />
                                            </center>
                                          </td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.Create}
                                              />
                                            </center>
                                          </td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.Edit}
                                              />
                                            </center>
                                          </td>

                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.Delete}
                                              />
                                            </center>
                                          </td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.Approve}
                                              />
                                            </center>
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={2}></Col>
                          <Col lg={10}>
                            <div className="table-responsive">
                              <table className="table table-bordered dataTable">
                                <thead className="bg-light">
                                  <tr>
                                    <th colSpan={9} style={{ width: "40%" }}>
                                      {"Organisation-Puchase"}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: "200px",
                                      }}
                                    ></td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Full Access
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      View
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Create
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Edit
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Delete
                                    </td>
                                  </tr>
                                  {ListOfOrganisationContacts?.map(
                                    (item, index) => (
                                      <>
                                        <tr>
                                          <td>{item.Name}</td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.FullAccess}
                                              />
                                            </center>
                                          </td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.View}
                                              />
                                            </center>
                                          </td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.Create}
                                              />
                                            </center>
                                          </td>
                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.Edit}
                                              />
                                            </center>
                                          </td>

                                          <td>
                                            <center>
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="Add"
                                                checked={item.Delete}
                                              />
                                            </center>
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  )}
                                  <tr>
                                  <td colSpan={7}>
                                    <Input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="Add"
                                      checked={true}
                                    />{" "}
                                    &nbsp;
                                    {
                                      "Allow Users to add, edit and delete Vendor's bank account details."
                                    }
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row> */}
                        <hr></hr>
                        <Row className="mt-3">
                          <Col lg={6}>
                            <button className="btn btn-primary" type="submit">
                              Save
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                navigate("/roles")
                              }}
                            >
                              Cancel
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </FormikProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <SwalError
              showError={showSwalError}
              setShowError={setShowSwalError}
              ErrorMsg={swalErrorMsg}
            ></SwalError>
          </Container>
        </div>
      </Spin>
    </React.Fragment>
  )
}
AddRoles.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(AddRoles))
