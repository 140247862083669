import React from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Form,
  Modal,
  Input,
  Button,
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import * as Yup from "yup"
import CustomInput from "Custom/CustomInput"
import { Field, FormikProvider, useFormik } from "formik"
import CustomSelect from "Custom/CustomSelect"
import CustomFlatpickr from "Custom/CustomFlatpicker"
import { useState } from "react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import { Spin } from "antd"

import swal from "sweetalert"
import moment from "moment"

import { get, post, postFormData } from "helpers/api_helper"
import {
  GET_SalesList,
  GET_ShipmentPackageList,
  GET_CarrierList,
  POST_CreateShipment,
  POST_UploadDocumnet,
  GET_ContactTypeCustomer,
  GET_ShipmentPreference,
} from "helpers/url_helper"
import SwalError from "Custom/SwalError"

const AddShipmentOrganisation = props => {
  const navigate = useNavigate()
  const [mode, setMode] = useState(0)
  const [shipmentNoModal, setShipmentModal] = useState(false)

  const [sONoOptions, setSONoOptions] = useState([])
  const [shipmentPreferenceOption, setShipmentPreferenceOption] = useState([])
  const [packageNoOptions, setPackageNoOptions] = useState([])
  const [carrierOption, setCarrierOptions] = useState([])
  const [customerOptions, setCustomerOptions] = useState([])

  const [salesOrderID, setSalesOrderID] = useState(0)
  const [contactID, setContactID] = useState(0)

  const [isLoading, setIsLoading] = useState(false)

  const [showSwalError, setShowSwalError] = useState(false)
  const [swalErrorMsg, setSwalErrorMsg] = useState("")

  // const initialItemDetail = {
  //   QuantitytoPack: 100,
  //   Ordered: 200,
  //   Unit: "Mtr",
  //   Packed: 100,
  //   ItemName: "Package-1",
  // }

  const initialFileUpload = {
    File: "",
  }

  const validationSchema = Yup.object().shape({
    CustomerName: Yup.string().required("Customer Name is a required field."),
    SalesOrderNumber: Yup.string().required(
      "Sales Order Number is a required field."
    ),
    PackageNumber: Yup.array()
      .required("Package Number is a required field.")
      .min(1, "Package Number is a required field.")
      .nullable(),
    ShipmentOrder: Yup.string().required("Shipment Order is a required field."),
    ShipmentDate: Yup.string().required("Shipment Date is a required field."),
    Carrier: Yup.string().required("Carrier is required field."),
    Notes: Yup.string().required("Notes is required field."),
  })

  const getFileUploadValidationCheck = (e, index) => {
    //alert(e)
    var pattern = /(\.|\/)(jpe?g|png|webp|svg|gif|ico)$/
    if (e.target.files?.length > 0) {
      if (!e.target.files[0]?.type.match(pattern)) {
        e.target.value = []
        swal("", "Only image files are allowed!", "warning")
        // return
      } else {
        setFieldValue(`ListOffileUpload.[${index}].File`, e.target.files[0])
      }
    }
  }

  const getFileUploadValidationCheckForInvoice = e => {
    //alert(e)
    var pattern = /(\.|\/)(jpe?g|png|webp|svg|gif|ico)$/
    if (e.target.files?.length > 0) {
      if (!e.target.files[0]?.type.match(pattern)) {
        e.target.value = []
        swal("", "Only image files are allowed!", "warning")
        // return
      } else {
        setFieldValue("InvoiceCopy.[0].File", e.target.files[0])
      }
    }
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CustomerName: "",
      SalesOrderNumber: "",
      PackageNumber: "",
      ShipmentOrder: "",
      ShipmentDate: "",
      Notes: "",
      Carrier: "",
      TrackingURL: "",
      TrackingNo: "",
      ShippingCharges: "",
      ShipmentPreference: "",
      BillLandingNo: "",
      ContainerNumber: "",
      ShipmentStatus: "",
      ListOffileUpload: [initialFileUpload],
      InvoiceCopy: [initialFileUpload],
      ContactID: 0,
    },
    validateOnBlur: false,
  })

  const {
    setValues,
    setFieldValue,
    values: { ListOffileUpload, PackageNumber, InvoiceCopy, ContactID },
  } = formik

  const handleValidSubmit = async values => {
    setIsLoading(true)

    let AttachFileIDs = []
    let errorMsgImageFlag = false

    // var pattern = /(\.|\/)(jpe?g|png|webp|svg|gif|ico)$/

    // await Promise.all(
    //   values.ListOffileUpload?.map(async x => {
    //     if (x.File) {
    //       if (!x.File?.type.match(pattern)) {
    //         errorMsgImageFlag = true
    //         return
    //       }
    //     }
    //   })
    // )

    // if (errorMsgImageFlag == true) {
    //   swal("", "Only image files are allowed!", "warning")
    //   setIsLoading(false)
    // } else {

    await Promise.all(
      values.ListOffileUpload?.map(async x => {
        if (x?.File) {
          let formData = new FormData()
          //alert(x.File)
          formData.append("module_slug", "org-purchase-vendor-shipment")
          formData.append("label", "Attach File(s) to Shipment")
          formData.append("file", x.File)
          setIsLoading(true)
          await postFormData(POST_UploadDocumnet, formData)
            .then(response => {
              if (response?.status === 200) {
                AttachFileIDs.push(response?.data?.id)
                setIsLoading(false)
              } else {
                // swal("Error!", response?.message?.file, "error")
                if (typeof response?.message == "string") {
                  swal({
                    title: "Error!",
                    text: response.message,
                    icon: "error",
                    button: "OK",
                  })
                } else {
                  setSwalErrorMsg(response?.message)
                  setShowSwalError(true)
                }
                setIsLoading(false)
              }
            })
            .catch(error => {
              console.log(error)
              setIsLoading(false)
            })
        }
      })
    )
    //}

    await Promise.all(
      values.InvoiceCopy?.map(async x => {
        if (x?.File) {
          //console.log("file 1", x?.File)
          let formData = new FormData()
          //alert(x.File)
          formData.append("module_slug", "org-purchase-vendor-shipment")
          formData.append("label", "Invoice")
          formData.append("file", x.File)
          setIsLoading(true)
          await postFormData(POST_UploadDocumnet, formData)
            .then(response => {
              //  console.log("file", response)
              if (response?.status === 200) {
                AttachFileIDs.push(response?.data?.id)
                setIsLoading(false)
              } else {
                // swal("Error!", response?.message?.file, "error")
                if (typeof response?.message == "string") {
                  swal({
                    title: "Error!",
                    text: response.message,
                    icon: "error",
                    button: "OK",
                  })
                } else {
                  setSwalErrorMsg(response?.message)
                  setShowSwalError(true)
                }
                setIsLoading(false)
              }
            })
            .catch(error => {
              console.log(error)
              setIsLoading(false)
            })
        }
      })
    )

    setIsLoading(true)
    const payload = {
      so_id: salesOrderID,
      shipment_number: values.ShipmentOrder,
      shipment_date:
        `${moment(values.ShipmentDate).format("YYYY-MM-DD")}` +
        " " +
        moment(new Date()).format("h:mm:ss"),
      tracking_url: values.TrackingURL,
      tracking_no: values.TrackingNo,
      shipment_carrier: values.Carrier,
      shipment_type: values.ShipmentPreference,
      bill_of_landing_no: values.BillLandingNo,
      container_no: values.ContainerNumber,
      shipping_line: values.Notes,
      status: "shipped",
      shipping_line: values.Notes,
      file_id: AttachFileIDs,
      shipping_charges: values.ShippingCharges,

      shipment_package: Array.from(values.PackageNumber, x => x.value),
    }

    //console.log("payload", payload)
    //console.log("payload", values.ListOffileUpload[1].File.name)

    //return
    post(POST_CreateShipment, payload)
      .then(response => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: response?.message,
            icon: "success",
            button: "OK",
          })
          setIsLoading(false)
          setTimeout(() => {
            navigate("/shipments-organisation")
          }, 1000)
        } else {
          // swal({
          //   title: "Error",
          //   text: response?.message,
          //   icon: "error",
          //   button: "OK",
          // })
          if (typeof response?.message == "string") {
            swal({
              title: "Error!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
          setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })

    //console.log(Array.from(values.PackageNumber, x => x.value).join(","))

    // setIsLoading(false)
  }

  useEffect(() => {
    //getSOList()
    getCarrierOptionList()
    getCustomerList()
    getShipmentReference()
  }, [])

  useEffect(() => {
    // alert(ContactID)
    getSOList()
  }, [ContactID])

  const getSOList = () => {
    setIsLoading(true)

    get(GET_SalesList + "?paginate=1000&page=1&contact_id=" + ContactID).then(
      res => {
        if (res?.data?.data?.length > 0) {
          let NewOptions = []

          res?.data?.data?.map(item =>
            NewOptions.push({
              label: item.sales_order_no,
              value: item.id,
            })
          )
          setSONoOptions(NewOptions)
        }
      }
    )
    setIsLoading(false)
  }

  const getShipmentReference = () => {
    setIsLoading(true)
    get(GET_ShipmentPreference).then(res => {
      if (res) {
        const valuesArray = res.data.shipment_preference
        var NewArrayOption = Object.entries(valuesArray)

        let NewOption = []
        NewArrayOption.map(x =>
          NewOption.push({
            label: x[1],
            value: x[0],
          })
        )

        setShipmentPreferenceOption(NewOption)
      }
    })
    setIsLoading(false)
  }

  const getCustomerList = () => {
    setIsLoading(true)

    get(GET_ContactTypeCustomer + "&paginate=1000&page=1").then(res => {
      if (res?.data?.data?.length > 0) {
        let NewOptions = []
        // console.log("cust", res?.data?.data)
        res?.data?.data?.map(item =>
          NewOptions.push({
            label: item?.company_name,
            value: item?.contact_id,
          })
        )
        setCustomerOptions(NewOptions)
      }
    })
    setIsLoading(false)
  }

  const getPackageNoList = id => {
    setPackageNoOptions([])
    formik.setFieldValue("PackageNumber", "")
    setIsLoading(true)
    // alert(id)
    get(GET_ShipmentPackageList + "?so_id=" + id).then(res => {
      // console.log("po", res?.data)

      if (res?.data?.length > 0) {
        let NewOptions = []

        res?.data?.map(item =>
          NewOptions.push({
            label: item.package_no,
            value: item.id,
          })
        )
        setPackageNoOptions(NewOptions)
      }
    })
    setIsLoading(false)
  }

  const getCarrierOptionList = () => {
    setIsLoading(true)

    get(GET_CarrierList).then(res => {
      if (res?.data?.carrier !== "") {
        let NewCarrierOptions = []

        //console.log("array", res?.data?.carrier)
        const arrayOfObj = Object.entries(res?.data?.carrier)

        arrayOfObj.map((item, index) => {
          NewCarrierOptions.push({
            label: item[1],
            value: item[0],
          })
        })

        setCarrierOptions(NewCarrierOptions)
      }
    })
    setIsLoading(false)
  }

  // const SalesOrderNumberOption = [
  //   { label: "SO-00001", value: 1 },
  //   { label: "SO-00002", value: 2 },
  //   { label: "SO-00003", value: 3 },
  // ]

  // const packageNoOptions = [
  //   { label: "1245-6451", value: 1 },
  //   { label: "9874-6958", value: 2 },
  //   { label: "1234-4321", value: 3 },
  // ]

  // const CarrierOption = [
  //   { label: "asasd", value: 1 },
  //   { label: "Xpress", value: 2 },
  //   { label: "P2P", value: 3 },
  // ]

  const initialListofShipmentNumber = {
    Prefix: "",
    NextNumber: "",
    Default: false,
    AddManually: true,
  }

  const validationSchemaShipmentNo = Yup.object().shape({})

  const formikShipmentNo = useFormik({
    validationSchema: validationSchemaShipmentNo,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitShipmentNo(values, formikHelpers),
    initialValues: {
      Setting: false,
      AddManually: true,
      ListofShipmentNumber: [initialListofShipmentNumber],
    },
    validateOnBlur: false,
  })

  const {
    values: { Setting, ListofShipmentNumber, AddManually },
  } = formikShipmentNo

  const handleValidSubmitShipmentNo = values => {
    setShipmentModal(false)
  }

  const delete_ShipmentNumber = index => {
    formikShipmentNo.setFieldValue(
      "ListofShipmentNumber",
      ListofShipmentNumber.filter((element, i) => i !== index)
    )
  }

  const AddShipmentNumber = () => {
    formikShipmentNo.setFieldValue("ListofShipmentNumber", [
      ...ListofShipmentNumber,
      {
        Prefix: "",
        NextNumber: "",
        Default: false,
      },
    ])
  }

  const delete_FileUpload = index => {
    formik.setFieldValue(
      "ListOffileUpload",
      ListOffileUpload.filter((element, i) => i !== index)
    )
  }

  const AddFileUpload = () => {
    formik.setFieldValue("ListOffileUpload", [
      ...ListOffileUpload,
      {
        File: "",
      },
    ])
  }

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{"Add Shipment"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            <Row className="mt-3">
              <Col lg={6}>
                <div className="mt-2" style={{ fontSize: "large" }}>
                  <b>Add Shipment</b>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <FormikProvider value={formik}>
                      <Form onSubmit={formik.handleSubmit}>
                        <Row className="mt-3">
                          <Col lg={2}>
                            <div className="mt-2" style={{ color: "#000000" }}>
                              Customer Name{" "}
                              <span className="text-danger">*</span>
                            </div>
                          </Col>
                          <Col lg={5}>
                            <Field
                              name="CustomerName"
                              // placeholder={"Customer  Name"}
                              // component={CustomInput}
                              // maxLength={50}
                              type="text"
                              placeholder={"Select Customer Name"}
                              component={CustomSelect}
                              options={customerOptions}
                              onChange={e => {
                                formik.setFieldValue("CustomerName", e?.value)
                                formik.setFieldValue("ContactID", e?.value)
                              }}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={2}>
                            <div className="mt-2" style={{ color: "#000000" }}>
                              Sales Order#{" "}
                              <span className="text-danger">*</span>
                            </div>
                          </Col>
                          <Col lg={5}>
                            <Field
                              name="SalesOrderNumber"
                              placeholder={"Select Sales Order Number"}
                              component={CustomSelect}
                              options={sONoOptions}
                              onChange={e => {
                                getPackageNoList(e?.value)
                                setSalesOrderID(e?.value)
                                formik.setFieldValue(
                                  "SalesOrderNumber",
                                  e?.value
                                )
                              }}
                            />
                          </Col>
                        </Row>

                        <hr></hr>
                        <Row>
                          <Col lg={2}>
                            <div className="mt-2" style={{ color: "#000000" }}>
                              Package No.#{" "}
                              <span className="text-danger">*</span>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <Field
                              name="PackageNumber"
                              placeholder={"Select Package No."}
                              component={CustomSelect}
                              options={packageNoOptions}
                              isMulti={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2} style={{ color: "#000000" }}>
                            Shipment Order#{" "}
                            <span className="text-danger">*</span>
                          </Col>
                          <Col lg={4}>
                            <div className="d-flex">
                              <Field
                                type="text"
                                name="ShipmentOrder"
                                placeholder={"Enter Shipment Order"}
                                component={CustomInput}
                                maxLength={50}
                              />
                              &nbsp; &nbsp;
                              <Link
                                onClick={() => {
                                  setShipmentModal(true)
                                }}
                                className="mt-2"
                                title="Edit"
                              >
                                <i className="ti-settings"></i>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2}>
                            <div className="mt-2" style={{ color: "#000000" }}>
                              Shipment Date{" "}
                              <span className="text-danger">*</span>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <Field
                              name={"ShipmentDate"}
                              placeholder={"dd/mm/yyyy"}
                              component={CustomFlatpickr}
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "d/m/Y",
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2} style={{ color: "#000000" }}>
                            Carrier <span className="text-danger">*</span>
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="text"
                              name="Carrier"
                              placeholder={"Select Carrier"}
                              component={CustomSelect}
                              options={carrierOption}
                            />
                          </Col>

                          <Col lg={2} style={{ color: "#000000" }}>
                            Tracking No#
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="text"
                              name="TrackingNo"
                              placeholder={"Enter Tracking Number"}
                              component={CustomInput}
                              maxLength={50}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2} style={{ color: "#000000" }}>
                            Tracking URL
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="text"
                              name="TrackingURL"
                              placeholder={"Enter Tracking URL"}
                              component={CustomInput}
                              maxLength={50}
                            />
                          </Col>
                          <Col lg={2} style={{ color: "#000000" }}>
                            Bill of Landing No
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="text"
                              name="BillLandingNo"
                              placeholder={"Enter Bill of Landing No"}
                              component={CustomInput}
                              maxLength={50}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2} style={{ color: "#000000" }}>
                            {"Shipping Charges (If Any)"}
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="number"
                              name="ShippingCharges"
                              placeholder={"Enter Shipping Charges"}
                              component={CustomInput}
                              min={0}
                              // icon={true}
                              // iconClass={"fas fa-rupee-sign"}
                            />
                          </Col>
                          <Col lg={2} style={{ color: "#000000" }}>
                            Container Number
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="text"
                              name="ContainerNumber"
                              placeholder={"Enter  Container Number"}
                              component={CustomInput}
                              maxLength={50}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2}>
                            <div className="mt-2" style={{ color: "#000000" }}>
                              Shipment Preference
                            </div>
                          </Col>
                          <Col lg={4}>
                            <Field
                              name="ShipmentPreference"
                              placeholder={"Select Shipment Preference"}
                              component={CustomSelect}
                              options={shipmentPreferenceOption}
                            />
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col lg={6}>
                            <Row>
                              <Col
                                lg={12}
                                className="mb-2"
                                style={{ color: "#000000" }}
                              >
                                {"Attach File(s) to Shipment"}
                              </Col>
                              {ListOffileUpload?.map((files, index) => (
                                <Col lg={9} className="mt-2">
                                  <div className="d-flex">
                                    <input
                                      type="file"
                                      id={`ListOffileUpload.[${index}].File`}
                                      name={`ListOffileUpload.[${index}].File`}
                                      className="form-control"
                                      onChange={e => {
                                        // setFieldValue(
                                        //   `ListOffileUpload.[${index}].File`,
                                        //   e.target.files[0]
                                        // )
                                        getFileUploadValidationCheck(e, index)
                                      }}
                                    />{" "}
                                    &nbsp;
                                    <i
                                      class="fas fa-times-circle"
                                      style={{
                                        color: "red",
                                        marginTop: "12px",
                                      }}
                                      onClick={() => {
                                        delete_FileUpload(index)
                                      }}
                                    ></i>
                                  </div>
                                </Col>
                              ))}

                              <Col lg={12} className="mt-2">
                                <button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={() => {
                                    AddFileUpload()
                                  }}
                                >
                                  <i
                                    class="fas fa-plus-circle"
                                    style={{ color: "dodgerblue" }}
                                  ></i>
                                  &nbsp; Add More
                                </button>
                              </Col>

                              {/* <Col lg={12}>
                              <text style={{ fontSize: "11px" }}>
                                You can upload a maximum of 10 files, 5MB each
                              </text>
                            </Col> */}
                            </Row>
                            <Row className="mt-3">
                              <Col lg={12} style={{ color: "#000000" }}>
                                {"Invoice Copy"} &nbsp;
                                {/* <span className="text-danger">*</span> */}
                              </Col>

                              <Col lg={9} className="mt-2">
                                <div className="d-flex">
                                  <input
                                    type="file"
                                    id={`InvoiceCopy.[0].File`}
                                    name={`InvoiceCopy.[0].File`}
                                    className="form-control"
                                    onChange={e => {
                                      setFieldValue(`InvoiceCopy.[0].File`, "")
                                      getFileUploadValidationCheckForInvoice(e)
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6}>
                            <Field
                              label="Notes"
                              type="textarea"
                              name="Notes"
                              placeholder={"Enter Notes"}
                              component={CustomInput}
                              required
                            />
                          </Col>
                        </Row>

                        <hr></hr>
                        <Row>
                          <Col lg={6}>
                            <button className="btn btn-primary" type="submit">
                              Send
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                navigate("/shipments-organisation")
                              }}
                            >
                              Cancel
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </FormikProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <SwalError
              showError={showSwalError}
              setShowError={setShowSwalError}
              ErrorMsg={swalErrorMsg}
            ></SwalError>
          </Container>
        </div>
        <Modal isOpen={shipmentNoModal} centered={true} size="lg">
          <div className="modal-header mdl_header">
            <h5 className="modal-title mt-0">{"Shipment Number"}</h5>
            <button
              type="button"
              className="btn close mt-2"
              onClick={() => setShipmentModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <FormikProvider value={formikShipmentNo}>
              <Form onSubmit={formikShipmentNo.handleSubmit}>
                <Row className="mt-3">
                  <Col lg={12}>
                    Your shipment number are set on auto-generate mode to save
                    your time. Are you sure about changing this setting
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={12}>
                    <Input
                      type="radio"
                      name="Setting"
                      id="Setting"
                      className="form-check-input"
                      onChange={e => {
                        formikShipmentNo.setFieldValue(
                          "Setting",
                          e?.target.value
                        )
                        formikShipmentNo.setFieldValue("AddManually", false)
                      }}
                      checked={Setting}
                    />
                    &nbsp;
                    <label className="custom-control-label" htmlFor="Setting">
                      Continue auto-generating shipment number
                    </label>
                  </Col>
                </Row>
                {Setting ? (
                  <>
                    <div className="table-responsive mt-3">
                      <table className="table table-bordered mb-0">
                        <thead>
                          <tr className="table-light">
                            <th style={{ minWidth: "200px" }}>Prefix</th>
                            <th style={{ minWidth: "200px" }}>Next Number</th>
                            <th style={{ minWidth: "50px" }}>Default</th>
                            <th style={{ minWidth: "80px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ListofShipmentNumber &&
                            ListofShipmentNumber.map(
                              (shipmentNumber, index) => (
                                <tr>
                                  <td>
                                    <Field
                                      type="text"
                                      name={`ListofShipmentNumber.[${index}].Prefix`}
                                      placeholder={"Prefix"}
                                      component={CustomInput}
                                      style={{ textTransform: "Uppercase" }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      type="number"
                                      name={`ListofShipmentNumber.[${index}].NextNumber`}
                                      placeholder={"Next Number"}
                                      component={CustomInput}
                                    />
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <Input
                                      type="radio"
                                      name={`ListofShipmentNumber.[${index}].Default`}
                                      id={`ListofShipmentNumber.[${index}].Default`}
                                      className="form-check-input"
                                      onChange={e => {
                                        formikShipmentNo.setFieldValue(
                                          `ListofShipmentNumber.[${index}].Default`,
                                          e?.target.checked
                                        )
                                      }}
                                      checked={shipmentNumber.Default}
                                    />
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      <Button
                                        className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                          padding: "0px",
                                        }}
                                        onClick={() => {}}
                                        title="Edit"
                                      >
                                        <i
                                          className="fas fa-edit"
                                          style={{ color: "#17a98c" }}
                                        ></i>
                                      </Button>
                                      &nbsp;
                                      <Button
                                        className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                          padding: "0px",
                                        }}
                                        title="Delete"
                                        onClick={() => {
                                          delete_ShipmentNumber(index)
                                        }}
                                      >
                                        <i
                                          class="fas fa-trash-alt"
                                          style={{
                                            color: "red",
                                          }}
                                        ></i>
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                    <Row className="mt-2">
                      <Col style={{ textAlign: "end" }}>
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={() => {
                            AddShipmentNumber()
                          }}
                        >
                          <i
                            class="fas fa-plus-circle"
                            style={{ color: "dodgerblue" }}
                          ></i>
                          &nbsp; Add More
                        </button>{" "}
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
                <Row className="mt-3">
                  <Col lg={12}>
                    <Input
                      type="radio"
                      name="AddManually"
                      id="AddManually"
                      className="form-check-input"
                      onChange={e => {
                        formikShipmentNo.setFieldValue(
                          "AddManually",
                          e?.target.value
                        )
                        formikShipmentNo.setFieldValue("Setting", false)
                      }}
                      checked={AddManually}
                    />
                    &nbsp;
                    <label
                      className="custom-control-label"
                      htmlFor="AddManually"
                    >
                      I will add them manually each time
                    </label>
                  </Col>
                </Row>
                <hr></hr>
                <Row className="mt-3">
                  <Col lg={12} style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" type="submit">
                      Save
                    </button>
                    &nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setShipmentModal(false)
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </FormikProvider>
          </div>
        </Modal>
      </Spin>
    </React.Fragment>
  )
}
AddShipmentOrganisation.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(AddShipmentOrganisation))
