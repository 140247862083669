import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"
import { POST_login, POST_Logout } from "helpers/url_helper"
import { post } from "helpers/api_helper"

import swal from "sweetalert"
const fireBaseBackend = getFirebaseBackend()

const loginPost = data => {
  return post(POST_login, data)
}

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      // const response = yield call(postFakeLogin, {
      //   email: user.email,
      //   password: user.password,
      // })
      // localStorage.setItem("authUser", JSON.stringify(response))
      // yield put(loginSuccess(response))
      const response = yield call(loginPost, {
        email: user.email,
        password: user.password,
      })
      if (response.status === 200) {
        localStorage.setItem("authUser", JSON.stringify(response?.data))
        //history("/dashboard")
        window.location = "/dashboard" //for token refresh
        yield put(loginSuccess(response))
        yield put(registerUserSuccessful(response))
      } else {
        swal("Oops", "Something went wrong..!", "error")
      }
    }
    history("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    post(POST_Logout)
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.log(error)
      })
    // localStorage.removeItem("authUser")
    // localStorage.removeItem("userCompanyInfo")
    localStorage.clear()
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
