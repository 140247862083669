import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Input,
  Badge,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CustomTable from "../../Custom/TableWithRemotePagination"
import classnames from "classnames"
import * as Yup from "yup"
import CustomInput from "Custom/CustomInput"
import { Field, FormikProvider, useFormik } from "formik"
import CustomSelect from "Custom/CustomSelect"
import { Spin } from "antd"
import { get, post } from "helpers/api_helper"
import {
  GET_ShipmentDetails,
  GET_ShipmentList,
  GET_UserCompanyInfo,
} from "helpers/url_helper"

import swal from "sweetalert"
import moment from "moment"

const Shipment = props => {
  const navigate = useNavigate()
  const [ID, setID] = useState("")

  const [totalSize, setTotalSize] = useState(3)
  const [hide, setHide] = useState(1)
  const [mode, setMode] = useState(1)

  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState("")

  const [shipmentDetail, setShipmentDetail] = useState([])
  const [packagesDetails, setPackagesDetails] = useState([])
  const [subPackagesDetails, setSubPackagesDetails] = useState([])
  const [shipmentStatus, setShipmentStatus] = useState("")

  const [shipmentAddress, setShipmentAddress] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10)
  const [shipmentListData, setShipmentListData] = useState([])
  const [packagesCount, setPackagesTotal] = useState([])
  const [orderDetails, setOrderDetails] = useState([])

  const [shipmentList, setShipmentList] = useState([])
  const [carrier, setCarrier] = useState([])

  const AuthUser = JSON.parse(localStorage.getItem("authUser"))
  const userCompanyInfo = JSON.parse(localStorage.getItem("userCompanyInfo"))

  const validationSchemaFilter = Yup.object().shape({})

  const formikFilter = useFormik({
    validationSchema: validationSchemaFilter,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitFilter(values, formikHelpers),
    initialValues: {
      Status: "",
      Search: "",
      SearchByColumn: "",
      // ContactID: 0,
      ContactID:
        AuthUser?.org == true
          ? userCompanyInfo?.organization?.id
          : userCompanyInfo?.["0"]?.contact_id,
      OrgFlag: AuthUser?.org,
    },
    validateOnBlur: false,
  })

  const {
    setFieldValue,
    values: { Search, Status, SearchByColumn, ContactID, OrgFlag },
  } = formikFilter

  const handleValidSubmitFilter = values => {}

  const columns = [
    {
      text: (
        <>
          <Input
            type="checkbox"
            className="form-check-input"
            id="Add"
            checked={false}
          />
          &nbsp; Date
        </>
      ),
      dataField: "Date",
      style: { minWidth: "150px" },
    },
    {
      text: "Shipment Order No.",
      dataField: "ShipmentOrderNo",
      style: { minWidth: "180px" },
    },
    {
      text: "Customer Name",
      dataField: "CustomerName",
      style: { minWidth: "200px" },
    },
    {
      text: "Sales Order No.",
      dataField: "SalesOrderNo",
      style: { minWidth: "150px" },
    },
    {
      text: "Package No.",
      dataField: "PackageNo",
      style: { minWidth: "150px" },
    },
    {
      text: "Carrier",
      dataField: "Carrier",
      style: { minWidth: "150px" },
    },
    {
      text: "Tracking No.",
      dataField: "TrackingNo",
      style: { minWidth: "150px" },
    },

    {
      text: "Status",
      dataField: "Status",
      style: { minWidth: "150px" },
    },
    {
      text: "Shipping Rate",
      dataField: "ShippingRate",
      style: { minWidth: "120px", textAlign: "right" },
    },
  ]

  const getData = (page = 1, sizePerPage = 10) => {
    setIsLoading(true)

    get(
      GET_ShipmentList +
        "?paginate=" +
        sizePerPage +
        "&page=" +
        page +
        "&contact_id=" +
        ContactID +
        (Search ? "&search=" + Search : "") +
        (SearchByColumn ? "&column_filter=" + SearchByColumn : "") +
        (Status ? "&status_filter=" + Status : "")
    )
      .then(res => {
        if (res.status === 200) {
          //console.log("data", res)
          if (res?.data?.data?.length > 0) {
            setShipmentList(
              res?.data?.data?.map((e, index) => {
                return {
                  Date: (
                    <>
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="Add"
                        checked={false}
                      />{" "}
                      &nbsp;{" "}
                      {e?.shipment_date
                        ? `${moment(e?.shipment_date)
                            .format("DD-MMM-YYYY")
                            .replace(/^\-/, "")}`
                        : ""}
                    </>
                  ),
                  ShipmentOrderNo: (
                    <>
                      {AuthUser?.permissions?.filter(
                        x => x.resource_id == 11 && x.view_per == 1
                      )?.length > 0 ? (
                        <Link
                          onClick={() => {
                            setHide(2)
                            getShipmentsDetail(e.id)
                            setCarrier(e.shipment_carrier)
                            setID(e.id)
                            setShipmentStatus(e.status)
                          }}
                        >
                          {e.shipment_number}
                        </Link>
                      ) : (
                        e.shipment_number
                      )}
                    </>
                  ),
                  Carrier: e.shipment_carrier,
                  TrackingNo: e.tracking_no,
                  SalesOrderNo: e.sales_order?.sales_order_no,
                  PackageNo: Array.from(e.package).join(" , "),
                  Status: (
                    <>
                      <text
                        style={{
                          color: e.status === "draft" ? "grey" : "green",
                          textTransform: "uppercase",
                        }}
                      >
                        {e.status}
                      </text>
                    </>
                  ),
                  CustomerName: e?.organization?.display_name,
                  ShippingRate: e.shipping_charges
                    ? parseFloat(e.shipping_charges).toFixed(2)
                    : "",
                }
              })
            )
            setTotalSize(res.data?.total)
            setIsLoading(false)
            setShipmentListData(res?.data?.data)
          }
        } else {
          setShipmentList([])
          setTotalSize(0)
          setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
    setCurrentPage(page)
    setCurrentSizePerPage(sizePerPage)
  }

  // useEffect(() => {
  //   //   get(GET_UserCompanyInfo).then(res => {
  //   //     if (res?.data) {
  //   if (OrgFlag == true) {
  //     formikFilter.setFieldValue("ContactID", userCompanyInfo?.organization?.id)
  //   } else {
  //     formikFilter.setFieldValue(
  //       "ContactID",
  //       userCompanyInfo?.["0"]?.contact_id
  //     )
  //   }
  //   //     }
  //   //   })
  //   getData()
  // }, [OrgFlag])

  // useEffect(() => {
  //   getData()
  // }, [Search, SearchByColumn, Status, ContactID])

  useEffect(() => {
    getData()
  }, [Search, SearchByColumn, Status])

  useEffect(() => {
    if (ID) {
      getShipmentsDetail(ID)
    }
  }, [ID])

  const shipmentOptions = [
    // { label: "Shipment Order No.", value: "shipment_order_no" },
    // { label: "Customer Name", value: "customer_name" },
    // { label: "Sales Order No.", value: "sales_order_no" },
    { label: "Package No.", value: "package_no" },
    // { label: "Carrier", value: "carrier" },
    // { label: "Tracking No.", value: "tracking_no" },
  ]

  const statusOption = [
    // { label: "ALL", value: 0 },
    { label: "DRAFT", value: "draft" },
    { label: "SHIPPED", value: "shipped" },
    { label: "NOT SHIPPED", value: "not_shipped" },
  ]

  const getShipmentsDetail = id => {
    setIsLoading(true)

    get(GET_ShipmentDetails + `${id}`).then(res => {
      if (res?.status === 200) {
        //console.log(res)
        setShipmentDetail(res?.data)
        setPackagesDetails(res?.data?.packages)
        setShipmentAddress(res?.data?.shipping_address)
        setOrderDetails(res?.data?.order_details)

        let subpackage = []
        res?.data?.packages?.map((e, index) => {
          setPackagesTotal(index + 1)
          e?.package_details
            ?.filter(y => y.package_id === e.id)
            ?.map((y, z) => subpackage.push(y))
        })

        setSubPackagesDetails(subpackage)
        //console.log("details", subpackage)

        //{setPackagesTotal(i + 1)}
        // setFieldValue("ListOfFulfillment", OrderItemsNew)
      }
    })
    setIsLoading(false)
  }

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{"Shipments"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            {hide === 1 ? (
              <>
                <Row className="mt-3">
                  <Col lg={6}>
                    <div className="mt-2" style={{ fontSize: "large" }}>
                      <b>Shipments</b>
                    </div>
                  </Col>
                  <Col lg={6} style={{ textAlign: "end" }}>
                    {AuthUser?.permissions?.filter(
                      x => x.resource_id == 11 && x.create_per == 1
                    )?.length > 0 ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          navigate("/add-shipment")
                        }}
                      >
                        Add Shipment
                      </button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="col-12">
                    <FormikProvider value={formikFilter}>
                      <Form onSubmit={formikFilter.handleSubmit}>
                        <Row>
                          <Col lg={12}>
                            <Row>
                              <Col lg={3} sm={6} md={3}>
                                <div class="form-group has-search">
                                  <span class="fa fa-search form-control-feedback"></span>
                                  <Input
                                    type="text"
                                    name="Search"
                                    class="form-control"
                                    placeholder="Search"
                                    value={Search}
                                    onChange={e => {
                                      // setSearch(e.target.value)
                                      formikFilter.setFieldValue(
                                        "Search",
                                        e.target.value
                                      )
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg={2} sm={6} md={2}>
                                <Field
                                  name="SearchByColumn"
                                  placeholder={"By Column"}
                                  component={CustomSelect}
                                  options={shipmentOptions}
                                />
                              </Col>
                              <Col lg={2} md={2}>
                                <Field
                                  name="Status"
                                  placeholder={"Status"}
                                  component={CustomSelect}
                                  options={statusOption}
                                />{" "}
                              </Col>
                              <Col
                                lg={2}
                                className="mt-1"
                                style={{ textAlign: "left" }}
                              >
                                <div
                                  onClick={() => {
                                    formikFilter.setFieldValue("Status", "")
                                    formikFilter.setFieldValue("Search", "")
                                    formikFilter.setFieldValue(
                                      "SearchByColumn",
                                      ""
                                    )
                                  }}
                                >
                                  <i
                                    className="mdi mdi-filter-remove"
                                    style={{
                                      fontSize: "20px",
                                      color: "#4a539f",
                                    }}
                                  ></i>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </FormikProvider>
                    <Card>
                      <CardBody>
                        <CustomTable
                          keyField="Vendor"
                          columns={columns}
                          data={shipmentList}
                          totalSize={totalSize}
                          getData={getData}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col
                    lg={3}
                    md={3}
                    sm={3}
                    style={{
                      height: "100%",
                    }}
                  >
                    <Row style={{ background: "#f0f0f0", marginLeft: "-25px" }}>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{ marginTop: "20px", marginBottom: "6px" }}
                      >
                        <div className="d-flex">
                          <div style={{ width: "400px" }}>All Shipments</div>
                        </div>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{
                          borderTop: "1px solid #cacaca",
                        }}
                      >
                        {shipmentListData?.map((x, i) => (
                          <Row
                            style={{
                              marginBottom: "5px",
                              marginTop: "5px",
                              backgroundColor: x.id === ID ? "#cecece96" : "",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setID(x?.id)
                              setCarrier(x.shipment_carrier)
                              setShipmentStatus(x.status)
                            }}
                          >
                            <div className="col-1 col-lg-1 col-md-1 col-sm-2">
                              {" "}
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="check"
                                checked={false}
                              />
                            </div>
                            <div className="col col-lg-6 col-md-6 col-sm-6">
                              <text>{x?.organization?.display_name}</text>
                              <br></br>
                              <div
                                style={{ fontSize: "10px", marginTop: "6px" }}
                              >
                                <text style={{ fontSize: "10px" }}>
                                  {x?.shipment_date
                                    ? `${moment(x?.shipment_date)
                                        .format("DD-MMM-YYYY")
                                        .replace(/^\-/, "")}`
                                    : ""}
                                </text>
                              </div>

                              <text style={{ fontSize: "10px" }}>
                                {Array.from(x.package).join(" , ")}
                              </text>
                            </div>
                            <div
                              className="col col-lg-5 col-md-5 col-sm-4"
                              style={{
                                marginLeft: "-2px",
                                textAlign: "end",
                                padding: "0.05rem 0.5rem",
                              }}
                            >
                              <div
                                style={{ fontSize: "10px", marginTop: "6px" }}
                              >
                                <Link to="#">{x.shipment_number}</Link> |{" "}
                                {x.sales_order?.sales_order_no}
                              </div>

                              <Link style={{ fontSize: "10px" }}>
                                {x.status}
                              </Link>
                              <br></br>
                              {/* <text style={{ fontSize: "10px" }}>asasd</text> */}
                            </div>
                          </Row>
                        ))}
                        {/* <Row style={{ marginBottom: "5px", marginTop: "5px" }}>
                          <div className="col-1 col-lg-1 col-md-1 col-sm-2">
                            {" "}
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="check"
                              checked={false}
                            />
                          </div>
                          <div className="col col-lg-6 col-md-6 col-sm-6">
                            <text>Deep</text>
                            <br></br>
                            <div style={{ fontSize: "10px", marginTop: "6px" }}>
                              <Link to="#">1547-9584</Link> | SO-00001
                            </div>

                            <text style={{ fontSize: "10px" }}>05/06/2023</text>
                          </div>
                          <div
                            className="col col-lg-5 col-md-5 col-sm-4"
                            style={{
                              marginLeft: "-2px",
                              textAlign: "end",
                              padding: "0.05rem 0.5rem",
                            }}
                          >
                            <text>asasd</text>
                            <br></br>
                            <Link style={{ fontSize: "10px" }}>Shipped</Link>
                            <br></br>
                            <text style={{ fontSize: "10px" }}>asasd</text>
                          </div>
                        </Row> */}
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    lg={9}
                    md={9}
                    sm={9}
                    style={{ borderLeft: "1px solid #cacaca" }}
                  >
                    <Row>
                      <Col
                        lg={12}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <div style={{ fontSize: "16px", color: "grey" }}>
                          {shipmentDetail.shipment_number}
                        </div>
                        <div style={{ textAlignLast: "end" }}>
                          <button
                            type="button"
                            className="btn"
                            style={{
                              marginTop: "-50px",
                              fontSize: "20px",
                              position: "sticky",
                              border: 0,
                            }}
                            onClick={() => setHide(1)}
                          >
                            <span>×</span>
                          </button>
                        </div>
                      </Col>
                      <Col lg={12} style={{ borderTop: "1px solid #cacaca" }}>
                        <Row>
                          <Nav tabs className="nav-tabs-custom">
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: mode === 1,
                                })}
                                onClick={() => {
                                  setMode(1)
                                }}
                              >
                                Packages &nbsp;
                                <Badge color="primary" className="rounded-pill">
                                  {packagesCount}
                                </Badge>
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent
                            activeTab={mode}
                            className="p-3 text-muted"
                          >
                            <TabPane tabId={1}>
                              <Col
                                lg={12}
                                md={12}
                                sm={12}
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <div className="table-responsive">
                                      <Table className="table mb-0">
                                        <thead>
                                          <tr>
                                            <th style={{ width: "250px" }}>
                                              Package#
                                            </th>
                                            {/* <th style={{ width: "150px" }}>
                                              Package No
                                            </th> */}
                                            <th>Date</th>
                                            <th
                                              style={{
                                                width: "200px",
                                              }}
                                            >
                                              Total Quantity
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {packagesDetails?.map((x, i) => (
                                            <tr>
                                              {/* <th>
                                                <i className="ti-dropbox"></i>
                                                &nbsp;
                                                <Link to="#">
                                               
                                                </Link>
                                              </th> */}
                                              <th>{x.package_no}</th>
                                              <td>
                                                {x.date
                                                  ? `${moment(x.date)
                                                      .format("DD-MMM-YYYY")
                                                      .replace(/^\-/, "")}`
                                                  : ""}
                                              </td>
                                              <td>
                                                {x.quantity_packed}
                                                &nbsp; &nbsp; pcs
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="mt-3">
                                  <Col lg={12}>
                                    <Card
                                      style={{
                                        borderRadius: "5px",
                                        backgroundColor: "#80808012",
                                      }}
                                    >
                                      <CardBody>
                                        <Row className="mb-3">
                                          <Col
                                            lg={12}
                                            style={{ fontSize: "16px" }}
                                          >
                                            <i class="fas fa-shipping-fast"></i>{" "}
                                            &nbsp; Shipment Details
                                          </Col>
                                        </Row>
                                        <Row
                                          style={{
                                            borderTop: "1px solid #cacaca",
                                          }}
                                        >
                                          <Col lg={6}>
                                            <Row>
                                              <Col
                                                lg={5}
                                                md={5}
                                                sm={5}
                                                className="mt-3"
                                              >
                                                Date of Shipment
                                              </Col>
                                              <Col
                                                lg={1}
                                                md={1}
                                                sm={1}
                                                className="mt-3"
                                              >
                                                :
                                              </Col>
                                              <Col
                                                lg={6}
                                                md={6}
                                                sm={6}
                                                className="mt-3"
                                              >
                                                {shipmentDetail.shipment_date
                                                  ? `${moment(
                                                      shipmentDetail.shipment_date
                                                    )
                                                      .format("DD-MMM-YYYY")
                                                      .replace(/^\-/, "")}`
                                                  : ""}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg={5} md={5} sm={5}>
                                                Carrier
                                              </Col>
                                              <Col lg={1} md={1} sm={1}>
                                                :
                                              </Col>
                                              <Col lg={6} md={6} sm={6}>
                                                {carrier}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg={5} md={5} sm={5}>
                                                {/* Carrier */}
                                                Tracking Url
                                              </Col>
                                              <Col lg={1} md={1} sm={1}>
                                                :
                                              </Col>
                                              <Col lg={6} md={6} sm={6}>
                                                {shipmentDetail.tracking_url}
                                              </Col>
                                            </Row>

                                            <Row>
                                              <Col lg={5} md={5} sm={5}>
                                                Tracking#{" "}
                                              </Col>
                                              <Col lg={1} md={1} sm={1}>
                                                :
                                              </Col>
                                              <Col lg={6} md={6} sm={6}>
                                                {shipmentDetail.tracking_no}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg={5} md={5} sm={5}>
                                                Tracking Status
                                              </Col>
                                              <Col lg={1} md={1} sm={1}>
                                                :
                                              </Col>
                                              <Col lg={6} md={6} sm={6}>
                                                {shipmentDetail.status}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg={5} md={5} sm={5}>
                                                Shipment Rate
                                              </Col>
                                              <Col lg={1} md={1} sm={1}>
                                                :
                                              </Col>
                                              <Col lg={6} md={6} sm={6}>
                                                {shipmentDetail.shipping_charges
                                                  ? shipmentDetail.shipping_charges
                                                  : ""}
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col
                                            lg={6}
                                            className="mt-3"
                                            style={{
                                              borderLeft: "1px solid #cacaca",
                                            }}
                                          >
                                            Shipment Notes :{" "}
                                            {shipmentDetail.notes
                                              ? shipmentDetail.notes
                                              : ""}
                                            <br></br>
                                            {/* <Link to="#">Add Notes</Link> */}
                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                                <Row style={{ backgroundColor: "#f8f9fa00" }}>
                                  <Col lg={1} md={1} sm={1}></Col>
                                  <Col
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <Row>
                                      <Col lg={2}>
                                        <Badge
                                          color="primary"
                                          className="bg-primary"
                                          style={{
                                            height: " 23px",
                                            width: "73px",
                                            fontSize: "15px",
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          {shipmentStatus}
                                        </Badge>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3">
                                      <Col lg={6} md={6} sm={6}>
                                        {/* {shipmentAddress?.addresses
                                          ?.filter(x => x.type === "shipping")
                                          ?.map(x => (
                                            <text>
                                              {x.nickname_address}
                                              <br></br>
                                              {x.attention}, <br></br>
                                              {x.address_line_1}, <br></br>
                                              {x.address_line_2}, <br></br>
                                              {x.address_line_3},{x.zip_code},
                                              {x.phone}
                                            </text>
                                          ))} */}
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        style={{ textAlign: "right" }}
                                      >
                                        <text
                                          style={{
                                            fontSize: "25px",
                                            color: "black",
                                          }}
                                        >
                                          SHIPMENT ORDER
                                        </text>
                                        <br></br>Shipment Order : &nbsp; &nbsp;
                                        {shipmentDetail.shipment_number}
                                      </Col>
                                    </Row>

                                    <Row className="mt-4">
                                      <Col lg={6}>
                                        Ship To<br></br>
                                        <Link to="#">
                                          {}
                                          {shipmentDetail?.organization
                                            ?.display_name
                                            ? shipmentDetail.organization
                                                ?.display_name
                                            : ""}
                                          <br></br>
                                          <text>
                                            {shipmentAddress?.nickname_address}
                                            <br></br>
                                            {shipmentAddress?.attention
                                              ? shipmentAddress?.attention + ","
                                              : ""}
                                            <br></br>
                                            {shipmentAddress?.address_line_1
                                              ? shipmentAddress?.address_line_1 +
                                                ","
                                              : ""}
                                            <br></br>
                                            {shipmentAddress?.address_line_2
                                              ? shipmentAddress?.address_line_2 +
                                                ","
                                              : ""}
                                            <br></br>
                                            {shipmentAddress?.address_line_3
                                              ? shipmentAddress?.address_line_3 +
                                                ","
                                              : ""}
                                            {shipmentAddress?.zip_code
                                              ? shipmentAddress?.zip_code + ","
                                              : ""}
                                            {shipmentAddress?.phone}
                                          </text>
                                        </Link>
                                      </Col>
                                      <Col lg={6}>
                                        <Row>
                                          <Col
                                            lg={6}
                                            style={{ textAlign: "right" }}
                                          >
                                            Sales order# :
                                          </Col>
                                          <Col
                                            lg={6}
                                            style={{ textAlign: "right" }}
                                          >
                                            {
                                              shipmentDetail?.sales_order
                                                ?.sales_order_no
                                            }
                                          </Col>
                                          <Col
                                            lg={6}
                                            style={{ textAlign: "right" }}
                                          >
                                            Order Date :
                                          </Col>
                                          <Col
                                            lg={6}
                                            style={{ textAlign: "right" }}
                                          >
                                            {shipmentDetail?.sales_order
                                              ?.created_at
                                              ? `${moment(
                                                  shipmentDetail?.sales_order
                                                    ?.created_at
                                                )
                                                  .format("DD-MMM-YYYY")
                                                  .replace(/^\-/, "")}`
                                              : ""}
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                    <Row className="mt-4">
                                      <Col lg={12}>
                                        <div className="table-responsive">
                                          <Table className="table mb-0">
                                            <thead
                                              style={{
                                                backgroundColor: "#484e53",
                                                color: "white",
                                              }}
                                            >
                                              <tr>
                                                <th style={{ width: "50px" }}>
                                                  #
                                                </th>
                                                <th>Item & Description</th>

                                                <th
                                                  style={{
                                                    textAlign: "right",
                                                    width: "100px",
                                                  }}
                                                >
                                                  Qty
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {orderDetails?.map((x, y) => (
                                                <tr>
                                                  <th scope="row">
                                                    {parseInt(y + 1)}
                                                  </th>
                                                  <td>{x.product_name}</td>

                                                  <td
                                                    style={{
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    {x.quantity_packed}
                                                    &nbsp; &nbsp; pcs
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </Table>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </TabPane>
                          </TabContent>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </div>
      </Spin>
    </React.Fragment>
  )
}
Shipment.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Shipment))
