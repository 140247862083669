import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Input,
  Badge,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  Button,
  Label,
  Modal,
} from "reactstrap"
import { useNavigate, Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CustomTable from "../../Custom/TableWithRemotePagination"
import * as Yup from "yup"
import CustomInput from "Custom/CustomInput"
import { Field, FormikProvider, useFormik } from "formik"
import CustomSelect from "Custom/CustomSelect"
import { Spin } from "antd"
import { get, post } from "helpers/api_helper"
import {
  GET_PackagesList,
  //GET_UserCompanyInfo,
  POST_PackageDetails,
} from "helpers/url_helper"

import moment from "moment"

const Package = props => {
  const navigate = useNavigate()
  const [ID, setID] = useState("")
  const [totalSize, setTotalSize] = useState(0)
  const [hide, setHide] = useState(1)

  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState("")

  const [packageList, setPackageList] = useState([])
  const [packageListData, setPackageListData] = useState([])
  const [packagesDetails, setPackagesDetails] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10)
  const [shipmentAddress, setShipmentAddress] = useState([])
  const [totalQty, setTotalQty] = useState(0)
  setTotalQty

  const AuthUser = JSON.parse(localStorage.getItem("authUser"))
  const userCompanyInfo = JSON.parse(localStorage.getItem("userCompanyInfo"))

  const columns = [
    {
      text: (
        <>
          <Input
            type="checkbox"
            className="form-check-input"
            id="Add"
            checked={false}
          />
          &nbsp; Date
        </>
      ),
      dataField: "Date",
      style: { minWidth: "150px" },
    },
    {
      text: "Package No.",
      dataField: "PackageNo",
      style: { minWidth: "150px" },
    },
    {
      text: "Carrier",
      dataField: "Carrier",
      style: { minWidth: "150px" },
    },
    {
      text: "Tracking No.",
      dataField: "TrackingNo",
      style: { minWidth: "150px" },
    },
    {
      text: "Sales Order No.",
      dataField: "SalesOrderNo",
      style: { minWidth: "150px" },
    },
    {
      text: "Status",
      dataField: "Status",
      style: { minWidth: "150px" },
    },
    {
      text: "Shipment Date",
      dataField: "ShipmentDate",
      style: { minWidth: "150px" },
    },
    {
      text: "Customer Name",
      dataField: "CustomerName",
      style: { minWidth: "200px" },
    },
    {
      text: "Quantity",
      dataField: "Quantity",
      style: { minWidth: "100px", textAlign: "center" },
    },
  ]

  const getData = (page = 1, sizePerPage = 10) => {
    setIsLoading(true)
    get(
      GET_PackagesList +
        "?page=" +
        page +
        "&paginate=" +
        sizePerPage +
        "&contact_id=" +
        ContactID +
        (Search ? "&search=" + Search : "") +
        (SearchByColumn ? "&column_filter=" + SearchByColumn : "") +
        (Status ? "&status_filter=" + Status : "")
    )
      .then(res => {
        if (res?.data?.data?.length > 0) {
          //console.log("Data", res)
          setPackageList(
            res?.data?.data?.map((e, index) => {
              return {
                Date: (
                  <>
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id={e.id}
                      checked={false}
                    />
                    &nbsp;
                    {e?.date
                      ? `${moment(e?.date)
                          .format("DD-MMM-YYYY")
                          .replace(/^\-/, "")}`
                      : ""}
                  </>
                ),
                PackageNo: (
                  <>
                    {AuthUser?.permissions?.filter(
                      x => x.resource_id == 10 && x.view_per == 1
                    )?.length > 0 ? (
                      <Link
                        onClick={() => {
                          setHide(2)
                          getPackageDetail(e.id)
                          setID(e.id)
                        }}
                      >
                        {e.package_no}
                      </Link>
                    ) : (
                      e.package_no
                    )}
                  </>
                ),
                Carrier: e.shipment_carrier,
                TrackingNo: e.tracking_no,
                SalesOrderNo: e["sales_order_no."],
                Status: (
                  <>
                    <Link to="#">{e.status}</Link>
                  </>
                ),
                ShipmentDate: (
                  <>
                    {e?.shipment_date
                      ? `${moment(e?.shipment_date)
                          .format("DD-MMM-YYYY")
                          .replace(/^\-/, "")}`
                      : ""}
                  </>
                ),
                CustomerName: e?.user,
                Quantity: e.total_quantity_packed,
              }
            })
          )
          setTotalSize(res?.data?.total)
          setIsLoading(false)
          setPackageListData(res?.data?.data)
        } else {
          setPackageList([])
          setTotalSize(0)
          setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
    setCurrentPage(page)
    setCurrentSizePerPage(sizePerPage)
  }

  const getPackageDetail = id => {
    setIsLoading(true)

    get(POST_PackageDetails + `${id}`).then(res => {
      if (res?.status === 200) {
        setPackagesDetails(res?.data)
        setShipmentAddress(res?.data?.organization)

        let TotalQty = 0
        res?.data?.products?.map((e, index) => {
          TotalQty = TotalQty + e?.quantity_packed
        })
        setTotalQty(TotalQty)
      }
    })
    setIsLoading(false)
  }

  useEffect(() => {
    if (ID) {
      getPackageDetail(ID)
    }
  }, [ID])
  // const getData = (page = 1, sizePerPage = 10) => {
  //   setIsLoading(true)
  //   get(
  //     GET_PackagesList +
  //       "?page=" +
  //       page +
  //       "&paginate=" +
  //       sizePerPage +
  //       "&contact_id=" +
  //       11 +
  //       (Search ? "&search=" + Search : "") +
  //       (SearchByColumn ? "&column_filter=" + SearchByColumn : "") +
  //       (Status ? "&status_filter=" + Status : "")
  //   )
  //     .then(res => {
  //       if (res?.data?.data?.length > 0) {
  //         setPackageList(
  //           res?.data?.data?.map((e, index) => {
  //             return {
  //               Date: (
  //                 <>
  //                   <Input
  //                     type="checkbox"
  //                     className="form-check-input"
  //                     id={e.id}
  //                     checked={false}
  //                   />
  //                   &nbsp;
  //                   {e?.date
  //                     ? `${moment(e?.date)
  //                         .format("DD-MMM-YYYY")
  //                         .replace(/^\-/, "")}`
  //                     : ""}
  //                 </>
  //               ),
  //               PackageNo: (
  //                 <>
  //                   <Link
  //                     onClick={() => {
  //                       setHide(2)
  //                     }}
  //                   >
  //                     {e.package_no}
  //                   </Link>
  //                 </>
  //               ),
  //               Carrier: e.shipment_carrier,
  //               TrackingNo: e.tracking_no,
  //               SalesOrderNo: e["sales_order_no."],
  //               Status: (
  //                 <>
  //                   <Link to="#">{e.status}</Link>
  //                 </>
  //               ),
  //               ShipmentDate: (
  //                 <>
  //                   {e?.shipment_date
  //                     ? `${moment(e?.shipment_date)
  //                         .format("DD-MMM-YYYY")
  //                         .replace(/^\-/, "")}`
  //                     : ""}
  //                 </>
  //               ),
  //               CustomerName: "",
  //               Quantity: e.total_quantity_packed,
  //             }
  //           })
  //         )
  //         setTotalSize(res?.data?.total)
  //         setIsLoading(false)
  //         setPackageListData(res?.data?.data)
  //         //}
  //       } else {
  //         setPackageList([])
  //         setTotalSize(0)
  //         setIsLoading(false)
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       setIsLoading(false)
  //     })
  //   setCurrentPage(page)
  //   setCurrentSizePerPage(sizePerPage)
  // }

  const validationSchemaFilter = Yup.object().shape({})

  const formikFilter = useFormik({
    validationSchema: validationSchemaFilter,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitFilter(values, formikHelpers),
    initialValues: {
      Status: "",
      Search: "",
      SearchByColumn: "",
      // ContactID: 0,
      ContactID:
        AuthUser?.org == true
          ? userCompanyInfo?.organization?.id
          : userCompanyInfo?.["0"]?.contact_id,
      OrgFlag: AuthUser?.org,
    },
    validateOnBlur: false,
  })

  const {
    setFieldValue,
    values: { Search, Status, SearchByColumn, ContactID, OrgFlag },
  } = formikFilter

  const handleValidSubmitFilter = values => {}

  // useEffect(() => {
  //   //   get(GET_UserCompanyInfo).then(res => {
  //   //     if (res?.data) {
  //   if (OrgFlag == true) {
  //     formikFilter.setFieldValue("ContactID", userCompanyInfo?.organization?.id)
  //   } else {
  //     formikFilter.setFieldValue(
  //       "ContactID",
  //       userCompanyInfo?.["0"]?.contact_id
  //     )
  //   }
  //   //     }
  //   //   })
  // }, [OrgFlag])

  // useEffect(() => {
  //   getData()
  // }, [Search, SearchByColumn, Status, ContactID])

  useEffect(() => {
    getData()
  }, [Search, SearchByColumn, Status])

  const packageOptions = [
    { label: "Package No.", value: "package_no" },
    // { label: "Carrier", value: "carrier" },
    // { label: "Tracking No.", value: "tracking_no" },
    // { label: "Sales Order No.", value: "sales_order_no" },
    // { label: "Customer Name", value: "customer_name" },
  ]

  const statusOption = [
    { label: "Delivered", value: "delivered" },
    // { label: "ALL", value: 0 },
    // { label: "SHIPPED", value: 1 },
    // { label: "NOT SHIPPED", value: 2 },
  ]

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{"Packages"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            {hide === 1 ? (
              <>
                <Row className="mt-3">
                  <Col lg={6}>
                    <div className="mt-2" style={{ fontSize: "large" }}>
                      <b>Packages</b>
                    </div>
                  </Col>
                  <Col lg={6} style={{ textAlign: "end" }}>
                    {AuthUser?.permissions?.filter(
                      x => x.resource_id == 10 && x.create_per == 1
                    )?.length > 0 ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          navigate("/add-package")
                        }}
                      >
                        Add Package
                      </button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="col-12">
                    <FormikProvider value={formikFilter}>
                      <Form onSubmit={formikFilter.handleSubmit}>
                        <Row>
                          <Col lg={12}>
                            <Row>
                              <Col lg={3} sm={6} md={3}>
                                <div class="form-group has-search">
                                  <span class="fa fa-search form-control-feedback"></span>
                                  <Input
                                    type="text"
                                    name="Search"
                                    class="form-control"
                                    placeholder="Search"
                                    value={Search}
                                    onChange={e => {
                                      // setSearch(e.target.value)
                                      formikFilter.setFieldValue(
                                        "Search",
                                        e.target.value
                                      )
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg={2} sm={6} md={2}>
                                <Field
                                  name="SearchByColumn"
                                  placeholder={"By Column"}
                                  component={CustomSelect}
                                  options={packageOptions}
                                />
                              </Col>
                              <Col lg={2} md={2}>
                                <Field
                                  name="Status"
                                  placeholder={"Status"}
                                  component={CustomSelect}
                                  options={statusOption}
                                />{" "}
                              </Col>
                              <Col
                                lg={2}
                                className="mt-1"
                                style={{ textAlign: "left" }}
                              >
                                <div
                                  onClick={() => {
                                    // setFieldValue("Status", "")
                                    // setSearch(""),
                                    //   setFieldValue("SearchByColumn", "")
                                    formikFilter.setFieldValue("Status", "")
                                    formikFilter.setFieldValue("Search", "")
                                    formikFilter.setFieldValue(
                                      "SearchByColumn",
                                      ""
                                    )
                                  }}
                                >
                                  <i
                                    className="mdi mdi-filter-remove"
                                    style={{
                                      fontSize: "20px",
                                      color: "#4a539f",
                                    }}
                                  ></i>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </FormikProvider>
                    <Card>
                      <CardBody>
                        <CustomTable
                          keyField="Vendor"
                          columns={columns}
                          data={packageList}
                          totalSize={totalSize}
                          getData={getData}
                        />
                      </CardBody>
                    </Card>
                    {/* <Card>
                      <CardBody>
                        <CustomTable
                          keyField="Vendor"
                          columns={columns}
                          data={packageList}
                          totalSize={totalSize}
                          getData={getData}
                        />
                      </CardBody>
                    </Card> */}
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col
                    lg={3}
                    md={3}
                    sm={3}
                    style={{
                      height: "100%",
                    }}
                  >
                    <Row style={{ background: "#f0f0f0", marginLeft: "-25px" }}>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{ marginTop: "20px", marginBottom: "6px" }}
                      >
                        <div className="d-flex">
                          <div style={{ width: "400px" }}>Packages</div>
                          <div style={{ textAlign: "right", width: "100%" }}>
                            {AuthUser?.permissions?.filter(
                              x => x.resource_id == 10 && x.create_per == 1
                            )?.length > 0 ? (
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  navigate("/add-package")
                                }}
                                style={{
                                  padding: "0.05rem 0.5rem",
                                }}
                              >
                                + New
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{
                          borderTop: "1px solid #cacaca",
                        }}
                      >
                        {packageListData?.map((x, i) => (
                          <Row
                            style={{
                              marginBottom: "5px",
                              marginTop: "5px",
                              backgroundColor: x.id === ID ? "#cecece96" : "",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setID(x?.id)
                            }}
                          >
                            <div className="col-1 col-lg-1 col-md-1 col-sm-2">
                              {" "}
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="check"
                                checked={false}
                              />
                            </div>
                            <div className="col col-lg-6 col-md-6 col-sm-6">
                              <text>{x?.user}</text>
                              <br></br>
                              <div
                                style={{ fontSize: "10px", marginTop: "6px" }}
                              >
                                <Link to="#"> {x.package_no}</Link> |&nbsp;
                                {x["sales_order_no."]}
                              </div>

                              <text style={{ fontSize: "10px" }}>
                                {x?.date
                                  ? `${moment(x?.date)
                                      .format("DD-MMM-YYYY")
                                      .replace(/^\-/, "")}`
                                  : ""}
                              </text>
                            </div>
                            <div
                              className="col col-lg-5 col-md-5 col-sm-4"
                              style={{
                                marginLeft: "-2px",
                                textAlign: "end",
                                padding: "0.05rem 0.5rem",
                              }}
                            >
                              <text>{x.total_quantity_packed}</text>
                              <br></br>
                              <Link style={{ fontSize: "10px" }}>
                                {x.status}
                              </Link>
                              <br></br>
                              <text style={{ fontSize: "10px" }}>
                                {x.tracking_no}
                              </text>
                            </div>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    lg={9}
                    md={9}
                    sm={9}
                    style={{ borderLeft: "1px solid #cacaca" }}
                  >
                    <Row>
                      <Col
                        lg={12}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <div style={{ fontSize: "16px", color: "grey" }}>
                          {packagesDetails.package_no}
                        </div>
                        <div style={{ textAlignLast: "end" }}>
                          {/* <button
                      className="btn btn-sm  btn-light"
                      style={{ marginTop: "-50px" }}
                      onClick={() => {}}
                    >
                      Edit
                    </button> */}
                          <button
                            type="button"
                            className="btn"
                            style={{
                              marginTop: "-50px",
                              fontSize: "20px",
                              position: "sticky",
                              border: 0,
                            }}
                            onClick={() => setHide(1)}
                          >
                            <span>×</span>
                          </button>
                        </div>
                      </Col>
                      <Col lg={12} style={{ borderTop: "1px solid #cacaca" }}>
                        <Row>
                          <Row className="mt-3">
                            <Col lg={12} style={{ marginLeft: "20px" }}>
                              <Card
                                style={{
                                  borderRadius: "5px",
                                  backgroundColor: "#80808012",
                                }}
                              >
                                <CardBody>
                                  <Row>
                                    <Col lg={1}>
                                      <img
                                        src={
                                          "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png"
                                        }
                                        alt=""
                                        className="avatar-xs rounded-square"
                                        style={{
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </Col>
                                    <Col lg={10}>
                                      <text>Shipment Order#</text> &nbsp; &nbsp;
                                      <Link to="#">
                                        {" "}
                                        {
                                          packagesDetails?.shipments
                                            ?.shipment_number
                                        }
                                      </Link>{" "}
                                      <br></br>
                                      &nbsp;
                                      <Badge
                                        color="primary"
                                        className="bg-primary"
                                        style={{
                                          height: " 23px",
                                          // width: "73px",
                                          fontSize: "15px",
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {packagesDetails.status}
                                      </Badge>
                                    </Col>
                                  </Row>
                                  {packagesDetails?.shipments?.map((x, i) => (
                                    <>
                                      <Row
                                        className="mt-3"
                                        style={{
                                          borderTop: "1px solid #cacaca",
                                        }}
                                      >
                                        <Col
                                          lg={3}
                                          md={3}
                                          sm={3}
                                          className="mt-3"
                                        >
                                          Shipment Number
                                        </Col>
                                        <Col
                                          lg={1}
                                          md={1}
                                          sm={1}
                                          className="mt-3"
                                        >
                                          :
                                        </Col>
                                        <Col
                                          lg={8}
                                          md={8}
                                          sm={8}
                                          className="mt-3"
                                        >
                                          {x?.shipment_number
                                            ? x?.shipment_number
                                            : ""}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={3} md={3} sm={3}>
                                          Date of Shipment
                                        </Col>
                                        <Col lg={1} md={1} sm={1}>
                                          :
                                        </Col>
                                        <Col lg={8} md={8} sm={8}>
                                          {x?.shipment_date
                                            ? `${moment(x?.shipment_date)
                                                .format("DD-MMM-YYYY")
                                                .replace(/^\-/, "")}`
                                            : ""}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={3} md={3} sm={3}>
                                          Carrier
                                        </Col>
                                        <Col lg={1} md={1} sm={1}>
                                          :
                                        </Col>
                                        <Col lg={8} md={8} sm={8}>
                                          {x?.shipment_carrier}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={3} md={3} sm={3}>
                                          Shipment Rate
                                        </Col>
                                        <Col lg={1} md={1} sm={1}>
                                          :
                                        </Col>
                                        <Col lg={8} md={8} sm={8}>
                                          Rs. {x?.shipping_charges}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={3} md={3} sm={3}>
                                          Tracking#{" "}
                                        </Col>
                                        <Col lg={1} md={1} sm={1}>
                                          :
                                        </Col>
                                        <Col lg={8} md={8} sm={8}>
                                          {x?.tracking_no ? x?.tracking_no : ""}
                                        </Col>
                                      </Row>
                                    </>
                                  ))}
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>

                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            style={{
                              marginTop: "10px",
                            }}
                          >
                            <Row style={{ backgroundColor: "#f8f9fa00" }}>
                              <Col lg={1} md={1} sm={1}></Col>
                              <Col
                                lg={10}
                                md={10}
                                sm={10}
                                style={{ marginLeft: "10px" }}
                              >
                                <Row>
                                  <Col lg={2}>
                                    <Badge
                                      color="primary"
                                      className="bg-primary"
                                      style={{
                                        height: " 23px",
                                        width: "73px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      {packagesDetails.status}
                                    </Badge>
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col lg={6} md={6} sm={6}>
                                    {shipmentAddress?.addresses
                                      ?.filter(x => x.type === "shipping")
                                      ?.map(x => (
                                        <text>
                                          {x.nickname_address}
                                          <br></br>
                                          {x.slug} ({x.attention}), <br></br>
                                          {x.address_line_1}, <br></br>
                                          {x.address_line_2}, <br></br>
                                          {x.address_line_3}, {x.phone},
                                          <br></br>
                                          {x.zip_code} ,<br></br>
                                          {x?.city?.city_name},
                                          {x?.state?.state_name},
                                          {x?.country?.name}
                                        </text>
                                      ))}
                                  </Col>
                                  {/* <Col
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    style={{ textAlign: "right" }}
                                  >
                                    <text
                                      style={{
                                        fontSize: "25px",
                                        color: "black",
                                      }}
                                    >
                                      Package Name
                                    </text>
                                    <br></br>Package# 1547-9584
                                  </Col> */}
                                </Row>
                                <Row
                                  className="mt-4"
                                  style={{
                                    borderTop: "1px solid #cacaca",
                                    borderBottom: "1px solid #cacaca",
                                  }}
                                >
                                  <Col lg={2} className="mt-2 mb-2">
                                    <div>
                                      <b>Package#</b>
                                      <br></br>
                                      {packagesDetails.package_no}
                                    </div>
                                  </Col>
                                  <Col lg={2} className="mt-2 mb-2">
                                    <div>
                                      <b>Order Date</b>
                                      <br></br>
                                      {packagesDetails?.shipments?.shipment_date
                                        ? `${moment(
                                            packagesDetails?.shipments
                                              ?.shipment_date
                                          )
                                            .format("DD-MMM-YYYY")
                                            .replace(/^\-/, "")}`
                                        : ""}
                                    </div>
                                  </Col>
                                  <Col lg={3} className="mt-2 mb-2">
                                    <div>
                                      <b>Package Date</b>
                                      <br></br>
                                      {packagesDetails?.date
                                        ? `${moment(packagesDetails?.date)
                                            .format("DD-MMM-YYYY")
                                            .replace(/^\-/, "")}`
                                        : ""}
                                    </div>
                                  </Col>
                                  <Col lg={2} className="mt-2 mb-2">
                                    <div>
                                      <b>Sales Order#</b>
                                      <br></br>
                                      {
                                        packagesDetails?.sales_order
                                          ?.sales_order_no
                                      }
                                    </div>
                                  </Col>
                                  <Col
                                    lg={2}
                                    md={2}
                                    sm={2}
                                    className="mt-2 mb-2"
                                  >
                                    <div
                                      style={{
                                        color: "black",
                                        backgroundColor: "#80808012",
                                      }}
                                    >
                                      <center>
                                        <div></div>
                                        <b>Total Qty</b>
                                        <br></br>
                                        {totalQty}
                                      </center>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="mt-4">
                                  <Col lg={12}>
                                    Bill To<br></br>
                                    <Link to="#">
                                      {" "}
                                      {shipmentAddress?.addresses
                                        ?.filter(x => x.type === "billing")
                                        ?.map(x => (
                                          <text>
                                            {x.nickname_address}
                                            <br></br>
                                            {x.slug} ({x.attention}), <br></br>
                                            {x.address_line_1}, <br></br>
                                            {x.address_line_2}, <br></br>
                                            {x.address_line_3}, {x.phone},
                                            <br></br>
                                            {x.zip_code} ,<br></br>
                                            {x?.city?.city_name},
                                            {x?.state?.state_name},
                                            {x?.country?.name}
                                          </text>
                                        ))}
                                    </Link>
                                  </Col>
                                </Row>
                                <Row className="mt-4">
                                  <Col lg={12}>
                                    <div className="table-responsive">
                                      <Table className="table mb-0">
                                        <thead
                                          style={{
                                            backgroundColor: "#484e53",
                                            color: "white",
                                          }}
                                        >
                                          <tr>
                                            <th style={{ width: "50px" }}>#</th>

                                            <th>Item & Description</th>
                                            <th
                                              style={{
                                                textAlign: "right",
                                                width: "100px",
                                              }}
                                            >
                                              Qty
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {packagesDetails?.products?.map(
                                            (x, y) => (
                                              <tr>
                                                <th scope="row">
                                                  {parseInt(y + 1)}
                                                </th>
                                                <td>{x.product_name}</td>

                                                <td
                                                  style={{ textAlign: "right" }}
                                                >
                                                  {x.quantity_packed}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </div>
      </Spin>
    </React.Fragment>
  )
}
Package.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Package))
