import React, { Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Form,
  Badge,
  Modal,
  Input,
  Label,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CustomInput from "Custom/CustomInput"
import { Field, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import CustomSelect from "Custom/CustomSelect"
import CustomFlatpickr from "Custom/CustomFlatpicker"
import { del, get, post, postFormData } from "helpers/api_helper"
import {
  GET_ShipmentPreference,
  GET_TermsAndCondition,
  GET_PaymentTerms,
  GET_OrganisationDetail,
  GET_ProductDetail,
  GET_ProductList,
  GET_ContactTypeVendor,
  GET_ContactDetail,
  GET_TaxDropDownList,
  POST_UploadDocumnet,
  POST_CreateTermsAndConditionGroup,
  POST_CreatePurchaseOrder,
  POST_CreateSalesOrder,
  GET_PurchaseOrderDetail,
  GET_GroupTermsCondition,
  GET_TdsTypes,
  DELETE_poImage,
} from "helpers/url_helper"
import { Spin } from "antd"
import swal from "sweetalert"
import moment from "moment"
import SwalError from "Custom/SwalError"

const AddPurchaseOrder = props => {
  const navigate = useNavigate()
  const { IdParam } = useParams()

  const [PONoModal, setPONoModal] = useState(false)
  const [editTermsAndConditionModal, setEditTermsAndConditionModal] =
    useState(false)
  const [disclaimerModal, setDisclaimerModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [shipmentPreferenceOption, setShipmentPreferenceOption] = useState([])
  const [paymentTermsOption, setPaymentTermsOption] = useState([])
  const [productOptions, setProductOptions] = useState([])
  const [vendorOptions, setVendorOptions] = useState([])
  //const [selectedVendorDetails, setSelectedVendorDetails] = useState([])
  const [taxOptions, setTaxOptions] = useState([])
  const [tdsTypeOptions, setTDSTypeOptions] = useState([])

  const [showSwalError, setShowSwalError] = useState(false)
  const [swalErrorMsg, setSwalErrorMsg] = useState("")

  const initialOrderItemList = {
    product_id: 0,
    tax_id: 0,
    description: "",
    hsn_id: 0,
    unit_id: 0,
    quantity: "",
    rate: "",
    discount_amt: "",
    amount: "",
    discount_rate: "",
    taxable_amount: 0,
    hsn_code: "",
    tax_rate: 0,
    tax_amount: 0,
    itemType: "",
    PackType: "",
    DiscountType: "%",
    DiscountTypeValue: 2,
    IsOpen: false,
    tax_caption: "",
    PackTypeDDLList: [],
    id: 0,
  }

  const initialFileUpload = {
    File: "",
  }

  const validationSchema = Yup.object().shape({
    contact_id: Yup.string().required("Vendor Name is a required field."),
    purchase_order_no: Yup.string().required(
      "Purchase Order# is a required field."
    ),
  })

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      contact_id: "",
      organization_id: 1,
      purchase_order_no: "",
      reference_estimate: "",
      primary_contact_lname: "",
      shipping_address_id: 0,
      source_of_supply: "",
      destination_of_supply: "",
      billing_address_id: 0,
      purchase_order_date: "",
      expected_delivery_date: "",
      shipment_preference: "",
      payment_terms_id: 0,
      po_status: "draft",
      purchase_order_version: "",
      customer_notes: "",
      subtotal: 0,
      tds_id: 0,
      packaging_charges: 0,
      forwarding_charges: 0,
      freight_charges: 0,
      documentation_charges: 0,
      total_tax: 0,
      discount: 0,
      discount_rate: 0,
      adjustment: 0,
      total: 0,
      currency_id: 1,
      template_id: 1,
      tc_group_list_id: 0,
      order_details: [],
      file_manager_id: [],

      ListOfOrderItems: [initialOrderItemList],
      ListOffileUpload: [initialFileUpload],
      GSTTreatment: "",
      GSTIN: "",
      billingAddress: null,
      shippingAddress: null,
      source_of_supplyStateName: "",
      destination_of_supplyStateName: "",
      deliverToAddress: null,
      ListOfTaxDetail: [],
      totalQty: 0,
      tc_list: [],
      tc_display: "",

      button: "Save",
      order_file: [],
    },
    validateOnBlur: false,
  })

  const {
    setValues,
    setFieldValue,
    values: {
      contact_id,
      ListOfOrderItems,
      GSTTreatment,
      GSTIN,
      billingAddress,
      shippingAddress,
      ListOffileUpload,
      deliverToAddress,
      subtotal,
      total_tax,
      adjustment,
      packaging_charges,
      forwarding_charges,
      freight_charges,
      documentation_charges,
      total,
      totalQty,
      ListOfTaxDetail,
      order_file,
      tc_group_list_id,
    },
  } = formik

  useEffect(() => {
    getVendorList()
    getShipmentPreference()
    getTermsAndConditionList()
    getPaymentTerms()
    getProductList()
    getTaxList()
    getTDSTypeList()

    if (IdParam) {
      getPurchaseOrderDetail(IdParam)
    } else {
      getOrganizationDetails()
      //setFieldValue(`ListOfOrderItems.[${index}].PackTypeDDLList`, "")
    }
  }, [])

  const getOrganizationDetails = async () => {
    setIsLoading(true)
    await get(GET_OrganisationDetail)
      .then(res => {
        //console.log("org details: ", res)
        if (res?.status == 200) {
          if (res?.data?.addresses?.length > 1) {
            let orgAddress = res?.data?.addresses?.[1]
            if (orgAddress) {
              setFieldValue("source_of_supply", orgAddress?.state_id)
              setFieldValue(
                "source_of_supplyStateName",
                orgAddress?.state?.state_name
              )
              setFieldValue("destination_of_supply", orgAddress?.state_id)
              setFieldValue(
                "destination_of_supplyStateName",
                orgAddress?.state?.state_name
              )
              setFieldValue("deliverToAddress", orgAddress)
            }
          }
          setFieldValue("organization_id", res?.data?.id)
        } else {
          setFieldValue("organization_id", 0)
          setFieldValue("source_of_supply", 0)
          setFieldValue("source_of_supplyStateName", "")
          setFieldValue("destination_of_supply", 0)
          setFieldValue("destination_of_supplyStateName", "")
          setFieldValue("deliverToAddress", null)
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  const getVendorList = async () => {
    setIsLoading(true)
    await get(GET_ContactTypeVendor + "&page=1&paginate=10000")
      .then(res => {
        if (res?.status == 200 && res?.data?.data.length > 0) {
          let NewOptions = []
          res?.data?.data?.map(item =>
            NewOptions.push({
              label: item.company_name,
              value: item.contact_id,
            })
          )
          setVendorOptions(NewOptions)
        } else {
          setVendorOptions([])
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (contact_id) {
      getVendorDetails(contact_id)
    } else {
      //setSelectedVendorDetails([])
      setFieldValue("billingAddress", null)
      setFieldValue("shippingAddress", null)
      setFieldValue("billing_address_id", 0)
      setFieldValue("shipping_address_id", 0)
      setFieldValue("GSTTreatment", "")
      setFieldValue("GSTIN", "")
      setFieldValue("primary_contact_lname", "")
    }
  }, [contact_id])

  const getVendorDetails = async ID => {
    setIsLoading(true)
    get(GET_ContactDetail + ID)
      .then(res => {
        if (res?.status == 200) {
          //setSelectedVendorDetails(res?.data)
          let billingAddress = res?.data?.contact?.addresses?.filter(
            x => x.type == "billing"
          )[0]
          let shippingAddress = res?.data?.contact?.addresses?.filter(
            x => x.type == "shipping"
          )[0]
          setFieldValue("billingAddress", billingAddress)
          setFieldValue("shippingAddress", shippingAddress)
          setFieldValue("billing_address_id", billingAddress?.id)
          setFieldValue("shipping_address_id", shippingAddress?.id)
          setFieldValue("GSTTreatment", res?.data?.contact?.gst_treatment)
          setFieldValue("GSTIN", res?.data?.contact?.gstn)
          setFieldValue(
            "primary_contact_lname",
            res?.data?.contact?.primary_contact_lname
          )
        } else {
          //setSelectedVendorDetails([])
          setFieldValue("billingAddress", null)
          setFieldValue("shippingAddress", null)
          setFieldValue("billing_address_id", 0)
          setFieldValue("shipping_address_id", 0)
          setFieldValue("GSTTreatment", "")
          setFieldValue("GSTIN", "")
          setFieldValue("primary_contact_lname", "")
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  const getProductList = () => {
    setIsLoading(true)
    get(
      GET_ProductList +
        "?module_name=org-purchase-purchase-order&page=1&paginate=5000"
    )
      .then(res => {
        if (res?.status == 200) {
          //console.log("Response", res)
          let NewOption = []

          res?.data?.data.map((item, index) => {
            NewOption.push({
              label: item.product_name,
              value: item.id,
            })
          })

          setProductOptions(NewOption)
        } else {
          setProductOptions([])
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  const getProductDetail = (id, index) => {
    if (id > 0) {
      setIsLoading(true)
      get(
        GET_ProductDetail + `${id}` + "?module_name=org-purchase-purchase-order"
      ).then(res => {
        if (res?.status == 200) {
          let NewOption = []
          res?.data?.units?.map((item, index) => {
            NewOption.push({
              label: item?.unit?.unit_name,
              value: item?.unit_id,
            })
          })

          //console.log(res)
          setFieldValue(
            `ListOfOrderItems.[${index}].PackTypeDDLList`,
            NewOption
          )

          setFieldValue(`ListOfOrderItems.[${index}].itemType`, res?.data?.type)
          setFieldValue(
            `ListOfOrderItems.[${index}].description`,
            res?.data?.short_desc
          )
          setFieldValue(
            `ListOfOrderItems.[${index}].hsn_id`,
            res?.data?.taxes[0]?.hsn_id
          )
          setFieldValue(
            `ListOfOrderItems.[${index}].hsn_code`,
            res?.data?.taxes[0]?.hsn_code?.hsn_code
          )

          setFieldValue(
            `ListOfOrderItems.[${index}].tax_id`,
            res?.data?.taxes[0]?.tax_id
          )
          setFieldValue(
            `ListOfOrderItems.[${index}].tax_rate`,
            res?.data?.taxes[0]?.tax?.rate
          )
          setFieldValue(
            `ListOfOrderItems.[${index}].tax_caption`,
            res?.data?.taxes[0]?.tax?.name
          )

          setFieldValue(
            `ListOfOrderItems.[${index}].unit_id`,
            res?.data?.units[0]?.unit_id
          )
          setFieldValue(
            `ListOfOrderItems.[${index}].PackType`,
            res?.data?.units[0]?.unit?.unit_name
          )
        }
      })
      setIsLoading(false)
    } else {
      setFieldValue(`ListOfOrderItems.[${index}].itemType`, "")
      setFieldValue(`ListOfOrderItems.[${index}].description`, "")
      setFieldValue(`ListOfOrderItems.[${index}].hsn_id`, 0)
      setFieldValue(`ListOfOrderItems.[${index}].hsn_code`, "")
      setFieldValue(`ListOfOrderItems.[${index}].unit_id`, 0)
      setFieldValue(`ListOfOrderItems.[${index}].PackType`, "")
      setFieldValue(`ListOfOrderItems.[${index}].PackTypeDDLList`, [])
    }
  }

  const getShipmentPreference = () => {
    setIsLoading(true)
    get(GET_ShipmentPreference).then(res => {
      if (res) {
        if (res?.status == 200) {
          const valuesArray = res?.data?.shipment_preference
          var NewArrayOption = Object.entries(valuesArray)

          let NewOption = []
          NewArrayOption.map(x =>
            NewOption.push({
              label: x[1],
              value: x[0],
            })
          )

          setShipmentPreferenceOption(NewOption)
        } else {
          setShipmentPreferenceOption([])
        }
      }
    })
    setIsLoading(false)
  }

  const getTermsAndConditionList = async () => {
    setIsLoading(true)
    await get(GET_TermsAndCondition).then(res => {
      if (res) {
        if (res?.status == 200) {
          let NewOption = []
          res?.data?.map(x =>
            NewOption.push({
              ID: x.id,
              Condition: x.conditions,
              checked: false,
            })
          )
          formikEditTermsAndCondition.setFieldValue(
            "termsAndConditionsList",
            NewOption
          )
        } else {
          formikEditTermsAndCondition.setFieldValue(
            "termsAndConditionsList",
            []
          )
        }
      }
    })
    setIsLoading(false)
  }

  const getPaymentTerms = () => {
    setIsLoading(true)
    get(GET_PaymentTerms).then(res => {
      if (res) {
        if (res?.status == 200) {
          let NewOptions = []
          res.data?.map(item =>
            NewOptions.push({
              label: item.label,
              value: item.id,
            })
          )
          setPaymentTermsOption(NewOptions)
        } else {
          setPaymentTermsOption([])
        }
      }
    })
    setIsLoading(false)
  }

  const getTaxList = () => {
    setIsLoading(true)
    get(GET_TaxDropDownList)
      .then(res => {
        if (res) {
          if (res?.status == 200 && res?.data?.length > 0) {
            let NewOptions = []
            res?.data?.map(item =>
              NewOptions.push({
                label: item.name,
                value: item.id,
                rate: item.rate,
                gst_type: item.gst_type,
              })
            )
            setTaxOptions(NewOptions)
          } else {
            setTaxOptions([])
          }
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  const getTDSTypeList = () => {
    setIsLoading(true)
    get(GET_TdsTypes)
      .then(res => {
        if (res) {
          if (res?.status == 200 && res?.data?.length > 0) {
            let NewOptions = []
            res?.data?.map(item =>
              NewOptions.push({
                label: item.title,
                value: item.id,
              })
            )
            setTDSTypeOptions(NewOptions)
          } else {
            setTDSTypeOptions([])
          }
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  const initialListofPurchaseOrderNumber = {
    Prefix: "",
    NextNumber: "",
    Default: false,
    AddManually: false,
  }

  const validationSchemaPONo = Yup.object().shape({})

  const formikPONo = useFormik({
    validationSchema: validationSchemaPONo,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitPONo(values, formikHelpers),
    initialValues: {
      Setting: false,
      AddManually: true,
      ListofPurchaseOrderNumber: [initialListofPurchaseOrderNumber],
    },
    validateOnBlur: false,
  })

  const {
    values: { Setting, ListofPurchaseOrderNumber, AddManually },
  } = formikPONo

  const handleValidSubmitPONo = values => {
    setPONoModal(false)
  }

  const validationSchemaEditTermsAndCondition = Yup.object().shape({})

  const formikEditTermsAndCondition = useFormik({
    validationSchema: validationSchemaEditTermsAndCondition,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitEditTermsAndCondition(values, formikHelpers),
    initialValues: {
      //TermsAndCondition: "",
      termsAndConditionsList: [],
    },
    validateOnBlur: false,
  })

  const {
    values: { termsAndConditionsList },
  } = formikEditTermsAndCondition

  const handleValidSubmitEditTermsAndCondition = values => {
    setFieldValue("tc_list", termsAndConditionsList)
    setFieldValue(
      "tc_display",
      termsAndConditionsList
        ?.filter(x => x.checked == true)
        ?.map(tc => {
          return tc.Condition
        })
        .join(", ")
    )
    setEditTermsAndConditionModal(false)
  }

  const DiscountTypeOption = [
    { label: "₹ ", value: 1 },
    { label: "%", value: 2 },
  ]

  const getPurchaseOrderDetail = id => {
    setIsLoading(true)
    get(GET_PurchaseOrderDetail + id)
      .then(response => {
        if (response?.status == 200) {
          let data = response?.data
          setValues({
            contact_id: data?.contact_id,
            organization_id: data?.organization_id,
            purchase_order_no: data?.purchase_order_no,
            reference_estimate: data?.reference_estimate,
            primary_contact_lname: data?.organization?.primary_contact_lname,
            shipping_address_id: data?.shipping_address_id,
            source_of_supply: data?.source_of_supply,
            destination_of_supply: data?.destination_of_supply,
            billing_address_id: data?.billing_address_id,
            purchase_order_date: new Date(data?.purchase_order_date),
            expected_delivery_date: new Date(data?.expected_delivery_date),
            shipment_preference: data?.shipment_preference,
            payment_terms_id: data?.payment_terms_id,
            po_status: data?.po_status,
            purchase_order_version: data?.purchase_order_version,
            customer_notes: data?.customer_notes,
            subtotal: data?.subtotal,
            tds_id: data?.tds_id,
            packaging_charges: data?.packaging_charges,
            forwarding_charges: data?.forwarding_charges,
            freight_charges: data?.freight_charges,
            documentation_charges: data?.documentation_charges,
            total_tax: data?.total_tax,
            discount: data?.discount,
            adjustment: data?.adjustment,
            total: data?.total,
            currency_id: data?.currency_id,
            template_id: data?.template_id,
            tc_group_list_id: data?.tc_group_list_id,

            billingAddress: data?.contact?.addresses?.filter(
              x => (x.type = "billing")
            )[0],
            shippingAddress: data?.contact?.addresses?.filter(
              x => (x.type = "shipping")
            )[0],
            deliverToAddress: data?.shipping_address,
            source_of_supplyStateName:
              "[" +
              data?.supply_state?.vehicle_series_code +
              "] " +
              data?.supply_state?.state_name,
            destination_of_supplyStateName:
              "[" +
              data?.destination_state?.vehicle_series_code +
              "] " +
              data?.destination_state?.state_name,
            GSTTreatment: "",
            GSTIN: "",

            ListOfOrderItems: data?.order_item?.map(item => {
              return {
                ...item,
                hsn_code: "",
                tax_rate: taxOptions?.filter(x => x.value == item?.tax_id)[0]
                  ?.rate,
                tax_caption: taxOptions?.filter(x => x.value == item?.tax_id)[0]
                  ?.label,
                tax_amount: item?.amount - item?.taxable_amount,
                itemType: item?.product?.type,
                PackType: "",
                DiscountType: "%",
                DiscountTypeValue: 2,
                PackTypeDDLList: [],
                IsOpen: false,
              }
            }),
            ListOffileUpload: [initialFileUpload],
            ListOfTaxDetail: [],
            button: "Save",
            totalQty: 0,
            tc_list: [],
            tc_display: "",
            order_file: data?.order_file,
          })

          // if (data?.tc_group_list_id != "") {
          //   get(
          //     GET_GroupTermsCondition + parseInt(data?.tc_group_list_id)
          //   ).then(res => {
          //     if (res?.status == 200) {
          //       let groupIds = res?.data
          //       let tcState = []
          //       console.log(
          //         "termsAndConditionsList: GET PO ",
          //         termsAndConditionsList
          //       )
          //       if (termsAndConditionsList?.length > 0) {
          //         termsAndConditionsList?.map(x => {
          //           if (groupIds.filter(g => g.tandc_id == x.ID).length > 0) {
          //             tcState.push({
          //               ...x,
          //               checked: true,
          //             })
          //           } else {
          //             tcState.push({
          //               ...x,
          //             })
          //           }
          //         })

          //         formikEditTermsAndCondition.setFieldValue(
          //           "termsAndConditionsList",
          //           tcState
          //         )
          //         setFieldValue("tc_list", tcState)
          //         setFieldValue(
          //           "tc_display",
          //           tcState
          //             ?.filter(x => x.checked == true)
          //             ?.map(tc => {
          //               return tc.Condition
          //             })
          //             .join(", ")
          //         )
          //       }
          //     }
          //   })
          // }

          // calculateSubTotal()
        } else {
          // swal("Error!", response?.message, "error")
          if (typeof response?.message == "string") {
            swal({
              title: "Error!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
          setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    //console.log("tc_group_list_id EFFECT:", termsAndConditionsList)
    if (IdParam) {
      calculateSubTotal()
      if (tc_group_list_id) {
        get(GET_GroupTermsCondition + tc_group_list_id).then(res => {
          if (res?.status == 200) {
            let groupIds = res?.data
            let tcState = []

            if (termsAndConditionsList?.length > 0) {
              termsAndConditionsList?.map(x => {
                if (groupIds.filter(g => g.tandc_id == x.ID).length > 0) {
                  tcState.push({
                    ...x,
                    checked: true,
                  })
                } else {
                  tcState.push({
                    ...x,
                  })
                }
              })

              formikEditTermsAndCondition.setFieldValue(
                "termsAndConditionsList",
                tcState
              )
              setFieldValue("tc_list", tcState)
              setFieldValue(
                "tc_display",
                tcState
                  ?.filter(x => x.checked == true)
                  ?.map(tc => {
                    return tc.Condition
                  })
                  .join(", ")
              )
            }
          }
        })
      }
    }
  }, [tc_group_list_id])

  const delete_OrderItem = index => {
    setFieldValue(
      "ListOfOrderItems",
      ListOfOrderItems.filter((element, i) => i !== index)
    )

    //calculateSubTotal()
    let tDiscount = 0,
      tDiscountRate = 0,
      tSubTotal = 0,
      tTotalTax = 0,
      tTotalQty = 0,
      taxList = []

    ListOfOrderItems.filter((element, i) => i !== index)?.map(x => {
      tDiscount = parseFloat(tDiscount) + parseFloat(x.discount_amt)
      tTotalTax = parseFloat(tTotalTax) + parseFloat(x.tax_amount)
      tSubTotal = parseFloat(tSubTotal) + parseFloat(x.taxable_amount)
      tTotalQty =
        parseFloat(tTotalQty) + parseFloat(x.quantity ? x.quantity : 0)
      if (x.tax_id > 0) {
        taxList.push({
          TaxCaption: x.tax_caption,
          TaxAmount: x.tax_amount,
        })
      }
    })

    tDiscountRate = (parseFloat(tDiscount) / parseFloat(tSubTotal)) * 100
    setFieldValue("discount", tDiscount)
    setFieldValue("discount_rate", tDiscountRate)
    setFieldValue("total_tax", tTotalTax)
    setFieldValue("subtotal", tSubTotal)
    setFieldValue("totalQty", tTotalQty)

    const listOfTaxDetail = calculateTaxCaptionTotal(taxList)
    setFieldValue("ListOfTaxDetail", listOfTaxDetail)
  }

  const AddOrderItem = () => {
    setFieldValue("ListOfOrderItems", [
      ...ListOfOrderItems,
      initialOrderItemList,
    ])
  }

  const delete_PONumber = index => {
    formikPONo.setFieldValue(
      "ListofPurchaseOrderNumber",
      ListofPurchaseOrderNumber.filter((element, i) => i !== index)
    )
  }

  const AddPONumber = () => {
    formikPONo.setFieldValue("ListofPurchaseOrderNumber", [
      ...ListofPurchaseOrderNumber,
      {
        Prefix: "",
        NextNumber: "",
        Default: false,
      },
    ])
  }

  const calculateItemWiseTotal = (
    index,
    Qty,
    Rate,
    DiscountRate,
    DiscountAmt,
    TaxRate,
    DiscountTypeValue,
    DiscountType,
    TaxId,
    TaxCaption
  ) => {
    let iQty = Qty ? parseFloat(Qty) : 0,
      iRate = Rate ? parseFloat(Rate) : 0,
      iDiscountRate = DiscountRate ? parseFloat(DiscountRate) : 0,
      iDiscountAmt = DiscountAmt ? parseFloat(DiscountAmt) : 0,
      iTaxRate = TaxRate ? parseFloat(TaxRate) : 0,
      iTaxAmount = 0,
      iGrossAmount = 0,
      iTaxableAmount = 0,
      iNetAmount = 0

    //if (iQty > 0 && iRate > 0) {
    iGrossAmount = iRate * iQty
    // if (iDiscountRate > 0 || iDiscountAmt > 0) {
    /*DiscountType: 1 = Rupees, 2 = Percentage */
    if (DiscountTypeValue == 1) {
      iDiscountRate = (iDiscountAmt / iGrossAmount) * 100
    } else {
      iDiscountAmt = (iGrossAmount * iDiscountRate) / 100
    }
    // }
    iTaxableAmount = iGrossAmount - iDiscountAmt
    if (iTaxRate > 0) {
      iTaxAmount = (iTaxableAmount * iTaxRate) / 100
    }
    iNetAmount = (iTaxableAmount + iTaxAmount).toFixed(2)
    //}
    // setFieldValue(`ListOfOrderItems.[${index}].discount_rate`, iDiscountRate)
    // setFieldValue(`ListOfOrderItems.[${index}].discount_amt`, iDiscountAmt)
    // setFieldValue(`ListOfOrderItems.[${index}].tax_amount`, iTaxAmount)
    // setFieldValue(`ListOfOrderItems.[${index}].taxable_amount`, iTaxableAmount)
    // setFieldValue(`ListOfOrderItems.[${index}].amount`, iNetAmount)
    let newOrderItem = [...ListOfOrderItems]
    newOrderItem[index].quantity = Qty
    newOrderItem[index].rate = Rate
    newOrderItem[index].discount_rate = iDiscountRate
    newOrderItem[index].discount_amt = iDiscountAmt
    newOrderItem[index].tax_amount = iTaxAmount
    newOrderItem[index].taxable_amount = iTaxableAmount
    newOrderItem[index].amount = iNetAmount
    newOrderItem[index].DiscountTypeValue = DiscountTypeValue
    newOrderItem[index].DiscountType = DiscountType
    newOrderItem[index].tax_id = TaxId
    newOrderItem[index].tax_rate = TaxRate
    newOrderItem[index].tax_caption = TaxCaption
    setFieldValue("ListOfOrderItems", newOrderItem)

    calculateSubTotal()
  }

  const calculateSubTotal = () => {
    let tDiscount = 0,
      tDiscountRate = 0,
      tSubTotal = 0,
      tTotalTax = 0,
      tTotalQty = 0,
      taxList = []

    ListOfOrderItems?.map(x => {
      tDiscount = parseFloat(tDiscount) + parseFloat(x.discount_amt)
      tTotalTax = parseFloat(tTotalTax) + parseFloat(x.tax_amount)
      tSubTotal = parseFloat(tSubTotal) + parseFloat(x.taxable_amount)
      tTotalQty =
        parseFloat(tTotalQty) + parseFloat(x.quantity ? x.quantity : 0)
      if (x.tax_id > 0) {
        taxList.push({
          TaxCaption: x.tax_caption,
          TaxAmount: x.tax_amount,
        })
      }
    })

    tDiscountRate = (parseFloat(tDiscount) / parseFloat(tSubTotal)) * 100
    setFieldValue("discount", tDiscount)
    setFieldValue("discount_rate", tDiscountRate)
    setFieldValue("total_tax", tTotalTax)
    setFieldValue("subtotal", tSubTotal)
    setFieldValue("totalQty", tTotalQty)

    const listOfTaxDetail = calculateTaxCaptionTotal(taxList)
    setFieldValue("ListOfTaxDetail", listOfTaxDetail)
  }

  const calculateTaxCaptionTotal = data => {
    const taxCaptionTotal = {}

    data.forEach(tax => {
      const { TaxCaption, TaxAmount } = tax
      if (taxCaptionTotal[TaxCaption]) {
        taxCaptionTotal[TaxCaption] += TaxAmount
      } else {
        taxCaptionTotal[TaxCaption] = TaxAmount
      }
    })

    return Object.keys(taxCaptionTotal).map(TaxCaption => ({
      TaxCaption,
      TaxAmount: taxCaptionTotal[TaxCaption],
    }))
  }

  const calculateFinalTotal = () => {
    let FinalAmount =
      parseFloat(subtotal) +
      parseFloat(total_tax) +
      (adjustment ? parseFloat(adjustment) : 0) +
      (freight_charges ? parseFloat(freight_charges) : 0) +
      (documentation_charges ? parseFloat(documentation_charges) : 0) +
      (packaging_charges ? parseFloat(packaging_charges) : 0) +
      (forwarding_charges ? parseFloat(forwarding_charges) : 0)
    setFieldValue("total", FinalAmount)
  }

  useEffect(() => {
    calculateFinalTotal()
  }, [
    subtotal,
    total_tax,
    adjustment,
    freight_charges,
    documentation_charges,
    packaging_charges,
    forwarding_charges,
  ])

  const onFileSelect = (e, index) => {
    if (e?.target?.files?.length > 0) {
      if (Math.round(e?.target?.files[0].size / 1024 / 1024) > 5) {
        e.target.value = []
        swal("Attach File(s)", "You can upload file upto 5MB size!", "warning")
      } else {
        let fileArray = [...ListOffileUpload]
        fileArray[index].File = e.target.files[0]
        setFieldValue("ListOffileUpload", fileArray)
      }
    }
  }

  const delete_FileUpload = index => {
    formik.setFieldValue(
      "ListOffileUpload",
      ListOffileUpload.filter((element, i) => i !== index)
    )
  }

  const AddFileUpload = () => {
    if (ListOffileUpload?.length < 10) {
      formik.setFieldValue("ListOffileUpload", [
        ...ListOffileUpload,
        {
          File: "",
        },
      ])
    } else {
      swal("Attach File(s)", "You can upload maximum 10 files!", "warning")
    }
  }

  const delete_uploadedFile = (id, poId, index) => {
    swal({
      title: "Are you sure you want to delete the uploaded file?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDo => {
      if (willDo) {
        delete_uploadedFileConfirm(id, poId, index)
      }
    })
  }

  const delete_uploadedFileConfirm = (id, poId, index) => {
    setIsLoading(true)
    del(DELETE_poImage + "?po_id=" + poId + "&file_manager_id=" + id)
      .then(response => {
        if (response?.status == 200) {
          formik.initialTouchedsetFieldValue(
            "order_file",
            order_file?.filter((x, i) => i != index)
          )
          swal("Delete File", response?.message, "success")
        } else {
          // swal("Delete File", response?.message, "error")
          if (typeof response?.message == "string") {
            swal({
              title: "Delete File!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  const handleValidSubmit = async values => {
    setIsLoading(true)

    //console.log("ListOffileUpload: ", values?.ListOffileUpload)
    let fileManagerId = []
    await Promise.all(
      values?.ListOffileUpload?.map(async x => {
        if (x?.File) {
          let formData = new FormData()
          formData.append("module_slug", "org-purchase-purchase-order")
          formData.append("label", "Attach File")
          formData.append("file", x?.File)
          await postFormData(POST_UploadDocumnet, formData)
            .then(response => {
              if (response?.status == 200) {
                fileManagerId.push(response?.data?.id)
                console.log("file added")
              } else {
                // swal("Error!", response?.message, "error")
                if (typeof response?.message == "string") {
                  swal({
                    title: "Error!",
                    text: response.message,
                    icon: "error",
                    button: "OK",
                  })
                } else {
                  setSwalErrorMsg(response?.message)
                  setShowSwalError(true)
                }
                setIsLoading(false)
                return
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    )

    //console.log("RETURN files id: ", fileManagerId)

    if (
      values?.ListOffileUpload?.filter(x => x?.File != "")?.length > 0 &&
      fileManagerId?.length == 0
    ) {
      //console.log("file not saved")
      return
    } else {
      //console.log("file saved.... ")
    }

    //console.log("tc_list: ", values?.tc_list)
    let ids = []
    let tcGroup_id = 0
    values?.tc_list
      ?.filter(x => x.checked)
      ?.map(t => {
        ids.push(t.ID)
      })
    if (Object.entries(ids)?.length > 0) {
      await post(POST_CreateTermsAndConditionGroup, { ids })
        .then(response => {
          if (response?.status == 200) {
            tcGroup_id = response?.data?.id
          } else {
            // swal("Error!", response?.message, "error")
            if (typeof response?.message == "string") {
              swal({
                title: "Error!",
                text: response.message,
                icon: "error",
                button: "OK",
              })
            } else {
              setSwalErrorMsg(response?.message)
              setShowSwalError(true)
            }
            setIsLoading(false)
            return
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    //console.log("tcGroup_id: ", tcGroup_id)

    let payload = {
      ...values,
      purchase_order_date: values.purchase_order_date
        ? moment(values.purchase_order_date).format("yyyy-MM-DD")
        : "",
      expected_delivery_date: values.expected_delivery_date
        ? moment(values.expected_delivery_date).format("yyyy-MM-DD")
        : "",
      tc_group_list_id: tcGroup_id,
      order_details: values?.ListOfOrderItems?.map(x => {
        return {
          product_id: x.product_id,
          tax_id: x.tax_id,
          description: x.description,
          hsn_id: x.hsn_id,
          quantity: x.quantity,
          unit_id: x.unit_id,
          rate: x.rate,
          amount: x.amount,
          discount_amt: x.discount_amt,
          discount_rate: x.discount_rate,
          taxable_amount: x.taxable_amount,
          id: x.id,
        }
      }),
      file_manager_id: fileManagerId,
      id: IdParam ? IdParam : 0,
      po_status: values?.button == "Save and Send" ? "saved" : "draft",
    }

    if (!IdParam) {
      delete payload["id"]
    }
    delete payload["ListOfOrderItems"]
    delete payload["ListOffileUpload"]
    delete payload["GSTTreatment"]
    delete payload["GSTIN"]
    delete payload["billingAddress"]
    delete payload["shippingAddress"]
    delete payload["source_of_supplyStateName"]
    delete payload["destination_of_supplyStateName"]
    delete payload["deliverToAddress"]
    delete payload["ListOfTaxDetail"]
    delete payload["totalQty"]
    delete payload["tc_list"]
    delete payload["tc_display"]
    delete payload["button"]
    delete payload["order_file"]

    //console.log("Payload: ", payload)

    await post(POST_CreatePurchaseOrder, payload)
      .then(response => {
        if (response?.status == 200) {
          let poId = response?.data?.po_id
          if (values?.button == "Save and Send") {
            post(POST_CreateSalesOrder, { po_id: poId })
              .then(response => {
                if (response?.status == 200) {
                  swal("Success!", response?.message, "success")
                  setIsLoading(false)
                  setTimeout(() => {
                    navigate("/purchase-order")
                  }, 1000)
                } else {
                  // swal("Error!", response?.message, "error")
                  if (typeof response?.message == "string") {
                    swal({
                      title: "Error!",
                      text: response.message,
                      icon: "error",
                      button: "OK",
                    })
                  } else {
                    setSwalErrorMsg(response?.message)
                    setShowSwalError(true)
                  }
                  setIsLoading(false)
                }
              })
              .catch(error => {
                console.log(error)
                setIsLoading(false)
              })
          } else {
            swal("Success!", response?.message, "success")
            setIsLoading(false)
            setTimeout(() => {
              navigate("/purchase-order")
            }, 1000)
          }
        } else {
          // swal("Error!", response?.message, "error")
          if (typeof response?.message == "string") {
            swal({
              title: "Error!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
          setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>
              {IdParam ? "Edit Purchase Order" : "Add Purchase Order"} | Katlax
              OMS
            </title>
          </MetaTags>

          <Container fluid>
            <Row className="mt-2">
              <Col lg={6}>
                <div style={{ fontSize: "large" }}>
                  <i className="ti-bag" style={{ fontWeight: "800" }}></i>
                  &nbsp;
                  <b>
                    {IdParam ? "Edit Purchase Order" : "Add Purchase Order"}
                  </b>
                </div>
              </Col>
            </Row>
            <Row className="mt-3" style={{ borderTop: "1px solid #cacaca" }}>
              <Col className="mt-3">
                <FormikProvider value={formik}>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Vendor Name <span className="text-danger">*</span>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <Field
                          name="contact_id"
                          placeholder={"Select Vendor Name"}
                          component={CustomSelect}
                          options={vendorOptions}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}></Col>
                      <Col lg={3}>
                        <Row>
                          <Col lg={12}>BILLING ADDRESS</Col>
                          <Col lg={12}>
                            {billingAddress ? (
                              <>
                                {billingAddress.attention} ,<br></br>
                                {billingAddress.address_line_1},<br></br>
                                {billingAddress.address_line_2},<br></br>
                                {billingAddress.address_line_3},<br></br>
                                {billingAddress.zip_code}
                              </>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={3}>
                        <Row>
                          <Col lg={12}>SHIPPING ADDRESS</Col>
                          <Col lg={12}>
                            {shippingAddress ? (
                              <>
                                {shippingAddress.attention} ,<br></br>
                                {shippingAddress.address_line_1},<br></br>
                                {shippingAddress.address_line_2},<br></br>
                                {shippingAddress.address_line_3},<br></br>
                                {shippingAddress.zip_code}
                              </>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg={2}></Col>
                      <Col lg={6}>
                        GST Treatment: &nbsp;
                        <text style={{ color: "black" }}>
                          {/* Register Bussiness - Regular */}
                          {GSTTreatment}
                        </text>
                        &nbsp;
                        <Link to="#">
                          <i className="ti-marker-alt"></i>
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}></Col>
                      <Col lg={6}>
                        GSTIN: &nbsp;
                        <text style={{ color: "black" }}>{GSTIN}</text>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Source of Supply{" "}
                          <span className="text-danger">*</span>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="text"
                          name="source_of_supplyStateName"
                          // value={"[AS] Assam"}
                          // placeholder={"Enter Source Of Supply"}
                          component={CustomInput}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Destination Of Supply{" "}
                          <span className="text-danger">*</span>
                        </div>
                      </Col>
                      <Col lg={4}>
                        {" "}
                        <Field
                          type="text"
                          name="destination_of_supplyStateName"
                          // value={"[MH] Maharashtra"}
                          // placeholder={"Enter Destination Of Supply"}
                          component={CustomInput}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Deliver To <span className="text-danger">*</span>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mt-2">
                          <Row>
                            <Col lg={12}>
                              {" "}
                              <Label>Organisation</Label>
                              {/* &nbsp;,
                            <Label>Customer</Label> */}
                            </Col>
                            <Col lg={12} style={{ marginTop: "-10px" }}>
                              {/* Agent */}
                              {deliverToAddress?.attention}
                              &nbsp;
                              <Link to="#">
                                <i className="ti-marker-alt"></i>
                              </Link>
                            </Col>
                          </Row>
                          <Row>
                            {/* <Col lg={12}>Maharashtra</Col>
                            <Col lg={12}>India,</Col> */}
                            <Col lg={12}>
                              {deliverToAddress ? (
                                <>
                                  {deliverToAddress.address_line_1},<br></br>
                                  {deliverToAddress.address_line_2},<br></br>
                                  {deliverToAddress.address_line_3},<br></br>
                                  {deliverToAddress.zip_code}
                                </>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                          {/* <Row className="mt-3">
                          <Col lg={12}>
                            <Link to="#">Change destination to deliver</Link>
                          </Col>
                        </Row> */}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Purchase Order# <span className="text-danger">*</span>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="d-flex">
                          <Field
                            type="text"
                            name="purchase_order_no"
                            placeholder={"Enter Purchase Order"}
                            component={CustomInput}
                            style={{ textTransform: "Uppercase" }}
                          />
                          &nbsp; &nbsp;
                          <Link
                            onClick={() => {
                              setPONoModal(true)
                            }}
                            className="mt-2"
                            title="Edit"
                          >
                            <i className="ti-settings"></i>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Reference#
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="text"
                          name="reference_estimate"
                          placeholder={"Enter Reference"}
                          component={CustomInput}
                          style={{ textTransform: "Uppercase" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Date
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="text"
                          name="purchase_order_date"
                          placeholder={"dd/mm/yyyy"}
                          component={CustomFlatpickr}
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "d/m/Y",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Expected Delivery Date
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="text"
                          name="expected_delivery_date"
                          placeholder={"dd/mm/yyyy"}
                          component={CustomFlatpickr}
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "d/m/Y",
                          }}
                        />
                      </Col>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Payment Terms
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Field
                          name="payment_terms_id"
                          placeholder={"Select Payment Terms"}
                          component={CustomSelect}
                          options={paymentTermsOption}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Shipment Preference
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Field
                          name="shipment_preference"
                          placeholder={"Select Shipment Preference"}
                          component={CustomSelect}
                          options={shipmentPreferenceOption}
                        />
                        {/* <span>
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="Add"
                            checked={false}
                          />
                          &nbsp; This transaction is applicable for reverse
                          charge
                        </span> */}
                      </Col>
                    </Row>
                    <hr></hr>
                    {/* <Row className="mt-3">
                    <Col lg={6}>
                      <Row>
                        <Col lg={3}>Item Rates Are</Col>
                        <Col lg={4}>
                          <Field
                            name="ItemRate"
                            placeholder={"Item Rate"}
                            component={CustomSelect}
                            options={ItemRateOption}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={2}></Col>
                    <Col lg={4}>
                      <Link to="#">
                        <i className="ti-settings"></i>&nbsp;Bulk Update Line
                        Items
                      </Link>
                    </Col>
                  </Row> */}
                    <div className="table-responsive abc">
                      <table className="table table-bordered mb-0">
                        <thead>
                          <tr className="table-light">
                            <th style={{ minWidth: "450px" }}>Item Detail</th>
                            <th style={{ minWidth: "170px" }}>
                              Unit & Order Qty
                            </th>
                            <th style={{ minWidth: "150px" }}>Pack Type</th>
                            <th style={{ minWidth: "150px" }}>Rate</th>
                            <th style={{ minWidth: "150px" }}>Discount</th>
                            <th style={{ minWidth: "200px" }}>Tax</th>
                            <th style={{ minWidth: "150px" }}>Amount</th>
                            <th
                              style={{
                                borderBlockColor: "#ffffff00",
                                borderColor: "#ffffff00",
                                backgroundColor: "#ffffff00",
                              }}
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          {ListOfOrderItems &&
                            ListOfOrderItems.map((orderItem, index) => (
                              <tr>
                                <td>
                                  <Row>
                                    <Col lg={1} md={1} sm={1}>
                                      <img
                                        src={
                                          "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png"
                                        }
                                        alt=""
                                        className="avatar-xs rounded-square"
                                        style={{
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </Col>
                                    &nbsp; &nbsp;
                                    <Col lg={10} md={10} sm={10}>
                                      <Row>
                                        <Col lg={11} md={11} sm={11}>
                                          <Field
                                            name={`ListOfOrderItems.[${index}].product_id`}
                                            placeholder={"Select Item"}
                                            component={CustomSelect}
                                            options={productOptions}
                                            onChange={e => {
                                              setFieldValue(
                                                `ListOfOrderItems.[${index}].product_id`,
                                                e ? e.value : 0
                                              )
                                              getProductDetail(
                                                e ? e.value : 0,
                                                index
                                              )
                                            }}
                                          />
                                        </Col>

                                        <Col lg={12} md={12} sm={12}>
                                          <text>
                                            {/* Description of Item Details */}
                                            {orderItem.description}
                                          </text>
                                        </Col>
                                        <Col lg={12} md={12} sm={12}>
                                          {orderItem.itemType ? (
                                            <Badge
                                              color="primary"
                                              className="bg-primary"
                                            >
                                              {orderItem?.itemType.toUpperCase()}
                                            </Badge>
                                          ) : (
                                            ""
                                          )}
                                          {orderItem?.hsn_code ? (
                                            <>
                                              &nbsp; HSN Code:{" "}
                                              <Link to="#">
                                                {orderItem.hsn_code}
                                              </Link>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </td>
                                <td>
                                  <Row>
                                    <Col lg={12}>
                                      <Field
                                        type="number"
                                        name={`ListOfOrderItems.[${index}].quantity`}
                                        placeholder={"Qty"}
                                        component={CustomInput}
                                        onChange={e => {
                                          if (e?.target?.value >= 0) {
                                            // setFieldValue(
                                            //   `ListOfOrderItems.[${index}].quantity`,
                                            //   e?.target?.value
                                            // )
                                            calculateItemWiseTotal(
                                              index,
                                              e?.target?.value
                                                ? e?.target?.value
                                                : 0,
                                              orderItem.rate,
                                              orderItem.discount_rate,
                                              orderItem.discount_amt,
                                              orderItem.tax_rate,
                                              orderItem.DiscountTypeValue,
                                              orderItem.DiscountType,
                                              orderItem.tax_id,
                                              orderItem.tax_caption
                                            )
                                          }
                                        }}
                                      />
                                    </Col>
                                    {/* <Col lg={12} style={{ textAlign: "right" }}>
                                      Mtr
                                    </Col> */}
                                  </Row>
                                </td>
                                <td>
                                  <Row>
                                    <Col lg={12}>
                                      {/* <Field
                                        type="text"
                                        name={`ListOfOrderItems.[${index}].PackType`}
                                        placeholder={"Pack Type"}
                                        component={CustomInput}
                                        readOnly
                                      /> */}
                                      <Field
                                        name={`ListOfOrderItems.[${index}].unit_id`}
                                        placeholder={"Pack Type"}
                                        component={CustomSelect}
                                        options={orderItem.PackTypeDDLList}
                                      />
                                    </Col>
                                    {/* <Col lg={12} style={{ textAlign: "right" }}>
                                      Mtr
                                    </Col> */}
                                  </Row>
                                </td>
                                <td>
                                  <Field
                                    type="number"
                                    name={`ListOfOrderItems.[${index}].rate`}
                                    placeholder={"Rate"}
                                    component={CustomInput}
                                    onChange={e => {
                                      // setFieldValue(
                                      //   `ListOfOrderItems.[${index}].rate`,
                                      //   e?.target?.value
                                      // )
                                      calculateItemWiseTotal(
                                        index,
                                        orderItem.quantity,
                                        e?.target?.value,
                                        orderItem.discount_rate,
                                        orderItem.discount_amt,
                                        orderItem.tax_rate,
                                        orderItem.DiscountTypeValue,
                                        orderItem.DiscountType,
                                        orderItem.tax_id,
                                        orderItem.tax_caption
                                      )
                                    }}
                                  />
                                </td>
                                <td>
                                  <ButtonDropdown
                                    isOpen={orderItem.IsOpen}
                                    toggle={() =>
                                      setFieldValue(
                                        `ListOfOrderItems.[${index}].IsOpen`,
                                        !orderItem.IsOpen
                                      )
                                    }
                                  >
                                    {/* <span
                                      style={{
                                        display:
                                          orderItem.DiscountTypeValue == 1
                                            ? ""
                                            : "none",
                                      }}
                                    >
                                      <Field
                                        type="number"
                                        name={`ListOfOrderItems.[${index}].discount_amt`}
                                        placeholder={"Discount"}
                                        component={CustomInput}
                                        min={0}
                                        onChange={e => {
                                          // setFieldValue(
                                          //   `ListOfOrderItems.[${index}].discount_amt`,
                                          //   e?.target?.value
                                          // )

                                          calculateItemWiseTotal(
                                            index,
                                            orderItem.quantity,
                                            orderItem.rate,
                                            orderItem.discount_rate,
                                            e?.target?.value,
                                            orderItem.tax_rate,
                                            orderItem.DiscountTypeValue,
                                            orderItem.DiscountType,
                                            orderItem.tax_id,
                                            orderItem.tax_caption
                                          )
                                        }}
                                      />
                                    </span>
                                    <span
                                      style={{
                                        display:
                                          orderItem.DiscountTypeValue == 2
                                            ? ""
                                            : "none",
                                      }}
                                    >
                                      <Field
                                        type="number"
                                        name={`ListOfOrderItems.[${index}].discount_rate`}
                                        placeholder={"Discount"}
                                        component={CustomInput}
                                        min={0}
                                        onChange={e => {
                                          let dRate = e?.target?.value
                                            ? e?.target?.value
                                            : 0
                                          if (dRate <= 100 && dRate >= 0) {
                                            // setFieldValue(
                                            //   `ListOfOrderItems.[${index}].discount_rate`,
                                            //   dRate
                                            // )

                                            calculateItemWiseTotal(
                                              index,
                                              orderItem.quantity,
                                              orderItem.rate,
                                              dRate,
                                              orderItem.discount_amt,
                                              orderItem.tax_rate,
                                              orderItem.DiscountTypeValue,
                                              orderItem.DiscountType,
                                              orderItem.tax_id,
                                              orderItem.tax_caption
                                            )
                                          }
                                        }}
                                      />
                                    </span> */}
                                    <Field
                                      type="number"
                                      name={`ListOfOrderItems.[${index}].discount`}
                                      placeholder={"Discount"}
                                      component={CustomInput}
                                      value={
                                        orderItem.DiscountTypeValue == 1
                                          ? orderItem?.discount_amt
                                          : orderItem?.discount_rate
                                      }
                                      onChange={e => {
                                        let dRate = e?.target?.value
                                          ? e?.target?.value
                                          : 0

                                        if (
                                          (orderItem.DiscountTypeValue == 2 &&
                                            dRate <= 100 &&
                                            dRate >= 0) ||
                                          orderItem.DiscountTypeValue == 1
                                        ) {
                                          calculateItemWiseTotal(
                                            index,
                                            orderItem.quantity,
                                            orderItem.rate,
                                            orderItem.DiscountTypeValue == 2
                                              ? e?.target?.value
                                              : orderItem.discount_rate,
                                            orderItem.DiscountTypeValue == 1
                                              ? e?.target?.value
                                              : orderItem.discount_amt,
                                            orderItem.tax_rate,
                                            orderItem.DiscountTypeValue,
                                            orderItem.DiscountType,
                                            orderItem.tax_id,
                                            orderItem.tax_caption
                                          )
                                        }
                                      }}
                                    />

                                    <DropdownToggle
                                      caret
                                      color="secondary"
                                      className="btn btn-secondary btn-lg DropDownBTN"
                                    >
                                      <div
                                        style={{
                                          marginLeft: "-11px",
                                          marginTop: "-5px",
                                        }}
                                      >
                                        <text
                                          style={{
                                            fontSize: "15px",
                                            color: "gray",
                                          }}
                                        >
                                          {orderItem.DiscountType}
                                        </text>
                                        &nbsp;
                                        <i
                                          style={{
                                            fontSize: "15px",
                                          }}
                                          className="fas fa-caret-down"
                                        ></i>
                                      </div>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {DiscountTypeOption?.map((x, i) => (
                                        <>
                                          <DropdownItem
                                            id={x.value}
                                            onClick={() => {
                                              // setFieldValue(
                                              //   `ListOfOrderItems.[${index}].discount_amt`,
                                              //   ""
                                              // )
                                              // setFieldValue(
                                              //   `ListOfOrderItems.[${index}].discount_rate`,
                                              //   ""
                                              // )
                                              // setFieldValue(
                                              //   `ListOfOrderItems.[${index}].DiscountTypeValue`,
                                              //   x.value
                                              // )
                                              // setFieldValue(
                                              //   `ListOfOrderItems.[${index}].DiscountType`,
                                              //   x.label
                                              // )
                                              calculateItemWiseTotal(
                                                index,
                                                orderItem.quantity,
                                                orderItem.rate,
                                                0,
                                                0,
                                                orderItem.tax_rate,
                                                x.value,
                                                x.label,
                                                orderItem.tax_id,
                                                orderItem.tax_caption
                                              )
                                            }}
                                          >
                                            {x.label}
                                          </DropdownItem>
                                        </>
                                      ))}
                                    </DropdownMenu>
                                  </ButtonDropdown>
                                </td>
                                <td>
                                  <Field
                                    name={`ListOfOrderItems.[${index}].tax_id`}
                                    placeholder={"Tax"}
                                    component={CustomSelect}
                                    options={taxOptions}
                                    onChange={e => {
                                      // if (e) {
                                      //   setFieldValue(
                                      //     `ListOfOrderItems.[${index}].tax_id`,
                                      //     e?.value
                                      //   )
                                      //   setFieldValue(
                                      //     `ListOfOrderItems.[${index}].tax_rate`,
                                      //     e?.rate
                                      //   )
                                      //   setFieldValue(
                                      //     `ListOfOrderItems.[${index}].tax_caption`,
                                      //     e?.label
                                      //   )
                                      // } else {
                                      //   setFieldValue(
                                      //     `ListOfOrderItems.[${index}].tax_id`,
                                      //     0
                                      //   )
                                      //   setFieldValue(
                                      //     `ListOfOrderItems.[${index}].tax_rate`,
                                      //     0
                                      //   )
                                      //   setFieldValue(
                                      //     `ListOfOrderItems.[${index}].tax_caption`,
                                      //     ""
                                      //   )
                                      // }
                                      calculateItemWiseTotal(
                                        index,
                                        orderItem.quantity,
                                        orderItem.rate,
                                        orderItem.discount_rate,
                                        orderItem.discount_amt,
                                        e ? e.rate : 0,
                                        orderItem.DiscountTypeValue,
                                        orderItem.DiscountType,
                                        e ? e?.value : 0,
                                        e ? e?.label : ""
                                      )
                                    }}
                                  />
                                </td>
                                <td>
                                  <Field
                                    type="number"
                                    name={`ListOfOrderItems.[${index}].amount`}
                                    placeholder={"Amount"}
                                    component={CustomInput}
                                    readOnly
                                  />
                                </td>
                                <td
                                  style={{
                                    borderBlockColor: "#ffffff00",
                                    borderColor: "#ffffff00",
                                  }}
                                >
                                  <i
                                    class="fas fa-times-circle"
                                    style={{
                                      color: "red",
                                      marginTop: "12px",
                                    }}
                                    onClick={() => {
                                      delete_OrderItem(index)
                                    }}
                                  ></i>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>{" "}
                    <hr></hr>
                    <Row className="mt-3">
                      <Col lg={3} md={3} sm={3}>
                        <Row>
                          <Col
                            lg={8}
                            md={8}
                            sm={8}
                            style={{ marginTop: "10px" }}
                          >
                            <button
                              className="btn btn-secondary"
                              type="button"
                              onClick={() => {
                                AddOrderItem()
                              }}
                            >
                              <i
                                class="fas fa-plus-circle"
                                style={{ color: "dodgerblue" }}
                              ></i>
                              &nbsp; Add another line
                            </button>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={9} md={9} sm={9} style={{ marginTop: "10px" }}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md={12} lg={12} sm={12}>
                                <Label
                                  md={6}
                                  sm={6}
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  <Label>Sub Total :</Label>
                                </Label>
                                <Label
                                  md={6}
                                  sm={6}
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "15px",
                                  }}
                                >
                                  {parseFloat(subtotal).toFixed(2)}
                                </Label>
                              </Col>
                              <Col
                                md={12}
                                lg={12}
                                sm={12}
                                style={{ marginTop: "-17px" }}
                              >
                                <Label
                                  md={6}
                                  sm={6}
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                    marginTop: "-17px",
                                  }}
                                >
                                  <b style={{ fontWeight: 100 }}>
                                    Total Quantity : {totalQty}
                                  </b>
                                </Label>
                              </Col>
                              <Col md={12} lg={12} sm={12} className="mb-1">
                                {/* <Label
                                  md={6}
                                  sm={6}
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  <Label>{"IGST [12%]"}</Label>
                                </Label>
                                <Label
                                  md={6}
                                  sm={6}
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "15px",
                                  }}
                                >
                                  Rs.1,000.00
                                </Label> */}
                                {ListOfTaxDetail?.map(x => (
                                  <>
                                    <Label
                                      md={6}
                                      sm={6}
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "15px",
                                      }}
                                    >
                                      <Label>{x.TaxCaption}</Label>
                                    </Label>
                                    <Label
                                      md={6}
                                      sm={6}
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "15px",
                                      }}
                                    >
                                      {x.TaxAmount?.toFixed(2)}
                                    </Label>
                                  </>
                                ))}
                              </Col>
                              {/* <Col md={12} lg={12} sm={12}>
                              <Label
                                md={6}
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "15px",
                                }}
                              >
                                <Field
                                  type="number"
                                  label="Total Tax Amount"
                                  name="TotalTaxAmount"
                                  placeholder={"Enter Total Tax Amount"}
                                  component={CustomInput}
                                  icon={true}
                                  iconClass={"mdi mdi-currency-inr"}
                                  min={0}
                                />
                              </Label>
                              <Label
                                md={6}
                                style={{
                                  textAlign: "right",
                                  paddingRight: "15px",
                                }}
                              >
                                Rs.10,000.00
                              </Label>
                            </Col> */}
                              <Col md={12} lg={12} sm={12}>
                                <Label
                                  md={8}
                                  sm={8}
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  <Row>
                                    <Col lg={6}>
                                      <Label>Adjustment</Label>
                                    </Col>
                                    <Col lg={6}>
                                      {" "}
                                      <Field
                                        type="number"
                                        name="adjustment"
                                        placeholder={"Adjustment"}
                                        component={CustomInput}
                                      />
                                    </Col>
                                  </Row>
                                </Label>
                                {/* <Label
                                  md={4}
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "15px",
                                  }}
                                >
                                  Rs.0.00
                                </Label> */}
                              </Col>
                              <Col md={12} lg={12} sm={12}>
                                <Label
                                  md={8}
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  <Row>
                                    <Col lg={6}>
                                      <Label>TDS</Label>
                                    </Col>
                                    <Col lg={6}>
                                      <Field
                                        name="tds_id"
                                        placeholder={"Select TDS"}
                                        component={CustomSelect}
                                        options={tdsTypeOptions}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={6}>
                                      <Label>Freight Charges</Label>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="d-flex">
                                        <Field
                                          type="number"
                                          name="freight_charges"
                                          placeholder={"Enter Freight Charges"}
                                          component={CustomInput}
                                        />
                                        &nbsp;&nbsp;
                                        <Link
                                          onClick={() => {
                                            setDisclaimerModal(true)
                                          }}
                                        >
                                          <i
                                            class="fa fa-asterisk"
                                            aria-hidden="true"
                                            style={{ fontSize: "12px" }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={6}>
                                      <Label>Document Charges</Label>
                                    </Col>
                                    <Col lg={6}>
                                      <Field
                                        type="number"
                                        name="documentation_charges"
                                        placeholder={"Enter Document Charges"}
                                        component={CustomInput}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={6}>
                                      <Label>Packaging Charges</Label>
                                    </Col>
                                    <Col lg={6}>
                                      <Field
                                        type="number"
                                        name="packaging_charges"
                                        placeholder={"Enter Packaging Charges"}
                                        component={CustomInput}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={6}>
                                      <Label>Forwarding Charges</Label>
                                    </Col>
                                    <Col lg={6}>
                                      <Field
                                        type="number"
                                        name="forwarding_charges"
                                        placeholder={"Enter Forwarding Charges"}
                                        component={CustomInput}
                                      />
                                    </Col>
                                  </Row>
                                </Label>
                                {/* <Label
                                md={4}
                                style={{
                                  textAlign: "right",
                                  paddingRight: "15px",
                                }}
                              >
                                Rs.0
                              </Label> */}
                              </Col>

                              <Col md={12} lg={12} sm={12}>
                                <hr></hr>
                                <Label
                                  md={8}
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                    fontSize: "17px",
                                    color: "black",
                                  }}
                                >
                                  {"Total (Rs.)"}
                                </Label>
                                <Label
                                  md={4}
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "15px",
                                    fontSize: "17px",
                                    color: "black",
                                  }}
                                >
                                  {parseFloat(total).toFixed(2)}
                                </Label>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col lg={6} style={{ verticalAlign: "bottom" }}>
                        <Field
                          label="Customer Notes"
                          type="textarea"
                          name="customer_notes"
                          placeholder={"Enter Customer Notes"}
                          component={CustomInput}
                          rows={3}
                          style={{ resize: "none" }}
                        />
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col lg={7} md={7} sm={12}>
                        <span style={{ float: "right" }}>
                          <Button
                            style={{
                              width: "25px",
                              height: "25px",
                              padding: "0px",
                            }}
                            onClick={() => {
                              setEditTermsAndConditionModal(true)
                            }}
                            title="Edit"
                          >
                            <i className="fas fa-edit"></i>
                          </Button>
                        </span>
                        <Field
                          label="Terms & Condition"
                          type="textarea"
                          name="tc_display"
                          placeholder={
                            "Terms & Condition of your business to be displayed in your transaction."
                          }
                          component={CustomInput}
                          rows={3}
                          disabled
                          style={{ backgroundColor: "#e9ecef", resize: "none" }}
                        />
                      </Col>
                      <Col lg={1} md={1} sm={12}></Col>
                      <Col lg={4} style={{ borderLeft: "1px solid #cacaca" }}>
                        <Row>
                          {order_file?.length > 0 ? (
                            <>
                              <Col lg={11}>
                                {order_file?.map((x, index) => (
                                  <Row>
                                    <Col lg={11}>
                                      <div className="d-flex justify-content-between">
                                        {x?.file_manager ? (
                                          <Link
                                            to={x?.file_manager?.file_url}
                                            title="View"
                                            target="blank"
                                          >
                                            {x?.file_manager?.file_name}
                                          </Link>
                                        ) : (
                                          "File not found"
                                        )}
                                        <Link
                                          to="#"
                                          title="Delete"
                                          onClick={() => {
                                            delete_uploadedFile(
                                              x?.id,
                                              x?.org_purchase_orders_id,
                                              index
                                            )
                                          }}
                                        >
                                          <i
                                            className="fas fa-trash-alt"
                                            style={{
                                              color: "red",
                                            }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                ))}
                              </Col>
                              <hr className="mt-2"></hr>
                            </>
                          ) : (
                            ""
                          )}
                          <Col lg={12}>
                            {"Attach File(s) to Purchase Order"}
                          </Col>

                          {ListOffileUpload?.map((files, index) => (
                            <Col lg={9} className="mt-2">
                              <div className="d-flex">
                                <input
                                  type="file"
                                  className="form-control"
                                  name={`ListOffileUpload.[${index}].File`}
                                  onChange={e => onFileSelect(e, index)}
                                />{" "}
                                &nbsp;&nbsp;
                                <i
                                  class="fas fa-times-circle"
                                  style={{
                                    color: "red",
                                    marginTop: "12px",
                                  }}
                                  onClick={() => {
                                    delete_FileUpload(index)
                                  }}
                                ></i>
                              </div>
                            </Col>
                          ))}

                          <Col lg={12} className="mt-2">
                            <button
                              className="btn btn-secondary"
                              type="button"
                              onClick={() => {
                                AddFileUpload()
                              }}
                            >
                              <i
                                class="fas fa-plus-circle"
                                style={{ color: "dodgerblue" }}
                              ></i>
                              &nbsp; Add More
                            </button>
                          </Col>

                          <Col lg={12}>
                            <text style={{ fontSize: "11px" }}>
                              You can upload a maximum of 10 files, 5MB each
                            </text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row className="mt-3">
                      <Col lg={6}>
                        <button
                          className="btn btn-primary"
                          type="submit"
                          onClick={() => {
                            setFieldValue("button", "Save")
                          }}
                        >
                          Save
                        </button>
                        &nbsp;&nbsp;
                        <button
                          className="btn btn-secondary"
                          type="submit"
                          onClick={() => {
                            setFieldValue("button", "Save and Send")
                          }}
                        >
                          Save and Send
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            navigate("/purchase-order")
                          }}
                        >
                          Cancel
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </FormikProvider>
              </Col>
            </Row>
            <SwalError
              showError={showSwalError}
              setShowError={setShowSwalError}
              ErrorMsg={swalErrorMsg}
            ></SwalError>
          </Container>
        </div>
        <Modal isOpen={PONoModal} centered={true} size="lg">
          <div className="modal-header mdl_header">
            <h5 className="modal-title mt-0">{"PO Purchase Number"}</h5>
            <button
              type="button"
              className="btn close mt-2"
              onClick={() => setPONoModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <FormikProvider value={formikPONo}>
              <Form onSubmit={formikPONo.handleSubmit}>
                <Row className="mt-3">
                  <Col lg={12}>
                    Your order number are set on auto-generate mode to save your
                    time. Are you sure about changing this setting
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={12}>
                    <Input
                      type="radio"
                      name="Setting"
                      id="Setting"
                      className="form-check-input"
                      onChange={e => {
                        //console.log(e.target.checked)
                        formikPONo.setFieldValue("Setting", e?.target.checked)
                        formikPONo.setFieldValue("AddManually", false)
                      }}
                      checked={Setting}
                    />
                    &nbsp;
                    <label className="custom-control-label" htmlFor="Setting">
                      Continue auto-generating order number
                    </label>
                  </Col>
                </Row>
                {Setting ? (
                  <>
                    <div className="table-responsive mt-3">
                      <table className="table table-bordered mb-0">
                        <thead>
                          <tr className="table-light">
                            <th style={{ minWidth: "200px" }}>Prefix</th>
                            <th style={{ minWidth: "200px" }}>Next Number</th>
                            <th style={{ minWidth: "50px" }}>Default</th>
                            <th style={{ minWidth: "80px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ListofPurchaseOrderNumber &&
                            ListofPurchaseOrderNumber.map(
                              (paurchaseOrderNumber, index) => (
                                <tr>
                                  <td>
                                    <Field
                                      type="text"
                                      name={`ListofPurchaseOrderNumber.[${index}].Prefix`}
                                      placeholder={"Prefix"}
                                      component={CustomInput}
                                      style={{ textTransform: "Uppercase" }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      type="number"
                                      name={`ListofPurchaseOrderNumber.[${index}].NextNumber`}
                                      placeholder={"Next Number"}
                                      component={CustomInput}
                                    />
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <Input
                                      type="radio"
                                      name={`ListofPurchaseOrderNumber.[${index}].Default`}
                                      id={`ListofPurchaseOrderNumber.[${index}].Default`}
                                      className="form-check-input"
                                      onChange={e => {
                                        formikPONo.setFieldValue(
                                          `ListofPurchaseOrderNumber.[${index}].Default`,
                                          e?.target.checked
                                        )
                                      }}
                                      checked={paurchaseOrderNumber.Default}
                                    />
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      <Button
                                        className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                          padding: "0px",
                                        }}
                                        onClick={() => {}}
                                        title="Edit"
                                      >
                                        <i
                                          className="fas fa-edit"
                                          style={{ color: "#17a98c" }}
                                        ></i>
                                      </Button>
                                      &nbsp;
                                      <Button
                                        className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                          padding: "0px",
                                        }}
                                        title="Delete"
                                        onClick={() => {
                                          delete_PONumber(index)
                                        }}
                                      >
                                        <i
                                          class="fas fa-trash-alt"
                                          style={{
                                            color: "red",
                                          }}
                                        ></i>
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                    <Row className="mt-2">
                      <Col style={{ textAlign: "end" }}>
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={() => {
                            AddPONumber()
                          }}
                        >
                          <i
                            class="fas fa-plus-circle"
                            style={{ color: "dodgerblue" }}
                          ></i>
                          &nbsp; Add More
                        </button>{" "}
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                <Row className="mt-3">
                  <Col lg={12}>
                    <Input
                      type="radio"
                      name="AddManually"
                      id="AddManually"
                      className="form-check-input"
                      onChange={e => {
                        formikPONo.setFieldValue(
                          "AddManually",
                          e?.target.checked
                        )
                        formikPONo.setFieldValue("Setting", false)
                      }}
                      checked={AddManually}
                    />
                    &nbsp;
                    <label
                      className="custom-control-label"
                      htmlFor="AddManually"
                    >
                      I will add them manually each time
                    </label>
                  </Col>
                </Row>
                <hr></hr>
                <Row className="mt-3">
                  <Col lg={12} style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" type="submit">
                      Save
                    </button>
                    &nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setPONoModal(false)
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </FormikProvider>
          </div>
        </Modal>
        <Modal isOpen={editTermsAndConditionModal} centered={true} size="lg">
          <div className="modal-header mdl_header">
            <h5 className="modal-title mt-0">{"Terms and Condition"}</h5>
            <button
              type="button"
              className="btn close mt-2"
              onClick={() => setEditTermsAndConditionModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <FormikProvider value={formikEditTermsAndCondition}>
              <Form onSubmit={formikEditTermsAndCondition.handleSubmit}>
                <Row>
                  {termsAndConditionsList?.map((x, i) => (
                    <>
                      <Col lg={12} className="mt-2">
                        <Input
                          type="checkbox"
                          name={`TermsAndCondition${i}`}
                          id={`TermsAndCondition${i}`}
                          className="form-check-input"
                          checked={x.checked}
                          onChange={e => {
                            let newArry = [...termsAndConditionsList]
                            newArry[i].checked = e.target.checked
                            formikEditTermsAndCondition.setFieldValue(
                              "termsAndConditionsList",
                              newArry
                            )
                          }}
                        />
                        {/* </Col>
                      <Col
                        lg={11}
                        style={{ textAlign: "left" }}
                        className="mt-2"
                      > */}{" "}
                        &nbsp;&nbsp;
                        {x.Condition}
                      </Col>
                    </>
                  ))}
                </Row>
                <hr></hr>
                <Row className="mt-3">
                  <Col lg={12} style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" type="submit">
                      Save
                    </button>
                    &nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setEditTermsAndConditionModal(false)
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </FormikProvider>
          </div>
        </Modal>
        <Modal isOpen={disclaimerModal} centered={true}>
          <div className="modal-header mdl_header">
            <h5 className="modal-title mt-0">
              <text style={{ color: "black" }}>{"Disclaimer"}</text>{" "}
            </h5>
            <button
              type="button"
              className="btn close mt-2"
              onClick={() => setDisclaimerModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col>
                <text>
                  Seller is not responsible for any differences that may occur
                  between freight estimates contained in a Quotation and actual
                  freight charges applicable at the time of shipment, and Buyer
                  shall incur and be responsible for all costs associated
                  therewith. Freight Charges.
                </text>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col lg={12} style={{ textAlign: "right" }}>
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={() => {
                    setDisclaimerModal(false)
                  }}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </div>
        </Modal>
      </Spin>
    </React.Fragment>
  )
}
AddPurchaseOrder.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(AddPurchaseOrder))
