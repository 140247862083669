import React, { Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Form,
  Badge,
  Modal,
  Input,
  Label,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CustomInput from "Custom/CustomInput"
import { Field, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import CustomSelect from "Custom/CustomSelect"
import CustomFlatpickr from "Custom/CustomFlatpicker"
import { get, post } from "helpers/api_helper"
import {
  GET_ShipmentPreference,
  GET_TermsAndCondition,
  GET_PaymentTerms,
  GET_OrganisationDetail,
} from "helpers/url_helper"
import { Spin } from "antd"
import swal from "sweetalert"

const AddPurchaseOrderCustomer = props => {
  const navigate = useNavigate()

  const [drp_secondary, setDrp_secondary] = useState(false)
  const [PONoModal, setPONoModal] = useState(false)
  const [editTermsAndConditionModal, setEditTermsAndConditionModal] =
    useState(false)
  const [disclaimerModal, setDisclaimerModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [shipmentPreferenceOption, setShipmentPreferenceOption] = useState([])
  const [termsAndConditionsList, setTermsAndConditionsList] = useState([])
  const [paymentTermsOption, setPaymentTermsOption] = useState([])

  const initialTaxItemList = {
    ItemName: "",
    UnitOrderQuanity: "",
    PackType: "",
    Rate: "",
    Discount: 0,
    Tax: "",
    Amount: "",
    Percentage: "",
    Rupees: "",
    DiscountType: "%",
    IsOpen: false,
  }

  const initialFileUpload = {
    File: "",
  }

  const validationSchema = Yup.object().shape({
    CustomerName: Yup.string().required("Customer Name is a required field."),
    PurchaseOrder: Yup.string().required("Purchase Order is a required field."),
    // SourceOfSupply: Yup.string().required(
    //   "Source Of Supply is a required field."
    // ),
  })

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CustomerName: "",
      DestinationOfSupply: "",
      SourceOfSupply: "",
      Reference: "",
      PurchaseOrder: "",
      ExpectedDeliveryDate: "",
      Date: "",
      PaymentTerms: "",
      ShipmentPreference: "",
      ItemRate: "",
      ListOfTaxItem: [initialTaxItemList],
      ListOffileUpload: [initialFileUpload],
    },
    validateOnBlur: false,
  })

  const {
    setFieldValue,
    values: { ListOfTaxItem, ListOffileUpload },
  } = formik

  const handleValidSubmit = values => {}

  useEffect(() => {
    getShipmentReference()
    getTermsAndConditionList()
    getPaymentTerms()
  }, [])

  const getShipmentReference = () => {
    setIsLoading(true)
    get(GET_ShipmentPreference).then(res => {
      if (res) {
        const valuesArray = res.data.shipment_preference
        var NewArrayOption = Object.entries(valuesArray)

        let NewOption = []
        NewArrayOption.map(x =>
          NewOption.push({
            label: x[1],
            value: x[0],
          })
        )

        setShipmentPreferenceOption(NewOption)
      }
    })
    setIsLoading(false)
  }

  const getTermsAndConditionList = () => {
    setIsLoading(true)
    get(GET_TermsAndCondition).then(res => {
      if (res) {
        let NewOption = []
        res?.data?.map(x =>
          NewOption.push({
            ID: x.id,
            Condition: x.conditions,
          })
        )

        setTermsAndConditionsList(NewOption)
      }
    })
    setIsLoading(false)
  }

  const getPaymentTerms = () => {
    setIsLoading(true)
    get(GET_PaymentTerms).then(res => {
      if (res) {
        let NewOptions = []
        res.data?.map(item =>
          NewOptions.push({
            label: item.label,
            value: item.id,
          })
        )
        setPaymentTermsOption(NewOptions)
      }
    })
    setIsLoading(false)
  }

  const initialListofPurchaseOrderNumber = {
    Prefix: "",
    NextNumber: "",
    Default: false,
    AddManually: false,
  }

  const validationSchemaPONo = Yup.object().shape({})

  const formikPONo = useFormik({
    validationSchema: validationSchemaPONo,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitPONo(values, formikHelpers),
    initialValues: {
      Setting: false,
      AddManually: false,
      ListofPurchaseOrderNumber: [initialListofPurchaseOrderNumber],
    },
    validateOnBlur: false,
  })

  const {
    values: { Setting, ListofPurchaseOrderNumber, AddManually },
  } = formikPONo

  const handleValidSubmitPONo = values => {}

  const validationSchemaEditTermsAndCondition = Yup.object().shape({})

  const formikEditTermsAndCondition = useFormik({
    validationSchema: validationSchemaEditTermsAndCondition,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitEditTermsAndCondition(values, formikHelpers),
    initialValues: {
      TermsAndCondition: "",
    },
    validateOnBlur: false,
  })

  const {
    values: {},
  } = formikEditTermsAndCondition

  const handleValidSubmitEditTermsAndCondition = values => {}

  const PaymentTermsOption = [
    { label: "PIA: Payment in advance", value: 1 },
    {
      label:
        "Net 7, 10, 15, 30, 60, or 90: Payment expected within 7, 10, 15, 30, 60, or 90 days after the invoice date",
      value: 2,
    },
    { label: "EOM: End of month", value: 3 },
    { label: "21 MFI: 21st of the month following invoice date", value: 4 },
    { label: "COD: Cash on delivery", value: 5 },
    { label: "CND: Cash next delivery", value: 6 },
  ]
  const TCSOption = [
    { label: "UGST", value: 1 },
    { label: "IGST", value: 2 },
    { label: "SGST", value: 3 },
  ]
  const TaxOption = [
    { label: "UGST [12%]", value: 1 },
    { label: "IGST [12%]", value: 2 },
    { label: "SGST [12%]", value: 3 },
  ]
  const ItemNameOption = [
    { label: "Raw Material 1", value: 1 },
    { label: "Raw Material 2", value: 2 },
    { label: "Raw Material 3", value: 3 },
  ]

  const DiscountTypeOption = [
    { label: "₹ ", value: 1 },
    { label: "%", value: 2 },
  ]

  const delete_TaxItem = index => {
    setFieldValue(
      "ListOfTaxItem",
      ListOfTaxItem.filter((element, i) => i !== index)
    )
  }

  const AddTaxList = () => {
    setFieldValue("ListOfTaxItem", [
      ...ListOfTaxItem,
      {
        ItemName: "",
        UnitOrderQuanity: "",
        PackType: "",
        Rate: "",
        Discount: 0,
        Tax: "",
        Amount: "",
        Percentage: "",
        Rupees: "",
        DiscountType: "%",
        IsOpen: false,
      },
    ])
  }

  const delete_PONumber = index => {
    formikPONo.setFieldValue(
      "ListofPurchaseOrderNumber",
      ListofPurchaseOrderNumber.filter((element, i) => i !== index)
    )
  }

  const AddPONumber = () => {
    formikPONo.setFieldValue("ListofPurchaseOrderNumber", [
      ...ListofPurchaseOrderNumber,
      {
        Prefix: "",
        NextNumber: "",
        Default: false,
      },
    ])
  }

  const countDiscount = (value, index) => {
    let newTaxList = []
    if (value === 1) {
      ListOfTaxItem?.map(x => {
        newTaxList.push({
          ItemName: x.ItemName,
          UnitOrderQuanity: x.UnitOrderQuanity,
          PackType: x.PackType,
          Rate: x.Rate,
          Discount: x.Discount,
          Tax: x.Tax,
          Amount: parseInt(x.Rate) - x.Discount,
          Percentage: x.Percentage,
          Rupees: x.Rupees,
          DiscountType: x.DiscountType,
          IsOpen: false,
        })
      })
    } else {
      ListOfTaxItem?.map(x => {
        newTaxList.push({
          ItemName: x.ItemName,
          UnitOrderQuanity: x.UnitOrderQuanity,
          PackType: x.PackType,
          Rate: x.Rate,
          Discount: x.Discount,
          Tax: x.Tax,
          Amount: parseInt(x.Rate) - (x.Rate * x.Discount) / 100,
          Percentage: x.Percentage,
          Rupees: x.Rupees,
          DiscountType: x.DiscountType,
          IsOpen: false,
        })
      })
    }
    setFieldValue("ListOfTaxItem", newTaxList)
  }

  const delete_FileUpload = index => {
    formik.setFieldValue(
      "ListOffileUpload",
      ListOffileUpload.filter((element, i) => i !== index)
    )
  }

  const AddFileUpload = () => {
    formik.setFieldValue("ListOffileUpload", [
      ...ListOffileUpload,
      {
        File: "",
      },
    ])
  }
  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{"Add Purchase Order"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            <Row className="mt-2">
              <Col lg={6}>
                <div style={{ fontSize: "large" }}>
                  <i className="ti-bag" style={{ fontWeight: "800" }}></i>
                  &nbsp;
                  <b>Edit Purchase Order</b>
                </div>
              </Col>
            </Row>
            <Row className="mt-3" style={{ borderTop: "1px solid #cacaca" }}>
              <Col className="mt-3">
                <FormikProvider value={formik}>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Customer Name <span className="text-danger">*</span>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <Field
                          name="CustomerName"
                          placeholder={"Select Customer Name"}
                          component={CustomInput}
                          value={"Deep"}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}></Col>
                      <Col lg={3}>
                        <Row>
                          <Col lg={12}>BILLING ADDRESS</Col>
                          {/* <Col lg={12}>
                          <Link to="#">+ Add new address</Link>
                        </Col> */}
                        </Row>
                      </Col>
                      <Col lg={3}>
                        <Row>
                          <Col lg={12}>SHIPPING ADDRESS</Col>
                          {/* <Col lg={12}>
                          <Link to="#">+ Add new address</Link>
                        </Col> */}
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg={2}></Col>
                      <Col lg={6}>
                        GST Treatment: &nbsp;
                        <text style={{ color: "black" }}>
                          Register Bussiness - Regular
                        </text>
                        &nbsp;
                        <Link to="#">
                          <i className="ti-marker-alt"></i>
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}></Col>
                      <Col lg={6}>
                        GSTIN: &nbsp;
                        <text style={{ color: "black" }}>18AABCU9603R1ZM</text>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Source of Supply{" "}
                          <span className="text-danger">*</span>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="text"
                          name="SourceOfSupply"
                          value={"[AS] Assam"}
                          placeholder={"Enter Source Of Supply"}
                          component={CustomInput}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Destination Of Supply{" "}
                          <span className="text-danger">*</span>
                        </div>
                      </Col>
                      <Col lg={4}>
                        {" "}
                        <Field
                          type="text"
                          name="DestinationOfSupply"
                          value={"[MH] Maharashtra"}
                          placeholder={"Enter Destination Of Supply"}
                          component={CustomInput}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Deliver To <span className="text-danger">*</span>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mt-2">
                          <Row>
                            <Col lg={12}>
                              {" "}
                              <Label>Organisation</Label>
                              {/* &nbsp;,
                            <Label>Customer</Label> */}
                            </Col>
                            <Col lg={12} style={{ marginTop: "-10px" }}>
                              Agent &nbsp;
                              <Link to="#">
                                <i className="ti-marker-alt"></i>
                              </Link>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12}>Maharashtra</Col>
                            <Col lg={12}>India,</Col>
                          </Row>
                          {/* <Row className="mt-3">
                          <Col lg={12}>
                            <Link to="#">Change destination to deliver</Link>
                          </Col>
                        </Row> */}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Purchase Order# <span className="text-danger">*</span>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="d-flex">
                          <Field
                            type="text"
                            name="PurchaseOrder"
                            placeholder={"Enter Purchase Order"}
                            component={CustomInput}
                            style={{ textTransform: "Uppercase" }}
                          />
                          &nbsp; &nbsp;
                          <Link
                            onClick={() => {
                              setPONoModal(true)
                            }}
                            className="mt-2"
                            title="Edit"
                          >
                            <i className="ti-settings"></i>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Reference#
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="text"
                          name="Reference"
                          placeholder={"Enter Reference"}
                          component={CustomInput}
                          style={{ textTransform: "Uppercase" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Date
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="text"
                          name="Date"
                          placeholder={"dd/mm/yyyy"}
                          component={CustomFlatpickr}
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "d/m/Y",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Expected Delivery Date
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Field
                          type="text"
                          name="ExpectedDeliveryDate"
                          placeholder={"dd/mm/yyyy"}
                          component={CustomFlatpickr}
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "d/m/Y",
                          }}
                        />
                      </Col>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Payment Terms
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Field
                          name="PaymentTerms"
                          placeholder={"Select Payment Terms"}
                          component={CustomSelect}
                          options={paymentTermsOption}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <div className="mt-2" style={{ color: "#000000" }}>
                          Shipment Preference
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Field
                          name="ShipmentPreference"
                          placeholder={"Select Shipment Preference"}
                          component={CustomSelect}
                          options={shipmentPreferenceOption}
                        />
                        <span>
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="Add"
                            checked={false}
                          />
                          &nbsp; This transaction is applicable for reverse
                          charge
                        </span>
                      </Col>
                    </Row>
                    <hr></hr>
                    {/* <Row className="mt-3">
                    <Col lg={6}>
                      <Row>
                        <Col lg={3}>Item Rates Are</Col>
                        <Col lg={4}>
                          <Field
                            name="ItemRate"
                            placeholder={"Item Rate"}
                            component={CustomSelect}
                            options={ItemRateOption}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={2}></Col>
                    <Col lg={4}>
                      <Link to="#">
                        <i className="ti-settings"></i>&nbsp;Bulk Update Line
                        Items
                      </Link>
                    </Col>
                  </Row> */}
                    <div className="table-responsive abc">
                      <table className="table table-bordered mb-0">
                        <thead>
                          <tr className="table-light">
                            <th style={{ minWidth: "450px" }}>Item Detail</th>
                            <th style={{ minWidth: "170px" }}>
                              Unit & Order Qty
                            </th>
                            <th style={{ minWidth: "150px" }}>Pack Type</th>
                            <th style={{ minWidth: "150px" }}>Rate</th>
                            <th style={{ minWidth: "150px" }}>Discount</th>
                            <th style={{ minWidth: "150px" }}>Tax</th>
                            <th style={{ minWidth: "150px" }}>Amount</th>
                            <th
                              style={{
                                borderBlockColor: "#ffffff00",
                                borderColor: "#ffffff00",
                                backgroundColor: "#ffffff00",
                              }}
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          {ListOfTaxItem &&
                            ListOfTaxItem.map((taxItem, index) => (
                              <tr>
                                <td>
                                  <Row>
                                    <Col lg={1} md={1} sm={1}>
                                      <img
                                        src={
                                          "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png"
                                        }
                                        alt=""
                                        className="avatar-xs rounded-square"
                                        style={{
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </Col>
                                    &nbsp; &nbsp;
                                    <Col lg={10} md={10} sm={10}>
                                      <Row>
                                        <Col lg={11} md={11} sm={11}>
                                          <Field
                                            name={`ListOfTaxItem.[${index}].ItemName`}
                                            placeholder={"Select Item"}
                                            component={CustomSelect}
                                            options={ItemNameOption}
                                          />
                                        </Col>

                                        <Col lg={12} md={12} sm={12}>
                                          <text>
                                            Description of Item Details
                                          </text>
                                        </Col>
                                        <Col lg={12} md={12} sm={12}>
                                          <Badge
                                            color="primary"
                                            className="bg-primary"
                                          >
                                            GOODS
                                          </Badge>
                                          &nbsp; HSN Code:{" "}
                                          <Link to="#">1234567</Link>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </td>
                                <td>
                                  <Row>
                                    <Col lg={12}>
                                      <Field
                                        type="number"
                                        name={`ListOfTaxItem.[${index}].UnitOrderQuanity`}
                                        placeholder={"Qty"}
                                        component={CustomInput}
                                      />
                                    </Col>
                                    <Col lg={12} style={{ textAlign: "right" }}>
                                      Mtr
                                    </Col>
                                  </Row>
                                </td>
                                <td>
                                  <Row>
                                    <Col lg={12}>
                                      <Field
                                        type="number"
                                        name={`ListOfTaxItem.[${index}].PackType`}
                                        placeholder={"Pack Type"}
                                        component={CustomInput}
                                        readOnly
                                      />
                                    </Col>
                                    <Col lg={12} style={{ textAlign: "right" }}>
                                      Mtr
                                    </Col>
                                  </Row>
                                </td>
                                <td>
                                  <Field
                                    type="number"
                                    name={`ListOfTaxItem.[${index}].Rate`}
                                    placeholder={"Rate"}
                                    component={CustomInput}
                                  />
                                </td>
                                <td>
                                  <ButtonDropdown
                                    isOpen={taxItem.IsOpen}
                                    toggle={() =>
                                      setFieldValue(
                                        `ListOfTaxItem.[${index}].IsOpen`,
                                        !taxItem.IsOpen
                                      )
                                    }
                                  >
                                    <Field
                                      type="number"
                                      name={`ListOfTaxItem.[${index}].Discount`}
                                      placeholder={"Discount"}
                                      component={CustomInput}
                                      min={0}
                                    />
                                    <DropdownToggle
                                      caret
                                      color="secondary"
                                      className="btn btn-secondary btn-lg DropDownBTN"
                                    >
                                      <div
                                        style={{
                                          marginLeft: "-11px",
                                          marginTop: "-5px",
                                        }}
                                      >
                                        <text
                                          style={{
                                            fontSize: "15px",
                                            color: "gray",
                                          }}
                                        >
                                          {taxItem.DiscountType}
                                        </text>
                                        &nbsp;
                                        <i
                                          style={{
                                            fontSize: "15px",
                                          }}
                                          className="fas fa-caret-down"
                                        ></i>
                                      </div>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {DiscountTypeOption?.map((x, i) => (
                                        <>
                                          <DropdownItem
                                            id={x.value}
                                            onClick={() => {
                                              countDiscount(x.value, index)
                                              setFieldValue(
                                                `ListOfTaxItem.[${index}].DiscountType`,
                                                x.label
                                              )
                                            }}
                                          >
                                            {x.label}
                                          </DropdownItem>
                                        </>
                                      ))}
                                    </DropdownMenu>
                                  </ButtonDropdown>
                                </td>
                                <td>
                                  <Field
                                    name={`ListOfTaxItem.[${index}].Tax`}
                                    placeholder={"Tax"}
                                    component={CustomSelect}
                                    options={TaxOption}
                                  />
                                </td>
                                <td>
                                  <Field
                                    type="number"
                                    name={`ListOfTaxItem.[${index}].Amount`}
                                    placeholder={"Amount"}
                                    component={CustomInput}
                                  />
                                </td>
                                <td
                                  style={{
                                    borderBlockColor: "#ffffff00",
                                    borderColor: "#ffffff00",
                                  }}
                                >
                                  <i
                                    class="fas fa-times-circle"
                                    style={{
                                      color: "red",
                                      marginTop: "12px",
                                    }}
                                    onClick={() => {
                                      delete_TaxItem(index)
                                    }}
                                  ></i>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>{" "}
                    <hr></hr>
                    <Row className="mt-3">
                      <Col lg={3} md={3} sm={3}>
                        <Row>
                          <Col
                            lg={8}
                            md={8}
                            sm={8}
                            style={{ marginTop: "10px" }}
                          >
                            <button
                              className="btn btn-secondary"
                              type="button"
                              onClick={() => {
                                AddTaxList()
                              }}
                            >
                              <i
                                class="fas fa-plus-circle"
                                style={{ color: "dodgerblue" }}
                              ></i>
                              &nbsp; Add another line
                            </button>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={9} md={9} sm={9} style={{ marginTop: "10px" }}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md={12} lg={12} sm={12}>
                                <Label
                                  md={6}
                                  sm={6}
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  <Label>{"Sub Total"} :</Label>
                                </Label>
                                <Label
                                  md={6}
                                  sm={6}
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "15px",
                                  }}
                                >
                                  Rs.10,000.00
                                </Label>
                              </Col>
                              <Col
                                md={12}
                                lg={12}
                                sm={12}
                                style={{ marginTop: "-17px" }}
                              >
                                <Label
                                  md={6}
                                  sm={6}
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                    marginTop: "-17px",
                                  }}
                                >
                                  <b style={{ fontWeight: 100 }}>
                                    Total Quantity : 1
                                  </b>
                                </Label>
                              </Col>
                              <Col md={12} lg={12} sm={12} className="mb-1">
                                <Label
                                  md={6}
                                  sm={6}
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  <Label>{"IGST [12%]"}</Label>
                                </Label>
                                <Label
                                  md={6}
                                  sm={6}
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "15px",
                                  }}
                                >
                                  Rs.1,000.00
                                </Label>
                              </Col>
                              {/* <Col md={12} lg={12} sm={12}>
                              <Label
                                md={6}
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "15px",
                                }}
                              >
                                <Field
                                  type="number"
                                  label="Total Tax Amount"
                                  name="TotalTaxAmount"
                                  placeholder={"Enter Total Tax Amount"}
                                  component={CustomInput}
                                  icon={true}
                                  iconClass={"mdi mdi-currency-inr"}
                                  min={0}
                                />
                              </Label>
                              <Label
                                md={6}
                                style={{
                                  textAlign: "right",
                                  paddingRight: "15px",
                                }}
                              >
                                Rs.10,000.00
                              </Label>
                            </Col> */}
                              <Col md={12} lg={12} sm={12}>
                                <Label
                                  md={8}
                                  sm={8}
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  <Row>
                                    <Col lg={6}>
                                      {" "}
                                      <Field
                                        type="text"
                                        name="Adjustment"
                                        placeholder={"Enter Adjustment"}
                                        value={"Adjustment"}
                                        component={CustomInput}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      {" "}
                                      <Field
                                        type="number"
                                        name="Tax"
                                        placeholder={"Enter Tax"}
                                        component={CustomInput}
                                        min={0}
                                      />
                                    </Col>
                                  </Row>
                                </Label>
                                <Label
                                  md={4}
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "15px",
                                  }}
                                >
                                  Rs.0.00
                                </Label>
                              </Col>
                              <Col md={12} lg={12} sm={12}>
                                <Label
                                  md={8}
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  <Row>
                                    <Col lg={6}>
                                      <Label>TCS</Label>
                                    </Col>
                                    <Col lg={6}>
                                      {" "}
                                      <Field
                                        name="TCS"
                                        placeholder={"Select TCS"}
                                        component={CustomSelect}
                                        options={TCSOption}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={6}>
                                      <Label>Freight Charges</Label>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="d-flex">
                                        <Field
                                          type="number"
                                          name="FreightCharges"
                                          placeholder={"Enter Freight Charges"}
                                          component={CustomInput}
                                          min={0}
                                        />{" "}
                                        &nbsp;&nbsp;
                                        <Link
                                          onClick={() => {
                                            setDisclaimerModal(true)
                                          }}
                                        >
                                          <i
                                            class="fa fa-asterisk"
                                            aria-hidden="true"
                                            style={{ fontSize: "12px" }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={6}>
                                      <Label>Document Charges</Label>
                                    </Col>
                                    <Col lg={6}>
                                      <Field
                                        type="number"
                                        name="DocumentCharges"
                                        placeholder={"Enter Document Charges"}
                                        component={CustomInput}
                                        min={0}
                                      />
                                    </Col>
                                  </Row>
                                </Label>
                                {/* <Label
                                md={4}
                                style={{
                                  textAlign: "right",
                                  paddingRight: "15px",
                                }}
                              >
                                Rs.0
                              </Label> */}
                              </Col>

                              <Col md={12} lg={12} sm={12}>
                                <hr></hr>
                                <Label
                                  md={8}
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                    fontSize: "17px",
                                    color: "black",
                                  }}
                                >
                                  {"Total (Rs.)"}
                                </Label>
                                <Label
                                  md={4}
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "15px",
                                    fontSize: "17px",
                                    color: "black",
                                  }}
                                >
                                  Rs.0
                                </Label>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col lg={6} style={{ verticalAlign: "bottom" }}>
                        <Field
                          label="Customer Notes"
                          type="textarea"
                          name="CustomerNotes"
                          placeholder={"Enter Customer Notes"}
                          component={CustomInput}
                        />
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col lg={7} md={7} sm={12}>
                        <Field
                          label="Terms & Condition"
                          type="textarea"
                          name="TermsCondition"
                          placeholder={
                            "Terms & Condition of your business to be displayed in your transaction."
                          }
                          component={CustomInput}
                          disabled
                          style={{ backgroundColor: "#e9ecef" }}
                        />
                      </Col>
                      <Col lg={1} md={1} sm={12} style={{ textAlign: "end" }}>
                        <Button
                          style={{
                            width: "25px",
                            height: "25px",
                            padding: "0px",
                          }}
                          onClick={() => {
                            setEditTermsAndConditionModal(true)
                          }}
                          title="Edit"
                        >
                          <i className="fas fa-edit"></i>
                        </Button>
                      </Col>
                      <Col lg={4} style={{ borderLeft: "1px solid #cacaca" }}>
                        <Row>
                          <Col lg={12}>
                            {"Attach File(s) to Purchase Order"}
                          </Col>
                          {ListOffileUpload?.map((files, index) => (
                            <Col lg={9} className="mt-2">
                              <div className="d-flex">
                                <input type="file" className="form-control" />{" "}
                                &nbsp;&nbsp;
                                <i
                                  class="fas fa-times-circle"
                                  style={{
                                    color: "red",
                                    marginTop: "12px",
                                  }}
                                  onClick={() => {
                                    delete_FileUpload(index)
                                  }}
                                ></i>
                              </div>
                            </Col>
                          ))}

                          <Col lg={12} className="mt-2">
                            <button
                              className="btn btn-secondary"
                              type="button"
                              onClick={() => {
                                AddFileUpload()
                              }}
                            >
                              <i
                                class="fas fa-plus-circle"
                                style={{ color: "dodgerblue" }}
                              ></i>
                              &nbsp; Add More
                            </button>
                          </Col>

                          <Col lg={12}>
                            <text style={{ fontSize: "11px" }}>
                              You can upload a maximum of 10 files, 5MB each
                            </text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row className="mt-3">
                      <Col lg={6}>
                        <button className="btn btn-primary" type="submit">
                          Save
                        </button>
                        &nbsp;&nbsp;
                        <button className="btn btn-secondary" type="button">
                          Save and Send
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            navigate("/purchase-order-customer")
                          }}
                        >
                          Cancel
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </FormikProvider>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal isOpen={PONoModal} centered={true} size="lg">
          <div className="modal-header mdl_header">
            <h5 className="modal-title mt-0">{"PO Purchase Number"}</h5>
            <button
              type="button"
              className="btn close mt-2"
              onClick={() => setPONoModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <FormikProvider value={formikPONo}>
              <Form onSubmit={formikPONo.handleSubmit}>
                <Row className="mt-3">
                  <Col lg={12}>
                    Your order number are set on auto-generate mode to save your
                    time. Are you sure about changing this setting
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={12}>
                    <Input
                      type="radio"
                      name="Setting"
                      id="Setting"
                      className="form-check-input"
                      onChange={e => {
                        console.log(e.target.checked)
                        formikPONo.setFieldValue("Setting", e?.target.checked)
                      }}
                      checked={Setting}
                    />
                    &nbsp;
                    <label className="custom-control-label" htmlFor="Setting">
                      Continue auto-generating order number
                    </label>
                  </Col>
                </Row>
                <div className="table-responsive mt-3">
                  <table className="table table-bordered mb-0">
                    <thead>
                      <tr className="table-light">
                        <th style={{ minWidth: "200px" }}>Prefix</th>
                        <th style={{ minWidth: "200px" }}>Next Number</th>
                        <th style={{ minWidth: "50px" }}>Default</th>
                        <th style={{ minWidth: "80px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ListofPurchaseOrderNumber &&
                        ListofPurchaseOrderNumber.map(
                          (paurchaseOrderNumber, index) => (
                            <tr>
                              <td>
                                <Field
                                  type="text"
                                  name={`ListofPurchaseOrderNumber.[${index}].Prefix`}
                                  placeholder={"Prefix"}
                                  component={CustomInput}
                                  style={{ textTransform: "Uppercase" }}
                                />
                              </td>
                              <td>
                                <Field
                                  type="number"
                                  name={`ListofPurchaseOrderNumber.[${index}].NextNumber`}
                                  placeholder={"Next Number"}
                                  component={CustomInput}
                                />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <Input
                                  type="radio"
                                  name={`ListofPurchaseOrderNumber.[${index}].Default`}
                                  id={`ListofPurchaseOrderNumber.[${index}].Default`}
                                  className="form-check-input"
                                  onChange={e => {
                                    formikPONo.setFieldValue(
                                      `ListofPurchaseOrderNumber.[${index}].Default`,
                                      e?.target.checked
                                    )
                                  }}
                                  checked={paurchaseOrderNumber.Default}
                                />
                              </td>
                              <td>
                                <div className="d-flex">
                                  <Button
                                    className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      padding: "0px",
                                    }}
                                    onClick={() => {}}
                                    title="Edit"
                                  >
                                    <i
                                      className="fas fa-edit"
                                      style={{ color: "#17a98c" }}
                                    ></i>
                                  </Button>
                                  &nbsp;
                                  <Button
                                    className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      padding: "0px",
                                    }}
                                    title="Delete"
                                    onClick={() => {
                                      delete_PONumber(index)
                                    }}
                                  >
                                    <i
                                      class="fas fa-trash-alt"
                                      style={{
                                        color: "red",
                                      }}
                                    ></i>
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
                <Row className="mt-2">
                  <Col style={{ textAlign: "end" }}>
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={() => {
                        AddPONumber()
                      }}
                    >
                      <i
                        class="fas fa-plus-circle"
                        style={{ color: "dodgerblue" }}
                      ></i>
                      &nbsp; Add More
                    </button>{" "}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={12}>
                    <Input
                      type="radio"
                      name="AddManually"
                      id="AddManually"
                      className="form-check-input"
                      onChange={e => {
                        formikPONo.setFieldValue(
                          "AddManually",
                          e?.target.checked
                        )
                      }}
                      checked={AddManually}
                    />
                    &nbsp;
                    <label
                      className="custom-control-label"
                      htmlFor="AddManually"
                    >
                      I will add them manually each time
                    </label>
                  </Col>
                </Row>
                <hr></hr>
                <Row className="mt-3">
                  <Col lg={12} style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" type="submit">
                      Save
                    </button>
                    &nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setPONoModal(false)
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </FormikProvider>
          </div>
        </Modal>
        <Modal isOpen={editTermsAndConditionModal} centered={true} size="lg">
          <div className="modal-header mdl_header">
            <h5 className="modal-title mt-0">{"Terms and Condition"}</h5>
            <button
              type="button"
              className="btn close mt-2"
              onClick={() => setEditTermsAndConditionModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <FormikProvider value={formikEditTermsAndCondition}>
              <Form onSubmit={formikEditTermsAndCondition.handleSubmit}>
                <Row>
                  {termsAndConditionsList?.map(x => (
                    <>
                      <Col lg={12} className="mt-2">
                        <Input
                          type="checkbox"
                          name="TermsAndCondition"
                          id="TermsAndCondition"
                          className="form-check-input"
                          checked={true}
                        />
                        {/* </Col>
                      <Col
                        lg={11}
                        style={{ textAlign: "left" }}
                        className="mt-2"
                      > */}{" "}
                        &nbsp;&nbsp;
                        {x.Condition}
                      </Col>
                    </>
                  ))}
                </Row>
                <hr></hr>
                <Row className="mt-3">
                  <Col lg={12} style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" type="submit">
                      Save
                    </button>
                    &nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setEditTermsAndConditionModal(false)
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </FormikProvider>
          </div>
        </Modal>
        <Modal isOpen={disclaimerModal} centered={true}>
          <div className="modal-header mdl_header">
            <h5 className="modal-title mt-0">
              <text style={{ color: "black" }}>{"Disclaimer"}</text>{" "}
            </h5>
            <button
              type="button"
              className="btn close mt-2"
              onClick={() => setDisclaimerModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col>
                <text>
                  Seller is not responsible for any differences that may occur
                  between freight estimates contained in a Quotation and actual
                  freight charges applicable at the time of shipment, and Buyer
                  shall incur and be responsible for all costs associated
                  therewith. Freight Charges.
                </text>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col lg={12} style={{ textAlign: "right" }}>
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={() => {
                    setDisclaimerModal(false)
                  }}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </div>
        </Modal>
      </Spin>
    </React.Fragment>
  )
}
AddPurchaseOrderCustomer.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(AddPurchaseOrderCustomer))
