import React from "react"
import { Modal } from "reactstrap"

export default function SwalError(props) {
  return (
    <div>
      <Modal isOpen={props.showError} backdrop={"static"} centered>
        <div className="modal-body">
          <div class="swal-icon swal-icon--error">
            <div class="swal-icon--error__x-mark">
              <span class="swal-icon--error__line swal-icon--error__line--left"></span>
              <span class="swal-icon--error__line swal-icon--error__line--right"></span>
            </div>
          </div>
          <div className="swal-title">Error!</div>
          <div className="swal-text">
            <ul>
              {props.ErrorMsg &&
                Object.keys(props.ErrorMsg).map(item => (
                  <li>{props.ErrorMsg[item]}</li>
                ))}
            </ul>
          </div>
          <div className="swal-footer mt-0 p-0">
            <div class="swal-button-container">
              <button
                type="button"
                className="swal-button swal-button--confirm"
                onClick={() => props.setShowError(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
