import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  Label,
  Modal,
} from "reactstrap"
import { useNavigate, Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CustomTable from "../../Custom/TableWithRemotePagination"
import classnames from "classnames"
import * as Yup from "yup"
import { Field, FormikProvider, useFormik } from "formik"
import CustomSelect from "Custom/CustomSelect"
import { get, post } from "helpers/api_helper"
import { GET_PurchaseOrderList, POST_DeleteCancelPO } from "helpers/url_helper"
import { Spin } from "antd"
import swal from "sweetalert"
import SwalError from "Custom/SwalError"
import moment from "moment"

const PurchaseOrder = props => {
  const navigate = useNavigate()
  const [totalSize, setTotalSize] = useState(0)
  // const [isDraft, setIsDraft] = useState(false)
  const [hide, setHide] = useState(1)
  const [mode, setMode] = useState(1)
  const [viewMore, setViewMore] = useState(false)
  const [fulfillmentPlanModal, setFulfillmentPlanModal] = useState(false)
  const [search, setSearch] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [purchaseOrderList, setPurchaseOrderList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10)
  const [POListData, setPOListData] = useState([])
  const [ID, setID] = useState("")

  const [showSwalError, setShowSwalError] = useState(false)
  const [swalErrorMsg, setSwalErrorMsg] = useState("")

  var Auth = JSON.parse(localStorage.getItem("authUser"))

  const columns = [
    {
      text: (
        <>
          <Input
            type="checkbox"
            className="form-check-input"
            id="Add"
            checked={false}
          />
          &nbsp; Date
        </>
      ),
      dataField: "Date",
      style: { minWidth: "150px" },
    },
    {
      text: "Purchase Order",
      dataField: "PurchaseOrder",
      style: { minWidth: "150px" },
    },
    {
      text: "Reference",
      dataField: "Reference",
      style: { minWidth: "150px" },
    },
    {
      text: "Vendor Name",
      dataField: "VendoreName",
      style: { minWidth: "200px" },
    },
    {
      text: "Status",
      dataField: "Status",
      style: { minWidth: "100px" },
    },
    {
      text: (
        <>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Amount
        </>
      ),
      dataField: "Amount",
      style: { minWidth: "150px", textAlign: "right" },
    },
    {
      text: "Expected Delivery Date",
      dataField: "ExpectedDeliveryDate",
      style: { minWidth: "230px" },
    },
    {
      text: "Actions",
      dataField: "Actions",
      style: { maxWidth: "90px", minWidth: "70px" },
    },
  ]

  // const rows = [
  //   {
  //     Date: (
  //       <>
  //         <Input
  //           type="checkbox"
  //           className="form-check-input"
  //           id="Add"
  //           checked={false}
  //         />{" "}
  //         &nbsp; 31/05/2023
  //       </>
  //     ),
  //     PurchaseOrder: (
  //       <>
  //         <Link
  //           onClick={() => {
  //             setHide(2)
  //           }}
  //         >
  //           PO-00003
  //         </Link>
  //       </>
  //     ),
  //     Reference: "ESTIMATE####",
  //     VendoreName: "Test Company",
  //     Status: (
  //       <>
  //         <text style={{ color: "grey" }}>DRAFT</text>
  //       </>
  //     ),
  //     Amount: "Rs.12,578.72",
  //     ExpectedDeliveryDate: "05/06/2023",
  //     Actions: (
  //       <>
  //         <div className="d-flex">
  //           {/* {isDraft ? ( */}
  //           <Button
  //             className="btn btn-secondary waves-effect waves-light btn btn-secondary"
  //             style={{
  //               width: "25px",
  //               height: "25px",
  //               padding: "0px",
  //             }}
  //             onClick={() => {
  //               // navigate("/add-purchase-order")
  //             }}
  //             title="Edit"
  //           >
  //             <i className="fas fa-edit" style={{ color: "#17a98c" }}></i>
  //           </Button>
  //           {/* ) : (
  //             ""
  //           )} */}
  //           &nbsp;
  //           <Button
  //             className="btn btn-secondary waves-effect waves-light btn btn-secondary"
  //             style={{
  //               width: "25px",
  //               height: "25px",
  //               padding: "0px",
  //             }}
  //             title="Cancel"
  //           >
  //             <i
  //               class="fas fa-times-circle"
  //               style={{
  //                 color: "red",
  //               }}
  //             ></i>
  //           </Button>
  //         </div>
  //       </>
  //     ),
  //   },
  //   {
  //     Date: (
  //       <>
  //         <Input
  //           type="checkbox"
  //           className="form-check-input"
  //           id="Add"
  //           checked={false}
  //         />{" "}
  //         &nbsp; 30/05/2023
  //       </>
  //     ),
  //     PurchaseOrder: (
  //       <>
  //         <Link
  //           onClick={() => {
  //             setHide(2)
  //           }}
  //         >
  //           PO-00002
  //         </Link>
  //       </>
  //     ),
  //     Reference: "",
  //     VendoreName: "VDeep Vendor",
  //     Status: (
  //       <>
  //         <text style={{ color: "green" }}>ACCEPTED</text>
  //       </>
  //     ),
  //     Amount: "Rs.111,000.00",
  //     ExpectedDeliveryDate: "",
  //     Actions: (
  //       <>
  //         <div className="d-flex">
  //           <Button
  //             className="btn btn-secondary waves-effect waves-light btn btn-secondary"
  //             style={{
  //               width: "25px",
  //               height: "25px",
  //               padding: "0px",
  //             }}
  //             onClick={() => {
  //               // navigate("/add-purchase-order")
  //             }}
  //             title="Edit"
  //             disabled
  //           >
  //             <i className="fas fa-edit" style={{ color: "#17a98c" }}></i>
  //           </Button>
  //           &nbsp;
  //           <Button
  //             className="btn btn-secondary waves-effect waves-light btn btn-secondary"
  //             style={{
  //               width: "25px",
  //               height: "25px",
  //               padding: "0px",
  //             }}
  //             title="Cancel"
  //           >
  //             <i
  //               class="fas fa-times-circle"
  //               style={{
  //                 color: "red",
  //               }}
  //             ></i>
  //           </Button>
  //         </div>
  //       </>
  //     ),
  //   },
  //   {
  //     Date: (
  //       <>
  //         <Input
  //           type="checkbox"
  //           className="form-check-input"
  //           id="Add"
  //           checked={false}
  //         />{" "}
  //         &nbsp; 29/05/2023
  //       </>
  //     ),
  //     PurchaseOrder: (
  //       <>
  //         <Link
  //           onClick={() => {
  //             setHide(2)
  //           }}
  //         >
  //           PO-00001
  //         </Link>
  //       </>
  //     ),
  //     Reference: "",
  //     VendoreName: "Test Company",
  //     Status: (
  //       <>
  //         <text style={{ color: "#798ad8" }}>ISSUED</text>
  //       </>
  //     ),
  //     Amount: "Rs.1000.00",
  //     ExpectedDeliveryDate: "",
  //     Actions: (
  //       <>
  //         <div className="d-flex">
  //           <Button
  //             className="btn btn-secondary waves-effect waves-light btn btn-secondary"
  //             style={{
  //               width: "25px",
  //               height: "25px",
  //               padding: "0px",
  //             }}
  //             onClick={() => {
  //               // navigate("/add-purchase-order")
  //             }}
  //             title="Edit"
  //             disabled
  //           >
  //             <i className="fas fa-edit" style={{ color: "#17a98c" }}></i>
  //           </Button>
  //           &nbsp;
  //           <Button
  //             className="btn btn-secondary waves-effect waves-light btn btn-secondary"
  //             style={{
  //               width: "25px",
  //               height: "25px",
  //               padding: "0px",
  //             }}
  //             title="Cancel"
  //           >
  //             <i
  //               class="fas fa-times-circle"
  //               style={{
  //                 color: "red",
  //               }}
  //             ></i>
  //           </Button>
  //         </div>
  //       </>
  //     ),
  //   },
  // ]

  const getData = (page = 1, sizePerPage = 10) => {
    setIsLoading(true)
    get(
      GET_PurchaseOrderList +
        "?page=" +
        page +
        "&paginate=" +
        sizePerPage +
        (Search ? "&search=" + Search : "") +
        (SearchByColumn ? "&column_filter=" + SearchByColumn : "") +
        (Status ? "&status_filter=" + Status : "")
    )
      .then(res => {
        if (res?.data?.data?.length > 0) {
          setPurchaseOrderList(
            res?.data?.data?.map((e, index) => {
              return {
                Date: (
                  <>
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id="Add"
                      checked={false}
                    />{" "}
                    &nbsp;
                    {e.purchase_order_date
                      ? moment(e.purchase_order_date)
                          .format("DD-MMM-YYYY")
                          .replace(/^\-/, "")
                      : ""}
                  </>
                ),
                PurchaseOrder: (
                  <>
                    {Auth?.permissions?.filter(
                      x => x.resource_id == 5 && x.view_per == 1
                    )?.length > 0 ? (
                      <Link
                        onClick={() => {
                          setHide(2)
                          setID(e.id)
                        }}
                      >
                        {e.purchase_order_no}
                      </Link>
                    ) : (
                      e.purchase_order_no
                    )}
                  </>
                ),
                Reference: e.reference_estimate,
                VendoreName: e.contact.company_name,
                Status: (
                  <>
                    <text
                      style={{
                        color:
                          e.po_status === "draft"
                            ? "grey"
                            : e.po_status === "issued"
                            ? "#798ad8"
                            : "green",
                        textTransform: "uppercase",
                      }}
                    >
                      {e.po_status}
                    </text>
                  </>
                ),
                Amount: (
                  <>Rs. {e.total ? parseFloat(e.total).toFixed(2) : "0.00"}</>
                ),
                ExpectedDeliveryDate: e.expected_delivery_date
                  ? moment(e.expected_delivery_date)
                      .format("DD-MMM-YYYY")
                      .replace(/^\-/, "")
                  : "",
                Actions: (
                  <>
                    <div className="d-flex">
                      {/* {isDraft ? ( */}
                      {Auth?.permissions?.filter(
                        x => x.resource_id == 5 && x.edit_per == 1
                      )?.length > 0 ? (
                        <Link
                          className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                          style={{
                            width: "25px",
                            height: "25px",
                            padding: "0px",
                          }}
                          to={`/edit-purchase-order/${e.id}`}
                          title="Edit"
                          disabled={e.po_status === "draft" ? false : true}
                        >
                          <i
                            className="fas fa-edit"
                            style={{ color: "#17a98c" }}
                          ></i>
                        </Link>
                      ) : (
                        ""
                      )}
                      {/* ) : (
                          ""
                        )} */}
                      &nbsp;
                      {Auth?.permissions?.filter(
                        x => x.resource_id == 5 && x.delete_per == 1
                      )?.length > 0 ? (
                        <>
                          <Button
                            className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                            style={{
                              width: "25px",
                              height: "25px",
                              padding: "0px",
                            }}
                            title="Cancel"
                            onClick={() => {
                              cancel_po(e.id)
                            }}
                          >
                            <i
                              class="fas fa-times-circle"
                              style={{
                                color: "red",
                              }}
                            ></i>
                          </Button>
                          &nbsp;
                          <Button
                            className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                            style={{
                              width: "25px",
                              height: "25px",
                              padding: "0px",
                            }}
                            title="Delete"
                            onClick={() => {
                              delete_po(e.id)
                            }}
                          >
                            <i
                              className="fas fa-trash-alt"
                              style={{
                                color: "red",
                              }}
                            ></i>
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ),
              }
            })
          )
          setTotalSize(res?.data?.total)
          setIsLoading(false)
          setPOListData(res?.data?.data)
        } else {
          setPurchaseOrderList([])
          setTotalSize(0)
          setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
    setCurrentPage(page)
    setCurrentSizePerPage(sizePerPage)
  }

  const validationSchemaFilter = Yup.object().shape({})

  const formikFilter = useFormik({
    validationSchema: validationSchemaFilter,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitFilter(values, formikHelpers),
    initialValues: {
      Status: "",
      Search: "",
      SearchByColumn: "",
    },
    validateOnBlur: false,
  })

  const {
    setFieldValue,
    values: { Search, Status, SearchByColumn },
  } = formikFilter

  const handleValidSubmitFilter = values => {}

  useEffect(() => {
    getData()
  }, [Search, SearchByColumn, Status])

  const purchaseOrderOptions = [
    { label: "Purchase Order", value: "purchase_order" },
    { label: "Vendor Name", value: "vendor_name" },
  ]

  const statusOption = [
    { label: "DRAFT", value: "draft" },
    { label: "APPROVAL", value: "approval" },
    { label: "PENDING", value: "pending" },
    { label: "SEND", value: "send" },
  ]

  const cancel_po = ID => {
    swal({
      title: "Are you sure you want to cancel the purchase order?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDo => {
      if (willDo) {
        cancel_poConfirm(ID)
      }
    })
  }

  const cancel_poConfirm = async ID => {
    setIsLoading(true)
    await post(POST_DeleteCancelPO, {
      po_id: ID,
      action: "canceled",
    })
      .then(response => {
        if (response?.status == 200) {
          swal({
            title: "Cancel Purchase Order",
            text: response?.message,
            icon: "success",
            button: "OK",
          })
          getData(currentPage, currentSizePerPage)
          setIsLoading(false)
        } else {
          // swal("Error!", response?.message, "error")
          if (typeof response?.message == "string") {
            swal({
              title: "Error!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
          setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  const delete_po = ID => {
    swal({
      title: "Are you sure you want to delete the purchase order?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDo => {
      if (willDo) {
        delete_poConfirm(ID)
      }
    })
  }

  const delete_poConfirm = async ID => {
    setIsLoading(true)
    await post(POST_DeleteCancelPO, {
      po_id: ID,
      action: "delete",
    })
      .then(response => {
        if (response?.status == 200) {
          swal({
            title: "Delete Purchase Order",
            text: response?.message,
            icon: "success",
            button: "OK",
          })
          getData(currentPage, currentSizePerPage)
          setIsLoading(false)
        } else {
          // swal("Error!", response?.message, "error")
          if (typeof response?.message == "string") {
            swal({
              title: "Error!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
          setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{"Purchase Orders"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            {hide === 1 ? (
              <>
                <Row className="mt-3">
                  <Col lg={6}>
                    <div className="mt-2" style={{ fontSize: "large" }}>
                      <b>Purchase Orders</b>
                    </div>
                  </Col>
                  <Col lg={6} style={{ textAlign: "end" }}>
                    {Auth?.permissions?.filter(
                      x => x.resource_id == 5 && x.create_per == 1
                    )?.length > 0 ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          navigate("/add-purchase-order")
                        }}
                      >
                        Add Purchase Order
                      </button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="col-12">
                    <FormikProvider value={formikFilter}>
                      <Form onSubmit={formikFilter.handleSubmit}>
                        <Row>
                          <Col lg={12}>
                            <Row>
                              <Col lg={3} sm={6} md={3}>
                                <div class="form-group has-search">
                                  <span class="fa fa-search form-control-feedback"></span>
                                  {/* <Field
                                    type="text"
                                    name="Search"
                                    component={CustomInput}
                                    placeholder="Search"
                                  /> */}
                                  <Input
                                    type="text"
                                    name="Search"
                                    class="form-control"
                                    placeholder="Search"
                                    value={Search}
                                    onChange={e => {
                                      //setSearch(e.target.value)
                                      formikFilter.setFieldValue(
                                        "Search",
                                        e.target.value
                                      )
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg={2} sm={6} md={2}>
                                <Field
                                  name="SearchByColumn"
                                  placeholder={"By Column"}
                                  component={CustomSelect}
                                  options={purchaseOrderOptions}
                                />
                              </Col>
                              <Col lg={2} md={2}>
                                <Field
                                  name="Status"
                                  placeholder={"Status"}
                                  component={CustomSelect}
                                  options={statusOption}
                                />
                              </Col>
                              <Col
                                lg={2}
                                className="mt-1"
                                style={{ textAlign: "left" }}
                              >
                                <div
                                  onClick={() => {
                                    //setSearch("")
                                    formikFilter.setFieldValue("Status", "")
                                    formikFilter.setFieldValue("Search", "")
                                    formikFilter.setFieldValue(
                                      "SearchByColumn",
                                      ""
                                    )
                                  }}
                                >
                                  <i
                                    className="mdi mdi-filter-remove"
                                    style={{
                                      fontSize: "20px",
                                      color: "#4a539f",
                                    }}
                                  ></i>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </FormikProvider>
                    <Card>
                      <CardBody className="table-fixed">
                        <CustomTable
                          keyField="Vendor"
                          columns={columns}
                          data={purchaseOrderList}
                          totalSize={totalSize}
                          getData={getData}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col
                    lg={3}
                    md={3}
                    sm={3}
                    style={{
                      // minHeight: "500px",
                      height: "100%",
                    }}
                  >
                    <Row style={{ background: "#f0f0f0", marginLeft: "-25px" }}>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{ marginTop: "20px", marginBottom: "6px" }}
                      >
                        <div className="d-flex">
                          <div style={{ width: "400px" }}>
                            All Paurchase Order
                          </div>
                          <div style={{ textAlign: "right", width: "100%" }}>
                            {Auth?.permissions?.filter(
                              x => x.resource_id == 5 && x.create_per == 1
                            )?.length > 0 ? (
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  navigate("/add-purchase-order")
                                }}
                                style={{
                                  padding: "0.05rem 0.5rem",
                                }}
                              >
                                + New
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{
                          borderTop: "1px solid #cacaca",
                        }}
                      >
                        {POListData?.map((x, i) => (
                          <Row
                            style={{
                              marginBottom: "5px",
                              marginTop: "5px",
                              backgroundColor: x.id === ID ? "#cecece96" : "",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setID(x?.id)
                            }}
                          >
                            <div className="col-1 col-lg-1 col-md-1 col-sm-2">
                              {" "}
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="check"
                                checked={false}
                              />
                            </div>
                            <div className="col col-lg-6 col-md-6 col-sm-6">
                              <text>{x.contact.company_name}</text>
                              <br></br>
                              <div
                                style={{ fontSize: "10px", marginTop: "6px" }}
                              >
                                <Link to="#"> {x.purchase_order_no}</Link>{" "}
                                |&nbsp;
                                {x.purchase_order_date
                                  ? moment(x.purchase_order_date)
                                      .format("DD-MMM-YYYY")
                                      .replace(/^\-/, "")
                                  : ""}
                              </div>
                            </div>
                            <div
                              className="col col-lg-5 col-md-5 col-sm-4"
                              style={{
                                marginLeft: "-2px",
                                textAlign: "end",
                                padding: "0.05rem 0.5rem",
                              }}
                            >
                              <text>
                                Rs.{" "}
                                {x.total
                                  ? parseFloat(x.total).toFixed(2)
                                  : "0.00"}
                              </text>
                              <br></br>
                              {/* <text
                                style={{ fontSize: "10px", color: "#798ad8" }}
                              > */}
                              <text
                                style={{
                                  color:
                                    x.po_status === "draft"
                                      ? "grey"
                                      : x.po_status === "issued"
                                      ? "#798ad8"
                                      : "green",
                                  textTransform: "uppercase",
                                  fontSize: "10px",
                                }}
                              >
                                {x.po_status}
                                {/* </text> */}
                              </text>
                            </div>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    lg={9}
                    md={9}
                    sm={9}
                    style={{ borderLeft: "1px solid #cacaca" }}
                  >
                    <Row>
                      <Col
                        lg={12}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <div style={{ fontSize: "16px", color: "grey" }}>
                          PO-00001
                        </div>
                        <div style={{ textAlignLast: "end" }}>
                          {/* <button
                      className="btn btn-sm  btn-light"
                      style={{ marginTop: "-50px" }}
                      onClick={() => {}}
                    >
                      Edit
                    </button> */}
                          <button
                            type="button"
                            className="btn"
                            style={{
                              marginTop: "-50px",
                              fontSize: "20px",
                              position: "sticky",
                              border: 0,
                            }}
                            onClick={() => setHide(1)}
                          >
                            <span>×</span>
                          </button>
                        </div>
                      </Col>
                      <Col lg={12} style={{ borderTop: "1px solid #cacaca" }}>
                        <Nav tabs className="nav-tabs-custom">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: mode === 1,
                              })}
                              onClick={() => {
                                setMode(1)
                              }}
                            >
                              Comments & History
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: mode === 2,
                              })}
                              onClick={() => {
                                setMode(2)
                              }}
                            >
                              Bill
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={mode} className="p-3 text-muted">
                          <TabPane tabId={1}>
                            {/* <Row>
                        <Col lg={3}>31/05/2023 12:40PM</Col>
                        <Col lg={9}>
                          Purchase Order created for Rs.1000.00 by Agent.
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col lg={3}>31/05/2023 12:40PM</Col>
                        <Col lg={9}>
                          Purchase Order created for Rs.1000.00 by Agent.
                        </Col>
                      </Row> */}

                            <ol className="activity-feed mb-0 ps-2 ms-1">
                              <li className="feed-item">
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "11px" }}
                                >
                                  31/05/2023 12:40PM
                                </p>
                                <p className="mb-0">
                                  Purchase Order created for Rs.1000.00 by
                                  Agent.
                                </p>
                              </li>
                              <li className="feed-item">
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "11px" }}
                                >
                                  31/05/2023 12:40PM
                                </p>
                                <p className="mb-0">
                                  Purchase Order created for Rs.1000.00 by
                                  Agent.
                                </p>
                              </li>
                              <li className="feed-item">
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "11px" }}
                                >
                                  31/05/2023 12:40PM
                                </p>
                                <p className="mb-0">
                                  Purchase Order created for Rs.1000.00 by
                                  Agent.
                                </p>
                              </li>
                              {viewMore ? (
                                <>
                                  <li className="feed-item">
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "11px" }}
                                    >
                                      31/05/2023 12:40PM
                                    </p>
                                    <p className="mb-0">
                                      Purchase Order created for Rs.1000.00 by
                                      Agent.
                                    </p>
                                  </li>
                                  <li className="feed-item">
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "11px" }}
                                    >
                                      31/05/2023 12:40PM
                                    </p>
                                    <p className="mb-0">
                                      Purchase Order created for Rs.1000.00 by
                                      Agent.
                                    </p>
                                  </li>
                                  <li className="feed-item">
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "11px" }}
                                    >
                                      31/05/2023 12:40PM
                                    </p>
                                    <p className="mb-0">
                                      Purchase Order created for Rs.1000.00 by
                                      Agent.
                                    </p>
                                  </li>
                                </>
                              ) : (
                                ""
                              )}
                            </ol>
                            <Row>
                              <Col lg={12} style={{ textAlign: "right" }}>
                                <Link
                                  onClick={() => {
                                    viewMore
                                      ? setViewMore(false)
                                      : setViewMore(true)
                                  }}
                                >
                                  {viewMore ? "View Less" : "View More"}
                                </Link>
                              </Col>
                            </Row>
                            <Row
                              style={{
                                borderTop: "1px solid #cacaca",
                              }}
                            >
                              <Col
                                lg={12}
                                md={12}
                                sm={12}
                                style={{ marginTop: "40px" }}
                              >
                                <Row style={{ backgroundColor: "#f8f9fa00" }}>
                                  <Col lg={1}></Col>
                                  <Col lg={10} md={10} sm={10}>
                                    <Row>
                                      <Col lg={6} md={12} sm={12}>
                                        <text
                                          style={{
                                            fontSize: "20px",
                                            color: "black",
                                          }}
                                        >
                                          PURCHASE ORDER
                                        </text>
                                        <br></br>
                                        <text>
                                          Purchase Order#<b>PO-00001</b>
                                        </text>
                                        <Row style={{ marginTop: "30px" }}>
                                          <Col lg={2}>
                                            <button
                                              className="btn btn-primary"
                                              disabled
                                              style={{
                                                padding: "0.05rem 0.5rem",
                                                backgroundColor: "#626ed4",
                                              }}
                                            >
                                              ISSUED
                                            </button>
                                          </Col>
                                        </Row>
                                        <Row style={{ marginTop: "15px" }}>
                                          <Col lg={7} md={12} sm={12}>
                                            <b>ORDER PAYMENT</b>
                                          </Col>
                                          <Col lg={5} md={5} sm={12}>
                                            31/05/2023
                                          </Col>
                                        </Row>

                                        <Row style={{ marginTop: "15px" }}>
                                          <Col lg={7} md={12} sm={12}>
                                            <b>PAYMENT TERMS</b>
                                          </Col>
                                          <Col lg={5} md={5} sm={12}>
                                            Due on Receipt
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col lg={1}></Col>
                                      <Col
                                        lg={5}
                                        md={12}
                                        sm={12}
                                        style={{ marginTop: "15px" }}
                                      >
                                        {" "}
                                        <text>
                                          <b>VENDOR ADDRESS</b>
                                        </text>
                                        <br></br>
                                        <Link to="#">Test Company</Link>
                                        <br></br>
                                        <br></br>
                                        <Row style={{ marginTop: "30px" }}>
                                          <Col lg={12} md={12} sm={12}>
                                            <b>DELIVERY ADDRESS</b>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col lg={12} md={12} sm={12}>
                                            A-910,Titanium City Center, Prahlad
                                            Nagar, Ahmedabad , Gujarat - 380018
                                          </Col>
                                        </Row>
                                        <Row className="mt-3">
                                          <Col
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            style={{ textAlign: "right" }}
                                          >
                                            <button
                                              className="btn btn-light"
                                              type="button"
                                              onClick={() => {
                                                setFulfillmentPlanModal(true)
                                              }}
                                            >
                                              View Fulfillment Plan
                                            </button>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row style={{ marginTop: "10px" }}>
                                      <Col lg={12}>
                                        <div className="table-responsive">
                                          <table className="table mb-0">
                                            <thead>
                                              <tr className="table-light">
                                                <th
                                                  style={{ minWidth: "220px" }}
                                                >
                                                  {/* <th style={{ minWidth: "250px" }}> */}
                                                  ITEMS & DESCRIPTION
                                                </th>
                                                <th
                                                  style={{ minWidth: "50px" }}
                                                >
                                                  ORDERD
                                                </th>
                                                <th
                                                  style={{ minWidth: "100px" }}
                                                >
                                                  STATUS
                                                </th>
                                                <th
                                                  style={{ minWidth: "100px" }}
                                                >
                                                  RATE
                                                </th>
                                                <th
                                                  style={{ minWidth: "100px" }}
                                                >
                                                  AMOUNT
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  {/* <Row>
                                              <Col lg={6} md={6} sm={6}> */}
                                                  <img
                                                    src={
                                                      "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png"
                                                    }
                                                    alt=""
                                                    className="avatar-xs rounded-square"
                                                    style={{
                                                      borderRadius: "5px",
                                                    }}
                                                  />
                                                  {/* </Col>
                                              <Col lg={10} md={10} sm={10}> */}
                                                  &nbsp;
                                                  <span> Raw Material</span>
                                                  {/* </Col> */}
                                                  {/* </Row> */}
                                                </td>
                                                <td>1 Box</td>
                                                <td>
                                                  0 Received<br></br>0 Billed
                                                </td>
                                                <td>Rs.10,000.00</td>
                                                <td>10,000.00</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>{" "}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        md={6}
                                        lg={6}
                                        sm={12}
                                        style={{ marginBottom: "-9px" }}
                                      ></Col>
                                      <Col
                                        md={6}
                                        lg={6}
                                        sm={12}
                                        style={{ marginBottom: "-9px" }}
                                      >
                                        <Label
                                          md={6}
                                          style={{
                                            textAlign: "left",
                                            paddingLeft: "15px",
                                          }}
                                        >
                                          <b style={{ fontWeight: 600 }}>
                                            {"Sub Total"} :
                                          </b>
                                        </Label>
                                        <Label
                                          md={6}
                                          style={{
                                            textAlign: "right",
                                            paddingRight: "15px",
                                          }}
                                        >
                                          Rs.10,000.00
                                        </Label>
                                      </Col>
                                      <Col md={6} lg={6} sm={12}></Col>
                                      <Col md={6} lg={6} sm={12}>
                                        <Label
                                          md={6}
                                          style={{
                                            textAlign: "left",
                                            paddingLeft: "15px",
                                            marginTop: "-17px",
                                          }}
                                        >
                                          <b style={{ fontWeight: 100 }}>
                                            Total Quantity : 1
                                          </b>
                                        </Label>
                                      </Col>

                                      <Col
                                        md={6}
                                        lg={6}
                                        sm={12}
                                        className="mb-1"
                                      ></Col>
                                      <Col
                                        md={6}
                                        lg={6}
                                        sm={12}
                                        className="mb-1"
                                      >
                                        <Label
                                          md={6}
                                          style={{
                                            textAlign: "left",
                                            paddingLeft: "15px",
                                          }}
                                        >
                                          <b style={{ fontWeight: 600 }}>
                                            {"Discount"} :
                                          </b>
                                        </Label>
                                        <Label
                                          md={6}
                                          style={{
                                            textAlign: "right",
                                            paddingRight: "15px",
                                          }}
                                        >
                                          Rs.1,000.00
                                        </Label>
                                      </Col>

                                      <Col
                                        md={6}
                                        lg={6}
                                        sm={12}
                                        className="mb-1"
                                      ></Col>
                                      <Col
                                        md={6}
                                        lg={6}
                                        sm={12}
                                        className="mb-1"
                                      >
                                        <hr className="mt-0 mb-0"></hr>
                                        <Label
                                          md={6}
                                          style={{
                                            textAlign: "left",
                                            paddingLeft: "15px",
                                          }}
                                        >
                                          <b style={{ fontWeight: 600 }}>
                                            {"Total"} :
                                          </b>
                                        </Label>
                                        <Label
                                          md={6}
                                          style={{
                                            textAlign: "right",
                                            paddingRight: "15px",
                                          }}
                                        >
                                          Rs.9,000.00
                                        </Label>
                                        <hr className="mt-0 mb-0"></hr>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={1}></Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={12} style={{ marginBottom: "20px" }}>
                                <hr></hr>
                                <div>
                                  <b>31/05/2023 | PurchaseOrderReceipt</b>{" "}
                                  &nbsp;
                                  <Link to="#" onClick={() => window.open("/")}>
                                    <i className="ti-download"></i> Download
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId={2}></TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            <SwalError
              showError={showSwalError}
              setShowError={setShowSwalError}
              ErrorMsg={swalErrorMsg}
            ></SwalError>
          </Container>
        </div>
        <Modal isOpen={fulfillmentPlanModal} centered={true} size="lg">
          <div className="modal-header mdl_header">
            <h5 className="modal-title mt-0">
              <text style={{ color: "black" }}>
                {"Fulfillment Planning Details"}
              </text>{" "}
            </h5>
            <button
              type="button"
              className="btn close mt-2"
              onClick={() => setFulfillmentPlanModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="table-responsive">
              <table className="table table-bordered mb-0">
                <thead>
                  <tr className="table-light">
                    <th style={{ minWidth: "250px" }}>ITEMS & DESCRIPTION</th>
                    <th style={{ minWidth: "150px" }}>ORDERED</th>
                    <th style={{ minWidth: "200px" }}>
                      FULFILLMENT PLANNING DATES
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img
                        src={
                          "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png"
                        }
                        alt=""
                        className="avatar-xs rounded-square"
                        style={{
                          borderRadius: "5px",
                        }}
                      />
                      &nbsp;<span> Raw Material</span>
                    </td>
                    <td>
                      1235 Box <br></br>
                      Pending-31
                    </td>
                    <td>
                      19-Jun-2023 <br></br>
                      20-Jun-2023
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr></hr>
            <Row>
              <Col lg={12} style={{ textAlign: "right" }}>
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={() => {
                    setFulfillmentPlanModal(false)
                  }}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </div>
        </Modal>
      </Spin>
    </React.Fragment>
  )
}
PurchaseOrder.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(PurchaseOrder))
