import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  BreadcrumbItem,
  Button,
  Input,
  Modal,
  Form,
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CustomTable from "../../Custom/TableWithRemotePagination"

import * as Yup from "yup"
import CustomInput from "Custom/CustomInput"
import { Field, FormikProvider, useFormik } from "formik"
import CustomSelect from "Custom/CustomSelect"
import PhoneInput from "Custom/CustomPhoneInput"
import { get, post } from "helpers/api_helper"
import {
  GET_userdetail,
  GET_users,
  POST_AddUser,
  GET_UserSalutation,
  //GET_RoleType,
  GET_roles,
} from "helpers/url_helper"
import { Spin } from "antd"
import swal from "sweetalert"
import SwalError from "Custom/SwalError"

const UserList = props => {
  const [listUserData, setListUserData] = useState([])
  const [totalSize, setTotalSize] = useState(0)
  const [modal_center, setmodal_center] = useState(false)
  const [country, setCountry] = useState("")
  const [countryWork, setCountryWork] = useState("")
  const [userID, setUserID] = useState("")
  const [modalHeaderName, setModatHeaderName] = useState("Add Users")
  const [userSalutationOption, setUserSalutationOption] = useState([])
  const [roleTypeOption, setRoleTypeOption] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10)

  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState("")

  const [showSwalError, setShowSwalError] = useState(false)
  const [swalErrorMsg, setSwalErrorMsg] = useState("")

  var Auth = JSON.parse(localStorage.getItem("authUser"))

  const validationSchema = Yup.object().shape({
    Email: Yup.string().required("Email is a required field."),
    Role: Yup.string().required("Role is a required field."),
    // FirstName: Yup.string().required("First Name is a required field."),
    // Lastname: Yup.string().required("Last Name is a required field."),
    // WorkPhone: Yup.string().required("Work Phone is a required field."),
    Mobile: Yup.string().required("Mobile No is a required field."),
    // Salutation: Yup.string().required("Salutation is a required field."),
  })

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      Email: "",
      Role: "",
      FirstName: "",
      Lastname: "",
      WorkPhone: "",
      Mobile: "",
      Salutation: "",
      CountryCode: "+91",
      WorkCountryCode: "+91",
    },
    validateOnBlur: false,
  })

  const {
    setValues,
    setFieldValue,
    values: { CountryCode, WorkCountryCode },
  } = formik

  const handleValidSubmit = values => {
    setIsLoading(true)
    const Data = {
      email: values.Email,
      salutation: values.Salutation,
      first_name: values.FirstName,
      last_name: values.Lastname,
      // mobile: "+" + CountryCode?.replace(/\+/g, "") + values.Mobile,
      // phone: "+" + WorkCountryCode?.replace(/\+/g, "") + values.WorkPhone,
      mobile: values.Mobile
        ? "+" + CountryCode.replace(/\+/g, "") + " " + values.Mobile
        : "",
      phone: values.WorkPhone
        ? "+" + WorkCountryCode.replace(/\+/g, "") + " " + values.WorkPhone
        : "",
      role_id: values.Role,
      id: userID,
    }

    //console.log("Update", Data)
    post(POST_AddUser, Data)
      .then(response => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: response.message,
            icon: "success",
            button: "OK",
          })
          setmodal_center(false)
          getData()
        } else {
          if (typeof response?.message == "string") {
            swal({
              title: "Error",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
        }
        setIsLoading(false)
      })
      .catch(error => {
        error.response.data
        setIsLoading(false)
      })
  }

  const validationSchemaFilter = Yup.object().shape({})

  const formikFilter = useFormik({
    validationSchema: validationSchemaFilter,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitFilter(values, formikHelpers),
    initialValues: {
      Status: "",
      Search: "",
      SearchByColumn: "",
    },
    validateOnBlur: false,
  })

  const {
    values: { Search, Status, SearchByColumn },
  } = formikFilter

  const handleValidSubmitFilter = values => {}

  const getUserSalutation = () => {
    setIsLoading(true)
    get(GET_UserSalutation).then(res => {
      if (res) {
        let NewOptions = []
        res.data.salutation?.map(item =>
          NewOptions.push({
            label: item,
            value: item,
          })
        )
        setUserSalutationOption(NewOptions)
      }
    })
    setIsLoading(false)
  }

  // const getRoleType = () => {
  //   setIsLoading(true)
  //   get(GET_RoleType).then(res => {
  //     if (res) {
  //       let NewOptions = []
  //       res.data.role_type?.map((item, i) =>
  //         NewOptions.push({
  //           label: item,
  //           value: item,
  //         })
  //       )
  //       setRoleTypeOption(NewOptions)
  //     }
  //   })
  //   setIsLoading(false)
  // }

  const getRoleType = () => {
    setIsLoading(true)

    get(GET_roles + "?paginate=100&page=1").then(res => {
      if (res) {
        let NewOptions = []

        res?.data?.data?.map((item, i) =>
          NewOptions.push({
            label: item.role_name,
            value: item.id,
          })
        )
        setRoleTypeOption(NewOptions)
      }
    })
    setIsLoading(false)
  }

  const columns = [
    {
      text: "Name",
      dataField: "Name",
      style: { minWidth: "150px" },
    },
    {
      text: "Email",
      dataField: "Email",
      style: { minWidth: "200px" },
    },
    {
      text: "Role",
      dataField: "Role",
      style: { minWidth: "120px" },
    },
    {
      text: "Status",
      dataField: "Status",
      style: { minWidth: "100px" },
    },
    {
      text: "Actions",
      dataField: "Actions",
      style: { maxWidth: "70px", minWidth: "70px" },
    },
  ]

  const getData = (page = 1, sizePerPage = 10) => {
    setIsLoading(true)
    // alert(SearchByColumn)
    get(
      GET_users +
        "?paginate=" +
        sizePerPage +
        "&page=" +
        page +
        (Search ? "&search=" + Search : "") +
        (SearchByColumn ? "&column_filter=" + SearchByColumn : "") +
        (Status ? "&status_filter=" + Status : "")
    )
      .then(res => {
        if (res.status === 200) {
          if (res?.data?.data?.length > 0) {
            setListUserData(
              res?.data?.data?.map((e, index) => {
                return {
                  Name: e.first_name + " " + e.last_name,
                  Email: e.email,
                  Role: e.role_name,
                  Status: e.status,
                  Actions: (
                    <>
                      <div className="d-flex">
                        {Auth?.permissions?.filter(
                          x => x.resource_id == 2 && x.edit_per == 1
                        )?.length > 0 ? (
                          <Button
                            className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                            style={{
                              width: "25px",
                              height: "25px",
                              padding: "0px",
                            }}
                            onClick={() => {
                              formik.resetForm()
                              setUserID(e.id)
                              getUserDetail(e.id)
                              setModatHeaderName("Edit User")
                            }}
                            title="Edit"
                          >
                            <i
                              className="fas fa-edit"
                              style={{ color: "#17a98c" }}
                            ></i>
                          </Button>
                        ) : (
                          ""
                        )}
                        &nbsp;
                        {Auth?.permissions?.filter(
                          x => x.resource_id == 2 && x.delete_per == 1
                        )?.length > 0 ? (
                          <Button
                            className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                            style={{
                              width: "25px",
                              height: "25px",
                              padding: "0px",
                            }}
                            title={"Block"}
                            onClick={() => {
                              setUserID(e.id)
                            }}
                          >
                            <i
                              className="fas fa-ban"
                              style={{ color: "red" }}
                            ></i>
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ),
                }
              })
            )
            setTotalSize(res.data.total)
            setIsLoading(false)
          }
        } else {
          setListUserData([])
          setTotalSize(0)
          setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
    setCurrentPage(page)
    setCurrentSizePerPage(sizePerPage)
  }

  useEffect(() => {
    getData()
  }, [Search, SearchByColumn, Status])

  useEffect(() => {
    getUserSalutation()
    getRoleType()
  }, [])

  const userOptions = [
    { label: "Name", value: "name" },
    { label: "Email", value: "email" },
    { label: "Role", value: "role" },
  ]

  const statusOption = [
    // { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ]

  const getUserDetail = id => {
    setIsLoading(true)
    get(
      GET_userdetail + `${id}`
      // , {
      //    headers: { Authorization: `Bearer ${Auth.user.auth.token}` },
      // }
    ).then(res => {
      if (res?.status == 200) {
        setValues({
          Email: res.data.email,
          Role: res.data.role_id,
          FirstName: res.data.first_name,
          Lastname: res.data.last_name,
          //WorkPhone: res.data.phone,
          WorkPhone: res.data.phone
            ? res.data.phone.replace(res.data.phone.split(" ")[0], "").trim()
            : res.data.phone,
          //Mobile: res.data.mobile,
          Mobile: res.data.mobile
            ? res.data.mobile.replace(res.data.mobile.split(" ")[0], "").trim()
            : res.data.mobile,
          Salutation: res.data.salutation,
          CountryCode: res.data.mobile ? res.data.mobile.split(" ")[0] : "+91",
          WorkCountryCode: res.data.phone
            ? res.data.phone.split(" ")[0]
            : "+91",
        })
        setmodal_center(true)
      } else {
        // swal("Edit User", res?.message, "warning")
        if (typeof res?.message == "string") {
          swal({
            title: "Error!",
            text: res.message,
            icon: "error",
            button: "OK",
          })
        } else {
          setSwalErrorMsg(res?.message)
          setShowSwalError(true)
        }
      }
    })
    setIsLoading(false)
  }
  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{"Users"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            <Row className="mt-3">
              <Col lg={6}>
                <div className="mt-2" style={{ fontSize: "large" }}>
                  <b>Users</b>
                </div>
              </Col>
              <Col lg={6} style={{ textAlign: "end" }}>
                {Auth?.permissions?.filter(
                  x => x.resource_id == 2 && x.create_per == 1
                )?.length > 0 ? (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setUserID("")
                      formik.resetForm()
                      setModatHeaderName("Add Users")
                      setmodal_center(true)
                    }}
                  >
                    Add Users
                  </button>
                ) : (
                  ""
                )}
                &nbsp;
                <button className="btn btn-secondary">Export</button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="col-12">
                <FormikProvider value={formikFilter}>
                  <Form onSubmit={formikFilter.handleSubmit}>
                    <Row>
                      <Col lg={12}>
                        <Row>
                          <Col lg={3} sm={6} md={3}>
                            <div class="form-group has-search">
                              <span class="fa fa-search form-control-feedback"></span>
                              <Input
                                type="text"
                                name="Search"
                                class="form-control"
                                placeholder="Search"
                                value={Search}
                                onChange={e => {
                                  //setSearch(e.target.value)
                                  formikFilter.setFieldValue(
                                    "Search",
                                    e.target.value
                                  )
                                }}
                              />
                            </div>
                          </Col>
                          <Col lg={2} sm={6} md={2}>
                            <Field
                              name="SearchByColumn"
                              placeholder={"By Column"}
                              component={CustomSelect}
                              options={userOptions}
                            />
                          </Col>
                          <Col lg={2} md={2}>
                            <Field
                              name="Status"
                              placeholder={"Status"}
                              component={CustomSelect}
                              options={statusOption}
                            />{" "}
                          </Col>
                          <Col
                            lg={2}
                            className="mt-1"
                            style={{ textAlign: "left" }}
                          >
                            <div
                              onClick={() => {
                                // setSearch("")
                                // formikFilter.setFieldValue("Status", "")
                                // // formikFilter.setFieldValue("Search", "")

                                // formikFilter.setFieldValue("SearchByColumn", "")
                                formikFilter.setFieldValue("Status", "")
                                formikFilter.setFieldValue("Search", "")
                                formikFilter.setFieldValue("SearchByColumn", "")
                              }}
                            >
                              <i
                                className="mdi mdi-filter-remove"
                                style={{ fontSize: "20px", color: "#4a539f" }}
                              ></i>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </FormikProvider>
                <Card>
                  <CardBody className="table-fixed">
                    <CustomTable
                      keyField="Users"
                      columns={columns}
                      data={listUserData}
                      totalSize={totalSize}
                      getData={getData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <SwalError
              showError={showSwalError}
              setShowError={setShowSwalError}
              ErrorMsg={swalErrorMsg}
            ></SwalError>
          </Container>
        </div>
        <Modal isOpen={modal_center} centered={true} size="lg">
          <Spin size="large" spinning={isLoading} tip={"Loading..."}>
            <div className="modal-header mdl_header">
              <h5 className="modal-title mt-0">{modalHeaderName}</h5>
              <button
                type="button"
                className="btn close mt-2"
                onClick={() => setmodal_center(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                  <Row className="mt-2">
                    <Col lg={3}>
                      <div className="fieldNameLabel">
                        User Email <span className="text-danger">*</span>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <Field
                        type="email"
                        name="Email"
                        placeholder={"Enter Email"}
                        component={CustomInput}
                        maxLength={50}
                        disabled={userID ? true : false}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col lg={3}>
                      <div className="fieldNameLabel">
                        Role <span className="text-danger">*</span>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <Field
                        name="Role"
                        placeholder={"Select Role"}
                        component={CustomSelect}
                        options={roleTypeOption}
                        maxLength={50}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col lg={3}>
                      <div className="fieldNameLabel">User Name</div>
                    </Col>
                    <Col lg={3}>
                      <Field
                        name="Salutation"
                        placeholder={"Select Salutation"}
                        component={CustomSelect}
                        options={userSalutationOption}
                        maxLength={50}
                      />
                    </Col>
                    <Col lg={3}>
                      <Field
                        type="text"
                        name="FirstName"
                        placeholder={"Enter First Name"}
                        component={CustomInput}
                      />
                    </Col>
                    <Col lg={3}>
                      <Field
                        type="text"
                        name="Lastname"
                        placeholder={"Enter Last Name"}
                        component={CustomInput}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} className="mt-3">
                      <div className="fieldNameLabel mt-3">
                        User Contact <span className="text-danger">*</span>
                      </div>
                    </Col>

                    <Col lg={3}>
                      <Field
                        name="Mobile"
                        label="Mobile No"
                        placeholder={"Enter Mobile No"}
                        country={country}
                        countryCode={CountryCode}
                        component={PhoneInput}
                        onChange={(value, { countryCode, dialCode }) => {
                          //setCountry(countryCode)
                          setFieldValue("CountryCode", dialCode)
                          setFieldValue(
                            "Mobile",
                            value.substring(dialCode.toString().length)
                          )
                        }}
                        required
                      />
                    </Col>
                    <Col lg={3}>
                      <Field
                        type="text"
                        label="Work Phone"
                        name="WorkPhone"
                        placeholder={"Enter Work Phone"}
                        country={countryWork}
                        countryCode={WorkCountryCode}
                        component={PhoneInput}
                        onChange={(value, { countryCode, dialCode }) => {
                          // setCountryWork(countryCode)
                          setFieldValue("WorkCountryCode", dialCode)
                          setFieldValue(
                            "WorkPhone",
                            value.substring(dialCode.toString().length)
                          )
                        }}
                      />
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col lg={6}>
                      <button className="btn btn-primary" type="submit">
                        Send
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          setmodal_center(false)
                        }}
                      >
                        Cancel
                      </button>
                    </Col>
                  </Row>
                </Form>
              </FormikProvider>
            </div>
          </Spin>
        </Modal>
      </Spin>
    </React.Fragment>
  )
}
UserList.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(UserList))
