import React from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody, Container, Form } from "reactstrap"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import * as Yup from "yup"
import CustomInput from "Custom/CustomInput"
import { Field, FormikProvider, useFormik } from "formik"
import CustomSelect from "Custom/CustomSelect"
import CustomFlatpickr from "Custom/CustomFlatpicker"
import { useState } from "react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Spin } from "antd"
import { get, post } from "helpers/api_helper"
import {
  GET_OrganisationDetail,
  GET_PackageSoItemList,
  POST_CreatePackage,
  POST_PackageSoList,
  //GET_UserCompanyInfo,
} from "helpers/url_helper"
import moment from "moment"
import SwalError from "Custom/SwalError"

const AddPackage = props => {
  const navigate = useNavigate()
  const [mode, setMode] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [salesOrderNumberOption, setSalesOrderNumberOption] = useState([])
  const [saleOrderNumber, setSaleOrderNumber] = useState(null)

  const [showSwalError, setShowSwalError] = useState(false)
  const [swalErrorMsg, setSwalErrorMsg] = useState("")

  const AuthUser = JSON.parse(localStorage.getItem("authUser"))
  const userCompanyInfo = JSON.parse(localStorage.getItem("userCompanyInfo"))

  // const [salesOrderNo, setSalesOrderNo] = useState("")

  // const initialItemDetail = {
  //   QuantitytoPack: 100,
  //   Ordered: 200,
  //   Unit: "Mtr",
  //   Packed: 100,
  //   ItemName: "Package-1",
  // }

  const validationSchema = Yup.object().shape({
    SalesOrderNumber: Yup.string().required(
      "Sales Order Number is a required field."
    ),
    PackageSlip: Yup.string().required("Package Slip is a required field."),
    Date: Yup.string().required("Date is a required field."),
  })

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CustomerName: "",
      SalesOrderNumber: "",
      PackageSlip: "",
      Date: new Date(),
      InternalNotes: "",
      ListOfItemDetail: null,
      organization_id: null,
      ContactID: 0,
      OrgFlag: AuthUser?.org,
    },
    validateOnBlur: false,
  })

  const {
    setValues,
    setFieldValue,
    values: {
      SalesOrderNumber,
      ListOfItemDetail,
      CustomerName,
      organization_id,
      ContactID,
      OrgFlag,
    },
  } = formik

  useEffect(() => {
    // organisationDetail()
    //userCompanyInfoDetail()

    formik.setFieldValue("organization_id", userCompanyInfo?.organization?.id)
    formik.setFieldValue(
      "CustomerName",
      userCompanyInfo?.organization?.display_name
    )

    if (OrgFlag == true) {
      formik.setFieldValue("ContactID", userCompanyInfo?.organization?.id)
    } else {
      formik.setFieldValue("ContactID", userCompanyInfo?.["0"]?.contact_id)
    }
  }, [OrgFlag])

  useEffect(() => {
    getData()
    //packageSoItemList()
  }, [organization_id])

  const handleValidSubmit = values => {
    const NewDate = new Date(values.Date)
    const CurruntTimeDate = new Date()
    const CurruntTime = moment(CurruntTimeDate).format("HH:mm:ss")
    const CurruntDate = moment(NewDate).format(`YYYY-MM-DD ${CurruntTime}`)

    const packageDetails =
      values?.ListOfItemDetail?.map(item => ({
        purchase_order_items_id: item.id,
        quantity_packed: item.QuantitytoPack ? item.QuantitytoPack : 0,
      })) || []

    const Data = {
      so_id: values.SalesOrderNumber,
      // package_no: saleOrderNumber ? saleOrderNumber : "", //saleOrderNumber || "",
      package_no: values.PackageSlip ? values.PackageSlip : "",
      date: CurruntDate,
      internal_notes: values.InternalNotes ? values.InternalNotes : "",
      organization_id: organization_id,
      package_details: packageDetails,
    }
    // console.log("shree", Data)
    setIsLoading(true)
    post(POST_CreatePackage, Data)
      .then(response => {
        if (response && response.status === 200) {
          swal({
            title: "Success",
            text: response.message,
            icon: "success",
            button: "OK",
          })
          setTimeout(() => {
            navigate("/packages")
          }, 1000)
        } else {
          // swal({
          //   title: "Error",
          //   text: response.message + "",
          //   icon: "error",
          //   button: "OK",
          // })
          if (typeof response?.message == "string") {
            swal({
              title: "Error!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  // const handleSaleOrderOptionChange = item => {
  //   formik.setFieldValue(
  //     "SalesOrderNumber",
  //     item && item.label && item.value ? item.value : null
  //   )

  //   setSaleOrderNumber(item && item.label && item.value ? item.label : null)

  //   packageSoItemList(item && item.label && item.value ? item.value : null)
  // }

  const getData = () => {
    const Data = {
      contact_id: ContactID,
      organization_id: organization_id,
    }
    setIsLoading(true)
    //  console.log("id", Data)
    post(POST_PackageSoList, Data)
      .then(res => {
        if (res?.data && res.status === 200) {
          //console.log("getData", res)
          let NewOption = []
          res?.data?.map(item => {
            NewOption.push({
              label: item.sales_order_no,
              value: item.id,
            })
          })
          setSalesOrderNumberOption(NewOption)
        }
        //console.log("Unexpected Status Error", res.status)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const packageSoItemList = soID => {
    if (soID) {
      setIsLoading(true)
      get(GET_PackageSoItemList + "?so_id=" + soID)
        .then(res => {
          //console.log("PackageSoItemList", res)
          if (res?.data?.length > 0 && res.status === 200) {
            const itemDetails = res?.data?.map(item => ({
              id: item.id,
              QuantitytoPack: "",
              Ordered: item.quantity,
              Unit: item.unit.unit_name,
              Packed: item.quantity_packed,
              ItemName: item.product.product_name,
            }))
            formik.setFieldValue("ListOfItemDetail", itemDetails)
          } else {
            formik.setFieldValue("ListOfItemDetail", null)
            setIsLoading(false)
            //console.log("Unexpected Status Error", res.status)
          }
        })
        .catch(error => {
          console.log("Error", error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      formik.setFieldValue("ListOfItemDetail", null)
    }
  }

  // const organisationDetail = async () => {
  //   setIsLoading(true)
  //   try {
  //     await get(GET_OrganisationDetail).then(res => {
  //       if (res?.data) {
  //         formik.setFieldValue("organization_id", res?.data?.id)
  //         formik.setFieldValue(
  //           "CustomerName",
  //           `${res?.data?.primary_contact_salutation || ""} ${
  //             res?.data?.primary_contact_fname || ""
  //           } ${res?.data?.primary_contact_lname || ""}`
  //         )
  //       }
  //     })
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   setIsLoading(false)
  // }

  // const userCompanyInfoDetail = async () => {
  //   setIsLoading(true)
  //   try {
  //     await get(GET_UserCompanyInfo).then(res => {
  //       //console.log("res", res?.data?.["0"]?.contact_id)
  //       if (res?.data) {
  //         formik.setFieldValue("organization_id", res?.data?.organization?.id)
  //         formik.setFieldValue(
  //           "CustomerName",
  //           res?.data?.organization?.display_name
  //         )

  //         if (OrgFlag == true) {
  //           formik.setFieldValue("ContactID", res?.data?.organization?.id)
  //         } else {
  //           formik.setFieldValue("ContactID", res?.data?.["0"]?.contact_id)
  //         }
  //       }
  //     })
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   setIsLoading(false)
  // }

  // const SalesOrderNumberOption = [
  //   { label: "SO-00001", value: 1 },
  //   { label: "SO-00002", value: 2 },
  //   { label: "SO-00003", value: 3 },
  // ]

  useEffect(() => {
    if (SalesOrderNumber) {
      setMode(1)
    } else {
      setMode(0)
    }
  }, [SalesOrderNumber])

  // const AddNewItemDetail = () => {
  //   setFieldValue("ListOfItemDetail", [
  //     ...ListOfItemDetail,
  //     {
  //       QuantitytoPack: 100,
  //       Ordered: 200,
  //       Unit: "Mtr",
  //       Packed: 100,
  //       ItemName: "Package-1",
  //     },
  //   ])
  // }

  // const delete_ItemDetail = index => {
  //   setFieldValue(
  //     "ListOfItemDetail",
  //     ListOfItemDetail.filter((element, i) => i !== index)
  //   )
  // }

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{"Add Package"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            <Row className="mt-3">
              <Col lg={6}>
                <div className="mt-2" style={{ fontSize: "large" }}>
                  <b>Add Package</b>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <FormikProvider value={formik}>
                      <Form onSubmit={formik.handleSubmit}>
                        <Row className="mt-3">
                          <Col lg={2}>
                            <div className="mt-2" style={{ color: "#000000" }}>
                              Customer Name
                            </div>
                          </Col>
                          <Col lg={5}>
                            <Field
                              name="CustomerName"
                              placeholder={"Customer Name"}
                              component={CustomInput}
                              readOnly
                              maxLength={50}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={2}>
                            <div className="mt-2" style={{ color: "#000000" }}>
                              Sales Order Number{" "}
                              <span className="text-danger">*</span>
                            </div>
                          </Col>
                          <Col lg={5}>
                            <Field
                              name="SalesOrderNumber"
                              placeholder={"Select Sales Order Number"}
                              component={CustomSelect}
                              options={salesOrderNumberOption}
                              //onChange={handleSaleOrderOptionChange}
                              onChange={e => {
                                packageSoItemList(e?.value)
                                setSaleOrderNumber(e?.label)
                                formik.setFieldValue(
                                  "SalesOrderNumber",
                                  e?.value
                                )
                              }}
                            />
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col lg={2} style={{ color: "#000000" }}>
                            Package Slip <span className="text-danger">*</span>
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="text"
                              name="PackageSlip"
                              placeholder={"Enter Package Slip"}
                              component={CustomInput}
                              maxLength={50}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2}>
                            <div className="mt-2" style={{ color: "#000000" }}>
                              Date <span className="text-danger">*</span>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <Field
                              name={"Date"}
                              placeholder={"dd/mm/yyyy"}
                              component={CustomFlatpickr}
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "d/m/Y",
                              }}
                            />
                          </Col>
                        </Row>
                        {mode === 1 ? (
                          <>
                            <hr></hr>
                            <Row>
                              <Col lg={12}>
                                <div className="table-responsive">
                                  <table className="table table-bordered mb-0">
                                    <thead>
                                      <tr className="table-light">
                                        <th style={{ minWidth: "250px" }}>
                                          ITEMS & DESCRIPTION
                                        </th>
                                        <th style={{ minWidth: "250px" }}>
                                          ORDERED
                                        </th>
                                        <th style={{ minWidth: "250px" }}>
                                          UNIT
                                        </th>
                                        <th style={{ minWidth: "250px" }}>
                                          PACKED
                                        </th>
                                        <th style={{ minWidth: "250px" }}>
                                          QUANTITY TO PACK
                                        </th>

                                        <th
                                          style={{
                                            borderBlockColor: "#ffffff00",
                                            borderColor: "#ffffff00",
                                            backgroundColor: "#ffffff00",
                                          }}
                                        ></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ListOfItemDetail ? (
                                        ListOfItemDetail &&
                                        ListOfItemDetail.map(
                                          (itemdetail, index) => (
                                            <tr key={index}>
                                              <td>{itemdetail.ItemName}</td>
                                              <td>{itemdetail.Ordered}</td>
                                              <td>{itemdetail.Unit}</td>
                                              <td>{itemdetail.Packed}</td>
                                              <td>
                                                <Field
                                                  type="number"
                                                  name={`ListOfItemDetail.[${index}].QuantitytoPack`}
                                                  placeholder={
                                                    "Quantity to Pack"
                                                  }
                                                  component={CustomInput}
                                                  // min={0}
                                                  // max={itemdetail.Ordered}
                                                />
                                              </td>
                                              {/* <td
                                                style={{
                                                  borderBlockColor: "#ffffff00",
                                                  borderColor: "#ffffff00",
                                                }}
                                              >
                                                {index > 0 ? (
                                                  <i
                                                    class="fas fa-times-circle"
                                                    style={{
                                                      color: "red",
                                                      marginTop: "12px",
                                                    }}
                                                    onClick={() => {
                                                      delete_ItemDetail(index)
                                                    }}
                                                  ></i>
                                                ) : (
                                                  ""
                                                )}
                                              </td> */}
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td colSpan={6}>
                                            No matching records found
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Col>
                            </Row>
                            {/* <Row className="mt-3">
                              <Col lg={12}>
                                <button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={() => {
                                    AddNewItemDetail()
                                  }}
                                >
                                  <i
                                    class="fas fa-plus-circle"
                                    style={{ color: "dodgerblue" }}
                                  ></i>
                                  &nbsp; Add Item Detail
                                </button>
                              </Col>
                            </Row> */}
                          </>
                        ) : (
                          ""
                        )}

                        <hr></hr>
                        <Row>
                          <Col lg={6}>
                            <Field
                              label="Internal Notes"
                              type="textarea"
                              name="InternalNotes"
                              placeholder={"Enter Internal Notes"}
                              component={CustomInput}
                            />
                          </Col>
                        </Row>

                        <hr></hr>
                        <Row>
                          <Col lg={6}>
                            <button className="btn btn-primary" type="submit">
                              Send
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                navigate("/packages")
                              }}
                            >
                              Cancel
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </FormikProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <SwalError
              showError={showSwalError}
              setShowError={setShowSwalError}
              ErrorMsg={swalErrorMsg}
            ></SwalError>
          </Container>
        </div>
      </Spin>
    </React.Fragment>
  )
}
AddPackage.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(AddPackage))
