import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  CardHeader,
  Badge,
  Table,
  Form,
} from "reactstrap"
import { useNavigate, Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CustomTable from "../../Custom/TableWithRemotePagination"
import * as Yup from "yup"
import CustomInput from "Custom/CustomInput"
import { Field, FormikProvider, useFormik } from "formik"
import CustomSelect from "Custom/CustomSelect"
import { Spin } from "antd"

const PackageOrganisation = props => {
  const navigate = useNavigate()
  const [totalSize, setTotalSize] = useState(3)
  const [isDraft, setIsDraft] = useState(false)
  const [hide, setHide] = useState(1)
  const [mode, setMode] = useState(1)

  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState("")

  const columns = [
    {
      text: (
        <>
          <Input
            type="checkbox"
            className="form-check-input"
            id="Add"
            checked={false}
          />
          &nbsp; Date
        </>
      ),
      dataField: "Date",
      style: { minWidth: "150px" },
    },
    {
      text: "Package No.",
      dataField: "PackageNo",
      style: { minWidth: "150px" },
    },
    {
      text: "Carrier",
      dataField: "Carrier",
      style: { minWidth: "150px" },
    },
    {
      text: "Tracking No.",
      dataField: "TrackingNo",
      style: { minWidth: "150px" },
    },
    {
      text: "Sales Order No.",
      dataField: "SalesOrderNo",
      style: { minWidth: "150px" },
    },
    {
      text: "Status",
      dataField: "Status",
      style: { minWidth: "150px" },
    },
    {
      text: "Shipment Date",
      dataField: "ShipmentDate",
      style: { minWidth: "150px" },
    },
    {
      text: "Customer Name",
      dataField: "CustomerName",
      style: { minWidth: "200px" },
    },
    {
      text: "Quantity",
      dataField: "Quantity",
      style: { minWidth: "100px", textAlign: "right" },
    },
  ]

  const rows = [
    {
      Date: (
        <>
          <Input
            type="checkbox"
            className="form-check-input"
            id="Add"
            checked={false}
          />{" "}
          &nbsp; 30/05/2023
        </>
      ),
      PackageNo: (
        <>
          <Link
            onClick={() => {
              setHide(2)
            }}
          >
            1234-1904
          </Link>
        </>
      ),
      Carrier: "asasda",
      TrackingNo: "123123123",
      SalesOrderNo: "SO-00001",
      Status: (
        <>
          <Link to="#">SHIPPED</Link>
        </>
      ),
      ShipmentDate: "05/06/2023",
      CustomerName: "Deep",
      Quantity: "100",
    },
    {
      Date: (
        <>
          <Input
            type="checkbox"
            className="form-check-input"
            id="Add"
            checked={false}
          />{" "}
          &nbsp; 29/05/2023
        </>
      ),
      PackageNo: (
        <>
          <Link
            onClick={() => {
              setHide(2)
            }}
          >
            9568-41
          </Link>
        </>
      ),
      Carrier: "asasda",
      TrackingNo: "123123123",
      SalesOrderNo: "SO-00002",
      Status: (
        <>
          <text style={{ color: "red" }}>NOT SHIPPED</text>
        </>
      ),
      ShipmentDate: "05/06/2023",
      CustomerName: "Deep",
      Quantity: "50",
    },
  ]

  const getData = (page = 1, sizePerPage = 10) => {}

  useEffect(() => {
    getData()
  }, [])

  const validationSchemaFilter = Yup.object().shape({})

  const formikFilter = useFormik({
    validationSchema: validationSchemaFilter,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitFilter(values, formikHelpers),
    initialValues: {
      Status: "",
      // Search: "",
      SearchByColumn: "",
    },
    validateOnBlur: false,
  })

  const {} = formikFilter

  const handleValidSubmitFilter = values => {}

  const packageOptions = [
    { label: "Package No.", value: 1 },
    { label: "Carrier", value: 2 },
    { label: "Tracking No.", value: 3 },
    { label: "Sales Order No.", value: 4 },
    { label: "Customer Name", value: 5 },
  ]

  const statusOption = [
    { label: "ALL", value: 0 },
    { label: "SHIPPED", value: 1 },
    { label: "NOT SHIPPED", value: 2 },
  ]

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{"Packages"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            {hide === 1 ? (
              <>
                <Row className="mt-3">
                  <Col lg={6}>
                    <div className="mt-2" style={{ fontSize: "large" }}>
                      <b>Packages</b>
                    </div>
                  </Col>
                  <Col lg={6} style={{ textAlign: "end" }}>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        navigate("/add-package-organisation")
                      }}
                    >
                      Add Package
                    </button>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="col-12">
                    <FormikProvider value={formikFilter}>
                      <Form onSubmit={formikFilter.handleSubmit}>
                        <Row>
                          <Col lg={12}>
                            <Row>
                              <Col lg={3} sm={6} md={3}>
                                <div class="form-group has-search">
                                  <span class="fa fa-search form-control-feedback"></span>
                                  <Input
                                    type="text"
                                    name="Search"
                                    class="form-control"
                                    placeholder="Search"
                                    value={search}
                                    onChange={e => {
                                      setSearch(e.target.value)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg={2} sm={6} md={2}>
                                <Field
                                  name="SearchByColumn"
                                  placeholder={"By Column"}
                                  component={CustomSelect}
                                  options={packageOptions}
                                />
                              </Col>
                              <Col lg={2} md={2}>
                                <Field
                                  name="Status"
                                  placeholder={"Status"}
                                  component={CustomSelect}
                                  options={statusOption}
                                />{" "}
                              </Col>
                              <Col
                                lg={2}
                                className="mt-1"
                                style={{ textAlign: "left" }}
                              >
                                <div
                                  onClick={() => {
                                    setSearch("")
                                    formikFilter.setFieldValue("Status", "")
                                    // formikFilter.setFieldValue("Search", "")
                                    formikFilter.setFieldValue(
                                      "SearchByColumn",
                                      ""
                                    )
                                  }}
                                >
                                  <i
                                    className="mdi mdi-filter-remove"
                                    style={{
                                      fontSize: "20px",
                                      color: "#4a539f",
                                    }}
                                  ></i>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </FormikProvider>
                    <Card>
                      <CardBody>
                        <CustomTable
                          keyField="Vendor"
                          columns={columns}
                          data={rows}
                          totalSize={totalSize}
                          getData={getData}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col
                    lg={3}
                    md={3}
                    sm={3}
                    style={{
                      height: "100%",
                    }}
                  >
                    <Row style={{ background: "#f0f0f0", marginLeft: "-25px" }}>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{ marginTop: "20px", marginBottom: "6px" }}
                      >
                        <div className="d-flex">
                          <div style={{ width: "400px" }}>Packages</div>
                          <div style={{ textAlign: "right", width: "100%" }}>
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                navigate("/add-package-organisation")
                              }}
                              style={{
                                padding: "0.05rem 0.5rem",
                              }}
                            >
                              + New
                            </button>
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{
                          borderTop: "1px solid #cacaca",
                        }}
                      >
                        <Row style={{ marginBottom: "5px", marginTop: "5px" }}>
                          <div className="col-1 col-lg-1 col-md-1 col-sm-2">
                            {" "}
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="check"
                              checked={false}
                            />
                          </div>
                          <div className="col col-lg-6 col-md-6 col-sm-6">
                            <text>Deep</text>
                            <br></br>
                            <div style={{ fontSize: "10px", marginTop: "6px" }}>
                              <Link to="#">1547-9584</Link> | SO-00001
                            </div>

                            <text style={{ fontSize: "10px" }}>05/06/2023</text>
                          </div>
                          <div
                            className="col col-lg-5 col-md-5 col-sm-4"
                            style={{
                              marginLeft: "-2px",
                              textAlign: "end",
                              padding: "0.05rem 0.5rem",
                            }}
                          >
                            <text>20</text>
                            <br></br>
                            <Link style={{ fontSize: "10px" }}>Shipped</Link>
                            <br></br>
                            <text style={{ fontSize: "10px" }}>asasd</text>
                          </div>
                        </Row>
                        <Row style={{ marginBottom: "5px", marginTop: "5px" }}>
                          <div className="col-1 col-lg-1 col-md-1 col-sm-2">
                            {" "}
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="check"
                              checked={false}
                            />
                          </div>
                          <div className="col col-lg-6 col-md-6 col-sm-6">
                            <text>Deep</text>
                            <br></br>
                            <div style={{ fontSize: "10px", marginTop: "6px" }}>
                              <Link to="#">abc</Link> | SO-00004
                            </div>

                            <text style={{ fontSize: "10px" }}>05/06/2023</text>
                          </div>
                          <div
                            className="col col-lg-5 col-md-5 col-sm-4"
                            style={{
                              marginLeft: "-2px",
                              textAlign: "end",
                              padding: "0.05rem 0.5rem",
                            }}
                          >
                            <text>20</text>
                            <br></br>
                            <Link style={{ fontSize: "10px" }}>
                              Not Shipped
                            </Link>
                            <br></br>
                            <text style={{ fontSize: "10px" }}></text>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    lg={9}
                    md={9}
                    sm={9}
                    style={{ borderLeft: "1px solid #cacaca" }}
                  >
                    <Row>
                      <Col
                        lg={12}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <div style={{ fontSize: "16px", color: "grey" }}>
                          1547-9584
                        </div>
                        <div style={{ textAlignLast: "end" }}>
                          {/* <button
                      className="btn btn-sm  btn-light"
                      style={{ marginTop: "-50px" }}
                      onClick={() => {}}
                    >
                      Edit
                    </button> */}
                          <button
                            type="button"
                            className="btn"
                            style={{
                              marginTop: "-50px",
                              fontSize: "20px",
                              position: "sticky",
                              border: 0,
                            }}
                            onClick={() => setHide(1)}
                          >
                            <span>×</span>
                          </button>
                        </div>
                      </Col>
                      <Col lg={12} style={{ borderTop: "1px solid #cacaca" }}>
                        <Row>
                          <Row className="mt-3">
                            <Col lg={12} style={{ marginLeft: "20px" }}>
                              <Card
                                style={{
                                  borderRadius: "5px",
                                  backgroundColor: "#80808012",
                                }}
                              >
                                <CardBody>
                                  <Row>
                                    <Col lg={1}>
                                      <img
                                        src={
                                          "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png"
                                        }
                                        alt=""
                                        className="avatar-xs rounded-square"
                                        style={{
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </Col>
                                    <Col lg={10}>
                                      <text>Shipment Order#</text>
                                      <br></br>
                                      <Link to="#">123123</Link> &nbsp;
                                      <Badge
                                        color="primary"
                                        className="bg-primary"
                                      >
                                        SHIPPED
                                      </Badge>
                                    </Col>
                                  </Row>
                                  <Row
                                    className="mt-3"
                                    style={{ borderTop: "1px solid #cacaca" }}
                                  >
                                    <Col lg={3} md={3} sm={3} className="mt-3">
                                      Date of Shipment
                                    </Col>
                                    <Col lg={1} md={1} sm={1} className="mt-3">
                                      :
                                    </Col>
                                    <Col lg={8} md={8} sm={8} className="mt-3">
                                      05/06/2023
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={3} md={3} sm={3}>
                                      Carrier
                                    </Col>
                                    <Col lg={1} md={1} sm={1}>
                                      :
                                    </Col>
                                    <Col lg={8} md={8} sm={8}>
                                      asasd
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={3} md={3} sm={3}>
                                      Shipment Rate
                                    </Col>
                                    <Col lg={1} md={1} sm={1}>
                                      :
                                    </Col>
                                    <Col lg={8} md={8} sm={8}>
                                      Rs.123,123.00
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={3} md={3} sm={3}>
                                      Tracking#{" "}
                                    </Col>
                                    <Col lg={1} md={1} sm={1}>
                                      :
                                    </Col>
                                    <Col lg={8} md={8} sm={8}>
                                      123123123
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>

                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            style={{
                              marginTop: "10px",
                            }}
                          >
                            <Row style={{ backgroundColor: "#f8f9fa00" }}>
                              <Col lg={1} md={1} sm={1}></Col>
                              <Col
                                lg={10}
                                md={10}
                                sm={10}
                                style={{ marginLeft: "10px" }}
                              >
                                <Row>
                                  <Col lg={2}>
                                    <Badge
                                      color="primary"
                                      className="bg-primary"
                                      style={{
                                        height: " 23px",
                                        width: "73px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      SHIPPED
                                    </Badge>
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col lg={6} md={6} sm={6}>
                                    Medkart<br></br>
                                    Maharashtra <br></br>
                                    India<br></br>
                                    GSTIN 27AAACE5055K2Z6
                                  </Col>
                                  <Col
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    style={{ textAlign: "right" }}
                                  >
                                    <text
                                      style={{
                                        fontSize: "25px",
                                        color: "black",
                                      }}
                                    >
                                      PACKAGE
                                    </text>
                                    <br></br>Package# 1547-9584
                                  </Col>
                                </Row>
                                <Row
                                  className="mt-4"
                                  style={{
                                    borderTop: "1px solid #cacaca",
                                    borderBottom: "1px solid #cacaca",
                                  }}
                                >
                                  <Col lg={2} className="mt-2 mb-2">
                                    <div>
                                      <b>Package#</b>
                                      <br></br>
                                      1234-512
                                    </div>
                                  </Col>
                                  <Col lg={2} className="mt-2 mb-2">
                                    <div>
                                      <b>Order Date</b>
                                      <br></br>
                                      05/06/2023
                                    </div>
                                  </Col>
                                  <Col lg={3} className="mt-2 mb-2">
                                    <div>
                                      <b>Package Date</b>
                                      <br></br>
                                      05/06/2023
                                    </div>
                                  </Col>
                                  <Col lg={2} className="mt-2 mb-2">
                                    <div>
                                      <b>Sales Order#</b>
                                      <br></br>
                                      SO-00002
                                    </div>
                                  </Col>
                                  <Col
                                    lg={2}
                                    md={2}
                                    sm={2}
                                    className="mt-2 mb-2"
                                  >
                                    <div
                                      style={{
                                        color: "black",
                                        backgroundColor: "#80808012",
                                      }}
                                    >
                                      <center>
                                        <div></div>
                                        <b>Total Qty</b>
                                        <br></br>
                                        20.00
                                      </center>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="mt-4">
                                  <Col lg={12}>
                                    Bill To<br></br>
                                    <Link to="#">Deep</Link>
                                  </Col>
                                </Row>
                                <Row className="mt-4">
                                  <Col lg={12}>
                                    <div className="table-responsive">
                                      <Table className="table mb-0">
                                        <thead
                                          style={{
                                            backgroundColor: "#484e53",
                                            color: "white",
                                          }}
                                        >
                                          <tr>
                                            <th style={{ width: "50px" }}>#</th>
                                            <th>Item Description</th>
                                            <th
                                              style={{
                                                textAlign: "right",
                                                width: "100px",
                                              }}
                                            >
                                              Oty
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <th scope="row">1</th>
                                            <td>ADFC</td>
                                            <td style={{ textAlign: "right" }}>
                                              200
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </div>
      </Spin>
    </React.Fragment>
  )
}
PackageOrganisation.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(PackageOrganisation))
