import React from "react"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import VerticalLayout from "components/VerticalLayout"
import HorizontalLayout from "components/HorizontalLayout"

//constants
import { layoutTypes } from "../../constants/layout"

const Authmiddleware = props => {
  const { layoutType } = useSelector(state => ({
    layoutType: state.Layout.layoutType,
  }))

  const getLayout = layoutType => {
    let Layout = VerticalLayout
    switch (layoutType) {
      case layoutTypes.VERTICAL:
        Layout = VerticalLayout
        break
      case layoutTypes.HORIZONTAL:
        Layout = HorizontalLayout
        break
      default:
        break
    }
    return Layout
  }

  const Layout = getLayout(layoutType)

  if (!localStorage.getItem("authUser")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  } else {
    let permissions = JSON.parse(localStorage.getItem("authUser"))?.permissions

    if (
      props?.path.includes("/roles") ||
      props?.path.includes("/new-role") ||
      props?.path.includes("/edit-role")
    ) {
      //1 = roles (organization)
      if (permissions?.filter(x => x.resource_id == 1)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (props?.path.includes("/user-list")) {
      //2 = users (organization)
      if (permissions?.filter(x => x.resource_id == 2)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (
      props?.path.includes("/vendors") ||
      props?.path.includes("/add-vendor") ||
      props?.path.includes("/edit-vendor")
    ) {
      //3 = vendors (organization)
      if (permissions?.filter(x => x.resource_id == 3)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (
      props?.path.includes("/customers") ||
      props?.path.includes("/add-customer") ||
      props?.path.includes("/edit-customer")
    ) {
      //4 = customers (organization)
      if (permissions?.filter(x => x.resource_id == 4)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (
      props?.path.includes("/purchase-order") ||
      props?.path.includes("/add-purchase-order") ||
      props?.path.includes("/edit-purchase-order")
    ) {
      //5 = purchase-order (organization)
      if (permissions?.filter(x => x.resource_id == 5)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (
      props?.path.includes("/bills-organisation") ||
      props?.path.includes("/add-bill-organisation")
    ) {
      //6 = vendor-bill (organization)
      if (permissions?.filter(x => x.resource_id == 6)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (
      props?.path.includes("/shipments-organisation") ||
      props?.path.includes("/add-shipment-organisation")
    ) {
      //7 = vendor-shipment (organization)
      if (permissions?.filter(x => x.resource_id == 7)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (
      props?.path.includes("/payment-organisation") ||
      props?.path.includes("/add-payment-organisation")
    ) {
      //8 = vendor-payment (organization)
      if (permissions?.filter(x => x.resource_id == 8)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (props?.path.includes("/sales-order")) {
      //9 = sales-order (vendor)
      if (permissions?.filter(x => x.resource_id == 9)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (
      props?.path.includes("/packages") ||
      props?.path.includes("/add-package")
    ) {
      //10 = package (vendor)
      if (permissions?.filter(x => x.resource_id == 10)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (
      props?.path.includes("/shipment") ||
      props?.path.includes("/add-shipment")
    ) {
      //11 = shipment (vendor)
      if (permissions?.filter(x => x.resource_id == 11)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (
      props?.path.includes("/invoices") ||
      props?.path.includes("/add-invoice")
    ) {
      //12 = vendor-invoice (vendor)
      if (permissions?.filter(x => x.resource_id == 12)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (
      props?.path.includes("/documents") ||
      props?.path.includes("/add-documents")
    ) {
      //13 = documents (all)
      if (permissions?.filter(x => x.resource_id == 13)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (
      props?.path.includes("/purchase-order-customer") ||
      props?.path.includes("/add-purchase-order-customer") ||
      props?.path.includes("/add-purchase-order-customer")
    ) {
      //14 = purchase-order (customer)
      if (permissions?.filter(x => x.resource_id == 14)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (props?.path.includes("/bills")) {
      //15 = bill (customer)
      if (permissions?.filter(x => x.resource_id == 15)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (props?.path.includes("/shipment-customer")) {
      //16 = shipment (customer)
      if (permissions?.filter(x => x.resource_id == 16)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (
      props?.path.includes("/payments") ||
      props?.path.includes("/add-payments")
    ) {
      //17 = payment (customer)
      if (permissions?.filter(x => x.resource_id == 17)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (props?.path.includes("/sales-order-organisation")) {
      //18 = sales-order (organization)
      if (permissions?.filter(x => x.resource_id == 18)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (
      props?.path.includes("/packages-organisation") ||
      props?.path.includes("/add-package-organisation")
    ) {
      //19 = package (organization)
      if (permissions?.filter(x => x.resource_id == 19)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (props?.path.includes("/shipment-organisation")) {
      //20 = shipment (organization)
      if (permissions?.filter(x => x.resource_id == 20)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (props?.path.includes("/invoice-customer")) {
      //21 = sales-invoice (organization)
      if (permissions?.filter(x => x.resource_id == 21)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    } else if (props?.path.includes("/company-profile")) {
      //22 = profile (all)
      if (permissions?.filter(x => x.resource_id == 22)?.length == 0) {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    }
  }

  return (
    <React.Fragment>
      <Layout>{props.children}</Layout>
    </React.Fragment>
  )
}

export default Authmiddleware
