import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Input,
  Badge,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Form,
  Modal,
} from "reactstrap"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CustomTable from "../../Custom/TableWithRemotePagination"
import classnames from "classnames"
import * as Yup from "yup"
import CustomInput from "Custom/CustomInput"
import { Field, FormikProvider, useFormik } from "formik"
import CustomSelect from "Custom/CustomSelect"
import { Spin } from "antd"

const ShipmentOrganisation = props => {
  const [totalSize, setTotalSize] = useState(3)
  const [hide, setHide] = useState(1)
  const [mode, setMode] = useState(1)
  const [qualityControlModal, setQualityCantrolModal] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState("")

  const columns = [
    {
      text: (
        <>
          <Input
            type="checkbox"
            className="form-check-input"
            id="Add"
            checked={false}
          />
          &nbsp; Date
        </>
      ),
      dataField: "Date",
      style: { minWidth: "150px" },
    },
    {
      text: "Shipment Order No.",
      dataField: "ShipmentOrderNo",
      style: { minWidth: "180px" },
    },
    {
      text: "Vendor Name",
      dataField: "VendorName",
      style: { minWidth: "200px" },
    },
    {
      text: "Sales Order No.",
      dataField: "SalesOrderNo",
      style: { minWidth: "150px" },
    },
    {
      text: "Package No.",
      dataField: "PackageNo",
      style: { minWidth: "150px" },
    },
    {
      text: "Carrier",
      dataField: "Carrier",
      style: { minWidth: "150px" },
    },
    {
      text: "Tracking No.",
      dataField: "TrackingNo",
      style: { minWidth: "150px" },
    },

    {
      text: "Status",
      dataField: "Status",
      style: { minWidth: "150px" },
    },
    {
      text: "Shipping Rate",
      dataField: "ShippingRate",
      style: { minWidth: "150px", textAlign: "right" },
    },
  ]

  const rows = [
    {
      Date: (
        <>
          <Input
            type="checkbox"
            className="form-check-input"
            id="Add"
            checked={false}
          />{" "}
          &nbsp; 30/06/2023
        </>
      ),
      ShipmentOrderNo: (
        <>
          <Link
            onClick={() => {
              setHide(2)
            }}
          >
            123123
          </Link>
        </>
      ),
      Carrier: "asasda",
      TrackingNo: "123123123",
      SalesOrderNo: "SO-00002",
      PackageNo: "12345-1902",
      Status: (
        <>
          <Link to="#">SHIPPED</Link>
        </>
      ),
      VendorName: "Deep",
      ShippingRate: "Rs.105,856.00",
    },
  ]

  const getData = (page = 1, sizePerPage = 10) => {}

  useEffect(() => {
    getData()
  }, [])

  const validationSchema = Yup.object().shape({})

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      QuantityQC: "",
      QualityQC: "",
    },
    validateOnBlur: false,
  })

  const {
    setValues,
    setFieldValue,
    values: {},
  } = formik

  const handleValidSubmit = values => {}

  const validationSchemaFilter = Yup.object().shape({})

  const formikFilter = useFormik({
    validationSchema: validationSchemaFilter,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitFilter(values, formikHelpers),
    initialValues: {
      Status: "",
      // Search: "",
      SearchByColumn: "",
    },
    validateOnBlur: false,
  })

  const {} = formikFilter

  const handleValidSubmitFilter = values => {}

  const shipmentOptions = [
    { label: "Shipment Order No.", value: 1 },
    { label: "Customer Name", value: 2 },
    { label: "Sales Order No.", value: 3 },
    { label: "Package No.", value: 4 },
    { label: "Carrier", value: 5 },
    { label: "Tracking No.", value: 6 },
  ]

  const statusOption = [
    { label: "ALL", value: 0 },
    { label: "SHIPPED", value: 1 },
    { label: "NOT SHIPPED", value: 2 },
  ]

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{"Shipment Organisation"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            {hide === 1 ? (
              <>
                <Row className="mt-3">
                  <Col lg={6}>
                    <div className="mt-2" style={{ fontSize: "large" }}>
                      <b>Shipment Organisation</b>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="col-12">
                    <FormikProvider value={formikFilter}>
                      <Form onSubmit={formikFilter.handleSubmit}>
                        <Row>
                          <Col lg={12}>
                            <Row>
                              <Col lg={3} sm={6} md={3}>
                                <div class="form-group has-search">
                                  <span class="fa fa-search form-control-feedback"></span>
                                  <Input
                                    type="text"
                                    name="Search"
                                    class="form-control"
                                    placeholder="Search"
                                    value={search}
                                    onChange={e => {
                                      setSearch(e.target.value)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg={2} sm={6} md={2}>
                                <Field
                                  name="SearchByColumn"
                                  placeholder={"By Column"}
                                  component={CustomSelect}
                                  options={shipmentOptions}
                                />
                              </Col>
                              <Col lg={2} md={2}>
                                <Field
                                  name="Status"
                                  placeholder={"Status"}
                                  component={CustomSelect}
                                  options={statusOption}
                                />{" "}
                              </Col>
                              <Col
                                lg={2}
                                className="mt-1"
                                style={{ textAlign: "left" }}
                              >
                                <div
                                  onClick={() => {
                                    setSearch("")
                                    formikFilter.setFieldValue("Status", "")
                                    // formikFilter.setFieldValue("Search", "")
                                    formikFilter.setFieldValue(
                                      "SearchByColumn",
                                      ""
                                    )
                                  }}
                                >
                                  <i
                                    className="mdi mdi-filter-remove"
                                    style={{
                                      fontSize: "20px",
                                      color: "#4a539f",
                                    }}
                                  ></i>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </FormikProvider>
                    <Card>
                      <CardBody>
                        <CustomTable
                          keyField="Vendor"
                          columns={columns}
                          data={rows}
                          totalSize={totalSize}
                          getData={getData}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col
                    lg={3}
                    md={3}
                    sm={3}
                    style={{
                      height: "100%",
                    }}
                  >
                    <Row style={{ background: "#f0f0f0", marginLeft: "-25px" }}>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{ marginTop: "20px", marginBottom: "6px" }}
                      >
                        <div className="d-flex">
                          <div style={{ width: "400px" }}>
                            Shipments Organisation
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{
                          borderTop: "1px solid #cacaca",
                        }}
                      >
                        <Row style={{ marginBottom: "5px", marginTop: "5px" }}>
                          <div className="col-1 col-lg-1 col-md-1 col-sm-2">
                            {" "}
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="check"
                              checked={false}
                            />
                          </div>
                          <div className="col col-lg-6 col-md-6 col-sm-6">
                            <text>Deep</text>
                            <br></br>
                            <div style={{ fontSize: "10px", marginTop: "6px" }}>
                              <Link to="#">1547-9584</Link> | PO-00001
                            </div>

                            <text style={{ fontSize: "10px" }}>05/06/2023</text>
                          </div>
                          <div
                            className="col col-lg-5 col-md-5 col-sm-4"
                            style={{
                              marginLeft: "-2px",
                              textAlign: "end",
                              padding: "0.05rem 0.5rem",
                            }}
                          >
                            <text>asasd</text>
                            <br></br>
                            <Link style={{ fontSize: "10px" }}>Shipped</Link>
                            <br></br>
                            <text style={{ fontSize: "10px" }}>asasd</text>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    lg={9}
                    md={9}
                    sm={9}
                    style={{ borderLeft: "1px solid #cacaca" }}
                  >
                    <Row>
                      <Col
                        lg={12}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <div style={{ fontSize: "16px", color: "grey" }}>
                          1547-9584
                        </div>
                        <div style={{ textAlignLast: "end" }}>
                          <button
                            type="button"
                            className="btn"
                            style={{
                              marginTop: "-50px",
                              fontSize: "20px",
                              position: "sticky",
                              border: 0,
                            }}
                            onClick={() => setHide(1)}
                          >
                            <span>×</span>
                          </button>
                        </div>
                      </Col>
                      <Col lg={12} style={{ borderTop: "1px solid #cacaca" }}>
                        <Row>
                          <Nav tabs className="nav-tabs-custom">
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: mode === 1,
                                })}
                                onClick={() => {
                                  setMode(1)
                                }}
                              >
                                Packages &nbsp;
                                <Badge color="primary" className="rounded-pill">
                                  1
                                </Badge>
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent
                            activeTab={mode}
                            className="p-3 text-muted"
                          >
                            <TabPane tabId={1}>
                              <Col
                                lg={12}
                                md={12}
                                sm={12}
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <div className="table-responsive">
                                      <Table className="table mb-0">
                                        <thead>
                                          <tr>
                                            <th style={{ width: "150px" }}>
                                              Package#
                                            </th>
                                            <th>Date</th>
                                            <th
                                              style={{
                                                width: "200px",
                                              }}
                                            >
                                              Total Quantity
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <th>
                                              <i className="ti-dropbox"></i>
                                              &nbsp;
                                              <Link to="#">1234-3453</Link>
                                            </th>
                                            <td>06/06/2023</td>
                                            <td>
                                              20.00<br></br>
                                              pcs
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="mt-3">
                                  <Col lg={12}>
                                    <Card
                                      style={{
                                        borderRadius: "5px",
                                        backgroundColor: "#80808012",
                                      }}
                                    >
                                      <CardBody>
                                        <Row className="mb-3">
                                          <Col
                                            lg={12}
                                            style={{ fontSize: "16px" }}
                                          >
                                            <i class="fas fa-shipping-fast"></i>{" "}
                                            &nbsp; Shipment Details
                                          </Col>
                                        </Row>
                                        <Row
                                          style={{
                                            borderTop: "1px solid #cacaca",
                                          }}
                                        >
                                          <Col lg={6}>
                                            <Row>
                                              <Col
                                                lg={5}
                                                md={5}
                                                sm={5}
                                                className="mt-3"
                                              >
                                                Date of Shipment
                                              </Col>
                                              <Col
                                                lg={1}
                                                md={1}
                                                sm={1}
                                                className="mt-3"
                                              >
                                                :
                                              </Col>
                                              <Col
                                                lg={6}
                                                md={6}
                                                sm={6}
                                                className="mt-3"
                                              >
                                                05/06/2023
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg={5} md={5} sm={5}>
                                                Carrier
                                              </Col>
                                              <Col lg={1} md={1} sm={1}>
                                                :
                                              </Col>
                                              <Col lg={6} md={6} sm={6}>
                                                asasd
                                              </Col>
                                            </Row>

                                            <Row>
                                              <Col lg={5} md={5} sm={5}>
                                                Tracking#{" "}
                                              </Col>
                                              <Col lg={1} md={1} sm={1}>
                                                :
                                              </Col>
                                              <Col lg={6} md={6} sm={6}>
                                                123123123
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg={5} md={5} sm={5}>
                                                Tracking Status
                                              </Col>
                                              <Col lg={1} md={1} sm={1}>
                                                :
                                              </Col>
                                              <Col lg={6} md={6} sm={6}>
                                                Shipped
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg={5} md={5} sm={5}>
                                                Shipment Rate
                                              </Col>
                                              <Col lg={1} md={1} sm={1}>
                                                :
                                              </Col>
                                              <Col lg={6} md={6} sm={6}>
                                                Rs.123,123.00
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col
                                            lg={6}
                                            className="mt-3"
                                            style={{
                                              borderLeft: "1px solid #cacaca",
                                            }}
                                          >
                                            Shipment Notes<br></br>
                                            {/* <Link to="#">Add Notes</Link> */}
                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                                <Row style={{ backgroundColor: "#f8f9fa00" }}>
                                  <Col lg={1} md={1} sm={1}></Col>
                                  <Col
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <Row>
                                      <Col lg={2}>
                                        <Badge
                                          color="primary"
                                          className="bg-primary"
                                          style={{
                                            height: " 23px",
                                            width: "73px",
                                            fontSize: "15px",
                                          }}
                                        >
                                          SHIPPED
                                        </Badge>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3">
                                      <Col lg={6} md={6} sm={6}>
                                        Medkart<br></br>
                                        Maharashtra <br></br>
                                        India<br></br>
                                        GSTIN 27AAACE5055K2Z6
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        style={{ textAlign: "right" }}
                                      >
                                        <text
                                          style={{
                                            fontSize: "25px",
                                            color: "black",
                                          }}
                                        >
                                          SHIPMENT ORDER
                                        </text>
                                        <br></br>Shipment Order# 1547-9584
                                      </Col>
                                    </Row>

                                    <Row className="mt-4">
                                      <Col lg={6}>
                                        Ship To<br></br>
                                        <Link to="#">Deep</Link>
                                      </Col>
                                      <Col lg={6}>
                                        <Row>
                                          <Col
                                            lg={6}
                                            style={{ textAlign: "right" }}
                                          >
                                            Purchase order# :
                                          </Col>
                                          <Col
                                            lg={6}
                                            style={{ textAlign: "right" }}
                                          >
                                            PO-00002
                                          </Col>
                                          <Col
                                            lg={6}
                                            style={{ textAlign: "right" }}
                                          >
                                            Order Date :
                                          </Col>
                                          <Col
                                            lg={6}
                                            style={{ textAlign: "right" }}
                                          >
                                            03/06/2023
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                    <Row className="mt-4">
                                      <Col lg={12}>
                                        <div className="table-responsive">
                                          <Table className="table mb-0">
                                            <thead
                                              style={{
                                                backgroundColor: "#484e53",
                                                color: "white",
                                              }}
                                            >
                                              <tr>
                                                <th style={{ width: "50px" }}>
                                                  #
                                                </th>
                                                <th>Item & Description</th>
                                                <th
                                                  style={{
                                                    textAlign: "right",
                                                    width: "100px",
                                                  }}
                                                >
                                                  Qty
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <th scope="row">1</th>
                                                <td>ADFC</td>
                                                <td
                                                  style={{ textAlign: "right" }}
                                                >
                                                  20.00<br></br>
                                                  pcs
                                                </td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3">
                                      <Col
                                        lg={12}
                                        style={{ textAlign: "right" }}
                                      >
                                        <Button
                                          color="primary"
                                          className="bg-primary"
                                          onClick={() => {
                                            setQualityCantrolModal(true)
                                          }}
                                        >
                                          Mark as Received
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </TabPane>
                          </TabContent>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </div>
        <Modal isOpen={qualityControlModal} centered={true} size="lg">
          <div className="modal-header mdl_header">
            <h5 className="modal-title mt-0">{"Quality Cantrol"}</h5>
            <button
              type="button"
              className="btn close mt-2"
              onClick={() => setQualityCantrolModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <thead>
                          <tr className="table-light">
                            <th style={{ width: "50px" }}>#</th>
                            <th>Item & Description</th>
                            <th
                              style={{
                                textAlign: "right",
                                width: "50px",
                              }}
                            >
                              Qty
                            </th>
                            <th style={{ width: "150px" }}>Quantity QC</th>
                            <th style={{ width: "150px" }}>Quality QC</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td>ADFC</td>
                            <td style={{ textAlign: "right" }}>
                              20.00<br></br>
                              pcs
                            </td>
                            <td>
                              <Field
                                type="number"
                                name="QuantityQC"
                                placeholder={"Quantity"}
                                component={CustomInput}
                                max={0}
                              />
                            </td>
                            <td>
                              <Field
                                type="number"
                                name="QualityQC"
                                placeholder={"Quality"}
                                component={CustomInput}
                                max={0}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={6}>
                    <button className="btn btn-primary" type="submit">
                      Save
                    </button>
                    &nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setQualityCantrolModal(false)
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </FormikProvider>
          </div>
        </Modal>
      </Spin>
    </React.Fragment>
  )
}
ShipmentOrganisation.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ShipmentOrganisation))
