import React from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody, Container, Form } from "reactstrap"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import * as Yup from "yup"
import CustomInput from "Custom/CustomInput"
import { Field, FormikProvider, useFormik } from "formik"
import CustomSelect from "Custom/CustomSelect"
import CustomFlatpickr from "Custom/CustomFlatpicker"
import { useState } from "react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Spin } from "antd"

const AddPackageOrganisation = props => {
  const navigate = useNavigate()
  const [mode, setMode] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState("")

  const initialItemDetail = {
    QuantitytoPack: 100,
    Ordered: 200,
    Unit: "Mtr",
    Packed: 100,
    ItemName: "Package-1",
  }
  const validationSchema = Yup.object().shape({
    SalesOrderNumber: Yup.string().required(
      "Sales Order Number is a required field."
    ),
    PackageSlip: Yup.string().required("Package Slip is a required field."),
    Date: Yup.string().required("Date is a required field."),
  })

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      CustomerName: "",
      SalesOrderNumber: "",
      PackageSlip: "",
      Date: new Date(),
      InternalNotes: "",
      ListOfItemDetail: [initialItemDetail],
    },
    validateOnBlur: false,
  })

  const {
    setValues,
    setFieldValue,
    values: { SalesOrderNumber, ListOfItemDetail },
  } = formik

  const handleValidSubmit = values => {}

  const SalesOrderNumberOption = [
    { label: "SO-00001", value: 1 },
    { label: "SO-00002", value: 2 },
    { label: "SO-00003", value: 3 },
  ]

  const CustomerNameOption = [
    { label: "Deep", value: 1 },
    { label: "Nilesh Soni", value: 2 },
    { label: "Hitesh Vaghela", value: 3 },
  ]

  useEffect(() => {
    if (SalesOrderNumber) {
      setMode(1)
    } else {
      setMode(0)
    }
  }, [SalesOrderNumber])

  const AddNewItemDetail = () => {
    setFieldValue("ListOfItemDetail", [
      ...ListOfItemDetail,
      {
        QuantitytoPack: 100,
        Ordered: 200,
        Unit: "Mtr",
        Packed: 100,
        ItemName: "Package-1",
      },
    ])
  }

  const delete_ItemDetail = index => {
    setFieldValue(
      "ListOfItemDetail",
      ListOfItemDetail.filter((element, i) => i !== index)
    )
  }
  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
      <div className="page-content">
        <MetaTags>
          <title>{"Add Package"} | Katlax OMS</title>
        </MetaTags>

        <Container fluid>
          <Row className="mt-3">
            <Col lg={6}>
              <div className="mt-2" style={{ fontSize: "large" }}>
                <b>Add Package</b>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row className="mt-3">
                        <Col lg={2}>
                          <div className="mt-2" style={{ color: "#000000" }}>
                            Customer Name
                          </div>
                        </Col>
                        <Col lg={5}>
                          <Field
                            name="CustomerName"
                            placeholder={"Customer Name"}
                            component={CustomSelect}
                            options={CustomerNameOption}
                            maxLength={50}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={2}>
                          <div className="mt-2" style={{ color: "#000000" }}>
                            Sales Order Number{" "}
                            <span className="text-danger">*</span>
                          </div>
                        </Col>
                        <Col lg={5}>
                          <Field
                            name="SalesOrderNumber"
                            placeholder={"Select Sales Order Number"}
                            component={CustomSelect}
                            options={SalesOrderNumberOption}
                          />
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col lg={2} style={{ color: "#000000" }}>
                          Package Slip <span className="text-danger">*</span>
                        </Col>
                        <Col lg={4}>
                          <Field
                            type="text"
                            name="PackageSlip"
                            placeholder={"Enter Package Slip"}
                            component={CustomInput}
                            maxLength={50}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={2}>
                          <div className="mt-2" style={{ color: "#000000" }}>
                            Date <span className="text-danger">*</span>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <Field
                            name={"Date"}
                            placeholder={"dd/mm/yyyy"}
                            component={CustomFlatpickr}
                            options={{
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "d/m/Y",
                            }}
                          />
                        </Col>
                      </Row>
                      {mode === 1 ? (
                        <>
                          <hr></hr>
                          <Row>
                            <Col lg={12}>
                              <div className="table-responsive">
                                <table className="table table-bordered mb-0">
                                  <thead>
                                    <tr className="table-light">
                                      <th style={{ minWidth: "250px" }}>
                                        ITEMS & DESCRIPTION
                                      </th>
                                      <th style={{ minWidth: "250px" }}>
                                        ORDERED
                                      </th>
                                      <th style={{ minWidth: "250px" }}>
                                        UNIT
                                      </th>
                                      <th style={{ minWidth: "250px" }}>
                                        PACKED
                                      </th>
                                      <th style={{ minWidth: "250px" }}>
                                        OUANTITY TO PACK
                                      </th>

                                      <th
                                        style={{
                                          borderBlockColor: "#ffffff00",
                                          borderColor: "#ffffff00",
                                          backgroundColor: "#ffffff00",
                                        }}
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {ListOfItemDetail &&
                                      ListOfItemDetail.map(
                                        (itemdetail, index) => (
                                          <tr>
                                            <td>{itemdetail.ItemName}</td>
                                            <td>{itemdetail.Ordered}</td>
                                            <td>{itemdetail.Unit}</td>
                                            <td>{itemdetail.Packed}</td>
                                            <td>
                                              <Field
                                                type="number"
                                                name={`ListOfItemDetail.[${index}].QuantitytoPack`}
                                                placeholder={"Quantity to Pack"}
                                                component={CustomInput}
                                                min={0}
                                                max={itemdetail.Ordered}
                                              />
                                            </td>
                                            <td
                                              style={{
                                                borderBlockColor: "#ffffff00",
                                                borderColor: "#ffffff00",
                                              }}
                                            >
                                              {index > 0 ? (
                                                <i
                                                  class="fas fa-times-circle"
                                                  style={{
                                                    color: "red",
                                                    marginTop: "12px",
                                                  }}
                                                  onClick={() => {
                                                    delete_ItemDetail(index)
                                                  }}
                                                ></i>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </Col>
                          </Row>{" "}
                          <Row className="mt-3">
                            <Col lg={12}>
                              <button
                                className="btn btn-secondary"
                                type="button"
                                onClick={() => {
                                  AddNewItemDetail()
                                }}
                              >
                                <i
                                  class="fas fa-plus-circle"
                                  style={{ color: "dodgerblue" }}
                                ></i>
                                &nbsp; Add Item Detail
                              </button>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}

                      <hr></hr>
                      <Row>
                        <Col lg={6}>
                          <Field
                            label="Internal Notes"
                            type="textarea"
                            name="InternalNotes"
                            placeholder={"Enter Internal Notes"}
                            component={CustomInput}
                          />
                        </Col>
                      </Row>

                      <hr></hr>
                      <Row>
                        <Col lg={6}>
                          <button className="btn btn-primary" type="submit">
                            Send
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                              navigate("/packages-organisation")
                            }}
                          >
                            Cancel
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </FormikProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div></Spin>
    </React.Fragment>
  )
}
AddPackageOrganisation.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(AddPackageOrganisation))
