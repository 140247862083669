import React, { Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CustomInput from "Custom/CustomInput"
import { Field, FieldArray, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import CustomSelect from "Custom/CustomSelect"
import CustomPhoneInput from "Custom/CustomPhoneInput"
import classnames from "classnames"
import InputMask from "react-input-mask"
import MaterialInput from "@material-ui/core/Input"
//import { reactSelectCustomStyles } from "../../Custom/reactSelectCustomStyles"
import { Spin } from "antd"
import { get, post, postFormData } from "helpers/api_helper"
import { t } from "i18next"
import {
  GET_UserSalutation,
  GET_ContactDetail,
  GET_GSTTreatment,
  GET_PaymentTerms,
  GET_Countries,
  GET_Cities,
  GET_State,
  POST_AddContact,
  GET_Currency,
  GET_Pincode,
  POST_UploadDocumnet,
  GET_TdsTypes,
} from "helpers/url_helper"
import SwalError from "Custom/SwalError"
//import { error } from "toastr"

const AddVendor = props => {
  const navigate = useNavigate()
  const { IdParam } = useParams()

  const AuthUser = JSON.parse(localStorage.getItem("authUser"))

  //const [sourceofSupplyerror, setSourceofSupplyError] = useState("")
  //const [isError, setIsError] = useState(false)
  //const [gSTTreatmenterror, setGSTTreatmentError] = useState("")

  //const [selectedFiles, setselectedFiles] = useState([])
  const [country, setCountry] = useState("")
  const [isAccountNumber, setIsAccountNumber] = useState(true)
  const [mode, setMode] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [userSalutationOption, setUserSalutationOption] = useState([])
  const [GSTTreatmentOption, setGSTTreatmentOption] = useState([])
  const [paymentTermsOption, setPaymentTermsOption] = useState([])
  const [billingAddressStateOption, setBillingAddressStateOption] = useState([])
  const [shippingAddresStateOption, setShippingAddressStateOption] = useState(
    []
  )
  const [billingAddressCountryOption, setBillingAddressCountryOption] =
    useState([])
  const [shippingAddressCountryOption, setShippingAddressCountryOption] =
    useState([])

  const [billingAddressZipCodeOption, setBillingAddressZipCodeOption] =
    useState([])
  const [shippingAddressZipCodeOption, setShippingAddressZipCodeOption] =
    useState([])
  const [billingAddressCityOption, setBillingAddressCityOption] = useState([])
  const [shippingAddressCityOption, setShippingAddressCityOption] = useState([])
  const [currencyOption, setCurrencyOption] = useState([])
  const [tdsTypeOptions, setTDSTypeOptions] = useState([])

  const [showSwalError, setShowSwalError] = useState(false)
  const [swalErrorMsg, setSwalErrorMsg] = useState("")

  const initListOfBankAccount = {
    BeneficiaryName: "",
    BankName: "",
    AccountNumber: "",
    ReEnterAccountNumber: "",
    IFSC: "",
    validateReEnterAccountNumber: "",
    CancelledCheque: "",
    UploadedFileName: "",
    BankDetailsID: 0,
  }
  const initListOfContactPerson = {
    ContactPersonSalutation: "",
    ContactPersonFirstName: "",
    ContactPersonLastName: "",
    ContactPersonEmail: "",
    ContactPersonWorkPhone: "",
    ContactPersonMobile: "",
    ContactPersonWorkPhoneCountryCode: "+91",
    ContactPersonMobileCountryCode: "+91",
    ContactPersonID: "",
  }

  const validationSchema = Yup.object().shape({
    DisplayName: Yup.string().required(
      "Vendor Display Name is a required field."
    ),
    Mobile: Yup.string().required("Mobile No. is a required field."),
    WorkPhone: Yup.string().required("Work Phone No. is a required field."),
    BillingAddressMobile: Yup.string().required(
      "Billing Address Mobile No  is a required field."
    ),
    ShippingAddressMobile: Yup.string().required(
      "Shipping Address Mobile No  is a required field."
    ),
    SourceofSupply: Yup.string().required(
      "Source of Supply is a required field."
    ),
    GSTTreatment: Yup.string().required("GST Treatment is a required field."),
  })

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      VendorID: "",
      Salutation: "",
      FirstName: "",
      Lastname: "",
      CompanyName: "",
      DisplayName: "",
      VendorEmail: "",
      Mobile: "",
      WorkPhone: "",
      Skype: "",
      Designation: "",
      Department: "",
      Website: "",
      Remark: "",
      CountryCode: "+91",
      WorkPhoneCountryCode: "+91",
      ListOfBankAccount: [initListOfBankAccount],
      ListOfContactPerson: [initListOfContactPerson],
      BillingAddressID: 0,
      ShippingAddressID: 0,
      BillingAddressAttention: "",
      BillingAddressCountry: "51",
      BillingAddressAddressLine1: "",
      BillingAddressAddressLine2: "",
      BillingAddressAddressLine3: "",
      BillingAddressCity: "",
      BillingAddressState: "",
      BillingAddressZipCode: "",
      BillingAddressMobile: "",
      BillingAddressMobileCountryCode: "+91",
      BillingAddressFax: "",
      ShippingAddressAttention: "",
      ShippingAddressCountry: "",
      ShippingAddressAddressLine1: "",
      ShippingAddressAddressLine2: "",
      ShippingAddressAddressLine3: "",
      ShippingAddressCity: "",
      ShippingAddressState: "",
      ShippingAddressZipCode: "",
      ShippingAddressMobile: "",
      ShippingAddressMobileCountryCode: "+91",
      ShippingAddressFax: "",
      GSTTreatment: "",
      GSTN: "",
      PAN: "",
      SourceofSupply: "",
      Currency: "",
      PaymentTerm: "",
      TDS: "",
      TIN: "",
      ServiceTaxNo: "",
      TaxNo: "",
      MSMENo: "",
      OpeningBalance: "",
      CreditLimit: "",
      SkypeNameNumber: "",
      Facebook: "",
      Twitter: "",
      DisplayNameOption: [],
      UdhyogAadhar: "",
      TAN: "",
      Country: "",
      State: "",
    },
    validateOnBlur: false,
  })

  const {
    setFieldValue,
    setValues,
    values: {
      FirstName,
      Lastname,
      CompanyName,
      GSTN,
      CountryCode,
      WorkPhoneCountryCode,
      DisplayNameOption,
      ListOfBankAccount,
      ListOfContactPerson,
      BillingAddressAttention,
      BillingAddressCountry,
      BillingAddressAddressLine1,
      BillingAddressAddressLine2,
      BillingAddressAddressLine3,
      BillingAddressCity,
      BillingAddressState,
      BillingAddressZipCode,
      BillingAddressMobile,
      BillingAddressFax,
      BillingAddressMobileCountryCode,
      ShippingAddressMobileCountryCode,
      Facebook,
      Twitter,
      ShippingAddressState,
      ShippingAddressCountry,
      ShippingAddressCity,
      ShippingAddressMobile,
      GSTTreatment,
      SourceofSupply,
      BillingAddressID,
      ShippingAddressID,
    },
  } = formik

  const handleValidSubmit = values => {
    setIsLoading(true)
    if (
      ListOfBankAccount.filter(
        x =>
          x.AccountNumber == "" || x.ReEnterAccountNumber == "" || x.IFSC == ""
      ).length > 0
    ) {
      // console.log("Fill the details of Bank Account.")
      swal({
        title: "Warning",
        text: "Fill the details of Bank Account.",
        icon: "warning",
        button: "OK",
      })
      return
    }

    const Data = {
      contact_type: "vendor",
      company_name: values.CompanyName,
      primary_contact_salutation: values.Salutation,
      primary_contact_fname: values.FirstName,
      primary_contact_lname: values.Lastname,
      display_name: values.DisplayName,
      payment_terms_id: values.PaymentTerm,
      email: values.VendorEmail,
      // phone: values.WorkPhone
      //   ? IdParam
      //     ? values.WorkPhone
      //     : "+" + WorkPhoneCountryCode?.replace(/\+/g, "") + values.WorkPhone
      //   : "", //values.WorkPhone,
      // mobile: values.Mobile
      //   ? IdParam
      //     ? values.Mobile
      //     : "+" + CountryCode?.replace(/\+/g, "") + values.Mobile
      //   : "", //values.Mobile,

      mobile: values.Mobile
        ? "+" + CountryCode.replace(/\+/g, "") + " " + values.Mobile
        : "",
      phone: values.WorkPhone
        ? "+" + WorkPhoneCountryCode.replace(/\+/g, "") + " " + values.WorkPhone
        : "",
      designation: values.Designation,
      department: values.Department,
      website: values.Website,
      gst_treatment: values.GSTTreatment,
      gstn: values.GSTN,
      pan_no: values.PAN,
      tin_no: values.TIN,
      servicetax_number: values.ServiceTaxNo,
      tax_no: values.TaxNo,
      tds_id: values.TDS,
      msme_no: values.MSMENo,
      //tan_no:values.TAN,
      // udhyog_aadhar:values.UdhyogAadhar,
      supply_state_code: values.SourceofSupply, //458,
      opening_debit: 0,
      opening_credit: values.OpeningBalance ? values.OpeningBalance : 0,
      credit_limit: values.CreditLimit ? values.CreditLimit : 0,
      remarks: values.Remark,
      skype: values.SkypeNameNumber,
      facebook: values.Facebook,
      twitter: values.Twitter,
      currency_id: values.Currency,
      id: IdParam ? IdParam : 0,
      // updated_by: 0,

      addresses: [
        {
          type: "billing",
          attention: BillingAddressAttention,
          address_line_1: BillingAddressAddressLine1,
          address_line_2: BillingAddressAddressLine2,
          address_line_3: BillingAddressAddressLine3,
          city_id: BillingAddressCity,
          state_id: BillingAddressState,
          country_id: BillingAddressCountry,
          zip_code: BillingAddressZipCode,
          fax: BillingAddressFax,
          phone: BillingAddressMobile
            ? "+" +
              BillingAddressMobileCountryCode.replace(/\+/g, "") +
              " " +
              BillingAddressMobile
            : "",

          // phone: BillingAddressMobile
          //   ? BillingAddressID
          //     ? BillingAddressMobile
          //     : "+" +
          //       BillingAddressMobileCountryCode?.replace(/\+/g, "") +
          //       BillingAddressMobile
          //   : "", //BillingAddressMobile,
          nickname_address: "",
          id: BillingAddressID,
        },
        {
          type: "shipping",
          attention: values.ShippingAddressAttention,
          address_line_1: values.ShippingAddressAddressLine1,
          address_line_2: values.ShippingAddressAddressLine2,
          address_line_3: values.ShippingAddressAddressLine3,
          city_id: values.ShippingAddressCity,
          state_id: ShippingAddressState,
          country_id: ShippingAddressCountry,
          zip_code: values.ShippingAddressZipCode,
          fax: values.ShippingAddressFax,
          // phone: ShippingAddressMobile
          //   ? ShippingAddressID
          //     ? ShippingAddressMobile
          //     : "+" +
          //       ShippingAddressMobileCountryCode?.replace(/\+/g, "") +
          //       ShippingAddressMobile
          //   : "", //values.ShippingAddressMobile,
          phone: ShippingAddressMobile
            ? "+" +
              ShippingAddressMobileCountryCode.replace(/\+/g, "") +
              " " +
              ShippingAddressMobile
            : "",
          nickname_address: "",
          id: ShippingAddressID,
        },
      ],
      contact_persons: ListOfContactPerson.map(x => {
        return {
          salutation: x.ContactPersonSalutation,
          first_name: x.ContactPersonFirstName,
          last_name: x.ContactPersonLastName,
          email: x.ContactPersonEmail,
          // phone: x.ContactPersonWorkPhone
          //   ? x.ContactPersonID
          //     ? x.ContactPersonWorkPhone
          //     : "+" +
          //       x.ContactPersonWorkPhoneCountryCode?.replace(/\+/g, "") +
          //       x.ContactPersonWorkPhone
          //   : "", //x.ContactPersonWorkPhone,
          phone: x.ContactPersonWorkPhone
            ? "+" +
              x.ContactPersonWorkPhoneCountryCode.replace(/\+/g, "") +
              " " +
              x.ContactPersonWorkPhone
            : "",
          // mobile: x.ContactPersonMobile
          //   ? x.ContactPersonID
          //     ? x.ContactPersonMobile
          //     : "+" +
          //       x.ContactPersonMobileCountryCode?.replace(/\+/g, "") +
          //       x.ContactPersonMobile
          //   : "", //x.ContactPersonMobile,

          mobile: x.ContactPersonMobile
            ? "+" +
              x.ContactPersonMobileCountryCode.replace(/\+/g, "") +
              " " +
              x.ContactPersonMobile
            : "",
          id: x.ContactPersonID,
        }
      }),

      bank_details: ListOfBankAccount.map((x, i) => {
        return {
          bank_name: x.BankName,
          branch_name: x.BeneficiaryName,
          account_number: x.AccountNumber,
          ifsc: x.IFSC,
          micr_code: x.AccountNumber,
          swift_code: x.AccountNumber,
          bank_address: "",
          account_name: x.BeneficiaryName,
          account_code: "",
          file_manager_id: x.CancelledCheque,

          is_primary: i === 0 ? 1 : 0,
          id: x.BankDetailsID,
        }
      }),
    }

    if (!IdParam) {
      delete Data["id"]
    }

    //console.log("Add", Data)
    //return

    post(POST_AddContact, Data)
      .then(response => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: response.message,
            icon: "success",
            button: "OK",
          })
          navigate("/vendors")
        } else {
          // swal({
          //   title: "Error",
          //   text: response?.message,
          //   icon: "error",
          //   button: "OK",
          // })
          if (typeof response?.message == "string") {
            swal({
              title: "Error!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
          setIsLoading(false)
        }
      })
      .catch(error => {
        error.response.data
        setIsLoading(false)
      })
    //.catch(error => error.response.data)
  }

  useEffect(() => {
    getSalutation()
    getGSTTreatment()
    getPaymentTerms()
    getCountry()
    getCurrency()
    getTDSTypeList()
    if (IdParam) {
      get(GET_ContactDetail + `${IdParam}`).then(res => {
        if (res.status === 200) {
          setValues({
            Salutation: res.data.contact.primary_contact_salutation,
            FirstName: res.data.contact.primary_contact_fname,
            Lastname: res.data.contact.primary_contact_lname,
            CompanyName: res.data.contact.company_name,
            DisplayName: res.data.contact.display_name,
            VendorEmail: res.data.contact.email,
            //Mobile: res.data.contact.mobile,
            // WorkPhone: res.data.contact.phone,
            WorkPhone: res.data.contact.phone
              ? res.data.contact.phone
                  .replace(res.data.contact.phone.split(" ")[0], "")
                  .trim()
              : res.data.contact.phone,
            //Mobile: res.data.mobile,
            Mobile: res.data.contact.mobile
              ? res.data.contact.mobile
                  .replace(res.data.contact.mobile.split(" ")[0], "")
                  .trim()
              : res.data.contact.mobile,
            // Skype: res.data.contact.skype,
            Designation: res.data.contact.designation,
            Department: res.data.contact.department,

            Website: res.data.contact.website,
            Remark: res.data.contact.remarks,
            CountryCode: res.data.contact.mobile
              ? res.data.contact.mobile.split(" ")[0]
              : "+91",
            WorkPhoneCountryCode: res.data.contact.phone
              ? res.data.contact.phone.split(" ")[0]
              : "+91",

            ListOfBankAccount:
              res.data.contact.bank_details?.length > 0
                ? res.data.contact.bank_details.map(x => {
                    return {
                      BeneficiaryName: x.account_name,
                      BankName: x.bank_name,
                      AccountNumber: x.account_number,
                      ReEnterAccountNumber: x.account_number,
                      IFSC: x.ifsc,
                      validateReEnterAccountNumber: x.account_number
                        ? ""
                        : "Re-Enter Account Number does not match Account Number.",
                      CancelledCheque: x.file_manager_id,
                      UploadedFileName: x.file_manager_id
                        ? x.file_manger.file_url
                        : "",
                      BankDetailsID: x.id,
                    }
                  })
                : [initListOfBankAccount],
            ListOfContactPerson:
              res.data.contact.contact_persons?.length > 0
                ? res.data.contact.contact_persons.map(x => {
                    return {
                      ContactPersonSalutation: x.salutation,
                      ContactPersonFirstName: x.first_name,
                      ContactPersonLastName: x.last_name,
                      ContactPersonEmail: x.email,

                      //ContactPersonWorkPhone: x.phone,
                      ContactPersonWorkPhone: x.phone
                        ? x.phone.replace(x.phone.split(" ")[0], "").trim()
                        : x.phone,
                      // ContactPersonMobile: x.mobile,
                      ContactPersonMobile: x.mobile
                        ? x.mobile.replace(x.mobile.split(" ")[0], "").trim()
                        : x.mobile,
                      //ContactPersonWorkPhoneCountryCode: "+91",
                      ContactPersonWorkPhoneCountryCode: x.phone
                        ? x.phone.split(" ")[0]
                        : "+91",
                      //ContactPersonMobileCountryCode: "+91",
                      ContactPersonMobileCountryCode: x.mobile
                        ? x.mobile.split(" ")[0]
                        : "+91",
                      ContactPersonID: x.pivot.user_id,
                    }
                  })
                : [initListOfContactPerson],
            GSTTreatment: res.data.contact.gst_treatment,
            GSTN: res.data.contact.gstn,
            PAN: res.data.contact.pan_no,
            SourceofSupply: res.data.contact.supply_state_code,
            Currency: res.data.contact.currency_id,
            PaymentTerm: res.data.contact.payment_terms_id,
            TDS: res.data.contact.tds_id,
            TIN: res.data.contact.tin_no,
            ServiceTaxNo: res.data.contact.servicetax_number,
            TaxNo: res.data.contact.tax_no,
            MSMENo: res.data.contact.msme_no,
            OpeningBalance: res.data.contact.opening_credit, // res.data.contact.opening_debit,
            CreditLimit: res.data.contact.credit_limit, //res.data.contact.opening_credit,
            SkypeNameNumber: res.data.contact.skype,
            Facebook: res.data.contact.facebook,
            Twitter: res.data.contact.twitter,
            DisplayNameOption: [],
            UdhyogAadhar: "",
            TAN: "",
          })

          res.data.contact.addresses
            .filter(x => x.type === "billing")
            ?.map(x => {
              setFieldValue("BillingAddressAttention", x.attention)
              setFieldValue("BillingAddressCountry", x.country_id)
              setFieldValue("BillingAddressAddressLine1", x.address_line_1)
              setFieldValue("BillingAddressAddressLine2", x.address_line_2)
              setFieldValue("BillingAddressAddressLine3", x.address_line_3)
              setFieldValue("BillingAddressCity", x.city_id)
              setFieldValue("BillingAddressState", x.state_id)
              setFieldValue("BillingAddressZipCode", x.zip_code)
              // setFieldValue("BillingAddressMobile", x.phone)
              setFieldValue(
                "BillingAddressMobile",
                x.phone
                  ? x.phone.replace(x.phone.split(" ")[0], "").trim()
                  : x.phone
              )

              setFieldValue("BillingAddressFax", x.fax)
              setFieldValue("BillingAddressID", x.pivot.address_id)
              //setFieldValue("BillingAddressMobileCountryCode", "+91")
              setFieldValue(
                "BillingAddressMobileCountryCode",
                x.phone ? x.phone.split(" ")[0] : "+91"
              )
            })

          res.data.contact.addresses
            .filter(x => x.type === "shipping")
            ?.map(x => {
              setFieldValue("ShippingAddressAttention", x.attention)
              setFieldValue("ShippingAddressCountry", x.country_id)
              setFieldValue("ShippingAddressAddressLine1", x.address_line_1)
              setFieldValue("ShippingAddressAddressLine2", x.address_line_2)
              setFieldValue("ShippingAddressAddressLine3", x.address_line_3)
              setFieldValue("ShippingAddressCity", x.city_id)
              setFieldValue("ShippingAddressState", x.state_id)
              setFieldValue("ShippingAddressZipCode", x.zip_code)
              //setFieldValue("ShippingAddressMobile", x.phone)
              setFieldValue(
                "ShippingAddressMobile",
                x.phone
                  ? x.phone.replace(x.phone.split(" ")[0], "").trim()
                  : x.phone
              )
              setFieldValue("ShippingAddressFax", x.fax)
              setFieldValue("ShippingAddressID", x.pivot.address_id)
              //setFieldValue("ShippingAddressMobileCountryCode", "+91")
              setFieldValue(
                "ShippingAddressMobileCountryCode",
                x.phone ? x.phone.split(" ")[0] : "+91"
              )
            })
        }
      })
    }
  }, [])

  useEffect(() => {
    setFieldValue(
      "DisplayNameOption",
      FirstName != "" && Lastname != "" && CompanyName != ""
        ? [
            {
              label: FirstName + " " + Lastname,
              value: FirstName + " " + Lastname,
            },
            {
              label: Lastname + " " + FirstName,
              value: Lastname + " " + FirstName,
            },
            { label: CompanyName, value: CompanyName },
          ]
        : FirstName === "" && Lastname != "" && CompanyName != ""
        ? [
            {
              label: Lastname + " " + FirstName,
              value: Lastname + " " + FirstName,
            },
            { label: CompanyName, value: CompanyName },
          ]
        : Lastname === "" && FirstName != "" && CompanyName != ""
        ? [
            {
              label: FirstName + " " + Lastname,
              value: FirstName + " " + Lastname,
            },
            { label: CompanyName, value: CompanyName },
          ]
        : FirstName != "" && Lastname != "" && CompanyName === ""
        ? [
            {
              label: FirstName + " " + Lastname,
              value: FirstName + " " + Lastname,
            },
            {
              label: Lastname + " " + FirstName,
              value: Lastname + " " + FirstName,
            },
          ]
        : FirstName === "" && Lastname != "" && CompanyName === ""
        ? [
            {
              label: Lastname + " " + FirstName,
              value: Lastname + " " + FirstName,
            },
          ]
        : Lastname === "" && FirstName != "" && CompanyName === ""
        ? [
            {
              label: FirstName + " " + Lastname,
              value: FirstName + " " + Lastname,
            },
          ]
        : FirstName === "" && Lastname === "" && CompanyName != ""
        ? [{ label: CompanyName, value: CompanyName }]
        : []
    )
  }, [FirstName, Lastname, CompanyName])

  const AddContactPerson = () => {
    setFieldValue("ListOfContactPerson", [
      ...ListOfContactPerson,
      {
        ContactPersonSalutation: "",
        ContactPersonFirstName: "",
        ContactPersonLastName: "",
        ContactPersonEmail: "",
        ContactPersonWorkPhone: "",
        ContactPersonMobile: "",
        ContactPersonWorkPhoneCountryCode: "+91",
        ContactPersonMobileCountryCode: "+91",
        ContactPersonID: "",
      },
    ])
  }

  const getSalutation = () => {
    setIsLoading(true)
    get(GET_UserSalutation).then(res => {
      if (res) {
        let NewOptions = []
        res.data.salutation?.map(item =>
          NewOptions.push({
            label: item,
            value: item,
          })
        )
        setUserSalutationOption(NewOptions)
      }
    })
    setIsLoading(false)
  }

  const getGSTTreatment = () => {
    setIsLoading(true)
    get(GET_GSTTreatment).then(res => {
      if (res) {
        const valuesArray = res.data.GST_treatment
        var NewArrayOption = Object.entries(valuesArray)

        let NewOption = []
        NewArrayOption.map(x =>
          NewOption.push({
            label: x[1],
            value: x[0],
          })
        )

        setGSTTreatmentOption(NewOption)
      }
    })
    setIsLoading(false)
  }

  const getPaymentTerms = () => {
    setIsLoading(true)
    get(GET_PaymentTerms).then(res => {
      if (res) {
        let NewOptions = []
        res.data?.map(item =>
          NewOptions.push({
            label: item.label,
            value: item.id,
          })
        )
        setPaymentTermsOption(NewOptions)
      }
    })
    setIsLoading(false)
  }

  const getCurrency = () => {
    setIsLoading(true)
    get(GET_Currency).then(res => {
      if (res) {
        let NewOptions = []
        res.data?.map((item, index) =>
          NewOptions.push({
            label:
              item.currency + " " + "(" + item.symbol + " - " + item.code + ")",
            value: item.id,
          })
        )
        setCurrencyOption(NewOptions)
      }
    })
    setIsLoading(false)
  }

  const getCountry = () => {
    setIsLoading(true)
    get(GET_Countries).then(res => {
      if (res) {
        let NewOptions = []
        res.data?.map(item =>
          NewOptions.push({
            label: item.name,
            value: item.id,
          })
        )
        setBillingAddressCountryOption(NewOptions)
        setShippingAddressCountryOption(NewOptions)
      }
    })
    setIsLoading(false)
  }
  useEffect(() => {
    if (ShippingAddressCountry) {
      getShippingAddressState(
        ShippingAddressCountry ? ShippingAddressCountry : 0
      )
    } else {
      setShippingAddressCityOption([])
      setShippingAddressStateOption([])
    }
  }, [ShippingAddressCountry])

  useEffect(() => {
    if (BillingAddressCountry) {
      getBillingAddressState(BillingAddressCountry ? BillingAddressCountry : 0)
    } else {
      setBillingAddressCityOption([])
      setBillingAddressStateOption([])
    }
  }, [BillingAddressCountry])

  const getShippingAddressState = CountryID => {
    setIsLoading(true)
    get(GET_State + "?country_id=" + CountryID).then(res => {
      if (res) {
        let NewOptions = []
        res.data?.map(item =>
          NewOptions.push({
            label: item.state_name,
            value: item.id,
          })
        )
        setShippingAddressStateOption(NewOptions)
      }
    })
    setIsLoading(false)
  }

  const getBillingAddressState = CountryID => {
    setIsLoading(true)
    get(GET_State + "?country_id=" + CountryID).then(res => {
      if (res) {
        let NewOptions = []
        res.data?.map(item =>
          NewOptions.push({
            label: item.state_name,
            value: item.id,
          })
        )
        setBillingAddressStateOption(NewOptions)
      }
    })
    setIsLoading(false)
  }

  useEffect(() => {
    getShippingAddressCity(ShippingAddressState ? ShippingAddressState : 0)
  }, [ShippingAddressState])

  useEffect(() => {
    getBillingAddressCity(BillingAddressState ? BillingAddressState : 0)
  }, [BillingAddressState])

  const getShippingAddressCity = StateID => {
    setIsLoading(true)
    get(GET_Cities + "?state_id=" + StateID).then(res => {
      if (res) {
        let NewOptions = []
        res.data?.map(item =>
          NewOptions.push({
            label: item.city_name,
            value: item.id,
          })
        )
        setShippingAddressCityOption(NewOptions)
      }
    })
    setIsLoading(false)
  }

  const getBillingAddressCity = StateID => {
    setIsLoading(true)
    get(GET_Cities + "?state_id=" + StateID).then(res => {
      if (res) {
        let NewOptions = []
        res.data?.map(item =>
          NewOptions.push({
            label: item.city_name,
            value: item.id,
          })
        )
        setBillingAddressCityOption(NewOptions)
      }
    })
    setIsLoading(false)
  }

  useEffect(() => {
    getShippingAddressZipCode(ShippingAddressCity ? ShippingAddressCity : 0)
  }, [ShippingAddressCity])

  useEffect(() => {
    getBillingAddressZipCode(BillingAddressCity ? BillingAddressCity : 0)
  }, [BillingAddressCity])

  const getShippingAddressZipCode = CityID => {
    setIsLoading(true)
    get(GET_Pincode + "?city_id=" + CityID).then(res => {
      if (res) {
        let NewOptions = []
        res.data?.map(item =>
          NewOptions.push({
            label: item.city_name,
            value: item.id,
          })
        )
        setShippingAddressZipCodeOption(NewOptions)
      }
    })
    setIsLoading(false)
  }

  const getBillingAddressZipCode = CityID => {
    setIsLoading(true)
    get(GET_Pincode + "?city_id=" + CityID).then(res => {
      if (res) {
        let NewOptions = []
        res.data?.map(item =>
          NewOptions.push({
            label: item.city_name,
            value: item.id,
          })
        )
        setBillingAddressZipCodeOption(NewOptions)
      }
    })
    setIsLoading(false)
  }

  const getTDSTypeList = () => {
    setIsLoading(true)
    get(GET_TdsTypes)
      .then(res => {
        if (res) {
          if (res?.status == 200 && res?.data?.length > 0) {
            let NewOptions = []
            res?.data?.map(item =>
              NewOptions.push({
                label: item.title,
                value: item.id,
              })
            )
            setTDSTypeOptions(NewOptions)
          } else {
            setTDSTypeOptions([])
          }
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  // const TDSOption = [
  //   { label: "10%", value: 1 },
  //   { label: "20%", value: 2 },
  //   { label: "30%", value: 3 },
  // ]
  const delete_ContactPerson = index => {
    setFieldValue(
      "ListOfContactPerson",
      ListOfContactPerson.filter((element, i) => i !== index)
    )
  }

  const handleOtherDetailsBtn = async () => {
    var flag = true
    // alert(formik.values.GSTTreatment)

    if (SourceofSupply.length == 0) {
      swal({
        title: "Warning",
        text: "Select Source of Supply.",
        icon: "warning",
        button: "OK",
      })
      //formik.validateForm().SourceofSupply
      //formik.validationSchema = formik.validateField.SourceofSupply
      flag = false
    }

    if (GSTTreatment.length == 0) {
      swal({
        title: "Warning",
        text: "Select GST Treatment.",
        icon: "warning",
        button: "OK",
      })
      // if ((await formik.validateForm()).GSTTreatment) {
      //   //return
      // }
      // formik.validateForm().GSTTreatment
      //formik.validationSchema = formik.validateField.GSTTreatment
      //formik.validateForm().GSTTreatment(error)
      //formik.validationSchema

      flag = false
    }

    if (flag == true) {
      setMode(2)
    }
  }

  const handleAddressDetailsBtn = async () => {
    var flag = true

    if (BillingAddressMobile.length == 0) {
      swal({
        title: "Warning",
        text: "Fill the Billing Address Phone No.",
        icon: "warning",
        button: "OK",
      })
      flag = false
    }

    if (ShippingAddressMobile.length == 0) {
      swal({
        title: "Warning",
        text: "Fill the Shipping Address Phone No.",
        icon: "warning",
        button: "OK",
      })

      flag = false
    }

    if (flag == true) {
      setMode(3)
    }
  }

  const handleBankDetailsBtn = async () => {
    var flag = true

    if (
      ListOfBankAccount.filter(
        x =>
          x.AccountNumber == "" || x.ReEnterAccountNumber == "" || x.IFSC == ""
      ).length > 0
    ) {
      // console.log("Fill the details of Bank Account.")
      swal({
        title: "Warning",
        text: "Fill the details of Bank Account.",
        icon: "warning",
        button: "OK",
      })
      flag = false
    }

    if (flag == true) {
      setMode(5)
    }
  }

  const uploadCancelledChequeFile = (e, index) => {
    //(e)
    if (e.target.files[0]) {
      const file = e.target.files[0]
      var pattern = /(\.|\/)(jpe?g|png|webp|svg|gif|ico)$/
      // alert(file.size)

      // if (file.size > 1024) {
      //   swal("", "Please upload a file smaller than 1 MB", "warning")
      //   return
      // }

      if (!file.type.match(pattern)) {
        swal("", "Only image files are allowed!", "warning")
      } else {
        setIsLoading(true)
        //alert(file)
        let formData = new FormData()

        formData.append("module_slug", "org-contacts-vendors")
        formData.append("label", "Cancelled Cheque")
        formData.append("file", file)

        // file: file,
        // label: "Cancelled Cheque",
        // module_slug: "org-contacts-vendors",

        postFormData(POST_UploadDocumnet, formData)
          .then(response => {
            if (response.status === 200) {
              setFieldValue(
                "ListOfBankAccount.[" + index + "].CancelledCheque",
                ""
              )
              setFieldValue(
                "ListOfBankAccount.[" + index + "].CancelledCheque",
                response.data.id
              )
              setIsLoading(false)
              // alert(response.data.id)
              // swal("Success", "File Uploaded", "success")
            } else {
              // swal("Error!", t(response.data.file), "error")
              if (typeof response?.message == "string") {
                swal({
                  title: "Error!",
                  text: response.message,
                  icon: "error",
                  button: "OK",
                })
              } else {
                setSwalErrorMsg(response?.message)
                setShowSwalError(true)
              }
              setIsLoading(false)
            }
          })
          .catch(error => {
            console.log(error)
            setIsLoading(false)
          })
      }
    }
  }

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{IdParam ? "Edit Vendor" : "Add Vendor"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            <Row className="mt-3">
              <Col lg={6}>
                <div className="mt-2" style={{ fontSize: "large" }}>
                  <b>{IdParam ? "Edit Vendor" : "Add Vendor"}</b>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Card>
                  <CardBody>
                    <FormikProvider value={formik}>
                      <Form onSubmit={formik.handleSubmit}>
                        <Row>
                          <Col lg={2}>
                            <div className="mt-2" style={{ color: "#000000" }}>
                              Primary Contact
                            </div>
                          </Col>
                          <Col lg={2}>
                            <Field
                              name="Salutation"
                              placeholder={"Salutation"}
                              component={CustomSelect}
                              options={userSalutationOption}
                              maxLength={50}
                            />
                          </Col>
                          <Col lg={2}>
                            <Field
                              type="text"
                              name="FirstName"
                              placeholder={"First Name"}
                              component={CustomInput}
                            />
                          </Col>
                          <Col lg={2}>
                            <Field
                              type="text"
                              name="Lastname"
                              placeholder={"Last Name"}
                              component={CustomInput}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2}>
                            <div className="mt-2" style={{ color: "#000000" }}>
                              Company Name
                            </div>
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="text"
                              name="CompanyName"
                              placeholder={"Enter Company Name"}
                              component={CustomInput}
                              maxLength={50}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2}>
                            <div className="mt-2" style={{ color: "#000000" }}>
                              Vendor Display Name{" "}
                              <span className="text-danger">*</span>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <Field
                              name="DisplayName"
                              placeholder={"Select Display Name"}
                              component={CustomSelect}
                              options={DisplayNameOption}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            lg={2}
                            style={{ color: "#000000" }}
                            className="mt-2"
                          >
                            Vendor Email
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="email"
                              name="VendorEmail"
                              icon={true}
                              placeholder={"Enter Vendor Email"}
                              component={CustomInput}
                              maxLength={50}
                              iconClass={"mdi mdi-email"}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2} className="mt-3">
                            <div className="mt-4" style={{ color: "#000000" }}>
                              Vendor Phone{" "}
                              <span className="text-danger">*</span>
                            </div>
                          </Col>

                          <Col lg={3} sm={3} md={3}>
                            <Field
                              name="Mobile"
                              label="Mobile No"
                              placeholder={"Enter Mobile No"}
                              country={country}
                              countryCode={CountryCode}
                              component={CustomPhoneInput}
                              onChange={(value, { countryCode, dialCode }) => {
                                setFieldValue("CountryCode", dialCode)
                                setFieldValue(
                                  "Mobile",
                                  value.substring(dialCode.toString().length)
                                )
                              }}
                            />
                          </Col>
                          <Col lg={3} sm={3} md={3}>
                            <Field
                              label="Work Phone"
                              name="WorkPhone"
                              placeholder={"Enter Work Phone"}
                              country={country}
                              countryCode={WorkPhoneCountryCode}
                              component={CustomPhoneInput}
                              onChange={(value, { countryCode, dialCode }) => {
                                setFieldValue("WorkPhoneCountryCode", dialCode)
                                setFieldValue(
                                  "WorkPhone",
                                  value.substring(dialCode.toString().length)
                                )
                              }}
                            />
                          </Col>
                        </Row>

                        {/* <Row>
                        <Col lg={2} style={{ color: "#000000" }}>
                          Skype Name/Number
                        </Col>
                        <Col lg={4}>
                          <Field
                            type="text"
                            name="Skype"
                            placeholder={"Enter Skype Name/Number"}
                            component={CustomInput}
                            maxLength={50}
                            icon={true}
                            iconClass={"mdi mdi-skype"}
                          />
                        </Col>
                      </Row> */}
                        <Row>
                          <Col
                            lg={2}
                            style={{ color: "#000000" }}
                            className="mt-2"
                          >
                            Designation
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="text"
                              name="Designation"
                              placeholder={"Enter Designation"}
                              component={CustomInput}
                              maxLength={50}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            lg={2}
                            style={{ color: "#000000" }}
                            className="mt-2"
                          >
                            Department
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="text"
                              name="Department"
                              placeholder={"Enter Department"}
                              component={CustomInput}
                              maxLength={50}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            lg={2}
                            style={{ color: "#000000" }}
                            className="mt-2"
                          >
                            Website
                          </Col>
                          <Col lg={4}>
                            <Field
                              type="text"
                              name="Website"
                              placeholder={"Enter Website"}
                              component={CustomInput}
                              maxLength={50}
                            />
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col lg={12} sm={12} md={12}>
                            <Nav tabs className="nav-tabs-custom">
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: mode === 1,
                                  })}
                                  onClick={() => {
                                    setMode(1)
                                  }}
                                >
                                  Other Details
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  disabled={IdParam ? false : true}
                                  className={classnames({
                                    active: mode === 2,
                                  })}
                                  onClick={() => {
                                    setMode(2)
                                  }}
                                >
                                  Address
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  disabled={IdParam ? false : true}
                                  className={classnames({
                                    active: mode === 3,
                                  })}
                                  onClick={() => {
                                    setMode(3)
                                  }}
                                >
                                  Contact Person
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  disabled={IdParam ? false : true}
                                  className={classnames({
                                    active: mode === 4,
                                  })}
                                  onClick={() => {
                                    setMode(4)
                                  }}
                                >
                                  Bank Details
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  disabled={IdParam ? false : true}
                                  className={classnames({
                                    active: mode === 5,
                                  })}
                                  onClick={() => {
                                    setMode(5)
                                  }}
                                >
                                  Remarks
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent
                              activeTab={mode}
                              className="p-3 text-muted"
                            >
                              <TabPane tabId={1}>
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      GST Treatment &nbsp;
                                      <span className="text-danger">*</span>
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      name="GSTTreatment"
                                      placeholder={"GST Treatment"}
                                      component={CustomSelect}
                                      options={GSTTreatmentOption}
                                      maxLength={50}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      GSTN
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <InputMask
                                      mask="99aaaaa9999a9a*"
                                      value={GSTN}
                                      name="GSTN"
                                      className="form-control input-color"
                                      style={{
                                        height: "35px",
                                        marginBottom: "18px",
                                        color: "#495057",
                                        font: "revert",
                                      }}
                                      placeholder="Enter GST Number"
                                      onChange={event => {
                                        setFieldValue(
                                          "GSTN",
                                          event.target.value?.toUpperCase()
                                        )
                                        setFieldValue(
                                          "PAN",
                                          event.target.value
                                            ?.toUpperCase()
                                            .slice(2, 12)
                                        )
                                      }}
                                    >
                                      {inputProps => (
                                        <MaterialInput
                                          {...inputProps}
                                          type="tel"
                                          disableUnderline
                                        />
                                      )}
                                    </InputMask>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      PAN
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      type="text"
                                      name="PAN"
                                      placeholder={"Enter PAN"}
                                      component={CustomInput}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      Source of Supply{" "}
                                      <span className="text-danger">*</span>
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      name="SourceofSupply"
                                      placeholder={"Select Source of Supply"}
                                      component={CustomSelect}
                                      options={billingAddressStateOption}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      Currency
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      name="Currency"
                                      placeholder={"Select Currency"}
                                      component={CustomSelect}
                                      options={currencyOption}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      Payment Term
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      name="PaymentTerm"
                                      placeholder={"Select Payment Term"}
                                      component={CustomSelect}
                                      options={paymentTermsOption}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      TDS
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      name="TDS"
                                      placeholder={"Select TDS"}
                                      component={CustomSelect}
                                      options={tdsTypeOptions}
                                    />
                                  </Col>
                                </Row>
                                {/* <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      Udhyog Aadhar
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      type="text"
                                      name="UdhyogAadhar"
                                      placeholder={"Enter Udhyog Aadhar"}
                                      component={CustomInput}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      TAN No.
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      type="text"
                                      name="TAN"
                                      placeholder={"Enter TAN No."}
                                      component={CustomInput}
                                    />
                                  </Col>
                                </Row> */}
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      TIN No.
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      type="text"
                                      name="TIN"
                                      placeholder={"Enter TIN No."}
                                      component={CustomInput}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      Service Tax No.
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      type="text"
                                      name="ServiceTaxNo"
                                      placeholder={"Enter Service Tax No"}
                                      component={CustomInput}
                                    />
                                  </Col>
                                </Row>{" "}
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      Tax No.
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      type="text"
                                      name="TaxNo"
                                      placeholder={"Enter Tax No"}
                                      component={CustomInput}
                                    />
                                  </Col>
                                </Row>{" "}
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      MSME No.
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      type="text"
                                      name="MSMENo"
                                      placeholder={"Enter MSME No"}
                                      component={CustomInput}
                                    />
                                  </Col>
                                </Row>{" "}
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      Opening Balance
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      type="number"
                                      name="OpeningBalance"
                                      placeholder={"Enter Opening Balance"}
                                      component={CustomInput}
                                      onChange={e => {
                                        setFieldValue(
                                          "OpeningBalance",
                                          e.target.value.slice(0, 7)
                                        )
                                      }}
                                    />
                                  </Col>
                                </Row>{" "}
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      Credit Limit
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      type="text"
                                      name="CreditLimit"
                                      placeholder={"Enter Credit Limit"}
                                      component={CustomInput}
                                    />
                                  </Col>
                                </Row>{" "}
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      Skype Name/Number
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      type="text"
                                      name="SkypeNameNumber"
                                      placeholder={"Enter Skype Name/Number"}
                                      component={CustomInput}
                                      icon={true}
                                      iconClass={"mdi mdi-skype"}
                                    />
                                  </Col>
                                </Row>{" "}
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      Facebook
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      type="text"
                                      name="Facebook"
                                      placeholder={"Enter Facebook"}
                                      component={CustomInput}
                                      icon={true}
                                      iconClass={"mdi mdi-facebook"}
                                    />
                                  </Col>
                                </Row>
                                <Row
                                  style={{
                                    marginTop: "-15px",
                                    marginBottom: "8px",
                                  }}
                                >
                                  <Col lg={2}></Col>
                                  <Col lg={4}>
                                    <Link
                                      to={`https://www.facebook.com/${Facebook}`}
                                      target="_blank"
                                      style={{
                                        color: "skyblue",
                                      }}
                                    >
                                      {`https://www.facebook.com/${Facebook}`}
                                    </Link>
                                  </Col>
                                </Row>{" "}
                                <Row>
                                  <Col lg={2}>
                                    <div
                                      className="mt-2"
                                      style={{ color: "#000000" }}
                                    >
                                      Twitter
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Field
                                      type="text"
                                      name="Twitter"
                                      placeholder={"Enter Twitter"}
                                      component={CustomInput}
                                      // style={
                                      //   {
                                      //      marginBottom: "-16px",
                                      //      marginTop: "10px",
                                      //   }
                                      // }
                                      icon={true}
                                      iconClass={"mdi mdi-twitter"}
                                    />
                                  </Col>
                                </Row>
                                <Row
                                  style={{
                                    marginTop: "-15px",
                                    marginBottom: "8px",
                                  }}
                                >
                                  <Col lg={2}></Col>
                                  <Col lg={4}>
                                    <Link
                                      to={`https://twitter.com/${Twitter}`}
                                      target="_blank"
                                      style={{
                                        color: "skyblue",
                                      }}
                                    >
                                      {`https://twitter.com/${Twitter}`}
                                    </Link>
                                  </Col>
                                </Row>
                                <hr></hr>
                                <Row className="mt-3">
                                  <Col lg={6}>
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={handleOtherDetailsBtn}
                                      // onClick={() => {
                                      //   setMode(2)
                                      // }}
                                    >
                                      Next
                                    </button>
                                    &nbsp;&nbsp;
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      onClick={() => {
                                        navigate("/vendors")
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId={2}>
                                <Row>
                                  <Col lg={6}>
                                    <Row className="mb-2">
                                      <Col>
                                        <div
                                          style={{
                                            fontSize: "17px",
                                            color: "black",
                                          }}
                                        >
                                          <b>Billing Address</b>
                                        </div>
                                      </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Attention
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          name="BillingAddressAttention"
                                          placeholder={"Enter Attention"}
                                          component={CustomInput}
                                          maxLength={50}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Country/Religion
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          name="BillingAddressCountry"
                                          placeholder={"Select County/religion"}
                                          component={CustomSelect}
                                          options={billingAddressCountryOption}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Address Line 1
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          text="textarea"
                                          name="BillingAddressAddressLine1"
                                          placeholder={"Enter  Address Line 1"}
                                          component={CustomInput}
                                          maxLength={50}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Address Line 2
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          text="textarea"
                                          name="BillingAddressAddressLine2"
                                          placeholder={"Enter  Address Line 2"}
                                          component={CustomInput}
                                          maxLength={50}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Address Line 3
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          text="textarea"
                                          name="BillingAddressAddressLine3"
                                          placeholder={"Enter  Address Line 3"}
                                          component={CustomInput}
                                          maxLength={50}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          State
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          name="BillingAddressState"
                                          placeholder={"Select State"}
                                          component={CustomSelect}
                                          options={billingAddressStateOption}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          City
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          name="BillingAddressCity"
                                          placeholder={"Select City"}
                                          component={CustomSelect}
                                          options={billingAddressCityOption}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Zip Code
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          name="BillingAddressZipCode"
                                          placeholder={"Enter Zip Code"}
                                          component={CustomInput}
                                          maxLength={10}
                                        />
                                        {/* <Field
                                          name="BillingAddressZipCode"
                                          placeholder={"Enter Zip Code"}
                                          component={CustomSelect}
                                          options={billingAddressZipCodeOption}
                                        /> */}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Phone
                                        </div>
                                      </Col>

                                      <Col lg={8}>
                                        <Field
                                          name="BillingAddressMobile"
                                          placeholder={"Enter Phone No"}
                                          country={country}
                                          countryCode={
                                            BillingAddressMobileCountryCode
                                          }
                                          component={CustomPhoneInput}
                                          onChange={(
                                            value,
                                            { countryCode, dialCode }
                                          ) => {
                                            setFieldValue(
                                              "BillingAddressMobileCountryCode",
                                              dialCode
                                            )
                                            setFieldValue(
                                              "BillingAddressMobile",
                                              value.substring(
                                                dialCode.toString().length
                                              )
                                            )
                                          }}
                                          required
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Fax
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          name="BillingAddressFax"
                                          placeholder={"Enter Fax"}
                                          component={CustomInput}
                                          maxLength={50}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={6}>
                                    <Row className="mb-2">
                                      <Col lg={6}>
                                        <div
                                          style={{
                                            fontSize: "17px",
                                            color: "black",
                                          }}
                                        >
                                          <b>Shipping Address</b>
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div
                                          style={{
                                            textAlign: "right",
                                            color: "blue",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setFieldValue(
                                              "ShippingAddressAttention",
                                              BillingAddressAttention
                                            )
                                            setFieldValue(
                                              "ShippingAddressCountry",
                                              BillingAddressCountry
                                            )
                                            setFieldValue(
                                              "ShippingAddressAddressLine1",
                                              BillingAddressAddressLine1
                                            )
                                            setFieldValue(
                                              "ShippingAddressAddressLine2",
                                              BillingAddressAddressLine2
                                            )
                                            setFieldValue(
                                              "ShippingAddressAddressLine3",
                                              BillingAddressAddressLine3
                                            )
                                            setFieldValue(
                                              "ShippingAddressCity",
                                              BillingAddressCity
                                            )
                                            setFieldValue(
                                              "ShippingAddressState",
                                              BillingAddressState
                                            )
                                            setFieldValue(
                                              "ShippingAddressZipCode",
                                              BillingAddressZipCode
                                            )
                                            setFieldValue(
                                              "ShippingAddressMobile",
                                              BillingAddressMobile
                                            )
                                            setFieldValue(
                                              "ShippingAddressFax",
                                              BillingAddressFax
                                            )
                                          }}
                                        >
                                          <i class="fas fa-arrow-down"></i>{" "}
                                          &nbsp; Copy Billing Address
                                        </div>
                                      </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Attention
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          name="ShippingAddressAttention"
                                          placeholder={"Enter Attention"}
                                          component={CustomInput}
                                          maxLength={50}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Country/Religion
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          name="ShippingAddressCountry"
                                          placeholder={"Select County/religion"}
                                          component={CustomSelect}
                                          options={shippingAddressCountryOption}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Address Line 1
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          text="textarea"
                                          name="ShippingAddressAddressLine1"
                                          placeholder={"Enter  Address Line 1"}
                                          component={CustomInput}
                                          maxLength={50}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Address Line 2
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          text="textarea"
                                          name="ShippingAddressAddressLine2"
                                          placeholder={"Enter  Address Line 2"}
                                          component={CustomInput}
                                          maxLength={50}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Address Line 3
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          text="textarea"
                                          name="ShippingAddressAddressLine3"
                                          placeholder={"Enter  Address Line 3"}
                                          component={CustomInput}
                                          maxLength={50}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          State
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          name="ShippingAddressState"
                                          placeholder={"Select State"}
                                          component={CustomSelect}
                                          options={shippingAddresStateOption}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          City
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          name="ShippingAddressCity"
                                          placeholder={"Select City"}
                                          component={CustomSelect}
                                          options={shippingAddressCityOption}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Zip Code
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          name="ShippingAddressZipCode"
                                          placeholder={"Enter Zip Code"}
                                          component={CustomInput}
                                          maxLength={10}
                                        />
                                        {/* <Field
                                          name="ShippingAddressZipCode"
                                          placeholder={"Enter Zip Code"}
                                          component={CustomSelect}
                                          options={shippingAddressZipCodeOption}
                                        /> */}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Phone
                                        </div>
                                      </Col>

                                      <Col lg={8}>
                                        <Field
                                          name="ShippingAddressMobile"
                                          placeholder={"Enter Phone No"}
                                          country={country}
                                          countryCode={
                                            ShippingAddressMobileCountryCode
                                          }
                                          component={CustomPhoneInput}
                                          onChange={(
                                            value,
                                            { countryCode, dialCode }
                                          ) => {
                                            setFieldValue(
                                              "ShippingAddressMobileCountryCode",
                                              dialCode
                                            )
                                            setFieldValue(
                                              "ShippingAddressMobile",
                                              value.substring(
                                                dialCode.toString().length
                                              )
                                            )
                                          }}
                                          required
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div
                                          className="mt-2"
                                          style={{ color: "#000000" }}
                                        >
                                          Fax
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        <Field
                                          name="ShippingAddressFax"
                                          placeholder={"Enter Fax"}
                                          component={CustomInput}
                                          maxLength={50}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <hr></hr>
                                <Row className="mt-3">
                                  <Col lg={6}>
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={() => {
                                        setMode(1)
                                      }}
                                    >
                                      Previous
                                    </button>
                                    &nbsp;&nbsp;
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={handleAddressDetailsBtn}
                                      // onClick={() => {
                                      //   setMode(3)
                                      // }}
                                    >
                                      Next
                                    </button>
                                    &nbsp;&nbsp;
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      onClick={() => {
                                        navigate("/vendors")
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId={3}>
                                <div className="table-responsive abc">
                                  <table className="table table-bordered mb-0">
                                    <thead>
                                      <tr className="table-light">
                                        <th style={{ minWidth: "250px" }}>
                                          Salutation
                                        </th>
                                        <th style={{ minWidth: "250px" }}>
                                          First Name
                                        </th>
                                        <th style={{ minWidth: "250px" }}>
                                          Last Name
                                        </th>
                                        <th style={{ minWidth: "250px" }}>
                                          Email Address
                                        </th>
                                        <th style={{ minWidth: "250px" }}>
                                          Work Phone
                                        </th>
                                        <th style={{ minWidth: "250px" }}>
                                          Mobile
                                        </th>
                                        <th
                                          style={{
                                            borderBlockColor: "#ffffff00",
                                            borderColor: "#ffffff00",
                                            backgroundColor: "#ffffff00",
                                          }}
                                        ></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ListOfContactPerson &&
                                        ListOfContactPerson.map(
                                          (contactPerson, index) => (
                                            <tr>
                                              <td>
                                                {/* <select
                                                class="form-control"
                                                name={`ListOfContactPerson.[${index}].ContactPersonSalutation`}
                                              >
                                                <option>
                                                  Select Salutation
                                                </option>
                                                {SalutationOption?.map(
                                                  (x, i) => {
                                                    return (
                                                      <option
                                                        key={i}
                                                        value={x.value}
                                                      >
                                                        {x.label}
                                                      </option>
                                                    )
                                                  }
                                                )}
                                              </select> */}
                                                <Field
                                                  name={`ListOfContactPerson.[${index}].ContactPersonSalutation`}
                                                  placeholder={"Salutation"}
                                                  component={CustomSelect}
                                                  options={userSalutationOption}
                                                  isClearable={false}
                                                  style={{
                                                    width: "150px !important",
                                                  }}
                                                />
                                              </td>
                                              <td>
                                                <Field
                                                  type="text"
                                                  name={`ListOfContactPerson.[${index}].ContactPersonFirstName`}
                                                  placeholder={"First Name"}
                                                  component={CustomInput}
                                                  // style={{ width: "150px" }}
                                                />
                                              </td>
                                              <td>
                                                <Field
                                                  type="text"
                                                  name={`ListOfContactPerson.[${index}].ContactPersonLastName`}
                                                  placeholder={"Last Name"}
                                                  component={CustomInput}
                                                  // style={{ width: "150px" }}
                                                />
                                              </td>
                                              <td>
                                                <Field
                                                  type="email"
                                                  name={`ListOfContactPerson.[${index}].ContactPersonEmail`}
                                                  placeholder={"Email"}
                                                  component={CustomInput}
                                                  // style={{ width: "180px" }}
                                                />
                                              </td>

                                              <td>
                                                <Field
                                                  name={`ListOfContactPerson.[${index}].ContactPersonWorkPhone`}
                                                  placeholder={
                                                    "Enter Work Phone"
                                                  }
                                                  country={country}
                                                  countryCode={
                                                    contactPerson.ContactPersonWorkPhoneCountryCode
                                                  }
                                                  component={CustomPhoneInput}
                                                  onChange={(
                                                    value,
                                                    { countryCode, dialCode }
                                                  ) => {
                                                    // setFieldValue(
                                                    //   contactPerson.ContactPersonWorkPhoneCountryCode,
                                                    //   dialCode
                                                    // )
                                                    setFieldValue(
                                                      `ListOfContactPerson.[${index}].ContactPersonWorkPhoneCountryCode`,
                                                      //contactPerson.ContactPersonWorkPhoneCountryCode,
                                                      dialCode
                                                    )
                                                    setFieldValue(
                                                      `ListOfContactPerson.[${index}].ContactPersonWorkPhone`,
                                                      value.substring(
                                                        dialCode.toString()
                                                          .length
                                                      )
                                                    )
                                                  }}
                                                  // style={{ width: "175px" }}
                                                />
                                              </td>
                                              <td>
                                                <Field
                                                  name={`ListOfContactPerson.[${index}].ContactPersonMobile`}
                                                  placeholder={
                                                    "Enter Mobile No"
                                                  }
                                                  country={country}
                                                  countryCode={
                                                    contactPerson.ContactPersonMobileCountryCode
                                                  }
                                                  component={CustomPhoneInput}
                                                  onChange={(
                                                    value,
                                                    { countryCode, dialCode }
                                                  ) => {
                                                    // setFieldValue(
                                                    //   contactPerson.ContactPersonMobileCountryCode,
                                                    //   dialCode
                                                    // )
                                                    setFieldValue(
                                                      `ListOfContactPerson.[${index}].ContactPersonMobileCountryCode`,
                                                      //contactPerson.ContactPersonWorkPhoneCountryCode,
                                                      dialCode
                                                    )
                                                    setFieldValue(
                                                      `ListOfContactPerson.[${index}].ContactPersonMobile`,
                                                      value.substring(
                                                        dialCode.toString()
                                                          .length
                                                      )
                                                    )
                                                  }}
                                                  // style={{ width: "175px" }}
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  borderBlockColor: "#ffffff00",
                                                  borderColor: "#ffffff00",
                                                }}
                                              >
                                                <i
                                                  class="fas fa-times-circle"
                                                  style={{
                                                    color: "red",
                                                    marginTop: "12px",
                                                  }}
                                                  onClick={() => {
                                                    delete_ContactPerson(index)
                                                  }}
                                                ></i>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </div>{" "}
                                {/* {ListOfContactPerson &&
                                ListOfContactPerson.map(
                                  (contactPerson, index) => (
                                    <>
                                      <Row>
                                        <Col lg={3}>
                                          <Field
                                            label="Salutation"
                                            name={`ListOfContactPerson.[${index}].ContactPersonSalutation`}
                                            placeholder={"Salutation"}
                                            component={CustomSelect}
                                            options={SalutationOption}
                                            isClearable={false}
                                            // style={{
                                            //   width: "150px !important",
                                            // }}
                                          />
                                        </Col>
                                        <Col lg={3}>
                                          <Field
                                            label="First Name"
                                            type="text"
                                            name={`ListOfContactPerson.[${index}].FirstName`}
                                            placeholder={"First Name"}
                                            component={CustomInput}
                                            // style={{ width: "150px" }}
                                          />
                                        </Col>
                                        <Col lg={3}>
                                          <Field
                                            label="Last Name"
                                            type="text"
                                            name={`ListOfContactPerson.[${index}].LastName`}
                                            placeholder={"Last Name"}
                                            component={CustomInput}
                                            // style={{ width: "150px" }}
                                          />
                                        </Col>
                                        <Col lg={3}>
                                          <Field
                                            label="Email"
                                            type="text"
                                            name={`ListOfContactPerson.[${index}].Email`}
                                            placeholder={"Email"}
                                            component={CustomInput}
                                            // style={{ width: "180px" }}
                                          />
                                        </Col>
                                        <Col lg={3}>
                                          <Field
                                            label="Mobile No."
                                            name={`ListOfContactPerson.[${index}].Mobile`}
                                            placeholder={"Enter Mobile No"}
                                            country={country}
                                            countryCode={CountryCode}
                                            component={CustomPhoneInput}
                                            onChange={(
                                              value,
                                              { countryCode, dialCode }
                                            ) => {
                                              setFieldValue(
                                                "CountryCode",
                                                dialCode
                                              )
                                              setFieldValue(
                                                "Mobile",
                                                value.substring(
                                                  dialCode.toString().length
                                                )
                                              )
                                            }}
                                            // style={{ width: "175px" }}
                                          />
                                        </Col>
                                        <Col lg={3}>
                                          <Field
                                            label="Work Phone"
                                            name={`ListOfContactPerson.[${index}].WorkPhone`}
                                            placeholder={"Enter Work Phone"}
                                            country={country}
                                            countryCode={CountryCode}
                                            component={CustomPhoneInput}
                                            onChange={(
                                              value,
                                              { countryCode, dialCode }
                                            ) => {
                                              setFieldValue(
                                                "CountryCode",
                                                dialCode
                                              )
                                              setFieldValue(
                                                "WorkPhone",
                                                value.substring(
                                                  dialCode.toString().length
                                                )
                                              )
                                            }}
                                            // style={{ width: "175px" }}
                                          />
                                        </Col>
                                        <Col
                                          lg={1}
                                          style={{ textAlign: "end" }}
                                        >
                                          <div className="d-grid">
                                            <Link
                                              data-repeater-delete
                                              style={{
                                                color: "#bbbcc6",
                                                marginTop: "35px",
                                              }}
                                              value="Delete"
                                              onClick={() =>
                                                delete_ContactPerson(index)
                                              }
                                            >
                                              <i className="ti-trash"></i>{" "}
                                              Delete
                                            </Link>
                                          </div>
                                        </Col>
                                      </Row>
                                      <hr></hr>
                                    </>
                                  )
                                )} */}
                                <Row className="mt-3">
                                  <Col lg={12} style={{ textAlign: "left" }}>
                                    <Link
                                      onClick={() => {
                                        AddContactPerson()
                                      }}
                                    >
                                      <i className="fas fa-plus"></i> &nbsp; Add
                                      Contact Person
                                    </Link>
                                  </Col>
                                </Row>
                                <hr></hr>
                                <Row className="mt-3">
                                  <Col lg={6}>
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={() => {
                                        setMode(2)
                                      }}
                                    >
                                      Previous
                                    </button>
                                    &nbsp;&nbsp;
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={() => {
                                        setMode(4)
                                      }}
                                    >
                                      Next
                                    </button>
                                    &nbsp;&nbsp;
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      onClick={() => {
                                        navigate("/vendors")
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId={4}>
                                <FieldArray
                                  name="ListOfBankAccount"
                                  render={arrayHelpers => {
                                    return (
                                      <Fragment>
                                        {ListOfBankAccount?.map(
                                          (BankAccount, index) => (
                                            <div
                                              data-repeater-list="group-a"
                                              key={index}
                                            >
                                              <div data-repeater-item>
                                                <Row className="mb-2">
                                                  <Col>
                                                    <div
                                                      style={{
                                                        fontSize: "17px",
                                                        color: "black",
                                                      }}
                                                    >
                                                      <b>Bank {index + 1} </b>
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col lg={3}>
                                                    <div
                                                      className="mt-2"
                                                      style={{
                                                        color: "#000000",
                                                      }}
                                                    >
                                                      Beneficiary Name
                                                    </div>
                                                  </Col>
                                                  <Col lg={4}>
                                                    <Field
                                                      type="text"
                                                      name={`ListOfBankAccount.[${index}].BeneficiaryName`}
                                                      placeholder={
                                                        "Enter Beneficiary Name"
                                                      }
                                                      component={CustomInput}
                                                    />
                                                  </Col>
                                                  {index >= 1 ? (
                                                    <Col
                                                      lg={1}
                                                      // style={{
                                                      //   textAlign: "end",
                                                      // }}
                                                    >
                                                      <div className="d-grid">
                                                        <Link
                                                          data-repeater-delete
                                                          style={{
                                                            color: "#bbbcc6",
                                                          }}
                                                          value="Delete"
                                                          onClick={() =>
                                                            arrayHelpers.remove(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          <i className="ti-trash"></i>
                                                          Delete
                                                        </Link>
                                                      </div>
                                                    </Col>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Row>
                                                <Row>
                                                  <Col lg={3}>
                                                    <div
                                                      className="mt-2"
                                                      style={{
                                                        color: "#000000",
                                                      }}
                                                    >
                                                      Bank Name
                                                    </div>
                                                  </Col>
                                                  <Col lg={4}>
                                                    <Field
                                                      type="text"
                                                      name={`ListOfBankAccount.[${index}].BankName`}
                                                      placeholder={
                                                        "Enter Bank Name"
                                                      }
                                                      component={CustomInput}
                                                    />
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col lg={3}>
                                                    <div
                                                      className="mt-2"
                                                      style={{
                                                        color: "#000000",
                                                      }}
                                                    >
                                                      Account Number{" "}
                                                      <span className="text-danger">
                                                        *
                                                      </span>
                                                    </div>
                                                  </Col>
                                                  <Col lg={4}>
                                                    <Field
                                                      type={
                                                        isAccountNumber
                                                          ? "password"
                                                          : "text"
                                                      }
                                                      name={`ListOfBankAccount.[${index}].AccountNumber`}
                                                      placeholder={
                                                        "Enter Account Number"
                                                      }
                                                      component={CustomInput}
                                                      passwordEye={true}
                                                      setIsPassword={
                                                        setIsAccountNumber
                                                      }
                                                    />
                                                  </Col>
                                                </Row>

                                                <Row>
                                                  <Col lg={3}>
                                                    <div
                                                      className="mt-2"
                                                      style={{
                                                        color: "#000000",
                                                      }}
                                                    >
                                                      Re-Enter Account Number{" "}
                                                      <span className="text-danger">
                                                        *
                                                      </span>
                                                    </div>
                                                  </Col>
                                                  <Col lg={4}>
                                                    <Field
                                                      type="text"
                                                      name={`ListOfBankAccount.[${index}].ReEnterAccountNumber`}
                                                      placeholder={
                                                        "Re-Enter Account Number"
                                                      }
                                                      component={CustomInput}
                                                      onChange={e => {
                                                        setFieldValue(
                                                          `ListOfBankAccount.[${index}].ReEnterAccountNumber`,
                                                          e.target.value
                                                        )
                                                        if (
                                                          e.target.value &&
                                                          e.target.value !=
                                                            BankAccount.AccountNumber
                                                        ) {
                                                          setFieldValue(
                                                            `ListOfBankAccount.[${index}].validateReEnterAccountNumber`,
                                                            "Re-Enter Account Number does not match Account Number."
                                                          )
                                                        } else {
                                                          setFieldValue(
                                                            `ListOfBankAccount.[${index}].validateReEnterAccountNumber`,
                                                            ""
                                                          )
                                                        }
                                                      }}
                                                      style={{
                                                        marginBottom: "-16px",
                                                      }}
                                                    />
                                                    <span
                                                      style={{
                                                        color: "#ec4561",
                                                        fontSize: "80%",
                                                      }}
                                                    >
                                                      {
                                                        BankAccount.validateReEnterAccountNumber
                                                      }
                                                    </span>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col lg={3} className="mt-2">
                                                    <div
                                                      className="mt-3"
                                                      style={{
                                                        color: "#000000",
                                                      }}
                                                    >
                                                      IFSC{" "}
                                                      <span className="text-danger">
                                                        *
                                                      </span>
                                                    </div>
                                                  </Col>
                                                  <Col lg={4}>
                                                    <Field
                                                      type="text"
                                                      name={`ListOfBankAccount.[${index}].IFSC`}
                                                      placeholder={"Enter IFSC"}
                                                      component={CustomInput}
                                                      style={{
                                                        marginTop: "10px",
                                                      }}
                                                    />
                                                  </Col>
                                                  <Row>
                                                    <Col
                                                      lg={3}
                                                      className="mt-2"
                                                    >
                                                      <div
                                                        className="mt-3"
                                                        style={{
                                                          color: "#000000",
                                                        }}
                                                      >
                                                        Cancelled Cheque{" "}
                                                      </div>
                                                    </Col>

                                                    <Col
                                                      lg={4}
                                                      className="mt-2"
                                                    >
                                                      <input
                                                        type="file"
                                                        id={`ListOfBankAccount.[${index}].CancelledCheque`}
                                                        name={`ListOfBankAccount.[${index}].CancelledCheque`}
                                                        className="form-control"
                                                        onChange={e => {
                                                          uploadCancelledChequeFile(
                                                            e,
                                                            index
                                                          )
                                                        }}
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      />
                                                    </Col>
                                                    <Col
                                                      lg={2}
                                                      className="mt-2"
                                                    >
                                                      {BankAccount.UploadedFileName ? (
                                                        <button
                                                          type="button"
                                                          className="btn btn-secondary"
                                                          onClick={() => {
                                                            window.open(
                                                              BankAccount.UploadedFileName,
                                                              "_blank"
                                                            )
                                                          }}
                                                        >
                                                          Download
                                                        </button>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </Col>
                                                  </Row>
                                                </Row>
                                                <hr></hr>
                                              </div>
                                            </div>
                                          )
                                        )}
                                        <Row>
                                          <Col
                                            lg={12}
                                            style={{ textAlign: "left" }}
                                          >
                                            <Link
                                              onClick={() =>
                                                arrayHelpers.push(
                                                  initListOfBankAccount
                                                )
                                              }
                                            >
                                              <i className="fa fa-plus"></i>{" "}
                                              &nbsp; Add New Bank
                                            </Link>
                                          </Col>
                                        </Row>
                                        <hr></hr>
                                        <Row className="mt-3">
                                          <Col lg={6}>
                                            <button
                                              className="btn btn-primary"
                                              type="button"
                                              onClick={() => {
                                                setMode(3)
                                              }}
                                            >
                                              Previous
                                            </button>
                                            &nbsp;&nbsp;
                                            <button
                                              className="btn btn-primary"
                                              type="button"
                                              onClick={handleBankDetailsBtn}
                                              // onClick={() => {
                                              //   setMode(5)
                                              // }}
                                            >
                                              Next
                                            </button>
                                            &nbsp;&nbsp;
                                            <button
                                              type="button"
                                              className="btn btn-secondary"
                                              onClick={() => {
                                                navigate("/vendors")
                                              }}
                                            >
                                              Cancel
                                            </button>
                                          </Col>
                                        </Row>
                                      </Fragment>
                                    )
                                  }}
                                ></FieldArray>
                              </TabPane>
                              <TabPane tabId={5}>
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <text style={{ color: "black" }}>
                                        Remarks
                                      </text>{" "}
                                      (For Internal Use)
                                    </div>
                                    <Field
                                      type="textarea"
                                      name="Remark"
                                      placeholder={"Enter Remark"}
                                      component={CustomInput}
                                      maxLength={100}
                                    />
                                  </Col>
                                </Row>
                                <hr></hr>
                                <Row className="mt-3">
                                  <Col lg={6}>
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={() => {
                                        setMode(4)
                                      }}
                                    >
                                      Previous
                                    </button>
                                    &nbsp;&nbsp;
                                    <button
                                      className="btn btn-primary"
                                      type="submit"
                                    >
                                      Save
                                    </button>
                                    &nbsp;&nbsp;
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      onClick={() => {
                                        navigate("/vendors")
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </Col>
                                </Row>
                              </TabPane>
                            </TabContent>
                          </Col>
                        </Row>
                        {/* <hr></hr>
                        <Row className="mt-3">
                          <Col lg={6}>
                            <button className="btn btn-primary" type="submit">
                              Save
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                navigate("/vendors")
                              }}
                            >
                              Cancel
                            </button>
                          </Col>
                        </Row> */}
                      </Form>
                    </FormikProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <SwalError
              showError={showSwalError}
              setShowError={setShowSwalError}
              ErrorMsg={swalErrorMsg}
            ></SwalError>
          </Container>
        </div>
      </Spin>
    </React.Fragment>
  )
}
AddVendor.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(AddVendor))
