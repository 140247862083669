import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Input,
  Label,
  Modal,
  Form,
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CustomTable from "../../Custom/TableWithRemotePagination"
import { Field, FormikProvider, useFormik } from "formik"
import CustomFlatpickr from "Custom/CustomFlatpicker"
import CustomInput from "Custom/CustomInput"
import * as Yup from "yup"
import { Link } from "react-router-dom"
import CustomSelect from "Custom/CustomSelect"
import Select from "react-select"
import { get, post } from "helpers/api_helper"
import {
  GET_SalesList,
  GET_SalesOrderDetails,
  POST_SOAcceptReject,
  POST_AmmendRequest,
} from "helpers/url_helper"
import { Spin } from "antd"
import swal from "sweetalert"
import moment from "moment"
import SwalError from "Custom/SwalError"

const SalesOrder = props => {
  const [totalSize, setTotalSize] = useState(3)
  const [hide, setHide] = useState(1)
  const [planningScreenModal, setPlanningScreenModal] = useState(false)
  const [requestAmendModal, setRequestAmendModal] = useState(false)
  const [salesOrderList, setSalesOrderList] = useState([])
  const [salesOrderDetail, setSalesOrderDetail] = useState([])
  const [customerName, setCustomerName] = useState("")
  const [purchaseOrderDetail, setPurchaseOrderDetail] = useState([])
  const [orderItemDetail, setOrderItemDetail] = useState([])
  const [SOListData, setSOListData] = useState([])
  const [ID, setID] = useState("")
  const [billingAddress, setBillingAddress] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10)

  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [totalFulfillmentQty, setTotalFulfillmentQty] = useState("")

  const [showSwalError, setShowSwalError] = useState(false)
  const [swalErrorMsg, setSwalErrorMsg] = useState("")

  const AuthUser = JSON.parse(localStorage.getItem("authUser"))
  const userCompanyInfo = JSON.parse(localStorage.getItem("userCompanyInfo"))

  const columns = [
    {
      text: (
        <>
          <Input
            type="checkbox"
            className="form-check-input"
            id="Add"
            checked={false}
          />
          &nbsp; Date
        </>
      ),
      dataField: "Date",
      style: { minWidth: "150px" },
    },
    {
      text: "Sales Order No.",
      dataField: "SalesOrder",
      style: { minWidth: "150px" },
    },
    {
      text: "Reference",
      dataField: "Reference",
      style: { minWidth: "150px" },
    },
    {
      text: "Customer Name",
      dataField: "CustomerName",
      style: { minWidth: "200px" },
    },
    {
      text: "Order Status",
      dataField: "OrderStatus",
      style: { minWidth: "150px" },
    },
    {
      text: "Packed (Qty)",
      dataField: "Packed",
      style: { minWidth: "120px", textAlign: "center" },
    },
    {
      text: "Shipped (Qty)",
      dataField: "Shipped",
      style: { minWidth: "120px", textAlign: "center" },
    },
    {
      text: "Order (Qty)",
      dataField: "Order",
      style: { minWidth: "120px", textAlign: "center" },
    },
    {
      text: (
        <>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Amount
        </>
      ),
      dataField: "Amount",
      style: { minWidth: "150px", textAlign: "right" },
    },
    {
      text: "Expected Delivery Date",
      dataField: "ExpectedDeliveryDate",
      style: { minWidth: "180px", textAlign: "center" },
    },
  ]

  const getData = (page = 1, sizePerPage = 10) => {
    setIsLoading(true)
    get(
      GET_SalesList +
        "?paginate=" +
        sizePerPage +
        "&page=" +
        page +
        "&contact_id=" +
        ContactID +
        (Search ? "&search=" + Search : "") +
        (SearchByColumn ? "&column_filter=" + SearchByColumn : "") +
        (Status ? "&status_filter=" + Status : "")
    )
      .then(res => {
        if (res.status === 200) {
          console.log("data", res)
          if (res.data?.data?.length > 0) {
            setSalesOrderList(
              res.data?.data?.map((e, index) => {
                return {
                  Date: (
                    <>
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="Add"
                        checked={false}
                      />{" "}
                      &nbsp;
                      {e?.created_at
                        ? moment(e.created_at)
                            .format("DD-MMM-YYYY")
                            .replace(/^\-/, "")
                        : ""}
                    </>
                  ),
                  SalesOrder: (
                    <>
                      {AuthUser?.permissions?.filter(
                        x => x.resource_id == 9 && x.view_per == 1
                      )?.length > 0 ? (
                        <Link
                          onClick={() => {
                            setHide(2)
                            getSalesOrderDetail(e.id)
                            setID(e.id)
                          }}
                        >
                          {e.sales_order_no}
                        </Link>
                      ) : (
                        e.sales_order_no
                      )}
                    </>
                  ),
                  Reference: e.purchase_order.reference_estimate,
                  CustomerName:
                    e.contact.primary_contact_fname +
                    " " +
                    e.contact.primary_contact_lname,
                  OrderStatus: (
                    <>
                      <text
                        style={{
                          color:
                            e.so_status === "accepted"
                              ? "green"
                              : e.so_status === "pending"
                              ? "#798ad8"
                              : "gray",
                          textTransform: "uppercase",
                        }}
                      >
                        {e.so_status}
                      </text>
                    </>
                  ),
                  Packed: e.packed_qty,
                  Shipped: e.shipped_qty,
                  Order: e.order_qty,
                  Amount: (
                    <>
                      Rs. {e.amount ? parseFloat(e.amount).toFixed(2) : "0.00"}
                    </>
                  ),
                  ExpectedDeliveryDate: e?.purchase_order
                    ?.expected_delivery_date
                    ? moment(e.purchase_order.expected_delivery_date)
                        .format("DD-MMM-YYYY")
                        .replace(/^\-/, "")
                    : "", //e.purchase_order.expected_delivery_date,
                }
              })
            )
            setTotalSize(res.data.total)
            setIsLoading(false)
            setSOListData(res.data.data)
          }
        } else {
          setSalesOrderList([])
          setTotalSize(0)
          setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
    setCurrentPage(page)
    setCurrentSizePerPage(sizePerPage)
  }

  // const rows = [
  //   {
  //     Date: (
  //       <>
  //         <Input
  //           type="checkbox"
  //           className="form-check-input"
  //           id="Add"
  //           checked={false}
  //         />{" "}
  //         &nbsp; 31/05/2023
  //       </>
  //     ),
  //     SalesOrder: (
  //       <>
  //         <Link
  //           onClick={() => {
  //             setHide(2)
  //           }}
  //         >
  //           SO-00003
  //         </Link>
  //       </>
  //     ),
  //     Reference: "",
  //     CustomerName: "Deep",
  //     OrderStatus: (
  //       <>
  //         <text style={{ color: "#093e01" }}>APPROVED</text>
  //       </>
  //     ),
  //     Packed: "5",
  //     Shipped: "5",
  //     Order: "10",
  //     Amount: "Rs.12,578.72",
  //     ExpectedDeliveryDate: "05/06/2023",
  //   },
  //   {
  //     Date: (
  //       <>
  //         <Input
  //           type="checkbox"
  //           className="form-check-input"
  //           id="Add"
  //           checked={false}
  //         />{" "}
  //         &nbsp; 30/06/2023
  //       </>
  //     ),
  //     SalesOrder: (
  //       <>
  //         <Link
  //           onClick={() => {
  //             setHide(2)
  //           }}
  //         >
  //           SO-00002
  //         </Link>
  //       </>
  //     ),
  //     Reference: "",
  //     CustomerName: "Deep",
  //     OrderStatus: (
  //       <>
  //         <text style={{ color: "#798ad8" }}>CONFIRMED</text>
  //       </>
  //     ),
  //     Packed: "5",
  //     Shipped: "5",
  //     Order: "10",
  //     Amount: "Rs.111,000.00",
  //     ExpectedDeliveryDate: "05/06/2023",
  //   },
  //   {
  //     Date: (
  //       <>
  //         <Input
  //           type="checkbox"
  //           className="form-check-input"
  //           id="Add"
  //           checked={false}
  //         />{" "}
  //         &nbsp; 29/05/2023
  //       </>
  //     ),
  //     SalesOrder: (
  //       <>
  //         <Link
  //           onClick={() => {
  //             setHide(2)
  //           }}
  //         >
  //           SO-00001
  //         </Link>
  //       </>
  //     ),
  //     Reference: "",
  //     CustomerName: "Deep",
  //     OrderStatus: (
  //       <>
  //         <text style={{ color: "green" }}>CLOSED</text>
  //       </>
  //     ),
  //     Packed: "5",
  //     Shipped: "5",
  //     Order: "10",
  //     Amount: "Rs.1000.00",
  //     ExpectedDeliveryDate: "05/06/2023",
  //   },
  // ]

  const subinitialFulfillment = {
    id: "",
    planned_delivery_date: "",
    planned_delivery_quantity: 0,
  }
  // const initialFulfillment = {
  //   id: "",
  //   planned_delivery_date: "",
  //   planned_delivery_quantity: 0,
  // }

  const AddNewFulFillment = index => {
    setFieldValue(`ListOfFulfillment.[${index}].SubListOfFulfillment`, [
      ...ListOfFulfillment[index].SubListOfFulfillment,
      {
        id: "",
        planned_delivery_date: "",
        planned_delivery_quantity: 0,
      },
    ])
    // setFieldValue("ListOfFulfillment", [
    //   ...ListOfFulfillment,
    //   {
    //     id: "",
    //     planned_delivery_date: "",
    //     planned_delivery_quantity: 0,
    //   },
    // ])
  }

  const delete_Fulfillment = (index, index1) => {
    //  console.log("filter", `ListOfFulfillment.[${index}].SubListOfFulfillment`)
    setFieldValue(
      `ListOfFulfillment.[${index}].SubListOfFulfillment`,
      ListOfFulfillment[index]?.SubListOfFulfillment.filter(
        (element, i) => i !== index1
      )
    )
    // setFieldValue(
    //   "ListOfFulfillment",
    //   ListOfFulfillment.filter((element, i) => i !== index)
    // )
  }

  const validationSchemaFilter = Yup.object().shape({})

  const formikFilter = useFormik({
    validationSchema: validationSchemaFilter,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitFilter(values, formikHelpers),
    initialValues: {
      Status: "",
      Search: "",
      SearchByColumn: "",
      ContactID: 0,
      OrgFlag: AuthUser?.org,
    },
    validateOnBlur: false,
  })

  const {
    values: { Search, Status, SearchByColumn, ContactID, OrgFlag },
  } = formikFilter

  const handleValidSubmitFilter = values => {}

  useEffect(() => {
    if (!ContactID) {
      if (OrgFlag == true) {
        formikFilter.setFieldValue(
          "ContactID",
          userCompanyInfo?.organization?.id
        )
      } else {
        formikFilter.setFieldValue(
          "ContactID",
          userCompanyInfo?.["0"]?.contact_id
        )
      }
    } else {
      getData()
    }
  }, [Search, SearchByColumn, Status, ContactID, OrgFlag])

  // useEffect(() => {
  //   getData()
  // }, [Search, SearchByColumn, Status, ContactID])

  useEffect(() => {
    if (ID) {
      getSalesOrderDetail(ID)
    }
  }, [ID])

  const salesOrderOptions = [
    { label: "Sales Order No.", value: "sales_order_no" },
    { label: "Customer Name", value: "customer_name" },
    //{ label: "Order Qty", value: 3 },
  ]

  const statusOption = [
    { label: "Draft", value: "draft" },
    { label: "Approval", value: "approval" },
    { label: "Pending", value: "pending" },
    { label: "Send", value: "send" },
  ]

  const OptionSOAccept = [
    { label: "Accept", value: 1 },
    { label: "Request Amend", value: 2 },
    { label: "Reject", value: 3 },
  ]

  const getSalesOrderDetail = id => {
    setIsLoading(true)

    get(GET_SalesOrderDetails + `${id}`).then(res => {
      if (res.status === 200) {
        //console.log(res)
        setSalesOrderDetail(res.data)
        setCustomerName(
          res.data.contact.primary_contact_fname +
            " " +
            res.data.contact.primary_contact_lname
        )
        setPurchaseOrderDetail(res.data.purchase_order)
        setOrderItemDetail(res.data.purchase_order.order_item)
        setBillingAddress(res.data.contact.addresses)

        let OrderItemsNew = []

        res.data?.purchase_order?.order_item?.map((e, index) => {
          if (!e.v_sales_orders_detail) {
            OrderItemsNew.push({
              ...e,
              SubListOfFulfillment: [subinitialFulfillment],
              // v_sales_orders_detail: {
              //   id: 0,
              //   planned_delivery_quantity: "",
              //   planned_delivery_date: "",
              // },
            })
          } else {
            OrderItemsNew.push({
              ...e,
              SubListOfFulfillment: [subinitialFulfillment],
            })
          }
        })

        //console.log("fill data", OrderItemsNew)
        setFieldValue("ListOfFulfillment", OrderItemsNew)

        let TotalFulfillmentQty = 0
        //res.data?.purchase_order?.order_item?.length > 0 ?
        let fulfillmentList = []
        res.data?.purchase_order?.order_item?.map((e, index) => {
          e.v_sales_orders_detail?.map((i, index) => {
            if (i?.planned_delivery_quantity > 0) {
              TotalFulfillmentQty =
                parseInt(TotalFulfillmentQty) +
                parseInt(i?.planned_delivery_quantity)
            }
          })

          // fulfillmentList.push({
          //   id: e.v_sales_orders_detail?.id,
          //   planned_delivery_date:
          //     e.v_sales_orders_detail?.planned_delivery_date,
          //   planned_delivery_quantity:
          //     e.v_sales_orders_detail?.planned_delivery_quantity,
          // })
        })

        setTotalFulfillmentQty(TotalFulfillmentQty)
        // setFieldValue("ListOfFulfillment", fulfillmentList)
      }
    })
    setIsLoading(false)
  }

  const SORejected = async ID => {
    swal({
      title: "Are you sure you want to  Reject?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(confirmResult => {
      if (confirmResult) {
        SORejected_UserConfirm(ID)
      }
    })
  }

  const SORejected_UserConfirm = async ID => {
    setIsLoading(true)

    await post(POST_SOAcceptReject, {
      so_id: ID,
      type: "rejected",
    })
      .then(response => {
        if (response?.status === 200) {
          swal({
            title: "SO Rejected",
            text: "Sales Order has been Rejected.",
            icon: "success",
            button: "OK",
          })
          //getData()
          setIsLoading(false)
          getSalesOrderDetail(ID)
        } else {
          // swal("Error!", response.message, "error")
          if (typeof response?.message == "string") {
            swal({
              title: "Error!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
          setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  const validationSchema = Yup.object().shape({})

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      ListOfFulfillment: [],
    },
    validateOnBlur: false,
  })

  const {
    setFieldValue,
    setValues,
    values: { ListOfFulfillment },
  } = formik

  const handleValidSubmit = values => {
    setIsLoading(true)

    let flag = false
    let errorMessage = ""

    ListOfFulfillment?.map((item, i) => {
      let ItemTotalQty = 0
      //console.log("qty", item?.SubListOfFulfillment)
      item?.SubListOfFulfillment?.map((subitem, j) => {
        if (
          subitem?.planned_delivery_quantity === "" ||
          subitem?.planned_delivery_quantity === 0
        ) {
          flag = true
          errorMessage = "Fill the Delivery Date/Quantity."
          return
        } else {
          if (
            parseInt(subitem?.planned_delivery_quantity) === "NaN" ||
            parseInt(subitem?.planned_delivery_quantity) === "undefined" ||
            subitem?.planned_delivery_quantity === "" ||
            subitem?.planned_delivery_quantity === 0 ||
            subitem?.planned_delivery_date === "" ||
            subitem?.planned_delivery_date === "NaN" ||
            subitem?.planned_delivery_date === "undefined"
          ) {
            flag = true
            errorMessage = "Fill the Delivery Date/Quantity."
            return
          } else {
            ItemTotalQty = ItemTotalQty + subitem?.planned_delivery_quantity
          }
        }

        // if (subitem) {
        //   if (
        //     parseInt(subitem.planned_delivery_quantity) !== "NaN" ||
        //     parseInt(subitem.planned_delivery_quantity) > 0
        //   ) {
        //     if (
        //       parseInt(subitem.planned_delivery_quantity) >
        //       parseInt(item.quantity)
        //     ) {
        //       flag = true
        //       errorMessage =
        //         "Delivery Quantity must be less then or equal to Quantity."
        //       return
        //     }
        //   }
        // }
      })

      if (flag == false) {
        if (parseInt(ItemTotalQty) !== parseInt(item.quantity)) {
          flag = true
          errorMessage =
            item?.product?.product_name +
            " Item Delivery Quantity must be equal to Order Quantity."
          return
        }
      }
    })

    if (flag == true) {
      swal({
        title: "Warning",
        text: errorMessage,
        icon: "warning",
        button: "OK",
      })
      return
    }

    let orderDetailsArray = []

    // ListOfFulfillment?.map((x, i) => {
    //   if (x?.v_sales_orders_detail && x?.v_sales_orders_detail?.id > 0) {
    //     orderDetailsArray.push({
    //       id: x.v_sales_orders_detail.id,
    //       planned_delivery_date:
    //         `${moment(x.v_sales_orders_detail.planned_delivery_date).format(
    //           "YYYY-MM-DD"
    //         )}` +
    //         " " +
    //         moment(new Date()).format("h:mm:ss"),

    //       planned_delivery_quantity:
    //         x.v_sales_orders_detail.planned_delivery_quantity,
    //     })
    //   }
    // })

    ListOfFulfillment?.map((x, i) => {
      x.SubListOfFulfillment?.map((y, z) => {
        if (x?.SubListOfFulfillment && x?.id > 0) {
          orderDetailsArray.push({
            id: x.id,
            planned_delivery_date:
              `${moment(y.planned_delivery_date).format("YYYY-MM-DD")}` +
              " " +
              moment(new Date()).format("h:mm:ss"),

            planned_delivery_quantity: y.planned_delivery_quantity,
          })
        }
      })
    })

    const Data = {
      so_id: ID,
      type: "accepted",
      order_details: orderDetailsArray,
    }

    // console.log("save", Data)
    // return

    post(POST_SOAcceptReject, Data)
      .then(response => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: response?.message,
            icon: "success",
            button: "OK",
          })
          setIsLoading(false)
          setPlanningScreenModal(false)
          getSalesOrderDetail(ID)
          //setIsLoading(false)
        } else {
          // swal({
          //   title: "Error",
          //   text: response?.message,
          //   icon: "error",
          //   button: "OK",
          // })
          if (typeof response?.message == "string") {
            swal({
              title: "Error!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
          setIsLoading(false)
          getSalesOrderDetail(ID)
          //setPlanningScreenModal(false)
          //getSalesOrderDetail(ID)
        }
      })
      .catch(error => {
        error.response.data
        setIsLoading(false)
      })
  }

  const validationSchemaRequestAmend = Yup.object().shape({
    RequestAmendRemark: Yup.string().required("Remark is required field."),
  })

  const formikRequestAmend = useFormik({
    validationSchema: validationSchemaRequestAmend,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitRequestAmend(values, formikHelpers),
    initialValues: {
      RequestAmendRemark: "",
    },
    validateOnBlur: false,
  })

  const {
    values: { RequestAmendRemark },
  } = formikRequestAmend

  const handleValidSubmitRequestAmend = values => {
    setIsLoading(true)

    const Data = {
      so_id: ID,
      request: RequestAmendRemark,
    }

    //console.log("save", Data)

    post(POST_AmmendRequest, Data)
      .then(response => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: response?.message,
            icon: "success",
            button: "OK",
          })
          setIsLoading(false)
          getSalesOrderDetail(ID)
          setRequestAmendModal(false)
        } else {
          // swal({
          //   title: "Error",
          //   text: response?.message,
          //   icon: "error",
          //   button: "OK",
          // })
          if (typeof response?.message == "string") {
            swal({
              title: "Error!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }

          setIsLoading(false)
          getSalesOrderDetail(ID)
          setRequestAmendModal(false)
          //RequestAmendRemark = ""
        }
      })
      .catch(error => {
        error.response.data
        setIsLoading(false)
      })
  }

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{"Sales Orders"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            {hide === 1 ? (
              <>
                <Row className="mt-3">
                  <Col lg={6}>
                    <div className="mt-2" style={{ fontSize: "large" }}>
                      <b>Sales Orders</b>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="col-12">
                    <FormikProvider value={formikFilter}>
                      <Form onSubmit={formikFilter.handleSubmit}>
                        <Row>
                          <Col lg={12}>
                            <Row>
                              <Col lg={3} sm={6} md={3}>
                                <div class="form-group has-search">
                                  <span class="fa fa-search form-control-feedback"></span>
                                  <Input
                                    type="text"
                                    name="Search"
                                    class="form-control"
                                    placeholder="Search"
                                    value={Search}
                                    onChange={e => {
                                      //setSearch(e.target.value)
                                      formikFilter.setFieldValue(
                                        "Search",
                                        e.target.value
                                      )
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg={2} sm={6} md={2}>
                                <Field
                                  name="SearchByColumn"
                                  placeholder={"By Column"}
                                  component={CustomSelect}
                                  options={salesOrderOptions}
                                />
                              </Col>
                              <Col lg={2} md={2}>
                                <Field
                                  name="Status"
                                  placeholder={"Status"}
                                  component={CustomSelect}
                                  options={statusOption}
                                />{" "}
                              </Col>
                              <Col
                                lg={2}
                                className="mt-1"
                                style={{ textAlign: "left" }}
                              >
                                <div
                                  onClick={() => {
                                    formikFilter.setFieldValue("StatusName", "")
                                    formikFilter.setFieldValue("Search", "")
                                    formikFilter.setFieldValue(
                                      "SearchByColumn",
                                      ""
                                    )
                                    // setSearch("")
                                    // formikFilter.setFieldValue("Status", "")
                                    // // formikFilter.setFieldValue("Search", "")
                                    // formikFilter.setFieldValue(
                                    //   "SearchByColumn",
                                    //   ""
                                    // )
                                  }}
                                >
                                  <i
                                    className="mdi mdi-filter-remove"
                                    style={{
                                      fontSize: "20px",
                                      color: "#4a539f",
                                    }}
                                  ></i>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </FormikProvider>
                    <Card>
                      <CardBody>
                        <CustomTable
                          keyField="Vendor"
                          columns={columns}
                          data={salesOrderList}
                          totalSize={totalSize}
                          getData={getData}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col
                    lg={3}
                    md={3}
                    sm={3}
                    style={{
                      height: "100%",
                    }}
                  >
                    <Row style={{ background: "#f0f0f0", marginLeft: "-25px" }}>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{ marginTop: "20px", marginBottom: "6px" }}
                      >
                        <div className="d-flex">
                          <div style={{ width: "400px" }}>All Sales Order</div>
                          {/* <div style={{ textAlign: "right", width: "100%" }}>
                          <button
                            className="btn btn-primary"
                            onClick={() => {}}
                            style={{
                              padding: "0.05rem 0.5rem",
                            }}
                          >
                            + New
                          </button>
                        </div> */}
                        </div>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{
                          borderTop: "1px solid #cacaca",
                        }}
                      >
                        {SOListData?.map((x, i) => (
                          <Row
                            style={{
                              marginBottom: "5px",
                              marginTop: "5px",
                              backgroundColor: x.id === ID ? "#cecece96" : "",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setID(x?.id)
                            }}
                          >
                            <div className="col-1 col-lg-1 col-md-1 col-sm-2">
                              {" "}
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="check"
                                checked={false}
                              />
                            </div>
                            <div className="col col-lg-6 col-md-6 col-sm-6">
                              <text>
                                {x.contact.primary_contact_fname +
                                  " " +
                                  x.contact.primary_contact_lname}
                              </text>
                              <br></br>
                              <div
                                style={{ fontSize: "10px", marginTop: "6px" }}
                              >
                                <Link to="#">{x.sales_order_no}</Link> |
                                {x?.created_at
                                  ? moment(x.created_at)
                                      .format("DD-MMM-YYYY")
                                      .replace(/^\-/, "")
                                  : ""}
                              </div>
                            </div>
                            <div
                              className="col col-lg-5 col-md-5 col-sm-4"
                              style={{
                                marginLeft: "-2px",
                                textAlign: "end",
                                padding: "0.05rem 0.5rem",
                              }}
                            >
                              <text>
                                Rs.{" "}
                                {x.amount
                                  ? parseFloat(x.amount).toFixed(2)
                                  : "0.00"}
                              </text>
                              <br></br>
                              <text
                                style={{
                                  fontSize: "10px",
                                  color:
                                    x.so_status === "accepted"
                                      ? "green"
                                      : x.so_status === "pending"
                                      ? "#798ad8"
                                      : "gray",
                                  textTransform: "uppercase",
                                }}
                              >
                                {x.so_status}
                              </text>
                            </div>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    lg={9}
                    md={9}
                    sm={9}
                    style={{ borderLeft: "1px solid #cacaca" }}
                  >
                    <Row>
                      <Col
                        lg={12}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <div style={{ fontSize: "16px", color: "grey" }}>
                          {salesOrderDetail.sales_order_no}
                        </div>
                        <div style={{ textAlignLast: "end" }}>
                          <button
                            type="button"
                            className="btn"
                            style={{
                              marginTop: "-50px",
                              fontSize: "20px",
                              position: "sticky",
                              border: 0,
                            }}
                            onClick={() => {
                              setHide(1)
                              getData()
                            }}
                            // onClick={() => setHide(1)
                            // }
                          >
                            <span>×</span>
                          </button>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <Row
                          style={{
                            borderTop: "1px solid #cacaca",
                          }}
                        >
                          <Col lg={12} md={12} sm={12} className="mt-3">
                            <div>
                              <Row>
                                <Col lg={1} md={1} sm={1}>
                                  <img
                                    src={
                                      "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png"
                                    }
                                    alt=""
                                    className="avatar-xs rounded-square"
                                    style={{
                                      borderRadius: "5px",
                                    }}
                                  />
                                </Col>
                                <Col lg={11} md={11} sm={11}>
                                  <text style={{ color: "black" }}>
                                    <b>
                                      Accept Sales Order from {customerName}
                                    </b>
                                  </text>
                                  <br></br>
                                  <text>
                                    Sales Order has been created. To accept it
                                    you need to add Fulfillment Plan. Click on
                                    accept button to add fulfillment plan.
                                  </text>
                                  {salesOrderDetail.so_status === "pending" &&
                                  AuthUser?.permissions?.filter(
                                    x =>
                                      x.resource_id == 9 && x.approve_per == 1
                                  )?.length > 0 ? (
                                    <Row>
                                      <Col lg={8}></Col>
                                      <Col lg={4}>
                                        <Select
                                          style={{
                                            padding: "0.05rem 0.5rem",
                                          }}
                                          options={OptionSOAccept}
                                          onChange={e => {
                                            if (e?.value === 1) {
                                              setPlanningScreenModal(true)
                                              setRequestAmendModal(false)
                                            } else if (e.value === 2) {
                                              formikRequestAmend.resetForm()
                                              setRequestAmendModal(true)
                                              setPlanningScreenModal(false)
                                            } else if (e.value === 3) {
                                              SORejected(ID)
                                            }
                                          }}
                                        ></Select>
                                      </Col>
                                    </Row>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <hr style={{ marginTop: "20px" }}></hr>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            style={{
                              marginTop: "10px",
                            }}
                          >
                            <Row style={{ backgroundColor: "#f8f9fa00" }}>
                              <Col
                                lg={12}
                                md={12}
                                sm={12}
                                style={{ marginLeft: "10px" }}
                              >
                                <Row>
                                  <Col lg={6} md={12} sm={12}>
                                    <text
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      SALES ORDER
                                    </text>
                                    <br></br>
                                    <text>
                                      Sales Order# &nbsp;
                                      <b>{salesOrderDetail.sales_order_no}</b>
                                    </text>
                                    <Row style={{ marginTop: "10px" }}>
                                      <Col lg={2}>
                                        <button
                                          className="btn btn-primary"
                                          disabled
                                          style={{
                                            padding: "0.05rem 0.5rem",
                                            borderColor:
                                              salesOrderDetail.so_status ===
                                              "accepted"
                                                ? "green"
                                                : salesOrderDetail.so_status ===
                                                  "pending"
                                                ? "#798ad8"
                                                : "gray",
                                            backgroundColor:
                                              salesOrderDetail.so_status ===
                                              "accepted"
                                                ? "green"
                                                : salesOrderDetail.so_status ===
                                                  "pending"
                                                ? "#798ad8"
                                                : "gray",
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          {salesOrderDetail.so_status}
                                        </button>
                                      </Col>
                                    </Row>
                                    <Row style={{ marginTop: "15px" }}>
                                      <Col lg={7} md={12} sm={12}>
                                        <b>SO NUMBER</b>
                                      </Col>
                                      <Col lg={5} md={5} sm={12}>
                                        {salesOrderDetail.sales_order_no}
                                      </Col>
                                    </Row>

                                    <Row style={{ marginTop: "15px" }}>
                                      <Col lg={7} md={12} sm={12}>
                                        <b>PO NUMBER</b>
                                      </Col>
                                      <Col lg={5} md={5} sm={12}>
                                        {purchaseOrderDetail.purchase_order_no}
                                      </Col>
                                    </Row>
                                    <Row style={{ marginTop: "15px" }}>
                                      <Col lg={7} md={12} sm={12}>
                                        <b>EXPECTED DELIVERY DATE</b>
                                      </Col>
                                      <Col lg={5} md={5} sm={12}>
                                        {purchaseOrderDetail.expected_delivery_date
                                          ? moment(
                                              purchaseOrderDetail.expected_delivery_date
                                            )
                                              .format("DD-MMM-YYYY")
                                              .replace(/^\-/, "")
                                          : ""}
                                      </Col>
                                    </Row>
                                    <Row style={{ marginTop: "15px" }}>
                                      <Col lg={7} md={12} sm={12}>
                                        <b>ORDER DATE</b>
                                      </Col>
                                      <Col lg={5} md={5} sm={12}>
                                        {purchaseOrderDetail.purchase_order_date
                                          ? moment(
                                              purchaseOrderDetail.purchase_order_date
                                            )
                                              .format("DD-MMM-YYYY")
                                              .replace(/^\-/, "")
                                          : ""}
                                      </Col>
                                    </Row>
                                    <Row style={{ marginTop: "15px" }}>
                                      <Col lg={7} md={12} sm={12}>
                                        <b>CUSTOMER NAME</b>
                                      </Col>
                                      <Col lg={5} md={5} sm={12}>
                                        {customerName}
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={1}></Col>
                                  <Col
                                    lg={5}
                                    md={12}
                                    sm={12}
                                    style={{ marginTop: "15px" }}
                                  >
                                    {" "}
                                    {billingAddress
                                      ?.filter(x => x.type === "shipping")
                                      ?.map(x => (
                                        <text>
                                          <b>BILLING ADDRESS</b>
                                          <br></br>
                                          {x.nickname_address}
                                          <br></br>
                                          {x.attention}, <br></br>
                                          {x.address_line_1}, <br></br>
                                          {x.address_line_2}, <br></br>
                                          {x.address_line_3},{x.zip_code},
                                          {x.phone}
                                        </text>
                                      ))}
                                    <br></br>
                                    <Link to="#">{customerName}</Link>
                                  </Col>
                                </Row>
                                <Row style={{ marginTop: "40px" }}>
                                  <Col lg={12}>
                                    <div className="table-responsive">
                                      <table className="table mb-0">
                                        <thead>
                                          <tr className="table-light">
                                            <th></th>
                                            <th style={{ minWidth: "220px" }}>
                                              ITEMS & DESCRIPTION
                                            </th>
                                            <th style={{ minWidth: "100px" }}>
                                              ORDERD
                                            </th>
                                            <th style={{ minWidth: "250px" }}>
                                              FULFILLMENT AND PLANNING
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {orderItemDetail?.map((x, i) => (
                                            <tr>
                                              <td>
                                                <img
                                                  src={
                                                    "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png"
                                                  }
                                                  alt=""
                                                  className="avatar-xs rounded-square"
                                                  style={{
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                              </td>
                                              <td>
                                                &nbsp;
                                                <span>
                                                  {x.product?.product_name}
                                                  <br></br>
                                                  {x.description}
                                                </span>
                                              </td>
                                              <td>
                                                {x.quantity} <br></br>
                                                {/* Pending-
                                                {parseInt(
                                                  x.v_sales_orders_detail
                                                    ?.planned_delivery_quantity
                                                )
                                                  ? parseInt(x.quantity) -
                                                    parseInt(
                                                      x.v_sales_orders_detail
                                                        ?.planned_delivery_quantity
                                                    )
                                                  : x.quantity} */}
                                              </td>
                                              <td>
                                                {x.v_sales_orders_detail?.map(
                                                  (y, z) => (
                                                    <>
                                                      Delivery Date : &nbsp;
                                                      {`${moment(
                                                        y?.planned_delivery_date
                                                      )
                                                        .format("DD-MMM-YYYY")
                                                        .replace(/^\-/, "")}`}
                                                      <br></br>
                                                      Delivery Quantity : &nbsp;
                                                      {y?.planned_delivery_quantity
                                                        ? y?.planned_delivery_quantity
                                                        : 0}
                                                      <br></br>
                                                    </>
                                                  )
                                                )}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>{" "}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            <SwalError
              showError={showSwalError}
              setShowError={setShowSwalError}
              ErrorMsg={swalErrorMsg}
            ></SwalError>
          </Container>
        </div>

        <Modal isOpen={planningScreenModal} centered={true} size="lg">
          <div className="modal-header mdl_header">
            <h5 className="modal-title mt-0">
              <text style={{ color: "black" }}>{"Accept Sales Order"}</text>{" "}
            </h5>
            <button
              type="button"
              className="btn close mt-2"
              onClick={() => setPlanningScreenModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col md={6} lg={6} sm={6} className="mb-3 row">
                    <Col md={12} lg={12} sm={12}>
                      <b style={{ fontWeight: 600 }}>{"SO No"} :</b>
                      &nbsp; {salesOrderDetail.sales_order_no}
                    </Col>
                    <Col md={12} lg={12} sm={12}>
                      <b style={{ fontWeight: 600 }}>{"Customer Name"} :</b>
                      &nbsp; {customerName}
                    </Col>
                    <Col md={12} lg={12} sm={12}>
                      <b style={{ fontWeight: 600 }}>
                        {"Expected Delivery Date"} :
                      </b>
                      &nbsp; {purchaseOrderDetail.expected_delivery_date}
                    </Col>
                  </Col>
                  <Col md={6} lg={6} sm={6} className="mb-3 row">
                    <Col md={12} lg={12} sm={12}></Col>
                    <Col md={12} lg={12} sm={12}>
                      <b style={{ fontWeight: 600 }}>{"PO No"} :</b>
                      &nbsp; {purchaseOrderDetail.purchase_order_no}
                    </Col>
                    <Col md={12} lg={12} sm={12}>
                      <b style={{ fontWeight: 600 }}>{"Order Date"}</b>
                      &nbsp; {purchaseOrderDetail.purchase_order_date}
                    </Col>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col lg={4} md={4} sm={12}>
                    <b style={{ color: "black" }}>Total Item : </b>{" "}
                    {purchaseOrderDetail.total_item}
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <b style={{ color: "black" }}>Total Order Quantity : </b>
                    {purchaseOrderDetail.total_quantity}
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <b style={{ color: "black" }}>Total Fulfillment Qty : </b>{" "}
                    {totalFulfillmentQty}
                  </Col>
                </Row>
                <hr></hr>
                <div className="table-responsive">
                  <table className="table table-bordered mb-0">
                    <thead>
                      <tr className="table-light">
                        <th></th>
                        <th style={{ minWidth: "250px" }}>
                          ITEMS & DESCRIPTION
                        </th>
                        <th style={{ minWidth: "140px" }}>ORDERED</th>
                        <th style={{ minWidth: "260px" }}>
                          FULFILLMENT PLANNING
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ListOfFulfillment?.map((x, index) => (
                        <tr>
                          <td>
                            {" "}
                            <img
                              src={
                                "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png"
                              }
                              alt=""
                              className="avatar-xs rounded-square"
                              style={{
                                borderRadius: "5px",
                              }}
                            />
                            &nbsp;
                          </td>
                          <td>
                            <span>{x.product?.product_name}</span>
                          </td>
                          <td>
                            {x.quantity} <br></br>
                            {/* Pending (
                            {parseInt(
                              x.SubListOfFulfillment[0]
                                .planned_delivery_quantity
                            )
                              ? parseInt(x.quantity) -
                                parseInt(
                                  x.SubListOfFulfillment[0]
                                    ?.planned_delivery_quantity
                                )
                              : x.quantity}
                            ) */}
                          </td>
                          <td>
                            {ListOfFulfillment[index].SubListOfFulfillment?.map(
                              (x, index1) => (
                                <>
                                  <Row>
                                    <Col lg={5} md={5} sm={5}>
                                      <Field
                                        name={`ListOfFulfillment.[${index}].SubListOfFulfillment.[${index1}].planned_delivery_date`}
                                        placeholder={"dd/mm/yyyy"}
                                        component={CustomFlatpickr}
                                        options={{
                                          altInput: true,
                                          altFormat: "d/m/Y",
                                          dateFormat: "d/m/Y",
                                        }}
                                      />
                                    </Col>

                                    <Col lg={5} md={5} sm={5}>
                                      <Field
                                        type="number"
                                        name={`ListOfFulfillment.[${index}].SubListOfFulfillment.[${index1}].planned_delivery_quantity`}
                                        placeholder={"Quantity"}
                                        component={CustomInput}
                                        min={0}
                                      />
                                    </Col>

                                    <Col lg={1} md={1} sm={1}>
                                      <i
                                        class="fas fa-times-circle"
                                        style={{
                                          color: "red",
                                          marginTop: "12px",
                                        }}
                                        onClick={() => {
                                          delete_Fulfillment(index, index1)
                                        }}
                                      ></i>
                                    </Col>
                                  </Row>
                                </>
                              )
                            )}
                            <Row>
                              <Col>
                                <Link
                                  onClick={() => {
                                    AddNewFulFillment(index)
                                  }}
                                >
                                  + Add New Line
                                </Link>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <hr></hr>
                <Row>
                  <Col lg={12} style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" type="submit">
                      Accept
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-light"
                      type="button"
                      onClick={() => {
                        setIsLoading(false)
                        setPlanningScreenModal(false)
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </FormikProvider>
          </div>
        </Modal>
        <Modal isOpen={requestAmendModal} centered={true}>
          <div className="modal-header mdl_header">
            <h5 className="modal-title mt-0">
              <text style={{ color: "black" }}>{"Request Amend"}</text>{" "}
            </h5>
            <button
              type="button"
              className="btn close mt-2"
              onClick={() => setRequestAmendModal(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <FormikProvider value={formikRequestAmend}>
              <Form onSubmit={formikRequestAmend.handleSubmit}>
                <Row className="mt-2">
                  <Col lg={3}>
                    <div className="fieldNameLabel">
                      Remark <span className="text-danger">*</span>
                    </div>
                  </Col>
                  <Col lg={9}>
                    <Field
                      type="textarea"
                      name="RequestAmendRemark"
                      placeholder={"Enter Remark"}
                      component={CustomInput}
                      maxLength={500}
                      rows={3}
                    />
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col lg={12} style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" type="submit">
                      Save
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-light"
                      type="button"
                      onClick={() => {
                        setIsLoading(false)
                        setRequestAmendModal(false)
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </FormikProvider>
          </div>
        </Modal>
      </Spin>
    </React.Fragment>
  )
}
SalesOrder.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SalesOrder))
