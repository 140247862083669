import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container, Form } from "reactstrap"
import { Field, FormikProvider, useFormik } from "formik"
// Redux
import { connect, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

// action
import { userForgetPassword } from "../../store/actions"

// import images
//import logoSm from "../../assets/images/logo-sm.png"
import withRouter from "components/Common/withRouter"

import * as Yup from "yup"
import CustomInput from "Custom/CustomInput"
import OTPInput, { ResendOTP } from "otp-input-react"
import { post } from "helpers/api_helper"
import {
  Post_SendOTPForForgotPassword,
  POST_VerifyOTP,
  POST_ForgotPassword,
} from "helpers/url_helper"
import { Spin } from "antd"
import swal from "sweetalert"
import SwalError from "Custom/SwalError"

const ForgetPasswordPage = props => {
  //const dispatch = useDispatch()
  //const [oTP, setOTP] = useState(null)
  const [mode, setMode] = useState(0)
  const [validationMsgOTP, setValidationMsgOTP] = useState("")

  const [tokenForOTP, setTokenForOTP] = useState("")

  const [isPasswordNew, setIsPasswordNew] = useState(true)
  const [isPasswordConfirm, setIsPasswordConfirm] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [showSwalError, setShowSwalError] = useState(false)
  const [swalErrorMsg, setSwalErrorMsg] = useState("")

  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is a required field."),
  })

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      email: "",
    },
    validateOnBlur: false,
  })

  const {
    setFieldValue,
    setValues,
    values: { email },
  } = formik

  const handleValidSubmit = values => {
    setIsLoading(true)

    const Data = {
      email: email,
    }
    // console.log("OTP", Data)
    post(Post_SendOTPForForgotPassword, Data)
      .then(response => {
        if (response?.status === 200) {
          setMode(1)
          //console.log("OTP", response)
          setTokenForOTP(response.data.token)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          // swal({
          //   title: "Error",
          //   // text: response.message.email,
          //   text: response?.message?.email[0],
          //   icon: "error",
          //   button: "OK",
          // })
          if (typeof response?.message == "string") {
            swal({
              title: "Error!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  const validationSchemaOTP = Yup.object().shape({})

  const formikOTP = useFormik({
    validationSchema: validationSchemaOTP,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitOTP(values, formikHelpers),
    initialValues: {
      OTP: "",
    },
    validateOnBlur: false,
  })

  const {
    values: { OTP },
  } = formikOTP

  const handleOnChangeOTP = event => {
    setIsLoading(false)
    //alert(event)
    //setOTP(event)
    formikOTP.setFieldValue("OTP", event)
    setValidationMsgOTP("")
  }

  const renderButton = buttonProps => {
    return (
      <button {...buttonProps} className="btn text-primary">
        {buttonProps.remainingTime !== 0
          ? `${buttonProps.remainingTime} sec to Resend`
          : "Resend"}
      </button>
    )
  }
  const renderTime = () => React.Fragment

  const validateEmpty = () => {
    setIsLoading(false)
    var flag = true

    if (OTP == null || OTP.length < 6) {
      setValidationMsgOTP("Please Enter OTP.")
      flag = false
    }
    // alert(OTP)

    return flag
  }

  const handleValidSubmitOTP = values => {
    setIsLoading(true)

    const Data = {
      token: tokenForOTP,
      email: email,
      otp: OTP,
    }

    if (!validateEmpty()) {
      return false
    } else {
      setValidationMsgOTP("")
      post(POST_VerifyOTP, Data)
        .then(response => {
          if (response?.status === 200) {
            setTokenForOTP(response.data.token)
            setMode(2)
            setIsLoading(false)
          } else {
            // console.log("otp", Data)
            setIsLoading(false)
            // swal({
            //   title: "Error",
            //   text: response?.message,
            //   //text: "Invalid OTP",
            //   icon: "error",
            //   button: "OK",
            // })
            if (typeof response?.message == "string") {
              swal({
                title: "Error!",
                text: response.message,
                icon: "error",
                button: "OK",
              })
            } else {
              setSwalErrorMsg(response?.message)
              setShowSwalError(true)
            }
          }
        })
        .catch(error => {
          console.log(error)
          setIsLoading(false)
        })
    }
  }

  const validationSchemaConfirmPassword = Yup.object().shape({
    Password: Yup.string().required("Password is a required field."),
    ConfirmPassword: Yup.string()
      .oneOf([Yup.ref("Password"), null], "Passwords must match")
      .required("Confirm Password is a required field."),
  })

  const formikConfirmPassword = useFormik({
    validationSchema: validationSchemaConfirmPassword,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitSetPassword(values, formikHelpers),
    initialValues: {
      Password: "",
      ConfirmPassword: "",
    },
    validateOnBlur: false,
  })

  const {
    values: { Password, ConfirmPassword },
  } = formikConfirmPassword

  const handleValidSubmitSetPassword = values => {
    setIsLoading(true)

    const Data = {
      password: Password,
      password_confirmation: ConfirmPassword,
      token: tokenForOTP,
      email: email,
    }

    post(POST_ForgotPassword, Data)
      .then(response => {
        if (response?.status === 200) {
          setIsLoading(false)
          swal("Success!", response?.message, "success")
          setTimeout(() => {
            navigate("/login")
          }, 2000)
        } else {
          setIsLoading(false)
          // swal({
          //   title: "Error",
          //   text: response?.message,
          //   //text: "Invalid OTP",
          //   icon: "error",
          //   button: "OK",
          // })
          if (typeof response?.message == "string") {
            swal({
              title: "Error!",
              text: response.message,
              icon: "error",
              button: "OK",
            })
          } else {
            setSwalErrorMsg(response?.message)
            setShowSwalError(true)
          }
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password | Katlax OMS</title>
      </MetaTags>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div> */}
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="account-pages my-5 pt-3">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={4}>
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-2">
                      <h5 className="text-white font-size-20 mt-3">
                        Forget Password
                      </h5>
                      {/* <a href="index.html" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </a> */}
                      <p className="text-white-50">
                        {mode === 0
                          ? "Don't worry, just enter email and submit."
                          : mode === 1
                          ? "Enter OTP which is send to your email."
                          : mode === 2
                          ? "Set your new password."
                          : ""}
                      </p>
                    </div>
                  </div>
                  <CardBody className="p-3">
                    {props.forgetError && props.forgetError ? (
                      <Alert
                        color="danger"
                        style={{ marginTop: "13px" }}
                        className="mt-5"
                      >
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert
                        color="success"
                        style={{ marginTop: "13px" }}
                        className="mt-5"
                      >
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    {mode === 0 ? (
                      <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                          {props.error && typeof props.error === "string" ? (
                            <Alert color="danger">{props.error}</Alert>
                          ) : null}
                          <Row className="mt-4">
                            <Col lg={12}>
                              <Field
                                type="email"
                                label={"Email"}
                                name="email"
                                placeholder={"Enter Email"}
                                component={CustomInput}
                                maxLength={50}
                                icon={true}
                                iconClass={"mdi mdi-email"}
                                required
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col className="text-end">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Reset
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    ) : mode === 1 ? (
                      <FormikProvider value={formikOTP}>
                        <Form onSubmit={formikOTP.handleSubmit}>
                          {/* {props.error && typeof props.error === "string" ? (
                          <Alert color="danger">{props.error}</Alert>
                        ) : null} */}
                          <div>
                            <div
                              className="row branchOTP mt-4 mb-4"
                              style={{ textAlign: "center" }}
                            >
                              <OTPInput
                                style={{
                                  display: "inline",
                                  borderRadius: "5px",
                                }}
                                value={OTP}
                                onChange={handleOnChangeOTP}
                                autoFocus
                                OTPLength={6}
                                otpType="text"
                                disabled={false}
                                validate={{ required: { value: true } }}
                                required={true}
                              />
                              <span
                                style={{
                                  marginLeft: "-95px",
                                  color: "#ec4561",
                                  marginTop: "0.25rem",
                                  fontSize: "80%",
                                }}
                              >
                                {validationMsgOTP}
                              </span>
                            </div>
                          </div>
                          <Row className="mb-2">
                            <Col>
                              <ResendOTP
                                renderButton={renderButton}
                                renderTime={renderTime}
                                onResendClick={() => handleValidSubmit()}
                              />
                            </Col>
                            <Col
                              // style={{ textAlign: "left"}}
                              style={{ marginLeft: "10px" }}
                            >
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="Verify"
                              >
                                Verify
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    ) : mode === 2 ? (
                      <FormikProvider value={formikConfirmPassword}>
                        <Form
                          onSubmit={formikConfirmPassword.handleSubmit}
                          className="mt-4"
                        >
                          <div className="mb-2 ">
                            <Field
                              type={isPasswordNew ? "password" : "text"}
                              name="Password"
                              label="New Password"
                              placeholder="Enter New Password"
                              component={CustomInput}
                              icon={true}
                              iconClass={"mdi mdi-lock"}
                              passwordEye={true}
                              setIsPassword={setIsPasswordNew}
                              required
                            />
                          </div>
                          <div className="mb-2">
                            <Field
                              type={isPasswordConfirm ? "password" : "text"}
                              name="ConfirmPassword"
                              label="Confirm Password"
                              placeholder="Enter Confirm Password"
                              component={CustomInput}
                              icon={true}
                              iconClass={"mdi mdi-lock"}
                              passwordEye={true}
                              setIsPassword={setIsPasswordConfirm}
                              required
                            />
                          </div>
                          <Row className="mb-2">
                            <Col style={{ textAlign: "right" }}>
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Confirm
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    ) : (
                      ""
                    )}
                  </CardBody>
                </Card>
                <div className="mt-2 text-center">
                  <p>
                    Remember Password ?{" "}
                    <Link to="/login" className="fw-medium text-primary">
                      {" "}
                      Login{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
            <SwalError
              showError={showSwalError}
              setShowError={setShowSwalError}
              ErrorMsg={swalErrorMsg}
            ></SwalError>
          </Container>
        </div>
      </Spin>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)
