import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Form,
  Input,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CustomTable from "../../Custom/TableWithRemotePagination"
import * as Yup from "yup"
import { Field, FormikProvider, useFormik } from "formik"
import { GET_roles } from "helpers/url_helper"
import { get } from "helpers/api_helper"
import { Spin } from "antd"
import { Link } from "react-router-dom"

const Roles = props => {
  const navigate = useNavigate()
  const [totalSize, setTotalSize] = useState(0)
  const [roleID, setRoleID] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [listRoleData, setListRoleData] = useState([])
  const [search, setSearch] = useState("")

  const [currentPage, setCurrentPage] = useState(1)
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10)

  var Auth = JSON.parse(localStorage.getItem("authUser"))

  const columns = [
    {
      text: "Name",
      dataField: "Name",
      style: { minWidth: "200px" },
    },
    {
      text: "No. of User",
      dataField: "NoofUser",
      style: { minWidth: "100px" },
    },

    {
      text: "Actions",
      dataField: "Actions",
      style: { minWidth: "73px", maxWidth: "73px" },
    },
  ]

  const getData = (page = 1, sizePerPage = 10) => {
    setIsLoading(true)
    get(GET_roles + "?page=" + page + (search ? "&search=" + search : ""))
      .then(res => {
        //console.log("res", res)
        if (res?.data?.data?.length > 0) {
          setListRoleData(
            res.data.data.map((e, index) => {
              return {
                Name: e.role_name,
                NoofUser: e.users_count,
                Actions: (
                  <>
                    <div className="d-flex">
                      {Auth?.permissions?.filter(
                        x => x.resource_id == 1 && x.edit_per == 1
                      )?.length > 0 ? (
                        <Link
                          className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                          style={{
                            width: "25px",
                            height: "25px",
                            padding: "0px",
                          }}
                          to={`/edit-role/${e.id}`}
                          title="Edit"
                        >
                          <i
                            className="fas fa-edit"
                            style={{ color: "#17a98c" }}
                          ></i>
                        </Link>
                      ) : (
                        ""
                      )}
                      &nbsp;
                    </div>
                  </>
                ),
              }
            })
          )
          setTotalSize(res.data.total)
          setIsLoading(false)
        } else {
          setListRoleData([])
          setTotalSize(0)
          setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
    setCurrentPage(page)
    setCurrentSizePerPage(sizePerPage)
  }

  useEffect(() => {
    getData()
  }, [search])

  const validationSchemaFilter = Yup.object().shape({})

  const formikFilter = useFormik({
    validationSchema: validationSchemaFilter,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmitFilter(values, formikHelpers),
    initialValues: {
      // Search: "",
    },
    validateOnBlur: false,
  })

  const {} = formikFilter

  const handleValidSubmitFilter = values => {}

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{"Roles"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            <Row className="mt-3">
              <Col lg={6}>
                <div className="mt-2" style={{ fontSize: "large" }}>
                  <b>Roles</b>
                </div>
              </Col>
              <Col lg={6} style={{ textAlign: "end" }}>
                {Auth?.permissions?.filter(
                  x => x.resource_id == 1 && x.create_per == 1
                )?.length > 0 ? (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      navigate("/new-role")
                    }}
                  >
                    Add Role
                  </button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="col-12">
                <FormikProvider value={formikFilter}>
                  <Form onSubmit={formikFilter.handleSubmit}>
                    <Row>
                      <Col lg={12}>
                        <Row>
                          <Col lg={3} sm={6} md={3}>
                            <div class="form-group has-search">
                              <span class="fa fa-search form-control-feedback"></span>
                              <Input
                                type="text"
                                name="Search"
                                class="form-control"
                                value={search}
                                placeholder="Search"
                                onChange={e => {
                                  setSearch(e.target.value)
                                }}
                              />
                            </div>
                          </Col>

                          <Col
                            lg={2}
                            className="mt-1"
                            style={{ textAlign: "left" }}
                          >
                            <div
                              onClick={() => {
                                // formikFilter.setFieldValue("Search", "")
                                setSearch("")
                              }}
                            >
                              <i
                                className="mdi mdi-filter-remove"
                                style={{ fontSize: "20px", color: "#4a539f" }}
                              ></i>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </FormikProvider>
                <Card className="mt-3">
                  <CardBody className="table-fixed">
                    <CustomTable
                      keyField="Roles"
                      columns={columns}
                      data={listRoleData}
                      totalSize={totalSize}
                      getData={getData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Spin>
    </React.Fragment>
  )
}
Roles.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(Roles))
