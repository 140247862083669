import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Input,
  Collapse,
  Form,
  Label,
} from "reactstrap"
import { useNavigate, Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import CustomTable from "../../Custom/TableWithRemotePagination"
import * as Yup from "yup"
import { Field, FormikProvider, useFormik } from "formik"
import CustomSelect from "Custom/CustomSelect"
import { Spin } from "antd"
import { get } from "helpers/api_helper"
import {
  GET_ContactPOList,
  GET_ContactShipmentList,
  GET_ContactTypeVendor,
} from "helpers/url_helper"
import moment from "moment"

const Vendors = props => {
  const navigate = useNavigate()
  const [totalSize, setTotalSize] = useState(9)
  const [totalSizePuchaseOrder, setTotalSizePuchaseOrder] = useState(0)
  const [totalSizeShipment, setTotalSizeShipment] = useState(0)
  const [totalSizeBill, setTotalSizeBill] = useState(0)
  const [mode, setMode] = useState(0)
  const [colFilterPurchaseOrder, setColFilterPurchaseOrder] = useState(true)
  const [colFilterShipment, setColFilterShipment] = useState(true)
  const [colFilterBill, setColFilterBill] = useState(true)
  const [search, setSearch] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [vendorList, setVendorList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10)

  const [selectedID, setSelectedID] = useState(0)
  const [selectedCompanyName, setSelectedCompanyName] = useState("")
  const [poList, setPOList] = useState([])
  const [shipmentList, setShipmentList] = useState([])

  var Auth = JSON.parse(localStorage.getItem("authUser"))

  const validationSchema = Yup.object().shape({})

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) =>
      handleValidSubmit(values, formikHelpers),
    initialValues: {
      Status: "",
      Search: "",
      SearchByColumn: "",
    },
    validateOnBlur: false,
  })

  const {
    setFieldValue,
    values: { Search, Status, SearchByColumn },
  } = formik

  const handleValidSubmit = values => {}

  const columns = [
    {
      text: (
        <>
          <Input
            type="checkbox"
            className="form-check-input"
            id="Add"
            checked={false}
          />
          &nbsp; Company Name
        </>
      ),
      dataField: "CompanyName",
      style: { minWidth: "300px" },
      sort: true,
      onSort: (field, order) => {},
    },
    {
      text: "Email",
      dataField: "Email",
      style: { minWidth: "100px" },
      sort: true,
      onSort: (field, order) => {},
    },
    {
      text: "Phone",
      dataField: "Phone",
      style: { minWidth: "150px" },
    },
    {
      text: "Total PO",
      dataField: "TotalPO",
      style: { minWidth: "80px", textAlign: "center" },
    },
    {
      text: "PO Accepted",
      dataField: "POAccepted",
      style: { minWidth: "110px", textAlign: "center" },
    },
    {
      text: "PO Shipped",
      dataField: "POShipped",
      style: { minWidth: "100px", textAlign: "center" },
    },
    {
      text: "PO Received",
      dataField: "POReceived",
      style: { minWidth: "110px", textAlign: "center" },
    },
    {
      text: <>&nbsp;&nbsp;&nbsp;&nbsp;Payable</>,
      dataField: "Payable",
      style: { minWidth: "120px", textAlign: "right", paddingRight: "20px" },
    },
    {
      text: "Status",
      dataField: "Status",
      style: { minWidth: "100px", textAlign: "center" },
    },
    {
      text: "Created At",
      dataField: "CreatedAt",
      style: { minWidth: "250px" },
      sort: true,
      onSort: (field, order) => {},
    },
    {
      text: "Actions",
      dataField: "Actions",
      style: { maxWidth: "73px", minWidth: "73px" },
    },
  ]

  const columnsPuchaseOrder = [
    {
      text: "PO No.",
      dataField: "PONo",
      // style: { minWidth: "100px" },
    },
    {
      //text: "Ref No.",
      text: "Reference",
      dataField: "RefNo",
      // style: { minWidth: "150px" },
    },
    {
      text: "PO Date",
      dataField: "PODate",
      // style: { minWidth: "120px", textAlignLast: "center" },
    },
    {
      text: "Amount",
      dataField: "Amount",
      // style: { minWidth: "120px", textAlignLast: "center" },
    },
    {
      text: "Status",
      dataField: "Status",
      // style: { minWidth: "100px" },
    },
  ]

  // const rowsPuchaseOrder = [
  //   // {
  //   //   PONo: "deeppatel2@gmail.com",
  //   //   RefNo: "+91 9685741234",
  //   //   PODate: "0.00",
  //   //   Amount: "0.00",
  //   //   Status: "0.00",
  //   //   POReceived: "0.00",
  //   // },
  // ]

  const columnsShipment = [
    {
      text: "Shipment Date",
      dataField: "ShipmentDate",
      // style: { minWidth: "100px" },
    },
    {
      text: "Shipment No.",
      dataField: "ShipmentNo",
      // style: { minWidth: "150px" },
    },
    // {
    //   text: "Ref PO No.",
    //   dataField: "RefPONo",
    //   // style: { minWidth: "120px", textAlignLast: "center" },
    // },
    {
      text: "Tracking No.",
      dataField: "TrackingNo",
      // style: { minWidth: "120px", textAlignLast: "center" },
    },
    {
      text: "Carrier",
      dataField: "Carrier",
    },
    {
      text: "Status",
      dataField: "Status",
      // style: { minWidth: "100px" },
    },
  ]

  // const rowsShipment = [
  //   // {
  //   //   ShipmentDate: "deeppatel2@gmail.com",
  //   //   ShipmentNo: "+91 9685741234",
  //   //   RefPONo: "0.00",
  //   //   TrackingNo: "0.00",
  //   //   Carrier: "0.00",
  //   //   Status: "0.00",
  //   // },
  // ]

  const columnsBill = [
    {
      text: "Bill Date",
      dataField: "BillDate",
      // style: { minWidth: "100px" },
    },
    {
      text: "Bill No.",
      dataField: "BillNo",
      // style: { minWidth: "150px" },
    },
    {
      text: "PO No.",
      dataField: "PONo",
      // style: { minWidth: "120px", textAlignLast: "center" },
    },
    {
      text: "Amount",
      dataField: "Amount",
      // style: { minWidth: "120px", textAlignLast: "center" },
    },
    {
      text: "Status",
      dataField: "Status",
      // style: { minWidth: "100px" },
    },
  ]

  const rowsBill = [
    // {
    //   BillDate: "deeppatel2@gmail.com",
    //   BillNo: "+91 9685741234",
    //   PONo: "0.00",
    //   Amount: "0.00",
    //   Status: "0.00",
    // },
  ]

  const getData = (page = 1, sizePerPage = 10) => {
    //alert(Status)
    setIsLoading(true)
    get(
      GET_ContactTypeVendor +
        "&paginate=" +
        sizePerPage +
        "&page=" +
        page +
        (Search ? "&search=" + Search : "") +
        (SearchByColumn ? "&column_filter=" + SearchByColumn : "") +
        (Status ? "&status_filter=" + Status : "")
    )
      .then(res => {
        //console.log(res)
        if (res.status === 200) {
          if (res.data?.data?.length > 0) {
            setVendorList(
              res.data?.data?.map((e, index) => {
                return {
                  contact_id: e.contact_id,
                  company_name: e.company_name,
                  CompanyName: (
                    <>
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="Add"
                        checked={false}
                      />{" "}
                      &nbsp;
                      {Auth?.permissions?.filter(
                        x => x.resource_id == 3 && x.view_per == 1
                      )?.length > 0 ? (
                        <Link
                          to="#"
                          onClick={() => {
                            setSelectedID(e.contact_id)
                            setSelectedCompanyName(e.company_name)
                            setMode(1)
                          }}
                        >
                          {e.company_name}
                        </Link>
                      ) : (
                        e.company_name
                      )}
                    </>
                  ),
                  Email: e.email,
                  Phone: e.phone,
                  TotalPO: e.total_po,
                  POAccepted: e.po_accepted,
                  POShipped: e.po_shipped,
                  POReceived: e.po_received,
                  Payable: e.payable
                    ? parseFloat(e.payable).toFixed(2)
                    : "0.00",
                  Status: (
                    <>
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          title={e.status}
                          checked={e.status === "active" ? true : false}
                        ></input>
                      </div>
                    </>
                  ),
                  CreatedAt:
                    e.user.first_name +
                    " " +
                    e.user.last_name +
                    " : " +
                    `${moment(e.created_at)
                      .format("DD-MMM-YYYY")
                      .replace(/^\-/, "")}`,
                  // CreatedAt: "Hitesh Vaghela : 22-May-25",
                  Actions: (
                    <>
                      <div className="d-flex">
                        {Auth?.permissions?.filter(
                          x => x.resource_id == 3 && x.edit_per == 1
                        )?.length > 0 ? (
                          <Link
                            className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                            style={{
                              width: "22px",
                              height: "22px",
                              padding: "0px",
                            }}
                            to={`/edit-vendor/${e.contact_id}`}
                            title="Edit"
                          >
                            <i
                              className="fas fa-edit"
                              style={{ color: "#17a98c" }}
                            ></i>
                          </Link>
                        ) : (
                          ""
                        )}
                        &nbsp;
                        {Auth?.permissions?.filter(
                          x => x.resource_id == 3 && x.delete_per == 1
                        )?.length > 0 ? (
                          <Button
                            className="btn btn-secondary waves-effect waves-light btn btn-secondary"
                            style={{
                              width: "22px",
                              height: "22px",
                              padding: "0px",
                            }}
                            title="Delete"
                          >
                            <i
                              className="fas fa-trash-alt"
                              style={{ color: "red" }}
                            ></i>
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ),
                }
              })
            )
            setTotalSize(res.data.total)
            setIsLoading(false)
          }
        } else {
          setVendorList([])
          setTotalSize(0)
          setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
    setCurrentPage(page)
    setCurrentSizePerPage(sizePerPage)
  }

  useEffect(() => {
    getData()
  }, [Search, SearchByColumn, Status])

  const searchByOptions = [
    { label: "Company Name", value: "company_name" },
    { label: "Phone", value: "phone" },
    { label: "Email", value: "email" },
    //{ label: "Created At", value: "created at" },
  ]

  const statusOption = [
    // { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ]

  const getContactPOList = (page = 1, sizePerPage = 10) => {
    setIsLoading(true)
    get(
      GET_ContactPOList +
        selectedID +
        "?page=" +
        page +
        "&paginate=" +
        sizePerPage
    )
      .then(response => {
        if (response?.status == 200) {
          setPOList(
            response?.data?.data?.map(x => {
              return {
                PONo: x.purchase_order_no,
                RefNo: x.reference_estimate,
                PODate: x.purchase_order_date
                  ? moment(x.purchase_order_date)
                      .format("DD-MMM-YYYY")
                      .replace(/^\-/, "")
                  : "",
                Amount: x.total ? parseFloat(x.total).toFixed(2) : "0.00",
                Status: x.po_status,
              }
            })
          )
          setTotalSizePuchaseOrder(response?.data?.total)
        } else {
          setPOList([])
          setTotalSizePuchaseOrder(0)
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  const getContactShipmentList = (page = 1, sizePerPage = 10) => {
    setIsLoading(true)
    get(
      GET_ContactShipmentList +
        selectedID +
        "?page=" +
        page +
        "&paginate=" +
        sizePerPage
    )
      .then(response => {
        if (response?.status == 200) {
          setShipmentList(
            response?.data?.data?.map(x => {
              return {
                ShipmentDate: x.shipment_date
                  ? moment(x.shipment_date)
                      .format("DD-MMM-YYYY")
                      .replace(/^\-/, "")
                  : "",
                ShipmentNo: x.shipment_number,
                TrackingNo: x.tracking_no,
                Carrier: x.shipment_carrier,
                Status: x.status,
              }
            })
          )
          setTotalSizeShipment(response?.data?.total)
        } else {
          setShipmentList([])
          setTotalSizeShipment(0)
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (selectedID > 0) {
      getContactPOList()
      getContactShipmentList()
    } else {
      setPOList([])
      setShipmentList([])
      setTotalSizePuchaseOrder(0)
      setTotalSizeShipment(0)
    }
  }, [selectedID])

  return (
    <React.Fragment>
      <Spin size="large" spinning={isLoading} tip={"Loading..."}>
        <div className="page-content">
          <MetaTags>
            <title>{"Vendors"} | Katlax OMS</title>
          </MetaTags>

          <Container fluid>
            {mode === 0 ? (
              <>
                <Row className="mt-3">
                  <Col lg={6}>
                    <div className="mt-2" style={{ fontSize: "large" }}>
                      <b>Vendors</b>
                    </div>
                  </Col>
                  <Col lg={6} style={{ textAlign: "end" }}>
                    {Auth?.permissions?.filter(
                      x => x.resource_id == 3 && x.create_per == 1
                    )?.length > 0 ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          navigate("/add-vendor")
                        }}
                      >
                        Add Vendor
                      </button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="col-12">
                    <FormikProvider value={formik}>
                      <Form onSubmit={formik.handleSubmit}>
                        <Row>
                          <Col lg={12}>
                            <Row>
                              <Col lg={3} sm={6} md={3}>
                                <div class="form-group has-search">
                                  <span class="fa fa-search form-control-feedback"></span>
                                  <Input
                                    type="text"
                                    name="Search"
                                    class="form-control"
                                    value={Search}
                                    placeholder="Search"
                                    onChange={e => {
                                      //setSearch(e.target.value)
                                      formik.setFieldValue(
                                        "Search",
                                        e.target.value
                                      )
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg={2} sm={6} md={2}>
                                <Field
                                  name="SearchByColumn"
                                  placeholder={"By Column"}
                                  component={CustomSelect}
                                  options={searchByOptions}
                                />
                              </Col>
                              <Col lg={2} md={2}>
                                <Field
                                  name="Status"
                                  placeholder={"Status"}
                                  component={CustomSelect}
                                  options={statusOption}
                                />{" "}
                              </Col>
                              <Col
                                lg={2}
                                className="mt-2"
                                style={{ textAlign: "left" }}
                              >
                                <div
                                  onClick={() => {
                                    // setFieldValue("Status", "")
                                    // setSearch(""),
                                    //   setFieldValue("SearchByColumn", "")
                                    formik.setFieldValue("Status", "")
                                    formik.setFieldValue("Search", "")
                                    formik.setFieldValue("SearchByColumn", "")
                                  }}
                                >
                                  <i
                                    className="mdi mdi-filter-remove"
                                    style={{
                                      fontSize: "20px",
                                      color: "#4a539f",
                                    }}
                                  ></i>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </FormikProvider>
                    <Card>
                      <CardBody className="table-fixed">
                        <CustomTable
                          keyField="Vendor"
                          columns={columns}
                          data={vendorList}
                          totalSize={totalSize}
                          getData={getData}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    style={{
                      // minHeight: "500px",
                      height: "100%",
                    }}
                  >
                    <Row style={{ marginLeft: "-25px" }}>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{ marginTop: "20px", marginBottom: "6px" }}
                      >
                        <div className="d-flex">
                          <div style={{ width: "400px" }}>
                            <b>Active Vendor</b>
                          </div>
                          <div style={{ textAlign: "right", width: "100%" }}>
                            {Auth?.permissions?.filter(
                              x => x.resource_id == 3 && x.create_per == 1
                            )?.length > 0 ? (
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  navigate("/add-vendor")
                                }}
                                style={{
                                  padding: "0.05rem 0.5rem",
                                }}
                              >
                                +
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        style={{
                          borderTop: "1px solid #cacaca",
                        }}
                      >
                        <Row style={{ marginBottom: "5px", marginTop: "5px" }}>
                          {/* <Col lg={12} md={12} sm={12}>
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="check"
                              checked={false}
                            />{" "}
                            &nbsp;
                            <Link to="#">Test Company </Link>
                            <br></br>
                            <tex
                              style={{ fontSize: "12px", marginLeft: "22px" }}
                            >
                              Rs. 500
                            </tex>
                          </Col> */}
                          {vendorList?.map(x => (
                            <>
                              <Col
                                lg={12}
                                md={12}
                                sm={12}
                                style={{
                                  background:
                                    x?.contact_id == selectedID
                                      ? "#f0f0f0"
                                      : "",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="check"
                                  checked={false}
                                />{" "}
                                &nbsp;
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setSelectedID(x.contact_id)
                                    setSelectedCompanyName(x?.company_name)
                                  }}
                                >
                                  {x?.company_name}
                                </Link>
                                <br></br>
                                <text
                                  style={{
                                    fontSize: "12px",
                                    marginLeft: "22px",
                                  }}
                                >
                                  Rs.{" "}
                                  {x.Payable
                                    ? parseFloat(x.Payable).toFixed(2)
                                    : "0.00"}
                                </text>
                              </Col>
                              <hr className="mb-0 mt-0"></hr>
                            </>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    lg={10}
                    md={10}
                    sm={10}
                    style={{ borderLeft: "1px solid #cacaca" }}
                  >
                    <Row>
                      <Col
                        lg={12}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <div style={{ fontSize: "20px", color: "black" }}>
                          {selectedCompanyName}
                        </div>
                        <div style={{ textAlignLast: "end" }}>
                          {Auth?.permissions?.filter(
                            x => x.resource_id == 3 && x.edit_per == 1
                          )?.length > 0 ? (
                            <Link
                              to={`/edit-vendor/${selectedID}`}
                              className="btn btn-sm  btn-light"
                              style={{ marginTop: "-50px" }}
                            >
                              Edit
                            </Link>
                          ) : (
                            ""
                          )}
                          <button
                            type="button"
                            className="btn"
                            style={{
                              marginTop: "-50px",
                              fontSize: "20px",
                              position: "sticky",
                              border: 0,
                            }}
                            onClick={() => {
                              setSelectedID(0)
                              setSelectedCompanyName("")
                              setMode(0)
                            }}
                          >
                            <span>×</span>
                          </button>
                        </div>
                      </Col>
                      <Col lg={12} style={{ borderTop: "1px solid #cacaca" }}>
                        <div
                          className="accordion mt-3"
                          id="accordion"
                          style={{ marginLeft: "-11px", marginRight: "-11px" }}
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="colFilterPurchaseOrder"
                            >
                              <button
                                className={`accordion-button fw-medium ${
                                  !colFilterPurchaseOrder ? "collapsed" : ""
                                }`}
                                type="button"
                                onClick={() => {
                                  setColFilterPurchaseOrder(
                                    !colFilterPurchaseOrder
                                  )
                                }}
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#f8f9fa",
                                }}
                              >
                                <i class="fas fa-genderless"></i>&nbsp;&nbsp;
                                {"Purchase Order"}
                              </button>
                            </h2>

                            <Collapse
                              isOpen={colFilterPurchaseOrder}
                              className="accordion-collapse"
                            >
                              <div
                                className="accordion-body"
                                style={{
                                  backgroundColor: "#f8f9fa",
                                }}
                              >
                                <div className="text-muted">
                                  <Card>
                                    <CardBody style={{ marginBottom: "-40px" }}>
                                      <Row>
                                        <Col style={{ textAlign: "end" }}>
                                          {Auth?.permissions?.filter(
                                            x =>
                                              x.resource_id == 5 &&
                                              x.create_per == 1
                                          )?.length > 0 ? (
                                            <Link to="/add-purchase-order">
                                              + Add New
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </Col>
                                      </Row>
                                      <CustomTable
                                        keyField="PuchaseOrder"
                                        columns={columnsPuchaseOrder}
                                        data={poList}
                                        totalSize={totalSizePuchaseOrder}
                                        getData={getContactPOList}
                                      />
                                    </CardBody>
                                  </Card>
                                </div>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div
                          className="accordion mt-3"
                          id="accordion"
                          style={{ marginLeft: "-11px", marginRight: "-11px" }}
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="colFilterShipment"
                            >
                              <button
                                className={`accordion-button fw-medium ${
                                  !colFilterShipment ? "collapsed" : ""
                                }`}
                                type="button"
                                onClick={() => {
                                  setColFilterShipment(!colFilterShipment)
                                }}
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#f8f9fa",
                                }}
                              >
                                <i class="fas fa-genderless"></i>&nbsp;&nbsp;
                                {"Shipment"}
                              </button>
                            </h2>
                            <Collapse
                              isOpen={colFilterShipment}
                              className="accordion-collapse"
                            >
                              <div
                                className="accordion-body"
                                style={{
                                  backgroundColor: "#f8f9fa",
                                }}
                              >
                                <div className="text-muted">
                                  <Card>
                                    <CardBody style={{ marginBottom: "-40px" }}>
                                      <CustomTable
                                        keyField="Shipment"
                                        columns={columnsShipment}
                                        data={shipmentList}
                                        totalSize={totalSizeShipment}
                                        getData={getContactShipmentList}
                                      />
                                    </CardBody>
                                  </Card>
                                </div>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div
                          className="accordion mt-3"
                          id="accordion"
                          style={{ marginLeft: "-11px", marginRight: "-11px" }}
                        >
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="colFilterBill">
                              <button
                                className={`accordion-button fw-medium ${
                                  !colFilterBill ? "collapsed" : ""
                                }`}
                                type="button"
                                onClick={() => {
                                  setColFilterBill(!colFilterBill)
                                }}
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#f8f9fa",
                                }}
                              >
                                <i class="fas fa-genderless"></i>&nbsp;&nbsp;
                                {"Bill"}
                              </button>
                            </h2>
                            <Collapse
                              isOpen={colFilterBill}
                              className="accordion-collapse"
                            >
                              <div
                                className="accordion-body"
                                style={{
                                  backgroundColor: "#f8f9fa",
                                }}
                              >
                                <div className="text-muted">
                                  <Card>
                                    <CardBody style={{ marginBottom: "-40px" }}>
                                      <CustomTable
                                        keyField="Bill"
                                        columns={columnsBill}
                                        data={rowsBill}
                                        totalSize={totalSizeBill}
                                        getData={getData}
                                      />
                                    </CardBody>
                                  </Card>
                                </div>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </div>
      </Spin>
    </React.Fragment>
  )
}
Vendors.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Vendors))
